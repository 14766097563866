import { styled } from '@mui/material'

export const StyledManagerSupportContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.common.white,
  fontSize: theme.fontSize(1.75), // 14px
  lineHeight: 1.7142, // 24px
  marginTop: theme.spacing(2.5)
}))

export const StyledManagerSupportLink = styled('a')(({ theme }) => ({
  color: theme.palette.colors.yellow,
  margin: theme.spacing(0, 0.5),
  fontWeight: 'bold',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.colors.purple
  }
}))
