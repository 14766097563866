import { ButtonTypeMap, ExtendButtonBase } from '@mui/material'

import { ReactElement } from 'react'

import {
  StyledErrorPage,
  StyledErrorPageIcon,
  StyledErrorPageTitle,
  StyledErrorPageDescription,
  StyledErrorPageButton,
  StyledErrorPageCTAContainer
} from './styles'

interface ErrorPageDefaultProps {
  title: string
  icon?: ReactElement
  description: string
}

interface ErrorPageRequiredCTAProps extends ErrorPageDefaultProps {
  cta: JSX.Element // Make this field required and below optional
  ctaButtonProps?:
    | ExtendButtonBase<ButtonTypeMap<Record<string, unknown>, 'button'>>
    | Record<string, unknown>
  ctaButtonText?: string
}

interface ErrorPageOptionalCTAProps extends ErrorPageDefaultProps {
  cta?: JSX.Element // Make this field optional
  ctaButtonProps?:
    | ExtendButtonBase<ButtonTypeMap<Record<string, unknown>, 'button'>>
    | Record<string, unknown>
  ctaButtonText: string
}

// When cta is present, the other options should be optional and vice versa
export type ErrorPageProps = ErrorPageRequiredCTAProps | ErrorPageOptionalCTAProps

export default function ErrorPage({
  cta,
  ctaButtonProps = {},
  ctaButtonText,
  description,
  icon,
  title
}: ErrorPageProps) {
  return (
    <StyledErrorPage hideFooter hideHeader>
      {icon || <StyledErrorPageIcon />}
      <StyledErrorPageTitle variant="h1">{title}</StyledErrorPageTitle>
      <StyledErrorPageDescription variant="body1">
        {description}
      </StyledErrorPageDescription>
      {cta ? (
        <StyledErrorPageCTAContainer>{cta}</StyledErrorPageCTAContainer>
      ) : (
        <StyledErrorPageButton variant="outlined" {...ctaButtonProps}>
          {ctaButtonText}
        </StyledErrorPageButton>
      )}
    </StyledErrorPage>
  )
}
