import { CSSProperties } from 'react'

import { BrandThemeOptions } from './Brand'
import { OnarollColor } from './Colors'
import { OnarollColorPalette } from './OnarollColorPalette'

export const getDefaultColor = (
  newColor: CSSProperties['color'],
  defaultColor: OnarollColor
) => {
  return newColor || defaultColor
}

export const getBrandThemeOptions = (brandPalette?: BrandThemeOptions) => {
  if (!brandPalette) {
    return OnarollColorPalette
  }
  const cleanBrandPalette = Object.fromEntries(
    Object.entries(brandPalette).filter(([_, val]) => val && val !== '')
  )
  return {
    ...OnarollColorPalette,
    ...cleanBrandPalette
  }
}
