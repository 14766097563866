import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  suggestionsContainer: () => ({
    position: 'absolute',
    zIndex: 99999,
    width: '50%',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }),
  containerClass: { flex: 1, minWidth: '200px' }
}))
