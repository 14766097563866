import { isEmpty } from 'lodash'

import { Grid } from '@mui/material'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { TeamMemberV2 } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import { AdminContainer } from 'components/AdminContainer'
import { FeatureFlag, FeatureFlags } from 'components/FeatureFlag'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'

import { useNavigation } from 'hooks/useNavigation'

import {
  fetchTeamMemberThunk,
  getTeamMembersLoading,
  teamMemberSelectors
} from 'slices/admin'
import { updateEmploymentThunk } from 'slices/admin/employments'
import { setSnackbar } from 'slices/snackbar'
import { getWhoami } from 'slices/whoami'

import { TeamLeaderPaths, formatPhoneNumber } from 'utils/helpers'
import { UrlParamTypes } from 'utils/url'

import {
  StyledAvatarIcon,
  StyledData,
  StyledDataLabel,
  StyledEditButton,
  StyledTitle
} from './styles'

import { RootState } from 'reduxStore'

export default function TeamMemberView() {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const whoami = useSelector(getWhoami)

  const { id: memberIdFromParams } = useParams<UrlParamTypes>()

  const selectedTeamMember = useSelector((state: RootState) =>
    teamMemberSelectors.selectById(state, memberIdFromParams as string)
  )

  const isTeamMembersLoading = useSelector(getTeamMembersLoading)

  useEffect(() => {
    const pk = memberIdFromParams as string
    const thunk = fetchTeamMemberThunk({ pk })
    dispatch(thunk)
  }, [memberIdFromParams])

  const goToForm = () => {
    if (selectedTeamMember) {
      const base = TeamLeaderPaths.teamMembers
      const path = `${base}/${selectedTeamMember.id}/edit`
      navigation.push(path)
    }
  }

  const terminateWorkerProfile = async (employment_id: string) => {
    const base = TeamLeaderPaths.teamMembers

    if (selectedTeamMember) {
      const data = {
        termination_date: new Date().toISOString()
      }
      if (confirm(`Are you sure you want to terminate this worker?`)) {
        await dispatch(updateEmploymentThunk({ id: employment_id, data }))
        dispatch(
          setSnackbar({
            open: true,
            type: 'info',
            message: `Worker
              ${selectedTeamMember.id} Employment is terminated and Worker profile is now inactive.`
          })
        )
      }
      navigation.push(base)
    }
  }

  const getValues = () => {
    if (!selectedTeamMember) return null
    const {
      full_name = '',
      phone,
      birthday,
      roles,
      shifts_worked
    } = selectedTeamMember as TeamMemberV2

    const crudButtons = (
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <StyledEditButton
            variant="contained"
            color="primary"
            onClick={goToForm}
            fullWidth
          >
            Edit
          </StyledEditButton>
        </Grid>
        {whoami?.is_onaroll_staff &&
          selectedTeamMember?.employments?.map((employment) => (
            <Grid item xs={12} key={employment.id}>
              <StyledEditButton
                variant="contained"
                color="error"
                disabled={!!employment.termination_date}
                onClick={() => terminateWorkerProfile(employment.id)}
                fullWidth
              >
                Terminate Employment at {employment.store_name}
              </StyledEditButton>
            </Grid>
          ))}
      </Grid>
    )

    return (
      <Grid container justifyContent="center" style={{ display: 'grid' }}>
        <Grid container direction="row" alignItems="center" spacing={1} sx={{ mb: 4 }}>
          <Grid item xs="auto" sx={{ mr: 2 }}>
            <StyledAvatarIcon active={!isEmpty(selectedTeamMember?.onboarded_at)} />
          </Grid>
          <Grid item xs="auto">
            <StyledTitle>{full_name}</StyledTitle>
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={6}>
            <StyledDataLabel variant="body2">Phone Number</StyledDataLabel>
            <StyledData variant="body1">
              <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
            </StyledData>
          </Grid>

          <Grid item xs={6}>
            <StyledDataLabel variant="body2">Birthday</StyledDataLabel>
            <StyledData variant="body1">{birthday ?? 'N/A'}</StyledData>
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={6}>
            <StyledDataLabel variant="body2">Roles</StyledDataLabel>
            <StyledData variant="body1">
              {roles?.length ? roles.join(', ') : 'N/A'}
            </StyledData>
          </Grid>

          <Grid item xs={6}>
            <StyledDataLabel variant="body2">Shifts Worked</StyledDataLabel>
            <StyledData variant="body1">{shifts_worked ?? 'N/A'}</StyledData>
          </Grid>
        </Grid>
        <FeatureFlag flag={FeatureFlags.allowTlxEditAccess}>{crudButtons}</FeatureFlag>
      </Grid>
    )
  }

  const getContent = (
    <AdminContainer
      headerTitle="Profile"
      containerProps={{
        themeType: 'neutral',
        showBackButton: true,
        backButtonProps: {
          onBack: () =>
            navigation.push({
              pathname: TeamLeaderPaths.teamMembers,
              state: {
                from: location.pathname
              }
            })
        }
      }}
    >
      {getValues()}
    </AdminContainer>
  )

  return isTeamMembersLoading ? <HeartLoadingIndicator fullPage /> : getContent
}
