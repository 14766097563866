import { LOGIN_HELP_URL } from './consts'
import {
  StyledLoginHelpTitle,
  StyledLoginHelpLink
} from './loginHelp.styles'
import { useTranslation } from 'react-i18next'

export function LoginHelp() {
  const { t } = useTranslation()

  return (
    <>
      <StyledLoginHelpTitle>{t('pages.login.form.otp.loginHelp.text')}</StyledLoginHelpTitle>        
      <StyledLoginHelpLink
        target="_blank"
        rel="noreferrer"
        href={LOGIN_HELP_URL}
      >
        {t('pages.login.form.otp.loginHelp.linkText')}
      </StyledLoginHelpLink>
    </>
  )
}

export default LoginHelp
