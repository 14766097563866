import { Typography } from '@mui/material'

import { CSSProperties } from 'react'

import { CSVSchema } from 'types'

import GoalIcon, { GoalIconTypes } from 'components/icons/GoalIcon/GoalIcon'
import RowItem from 'components/rowItem'

import { Colors } from 'theme'

import { ItemSubHeading } from '../shared/styles'

interface CSVSchemaItemProps {
  item: CSVSchema
  onSelectItem: (key: CSVSchema) => void
  style: CSSProperties
}

export default function CSVSchemaItem({ item, onSelectItem, style }: CSVSchemaItemProps) {
  const { name, store_group } = item

  const content = (
    <>
      <Typography variant="h4">{name}</Typography>
      <ItemSubHeading variant="subtitle2">{store_group}</ItemSubHeading>
    </>
  )

  return (
    <RowItem
      item={item}
      image={<GoalIcon type={GoalIconTypes.worker} color={Colors.gray} />}
      content={content}
      onClick={onSelectItem}
      style={style}
    />
  )
}
