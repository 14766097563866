// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Children, ReactElement, useEffect, useState } from 'react'

import { ReactComponent as NavArrowLeftIcon } from 'assets/icons/AppIcons/NavArrowLeftIcon.svg'
import { ReactComponent as NavArrowRightIcon } from 'assets/icons/AppIcons/NavArrowRightIcon.svg'

import { NavIndexDots } from 'components/NavigationIndexDots'

import { StyledCarousel, StyledCarouselButtons, StyledCarouselIconButton } from './styles'

export interface CarouselProps {
  children: ReactElement[]
  onSwipe?: (pageIndex: number) => void
  showNavButtons?: boolean
  itemMaxWidth?: string
  spaceBetweenItems?: string
}

export function Carousel({
  children,
  itemMaxWidth = '300px',
  spaceBetweenItems = '0',
  onSwipe,
  showNavButtons
}: CarouselProps) {
  const amountOfItems = Children.count(children)

  const [pageIndex, setPageIndex] = useState(0)

  const lastIndex = amountOfItems - 1

  useEffect(() => {
    if (children.length <= pageIndex) {
      setPageIndex(children.length - 1)
    }
  }, [children.length])

  const onBack = () => {
    // set lower boundary
    setPageIndex((prevState) => (prevState === 0 ? 0 : prevState - 1))
  }

  const onNext = () => {
    // set upper boundary
    setPageIndex((prevState) => (prevState === lastIndex ? lastIndex : prevState + 1))
  }

  const onChangeIndex = (swipedPageIndex: number) => {
    setPageIndex(swipedPageIndex)
    onSwipe?.(swipedPageIndex)
  }

  return (
    <>
      <StyledCarousel
        enableMouseEvents
        style={{ overflowX: 'revert' }}
        index={pageIndex}
        onChangeIndex={onChangeIndex}
        itemMaxWidth={itemMaxWidth}
        amountOfItems={amountOfItems}
        data-testid="SwipeableCarousel"
        spaceBetween={spaceBetweenItems}
      >
        {children}
      </StyledCarousel>
      {showNavButtons && (
        <StyledCarouselButtons itemMaxWidth={itemMaxWidth} amountOfItems={amountOfItems}>
          <StyledCarouselIconButton
            disabled={pageIndex <= 0}
            onClick={onBack}
            size="large"
          >
            <NavArrowLeftIcon />
          </StyledCarouselIconButton>
          <NavIndexDots
            numDots={children.length}
            activeIndex={pageIndex}
            dotSize={3}
            dotDistanceBetween={15}
          />
          <StyledCarouselIconButton
            disabled={pageIndex >= lastIndex}
            onClick={onNext}
            size="large"
          >
            <NavArrowRightIcon />
          </StyledCarouselIconButton>
        </StyledCarouselButtons>
      )}
    </>
  )
}

export default Carousel
