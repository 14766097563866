import { Colors } from 'theme'

import { useTranslation } from 'react-i18next'

import { BackButton } from 'components/BackButton'

import {
  StyledOnboardingHeaderButtonsWrapper,
  StyledOnboardingSkipButton,
  StyledOnboardingSkipText
} from './styles'

interface OnboardingHeaderButtonsProps {
  isInitialPage: boolean
  isPerkStep: boolean
  onBack: () => void
  onSkip: () => void
}

const OnboardingHeaderButtons = ({
  isPerkStep,
  isInitialPage,
  onBack,
  onSkip
}: OnboardingHeaderButtonsProps) => {
  const { t } = useTranslation()

  if (isInitialPage) return null
  return (
    <StyledOnboardingHeaderButtonsWrapper
      style={{
        justifyContent: isPerkStep ? 'space-between' : 'flex-start'
      }}
    >
      <BackButton
        onlyIcon
        onBack={onBack}
        color={Colors.white}
        backgroundColor={Colors.black}
      />
      {isPerkStep && (
        <StyledOnboardingSkipButton
          variant="text"
          onClick={onSkip}
          data-testid="OnboardingHeader-SkipButton"
        >
          <StyledOnboardingSkipText variant="body1">
            {t('pages.onboarding.buttonText.skip')}
          </StyledOnboardingSkipText>
        </StyledOnboardingSkipButton>
      )}
    </StyledOnboardingHeaderButtonsWrapper>
  )
}

export default OnboardingHeaderButtons
