import { styled, Typography } from '@mui/material'

import CircularProgressBar from 'components/circularProgressBar'

export const StyledTeamLeaderGoalsOverviewContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  paddingBlock: '16px'
})

export const StyledTeamLeaderGoalOverviewText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '18px'
})

export const StyledTeamLeaderGoalOverviewSubText = styled(Typography)({
  fontWeight: 'bold'
})

export const StyledTeamLeaderGoalOverviewCTAText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.branding.primaryColor
}))

export const StyledTeamLeaderGoalOverviewTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'initial'
})

export const StyledTeamLeaderOnboardingProgress = styled(CircularProgressBar)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '120px',
    width: '120px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& > text': {
      fontSize: '12px !important' // override svg style
    }
  })
)
