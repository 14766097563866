// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListChildComponentProps } from 'react-window'

import { ByStoreFilters, CSVSchema } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import { AdminContainer } from 'components/AdminContainer'
import InfiniteList from 'components/InfiniteList'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'

import { useNavigation } from 'hooks/useNavigation'
import useWindowDimensions from 'hooks/useWindowDimensions'

import {
  fetchAllCSVSchemasThunk,
  getCSVSchemasLoading,
  getSchemasResponse,
  selectCSVSchemas
} from 'slices/admin'

import { AdminPaths } from 'utils/helpers'

import { CreateButton } from '../shared/styles'

import StoreFilters from '../StoreFilters'
import CSVSchemaItem from './CSVSchemaItem'

export default function CSVSchemas() {
  const { height, width } = useWindowDimensions()
  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  useEffect(() => {
    dispatch(fetchAllCSVSchemasThunk({ isInitial: true }))
  }, [])

  const [storeFilters, setStoreFilters] = useState<ByStoreFilters>({
    store_id: '',
    group_id: ''
  })

  const schemaStateLoading = useSelector(getCSVSchemasLoading)
  const allCSVSchemasResponse = useSelector(getSchemasResponse)
  const allCSVSchemas = useSelector(selectCSVSchemas)

  if (!allCSVSchemas && schemaStateLoading) {
    // only show loading indicator if we have no data and is currently loading
    // since we dispatch multiple actions resulting in the loading state being toggled many times
    // when loading new items in the InfiniteList
    return <HeartLoadingIndicator fullPage />
  }

  if (!allCSVSchemasResponse) {
    return null
  }

  const onSelectFilter = (filters: ByStoreFilters) => {
    setStoreFilters(filters)
    dispatch(fetchAllCSVSchemasThunk({ isInitial: true, queryParams: filters }))
  }

  const goToForm = (csvSchema?: CSVSchema) => {
    const base = AdminPaths.manageCSV
    const path = `${base}/${csvSchema ? csvSchema.id : 'create'}`
    navigation.push(path)
  }

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const csvSchema = allCSVSchemas[index]
    if (!csvSchema?.id) return null
    style = { ...style }
    return (
      <CSVSchemaItem
        key={csvSchema.id}
        item={csvSchema}
        onSelectItem={goToForm}
        style={style}
      />
    )
  }

  const nextPage = allCSVSchemasResponse?.next
  const loadNextPage = () => dispatch(fetchAllCSVSchemasThunk())

  const { group_id, store_id } = storeFilters
  return (
    <>
      <CreateButton
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => goToForm()}
        top={height * 0.83}
        left={width - 140}
      >
        Create +
      </CreateButton>
      <AdminContainer
        headerTitle="Data"
        containerProps={{ themeType: 'orange' }}
        filters={<StoreFilters onSelect={onSelectFilter} />}
        search=""
      >
        <InfiniteList<CSVSchema>
          hasNextPage={!!nextPage}
          isNextPageLoading={schemaStateLoading}
          loadNextPage={loadNextPage}
          items={allCSVSchemas}
          renderRow={renderRow}
          disabled={!!(group_id && store_id)}
        />
      </AdminContainer>
    </>
  )
}
