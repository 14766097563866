import { Button, Card, Grid, styled, Typography } from '@mui/material'

import { ReactComponent as AvatarIcon } from 'assets/icons/AppIcons/AvatarIcon.svg'

import FloatingButton from 'components/floatingButton'
import RowItem from 'components/rowItem'

export const StyledViewDataContainer = styled('div')({
  width: 'min-content',
  flexGrow: 1
})

export const StyledDataLabel = styled(Typography)(({ theme }) => ({
  color: theme.branding.subTextColor,
  justifyContent: 'center'
}))

export const StyledData = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(4)
}))

export const StyledCenteredGrid = styled(Grid)(() => ({
  display: 'grid',
  justifyContent: 'center'
}))

export const StyledAvatarIcon = styled(AvatarIcon, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: boolean }>(({ active, theme }) => ({
  '&': {
    fill: active ? theme.branding.secondaryColor : theme.branding.inactiveColor
  }
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  margin: 0,
  ...theme.customTypography.display2
}))

export const StyledSummaryCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(5),
  borderRadius: theme.borderRadius.medium,
  '& > svg': {
    marginBottom: theme.spacing(2)
  },
  minHeight: '126px',
  textAlign: 'center'
}))

export const StyledColoredSummaryCard = styled(StyledSummaryCard, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color?: 'accent' | 'secondary' }>(({ color, theme }) => {
  const backgroundColor =
    color === 'accent'
      ? theme.branding.accentColor
      : color === 'secondary'
      ? theme.branding.secondaryColor
      : 'inherit'

  const textColor =
    color === 'accent'
      ? theme.palette.getContrastTextColor(theme.branding.accentColor)
      : color === 'secondary'
      ? theme.palette.getContrastTextColor(theme.branding.secondaryColor)
      : 'inherit'

  return {
    ...(color && {
      backgroundColor
    }),
    ...(color && {
      color: textColor
    }),
    ...(color && {
      '& svg > *': {
        stroke: textColor
      }
    })
  }
})

export const StyledPointsValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'highlight'
})<{ highlight?: boolean }>(({ highlight, theme }) => ({
  marginBottom: theme.spacing(2),
  ...(highlight && { color: theme.branding.primaryColor }),
  ...theme.customTypography.display3
}))

export const StyledSummaryText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'highlight' && prop !== 'bold'
})<{ highlight?: boolean; bold?: boolean }>(({ bold, highlight, theme }) => ({
  ...(highlight && { color: theme.branding.primaryColor }),
  ...(bold ? { ...theme.customTypography.h4Size } : { ...theme.customTypography.h5Size }),
  ...(bold && { fontWeight: theme.typography.fontWeightBold })
}))

export const StyledSummaryButtonText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'highlight'
})<{ highlight?: boolean }>(({ highlight, theme }) => ({
  backgroundColor: highlight ? theme.branding.secondaryColor : theme.branding.accentColor,
  fontWeight: theme.typography.fontWeightBold,
  ...theme.customTypography.h4Size
}))

export const StyledEditButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}))

export const StyledFloatingSendPointsButton = styled(FloatingButton)(({ theme }) => ({
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  width: '56px',
  height: '56px',
  borderRadius: theme.borderRadius.half
}))

export const StyledTeamMemberItem = styled(RowItem)(({ theme }) => ({
  padding: 0,
  '.RowItem__wrapper': {
    padding: theme.spacing(3)
  },
  '.RowItem__content': {
    flex: 2,
    '.MuiTypography-root:last-child': {
      fontSize: theme.fontSize(2.15)
    }
  },
  '.RowItem__extraInfo': {
    flex: 1,
    alignItems: 'flex-end',
    button: {
      borderRadius: theme.borderRadius.small
    },
    p: {
      color: theme.branding.subTextColor,
      fontSize: theme.fontSize(1.7)
    }
  }
}))
