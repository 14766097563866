// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Button,
  ButtonTypeMap,
  Card,
  CardTypeMap,
  styled,
  Typography,
  TypographyTypeMap
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/icons/AppIcons/ArrowIcon.svg'
import { ReactComponent as JackpotIcon } from 'assets/icons/AppIcons/JackpotIcon.svg'

const StyledTile = styled<
  OverridableComponent<
    CardTypeMap<{ component?: ElementType<HTMLAttributes<HTMLElement>> }, 'article'>
  >
>(Card)(({ theme }) => ({
  textTransform: 'capitalize',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flex: '1 1 0',
  '&:first-of-type': {
    marginRight: theme.spacing(2.5)
  },
  '&:not(:first-of-type):not(:last-child)': {
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(2.5)
  },
  '&:last-child': {
    marginLeft: theme.spacing(2.5)
  }
}))

export const StyledPrimaryTile = styled(StyledTile)(({ theme }) => ({
  padding: theme.spacing(5, 3)
}))

export const StyledSecondaryTile = styled(StyledTile)(({ theme }) => ({
  backgroundColor: theme.branding.secondaryColor,
  color: theme.palette.getContrastTextColor(theme.branding.secondaryColor),
  '& .MuiButton-root': {
    backgroundColor: theme.branding.accentColor,
    color: theme.palette.getContrastTextColor(theme.branding.accentColor)
  },
  '& svg > path': {
    fill: theme.palette.getContrastTextColor(theme.branding.secondaryColor)
  }
}))

export const StyledAccentTile = styled(StyledTile)(({ theme }) => ({
  backgroundColor: theme.branding.accentColor,
  color: theme.palette.getContrastTextColor(theme.branding.accentColor),
  '& .MuiButton-root': {
    backgroundColor: theme.branding.secondaryColor,
    color: theme.palette.getContrastTextColor(theme.branding.secondaryColor)
  },
  '& svg > path': {
    fill: theme.palette.getContrastTextColor(theme.branding.accentColor)
  }
}))

export const StyledCTATextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
})

export const StyledGoalsButtonWrapper = styled('div')(({ theme }) => ({
  gridColumn: 'span 2',
  margin: theme.spacing(3)
}))

export const StyledNotOnboardedTeamMemberText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px'
})

export const StyledGoalButton = styled(Button)({
  width: '100%',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px',
  gridColumn: 'span 2', // full width
  gridRow: 'span 1',
  height: '10px'
})

export const StyledTileDisplay = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: '56px',
  lineHeight: '56px',
  '& svg': {
    marginLeft: theme.spacing(1),
    width: '40px',
    height: '40px'
  },
  textAlign: 'center'
}))

export const StyledTileSubtitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 0, 3)
}))

export const StyledTileTitle = styled(StyledTileSubtitle)(({ theme }) => ({
  padding: theme.spacing(0),
  fontWeight: 'bold'
}))

export const StyledTileCTA = styled<OverridableComponent<ButtonTypeMap>>(Button)(
  ({ theme }) => ({
    backgroundColor: theme.branding.primaryColor,
    marginTop: theme.spacing(3),
    width: '100%'
  })
)

export const StyledTileCTAShort = styled(StyledTileCTA)(({ theme }) => ({
  // Perfect padding and width to not overflow text with spanish version
  padding: theme.spacing(1.6, 2.8),
  minWidth: '123px'
}))

export const StyledJackpotTileEmptyIcon = styled(JackpotIcon, {
  shouldForwardProp: (prop) => prop !== 'currentTickets'
})<{ currentTickets: number }>(({ currentTickets, theme }) => ({
  ...(!currentTickets && {
    width: '50px !important',
    height: '50px !important',
    marginTop: theme.spacing(2)
  })
}))

export const StyledOnboardingPerkLogo = styled('img')(({ theme }) => ({
  width: '56px',
  height: '56px',
  marginBottom: theme.spacing(3)
}))

export const StyledGoalsContainer = styled(StyledTile)({
  alignItems: 'stretch',
  justifyContent: 'center',
  padding: '0px',
  gridColumn: 'span 2' // full width
})

export const StyledGoalsArrowIcon = styled(ArrowIcon)(({ theme }) => ({
  width: '12px',
  marginLeft: theme.spacing(0.75),
  transform: 'rotate(180deg)'
}))
