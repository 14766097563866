import { Colors } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

export default function RowItemLoader(props: ComponentProps<typeof ContentLoader> = {}) {
  return (
    <ContentLoader
      width={310}
      height={78}
      style={{ width: '100%', ...(props?.style || {}) }}
      viewBox="0 0 310 78"
      backgroundColor={Colors.inactive}
      foregroundColor={Colors.blue}
      {...props}
    >
      {/* icon */}
      <rect x="10" y="11" rx="10" ry="10" width="50" height="50" />
      {/* text */}
      <rect x="75" y="21" rx="3" ry="3" width="100" height="10" />
      <rect x="75" y="41" rx="3" ry="3" width="150" height="10" />
      {/* right content */}
      <rect x="250" y="28" rx="3" ry="3" width="50" height="18" />
    </ContentLoader>
  )
}
