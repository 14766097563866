import clsx from 'clsx'

import { CSSProperties, ElementType, HTMLAttributes, ReactElement } from 'react'

import { onA11yKeyDown } from 'utils/helpers'

import {
  StyledRowItem,
  StyledRowItemContent,
  StyledRowItemDivider,
  StyledRowItemExtraInfo,
  StyledRowItemImage,
  StyledRowItemWrapper
} from './styles'

export interface RowItemProps<T> {
  onClick?: (item: T) => void
  component?: ElementType<HTMLAttributes<HTMLElement>>
  item: T
  image: ReactElement
  content: ReactElement
  header?: ReactElement
  extraInfo?: ReactElement
  children?: ReactElement
  className?: string
  imageClass?: string
  contentClass?: string
  extraInfoClass?: string
  wrapperClass?: string
  dividerClass?: string
  style?: CSSProperties
  showDivider?: boolean
  enableHighlight?: boolean
  locked?: boolean
}

export function RowItem<T>({
  onClick,
  component = 'li',
  item,
  className,
  imageClass,
  contentClass,
  extraInfoClass,
  wrapperClass,
  image,
  content,
  extraInfo,
  children,
  style = {},
  showDivider = false,
  dividerClass,
  enableHighlight = false,
  locked = false,
  header,
  ...otherProps
}: RowItemProps<T>) {
  return (
    <div style={style}>
      <StyledRowItem
        role="button"
        data-testid="RowItem"
        tabIndex={0}
        component={component}
        className={clsx('RowItem', className, enableHighlight && 'RowItem--highlight')}
        locked={locked}
        onClick={() => onClick?.(item)}
        onKeyDown={(event) => onA11yKeyDown(event, () => onClick?.(item))}
        {...otherProps}
      >
        <StyledRowItemWrapper className={clsx('RowItem__wrapper', wrapperClass)}>
          <StyledRowItemImage className={clsx('RowItem__image', imageClass)}>
            {image}
          </StyledRowItemImage>
          <StyledRowItemContent className={clsx('RowItem__content', contentClass)}>
            {header && header}
            {content}
          </StyledRowItemContent>
          {extraInfo && (
            <StyledRowItemExtraInfo
              className={clsx('RowItem__extraInfo', extraInfoClass)}
            >
              {extraInfo}
            </StyledRowItemExtraInfo>
          )}
        </StyledRowItemWrapper>
        {showDivider && (
          <StyledRowItemDivider className={clsx('RowItem__divider', dividerClass)} />
        )}
        {children}
      </StyledRowItem>
    </div>
  )
}

export { PointsExtraInfo } from './extraInfo/points'
export default RowItem
