import {
  StyledJackpotInfo,
  StyledJackpotInfoTitle,
  StyledJackpotInfoContent
} from './styles'
import { useTranslation } from 'react-i18next'

export default function JackpotInfo() {
  const { t } = useTranslation()

  return (
    <StyledJackpotInfo moreSpace>
      <StyledJackpotInfoTitle variant="h3" component="h2">
        {t("pages.jackpot.jackpotInfo.title")}
      </StyledJackpotInfoTitle>
      <StyledJackpotInfoContent variant="body1">
        {t("pages.jackpot.jackpotInfo.description")}
      </StyledJackpotInfoContent>
      <ul>
        <li>
          <StyledJackpotInfoContent variant="body1">
            {t("pages.jackpot.jackpotInfo.descriptionList1")}
          </StyledJackpotInfoContent>
        </li>
        <li>
          <StyledJackpotInfoContent variant="body1">
            {t("pages.jackpot.jackpotInfo.descriptionList2")}
          </StyledJackpotInfoContent>
        </li>
        <li>
          <StyledJackpotInfoContent variant="body1">
            {t("pages.jackpot.jackpotInfo.descriptionList3")}
          </StyledJackpotInfoContent>
        </li>
      </ul>
      <StyledJackpotInfoContent variant="body1">
        {t("pages.jackpot.jackpotInfo.note")}
      </StyledJackpotInfoContent>
    </StyledJackpotInfo>
  )
}
