import { Colors } from 'theme'

export const MuiBottomNavActionConfig = (accentColor: string) => ({
  styleOverrides: {
    root: {
      '&.Mui-selected .MuiBottomNavigationAction-wrapper': {
        '& #JackpotIcon > path, #HelpIcon > path': {
          fill: accentColor
        },
        '& #GoalsIcon > circle, #PerksIcon > path': {
          stroke: accentColor
        }
      }
    },
    label: {
      textAlign: 'center' as any,
      letterSpacing: '.02em',
      textTransform: 'uppercase' as any,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '0.625rem',
      lineHeight: '15px',
      paddingTop: '0.5rem',
      color: Colors.gray,
      '&.Mui-selected': {
        fontSize: '0.7rem',
        color: accentColor
      }
    }
  }
})
