import { StyledHeartLoader, StyledHeartLoaderFullPage } from './styles'

// https://loading.io/css/
export interface HeartLoadingIndicatorProps {
  fullPage: boolean
}

export function HeartLoadingIndicator({ fullPage }: HeartLoadingIndicatorProps) {
  const loader = (
    <StyledHeartLoader data-testid="HeartLoadingIndicator">
      <div />
    </StyledHeartLoader>
  )

  return fullPage ? (
    <StyledHeartLoaderFullPage data-testid="HeartLoadingIndicatorContainer">
      {loader}
    </StyledHeartLoaderFullPage>
  ) : (
    loader
  )
}

export default HeartLoadingIndicator
