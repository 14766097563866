import { useEffect } from 'react'

import { isDevENV } from 'utils/helpers'
import { logWarning } from 'utils/logger'

export default function useHTTPS() {
  useEffect(() => {
    // Since heroku does not allow us to handle SSL and redirect accordingly,
    // we are going to have to do this manually in the app
    const {
      location: { hostname, protocol, pathname }
    } = window
    // We don't have SSL certs locally so we can safely skip this
    if (!isDevENV() && protocol !== 'https:') {
      // Strictly enforce ssl in non dev environments
      const enforcedHTTPSLink = new URL(`https://${hostname}${pathname}`)
      // This will cause a refresh (technically a redirect to the same page), from which
      // the client router will then pick up and handle accordingly
      logWarning(
        'User attempted to visit site unsecurely through http...redirecting to https',
        { location: window.location, enforcedHTTPSLink }
      )
      window.location.assign(enforcedHTTPSLink)
    }
  }, [])
}
