import { styled } from '@mui/material'

import confettiBackground from '../../assets/images/confetti.svg'

export const StyledConfettiBackground = styled('div')({
  backgroundImage: `url(${confettiBackground})`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '270px',
  zIndex: -1
})
