import { Typography, styled } from '@mui/material'

export const VerificationEmailBody = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  background: theme.palette.colors.inactive,
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: theme.borderRadius.small
}))

export const VerificationEmailText = styled(Typography)({
  fontWeight: 'bold'
})
