import { DateTime } from 'luxon'

import { fetchRecords } from 'api'

import { JackpotDrawingResponse, FetchCachedThunkProps } from 'types'

import { JACKPOT } from 'slices/constants'

import { getImpersonator } from 'slices/impersonation'
import { authenticatedThunkCreator, shouldRefetch } from 'slices/utils'

import { getJackpotCurrentDrawing } from './selectors'

import { RootState } from 'reduxStore'

export const fetchJackpotCurrentDrawingThunk = authenticatedThunkCreator<
  JackpotDrawingResponse,
  FetchCachedThunkProps | undefined
>(
  `${JACKPOT}/fetchJackpotCurrentDrawingThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, 'jackpotCurrentDrawing'),
  {
    condition: (options = {}, { getState }) => {
      const state = getState() as RootState
      const impersonator = getImpersonator(state)
      const prev = getJackpotCurrentDrawing(state)
      const drawingTime = prev?.drawing_at
      //always refetch if the jackpot drawing is in the past
      if (drawingTime && DateTime.fromISO(drawingTime) < DateTime.now()) return true
      return shouldRefetch(false, prev, impersonator, options)
    }
  }
)
