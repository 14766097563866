import { ButtonProps, SelectChangeEvent } from '@mui/material'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PreferredLanguage } from 'types'

import { updateUserSetting } from 'pages/userProfile/utils'

import useAppDispatch from 'hooks/useAppDispatch'

import { getWhoami } from 'slices/whoami'

import { StyledOnboardingLanguageButton } from './styles'

export interface OnboardingLanguageButtonProps {
  buttonSizeVariant: ButtonProps['size']
}

const OnboardingLanguageButton = ({
  buttonSizeVariant
}: OnboardingLanguageButtonProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const whoami = useSelector(getWhoami)

  const [preferredLanguage, setPreferredLanguage] = useState<PreferredLanguage | null>(
    whoami?.language ?? PreferredLanguage.english
  )

  useEffect(() => {
    setPreferredLanguage(i18n.language as PreferredLanguage)
  }, [t])

  const nextLanguage = useMemo(() => {
    return {
      language:
        preferredLanguage === PreferredLanguage.english
          ? PreferredLanguage.spanish
          : PreferredLanguage.english,
      text: preferredLanguage === PreferredLanguage.english ? '🇪🇸 Español' : '🇺🇸 English'
    }
  }, [preferredLanguage])

  const handleChangeLanguage = () => {
    const event = {
      target: {
        value: nextLanguage.language
      }
    } as unknown as SelectChangeEvent<PreferredLanguage>
    updateUserSetting<PreferredLanguage>(
      whoami?.user_profile_id,
      'language',
      dispatch,
      setPreferredLanguage
    )(event)
  }

  return (
    <StyledOnboardingLanguageButton
      onClick={handleChangeLanguage}
      variant="outlined"
      size={buttonSizeVariant}
      data-testid="OnboardingLanguageButton"
    >
      {nextLanguage.text}
    </StyledOnboardingLanguageButton>
  )
}

export default OnboardingLanguageButton
