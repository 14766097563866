import { Button, Grid } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useNavigation } from 'hooks/useNavigation'

import { RootPaths } from 'utils/helpers'

import { FaqContainer } from './styles'

import HomeSection from '../homeSection'

export default function HomeHelpSection() {
  const { t } = useTranslation()
  const navigation = useNavigation()

  const handleClick = () => {
    navigation.push(RootPaths.help)
  }
  return (
    <HomeSection
      title={t('pages.home.section.help.title')}
      subTitle={t('pages.home.section.help.subTitle')}
    >
      <Grid item xs={12}>
        <FaqContainer>
          <Button onClick={handleClick} fullWidth variant="contained" color="primary">
            {t('pages.home.section.help.faq')}
          </Button>
        </FaqContainer>
      </Grid>
    </HomeSection>
  )
}
