import { ADMIN_CELEBRATION_POINTS } from 'slices/constants'

import { RootState } from 'reduxStore'

import { CelebrationPointsAdapter } from './'

export const celebrationPointsSelectors =
  CelebrationPointsAdapter.getSelectors<RootState>(
    (state) => state[ADMIN_CELEBRATION_POINTS]
  )

export const getCelebrationPointsResponse = (state: RootState) =>
  state[ADMIN_CELEBRATION_POINTS].celebrationPointsResponse

export const getCelebrationPointsLoading = (state: RootState) =>
  state[ADMIN_CELEBRATION_POINTS].isLoading

export const getDistributionPoints = (state: RootState) =>
  state[ADMIN_CELEBRATION_POINTS].distributionPoints
