import clsx from 'clsx'
import startCase from 'lodash/startCase'

import {
  FormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps
} from '@mui/material'

export interface SelectProps<T> {
  isLoading?: boolean
  allItems: T[]
  getItemValue: (item: T) => string
  getItemText: (item: T) => string
  selectProps?: MuiSelectProps
  inputLabelProps?: InputLabelProps
  label: string
  loadingText?: string
  clearable?: boolean
  defaultClearableText?: string
  onChange: (e: SelectChangeEvent<unknown>) => void
  itemVal: string | null
  formControlProps?: FormControlProps
  className?: string
}

export default function Select<T>({
  isLoading,
  allItems,
  getItemValue,
  getItemText,
  selectProps = {},
  inputLabelProps = {},
  label,
  loadingText = 'Loading...',
  clearable,
  defaultClearableText = 'None',
  onChange,
  itemVal,
  formControlProps = {},
  className
}: SelectProps<T>) {
  const inputLabel = `${label}-input-label`
  const loadingKey = 'loading'

  const renderItems = () => {
    if (isLoading) {
      return <MenuItem value={loadingKey}>{loadingText}</MenuItem>
    }
    const items = allItems?.map((item) => {
      const value = getItemValue(item)
      const text = getItemText(item)
      return (
        <MenuItem key={value} value={value}>
          {startCase(text)}
        </MenuItem>
      )
    })
    const data = clearable
      ? [
          <MenuItem key="clearable-select-item" value="">
            <em>{startCase(defaultClearableText)}</em>
          </MenuItem>,
          ...items
        ]
      : items

    return data
  }

  return (
    <FormControl className={clsx(className)} {...formControlProps}>
      <InputLabel id={inputLabel} {...inputLabelProps}>
        {label}
      </InputLabel>
      <MuiSelect
        autoWidth
        labelId={inputLabel}
        id={`${label}-filter-select`}
        value={isLoading ? loadingKey : itemVal}
        onChange={onChange}
        disabled={Boolean(isLoading)}
        {...selectProps}
      >
        {renderItems()}
      </MuiSelect>
    </FormControl>
  )
}
