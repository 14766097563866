import { useTranslation } from 'react-i18next'

import EmailButton from 'pages/login/components/emailButton/EmailButton'

import { BrandedLogo, LanguageButton, PhoneNumberInput } from '../../components'
import { VerticalAnimationDirection } from 'components/AnimatedContent'

import {
  StyledAnimatedContent,
  StyledBody,
  StyledDisabledPage,
  StyledDivider,
  StyledForm,
  StyledFormTitle,
  StyledHeader,
  StyledTitle,
  StyledTitleContainer
} from './disabled.styles'

export const DisabledLoginForm = () => {
  const { t } = useTranslation()

  return (
    <StyledDisabledPage>
      <StyledAnimatedContent verticalAnimationDirection={VerticalAnimationDirection.DOWN}>
        <StyledHeader>
          <BrandedLogo isActive />
          <LanguageButton />
        </StyledHeader>
        <StyledBody>
          <StyledTitleContainer>
            <StyledTitle variant="body1" data-testid="EarnedTitle">
              {t('pages.login.form.disabled.earnTitle')}
            </StyledTitle>
            <StyledTitle variant="body1" data-testid="RedeemTitle">
              {t('pages.login.form.disabled.redeemTitle')}
            </StyledTitle>
          </StyledTitleContainer>
          <StyledForm data-testid="DisabledLoginForm">
            <StyledFormTitle variant="body1" data-testid="SignInTitle">
              {t('pages.login.form.signInTitle')}
            </StyledFormTitle>
            <PhoneNumberInput />
            <StyledDivider text={t('pages.login.form.disabled.divider')} />
            <EmailButton />
          </StyledForm>
        </StyledBody>
      </StyledAnimatedContent>
    </StyledDisabledPage>
  )
}
