import { Fab, styled } from '@mui/material'

export const StyledBottomScroll = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(17),
  right: theme.spacing(6),
  margin: '0 -0 0 -30px',
  zIndex: 2
}))

export const StyledBottomScrollButton = styled(Fab)(({ theme }) => ({
  background: 'rgba(115, 92, 221, 0.8)',
  '&:hover': {
    background: 'rgba(115, 92, 221, 0.6)'
  },
  '& svg path': {
    stroke: theme.branding.lightTextColor
  }
}))
