// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { JackpotPrize } from 'types'

import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { DRAWINGS, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchJackpotCurrentDrawingThunk } from '../drawings/actions'

export const prizesAdapter = createEntityAdapter<JackpotPrize>({
  selectId: (prize) => prize.id
})

export const prizesSlice = createSlice({
  name: DRAWINGS,
  initialState: prizesAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          //todo: make the isLoading update here explicit for if it's the next drawing or the current drawing
          fetchJackpotCurrentDrawingThunk.fulfilled,
        ),
        (state, action) => {
          prizesAdapter.setMany(state, action.payload.prizes)
          handleRequestFulfilled(state)
        }
      )
      .addMatcher(
        isAnyOf(
          fetchJackpotCurrentDrawingThunk.pending,
        ),
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          fetchJackpotCurrentDrawingThunk.rejected,
        ),
        (state, action) => {
          handleRequestRejected(state, action)
        }
      )
  }
})

export default prizesSlice.reducer
