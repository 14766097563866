import { ReactComponent as CaretIcon } from 'assets/icons/AppIcons/CaretDownIcon.svg'

import { Colors } from 'theme'

export interface FlippableCaretIconProps {
  color?: string
  flip?: boolean
}

export default function FlippableCaretIcon({
  color = Colors.black,
  flip
}: FlippableCaretIconProps) {
  return (
    <CaretIcon
      stroke={color}
      style={{ transform: flip ? 'rotateX(180deg)' : '', transition: 'transform 0.5s' }}
    />
  )
}
