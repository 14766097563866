// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  alpha,
  Button,
  ButtonTypeMap,
  Card,
  CardTypeMap,
  IconButton,
  styled,
  TextField,
  Typography,
  TypographyTypeMap
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import { BottomModal } from 'components/modals'
import RowItem from 'components/rowItem'

interface UseStyleProps {
  isOnSmallDevice: boolean
  canRedeem?: boolean
}

// Perks
export const StyledPerksGrid = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<UseStyleProps>(({ theme, isOnSmallDevice }) => ({
  display: 'grid',
  gridTemplateColumns: isOnSmallDevice ? '1fr' : 'repeat(auto-fit, minmax(152px, 1fr))',
  alignItems: 'center',
  gridGap: isOnSmallDevice ? theme.spacing(1) : theme.spacing(4),
  // 32px size of category container + 25px o f padding
  margin: 0,
  padding: 0
}))

export const StyledPerksSubheaderTitle = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  ...theme.customTypography.display
}))

export const StyledPerksWalletCTA = styled<OverridableComponent<ButtonTypeMap>>(Button)(
  ({ theme }) => ({
    marginTop: theme.spacing(2)
  })
)

// PerkCategories
export const StyledPerkCategories = styled('section')(({ theme }) => ({
  margin: theme.spacing(0, 0, 5),
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1)
}))

export const StyledPerkCategoriesText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginRight: theme.spacing(1)
}))

// PerkItem
export const StyledLargePerkItem = styled<
  OverridableComponent<
    CardTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'li'>
  >
>(Card, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice' && prop !== 'canRedeem'
})<UseStyleProps>(({ theme, isOnSmallDevice, canRedeem }) => ({
  padding: theme.spacing(2),
  border: canRedeem ? 'none' : `solid 1px ${theme.palette.colors.gray}`,
  borderRadius: theme.borderRadius.medium,
  textAlign: 'center',
  height: isOnSmallDevice ? '78px' : '156px',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  filter: canRedeem ? 'none' : 'grayscale(1)'
}))

export const StyledLargePerkBrandBar = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: '64px',
  height: theme.spacing(1),
  backgroundColor: theme.branding.lightTextColor,
  borderRadius: theme.borderRadius.small
}))

export const StyledPerkItemHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSlim'
})((props: { isSlim?: boolean }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: props.isSlim ? '0 0' : '0 0.625rem'
}))

export const StyledPerkItemDiscountText = styled(Typography)(({ theme }) => ({
  color: theme.branding.primaryColor,
  fontWeight: 'bold'
}))

export const StyledLockIcon = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.gray
}))

export const PerkItemTitleContainer = styled('div')`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledPerkItemTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<UseStyleProps>(({ isOnSmallDevice }) => ({
  textAlign: isOnSmallDevice ? 'left' : 'center',
  fontWeight: 'bold',
  wordBreak: 'break-word'
}))

export const StyledPerkItemTagline = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<UseStyleProps>(({ theme, isOnSmallDevice }) => ({
  marginBottom: isOnSmallDevice ? 0 : theme.spacing(2),
  textAlign: isOnSmallDevice ? 'left' : 'center',
  color: theme.branding.subTextColor
}))

export const PerkLogoContainer = styled('div')`
  flex: 1 1 0;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
`

export const StyledPerkItemLogo = styled('img')({
  maxWidth: '100px',
  maxHeight: '60px'
})

export const StyledSuggestPerkItem = styled(StyledLargePerkItem, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<UseStyleProps>(({ theme, isOnSmallDevice }) => ({
  padding: theme.spacing(3),
  background: 'rgba(53, 49, 66, 0.05)',
  display: 'flex',
  flexDirection: isOnSmallDevice ? 'row' : 'column',
  border: isOnSmallDevice ? `1px dashed ${theme.branding.subTextColor}` : 'none',
  borderRadius: isOnSmallDevice ? theme.borderRadius.small : theme.borderRadius.medium,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}))

export const SuggestPerkIconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<UseStyleProps>`
  width: 48px;
  height: 48px;
  margin: ${({ theme, isOnSmallDevice }) =>
    isOnSmallDevice ? theme.spacing(0, 2, 0, 0) : theme.spacing(0, 0, 2)};
  svg > circle {
    stroke-dasharray: ${({ isOnSmallDevice }) => (isOnSmallDevice ? 'initial' : '4, 4')};
    stroke-width: 1;
  }
`

export const StyledSlimPerk = styled(RowItem)({
  '.RowItem__content': {
    maxWidth: 'calc(100% - 138px)'
  },
  '.RowItem__extraInfo': {
    // TODO: Refactor all these width and height overrides
    // and set the width and height constraint only on
    // components that need them
    width: 'auto',
    height: 'auto',
    alignSelf: 'flex-start'
  }
}) as typeof RowItem

// SuggestPerk
export const StyledSuggestPerkHeaderTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '36px',
  textAlign: 'end',
  color: theme.branding.lightTextColor
}))

export const StyledSuggestPerSuccessContent = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(5),
  margin: theme.spacing(20, 0)
}))

export const StyledSuggestPerkSuccessTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'h1'>
  >
>(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

export const StyledSuggestPerkForm = styled('form')({
  // 120(custom header for this page), 100(footer), 40(cta button), 20(cta button padding)
  minHeight: 'calc(100vh - 120px - 100px - 40px - 20px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
})

export const StyledSuggestPerkFormField = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(5),
  margin: theme.spacing(20, 0),
  input: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px'
  }
}))

export const StyledSuggestPerkFormCTA = styled(Button)({
  width: '100%'
})

// SelectCategoriesModal
export const StyledCategoriesModal = styled(BottomModal)(({ theme }) => ({
  '.BaseModal__title': {
    padding: theme.spacing(0, 3, 2),
    ...theme.customTypography.display2
  },
  '.BaseModal__wrapper': {
    padding: theme.spacing(2, 0, 3),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 60px)',
    justifyContent: 'space-evenly',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(3)
  }
}))

export const StyledCategoriesModalIconContainer = styled('div')({
  textAlign: 'center',
  flexDirection: 'column'
})

export const StyledCategoriesModalIcon = styled(IconButton)({
  padding: 0
})

export const StyledCategoriesModalIconText = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }>
  >
>(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  fontSize: '10px',
  lineHeight: '15px',
  letterSpacing: '0.02em',
  textTransform: 'uppercase'
}))

export const StyledCategoriesModalFooter = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(4),
  gridTemplateColumns: 'repeat(auto-fit, 135px)',
  width: '100%',
  justifyContent: 'center',
  // TODO: Refactor these styles into outlined-secondary on the theme
  '& button:hover': {
    color: theme.branding.primaryColor,
    border: `1px solid ${alpha(theme.branding.primaryColor, 0.5)}`
  }
}))
