import { fetchRecords, patchRecord, postRecord, putRecord } from 'api'

import {
  Whoami,
  FetchCachedThunkProps,
  UpsertThunkProps,
  UserSettingsV2,
  UserSettingsV3,
  UserSettingsV4,
  VerifyEmailResponse,
  VerifyEmailRequest,
  SendVerificationEmailRequest
} from 'types'

import { AUTH, USER_INFO } from 'slices/constants'

import { getAuthIsLoading } from 'slices/auth'
import { authenticatedThunkCreator } from 'slices/utils'

import { getWhoami, getWhoamiIsLoading } from './selectors'

import { APIVersion } from 'utils/helpers'

import { RootState } from 'reduxStore'

export const fetchWhoamiThunk = authenticatedThunkCreator<
  Whoami,
  FetchCachedThunkProps | undefined
>(
  `${AUTH}/fetchWhoamiThunk`,
  async (authedDTO) => {
    const whoami: Whoami = await fetchRecords(authedDTO, 'whoami')
    return whoami
  },
  {
    condition: (options: FetchCachedThunkProps = {}, { getState }) => {
      const state = getState() as RootState
      const isLoading = getAuthIsLoading(state) || getWhoamiIsLoading(state)
      const prevWhoami = getWhoami(state)
      const shouldNotRefetch = Boolean(
        isLoading || (prevWhoami && options?.getCachedResults)
      )
      return !shouldNotRefetch
    }
  }
)

export const updateUserSettingsV2Thunk = authenticatedThunkCreator<
  UserSettingsV2,
  UpsertThunkProps<UserSettingsV2>
>(`${USER_INFO}/updateUserSettingsV2Thunk`, async (authedDTO) => {
  const settings: UserSettingsV2 = await putRecord(
    authedDTO,
    'userSettings',
    APIVersion.v2
  )
  return settings
})

export const updateUserSettingsV3Thunk = authenticatedThunkCreator<
  UserSettingsV3,
  UpsertThunkProps<UserSettingsV3>
>(`${USER_INFO}/updateUserSettingsV3Thunk`, async (authedDTO) => {
  const settings: UserSettingsV3 = await putRecord(
    authedDTO,
    'userSettings',
    APIVersion.v3
  )
  return settings
})

export const updateUserSettingsV4Thunk = authenticatedThunkCreator<
  UserSettingsV4,
  UpsertThunkProps<UserSettingsV4>
>(`${USER_INFO}/updateUserSettingsV4Thunk`, async (authedDTO) => {
  const settings: UserSettingsV4 = await patchRecord(
    authedDTO,
    'userSettings',
    APIVersion.v4
  )
  return settings
})

export const sendVerificationEmailThunk = authenticatedThunkCreator<
  string,
  UpsertThunkProps<SendVerificationEmailRequest>
>(`${USER_INFO}/sendVerificationEmailThunk`, async (authedDTO) => {
  return await postRecord(authedDTO, 'sendVerificationEmail', APIVersion.v1)
})

export const verifyEmailThunk = authenticatedThunkCreator<
  VerifyEmailResponse,
  UpsertThunkProps<VerifyEmailRequest>
>(`${USER_INFO}/verifyEmailThunk`, async (authedDTO) => {
  const settings: VerifyEmailResponse = await postRecord(authedDTO, 'verifyEmail')
  return settings
})
