import { Typography } from '@mui/material'
import Calendar from 'assets/images/calender-dynamic-gradient.png'
import { BirthdayInput, BirthdayInputLabel, BirthdayModalActionButton, BirthdayModalConfirmButton, BirthdayReminderTitle, CalendarIcon, InputWrapper } from './birthdayReminderModal.styles'
import { useState } from 'react';

interface BirthdayReminderModalProps {
  onConfirm: (date: string) => void
  onCancel: () => void
}

export default function BirthdayReminderModal({ onConfirm, onCancel }: BirthdayReminderModalProps) {
  const [date, setDate] = useState<string>("")

  const onClickConfirm = () => onConfirm(date)

  return (
    <>
      <CalendarIcon src={Calendar} alt="birthday calendar icon" />
      <BirthdayReminderTitle>
        Complete your profile.
      </BirthdayReminderTitle>
      <Typography variant='body2'>
        Don&apos;t miss out on birthday rewards!
      </Typography>
      <Typography variant='body2'>
        Enter your birthday today.
      </Typography>
      <InputWrapper>
        <BirthdayInputLabel>Enter birthday</BirthdayInputLabel>
        <BirthdayInput
          data-testid="BirthdayInput"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          type='date'
        />
      </InputWrapper>
      <BirthdayModalConfirmButton
        disabled={!date}
        variant="contained"
        onClick={onClickConfirm}
      >
        Confirm Birthday
      </BirthdayModalConfirmButton>
      <br />
      <BirthdayModalActionButton onClick={onCancel}>
        Maybe later
      </BirthdayModalActionButton>
    </>
  )
}
