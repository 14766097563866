import React, { ChangeEvent } from 'react'

import { canMoveForward, getNextDate, getPreviousDate, getTodaysDate } from 'utils/form'

import { DatePickerArrow, DatePickerContainer, StyledGoalsDatePicker } from './goalsDatePicker.styles'

import { ReactComponent as NavArrowLeftIcon } from 'assets/icons/AppIcons/NavArrowLeftIcon.svg'
import { ReactComponent as NavArrowRightIcon } from 'assets/icons/AppIcons/NavArrowRightIcon.svg'

import { DateTime } from 'luxon'

const GoalsDatePicker = ({ setCurrentDate, minimumDate, currentDate }: {
  setCurrentDate: React.Dispatch<React.SetStateAction<string>>
  minimumDate?: string
  currentDate: string
}) => {
  const todaysDate = getTodaysDate()

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    if(DateTime.fromISO(value).isValid){
      setCurrentDate(value)
    }
  }

  const moveDateBackward= () => {
    setCurrentDate(getPreviousDate(currentDate))
  }
  const moveDateForward = () => {
    setCurrentDate(getNextDate(currentDate))
  }

  return (<DatePickerContainer>
    <DatePickerArrow onClick={moveDateBackward}>
      <NavArrowLeftIcon />
    </DatePickerArrow>
    <StyledGoalsDatePicker
      value={currentDate}
      onChange={onChange}
      type="date"
      inputProps={{
        min: minimumDate, max: todaysDate
      }}
    />
    <DatePickerArrow onClick={moveDateForward} disabled={canMoveForward(currentDate)}>
      <NavArrowRightIcon />
    </DatePickerArrow>
  </DatePickerContainer>)
}

export default GoalsDatePicker
