import { LDFlagSet } from 'launchdarkly-js-client-sdk'

export const isValidFeatureFlag = <F extends LDFlagSet>(flags: F, flag: keyof F) => {
  if (!flags || !flag || !(flag in flags)) {
    return false
  }
  return true
}

// These are for boolean and string flags only (since strings can be empty which is the equivalent of being false)
export const isFeatureFlagOn = <F extends LDFlagSet>(flags: F, flag: keyof F) =>
  isValidFeatureFlag(flags, flag) && flags[flag]

export const isFeatureFlagOff = <F extends LDFlagSet>(flags: F, flag: keyof F) =>
  isValidFeatureFlag(flags, flag) && !flags[flag]
