// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { WINNERS, requestState } from "slices/constants";
import { handleRequestFulfilled, handleRequestRejected } from "slices/utils";
import { JackpotWinner } from "types";
import { fetchJackpotWinnersThunk } from "./actions";

export const winnersAdapter = createEntityAdapter<JackpotWinner>({
    selectId: (winner) => winner.id
})

export const winnersSlice = createSlice({
    name: WINNERS,
    initialState: winnersAdapter.getInitialState(requestState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(
                    fetchJackpotWinnersThunk.fulfilled
                ),
                (state, action) => {
                    winnersAdapter.setMany(state, action.payload.winners)
                    handleRequestFulfilled(state)
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchJackpotWinnersThunk.pending
                ),
                (state) => {
                    state.isLoading = true
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchJackpotWinnersThunk.rejected
                ),
                (state, action) => {
                    handleRequestRejected(state, action)
                }
            )
    }
})

export default winnersSlice.reducer
