import { CompletionState, GoalState } from 'generated/graphql'
import { isEmpty } from 'lodash'

import { Goal } from 'types'

import { GOAL_RESULT_STATUS, GOAL_RESULT_TEXT } from './consts'

export const getOneGoalVersion = (first: Goal, second: Goal) => {
  if (first.points > second.points) return first
  if (first.expires_at > second.expires_at) return first
  return second
}

export const getGoalVersionsPerParent = (goals: Goal[]) => {
  const indexByParent: Record<string, number> = {}
  const versions: Goal[] = []
  goals.forEach((newGoal: Goal, index: number) => {
    const { parent = '' } = newGoal
    if (!parent) {
      versions.push(newGoal)
      return
    }
    const i = indexByParent[parent]
    if (!Number.isInteger(i)) {
      versions.push(newGoal)
      indexByParent[parent] = index
      return
    }
    const oldGoal = versions[i]
    const goal = isEmpty(oldGoal) ? newGoal : getOneGoalVersion(oldGoal, newGoal)
    versions.splice(i, 1, goal)
  })
  return versions
}

export const convertGoalStatesForEmployeeToGoals = (
  goalStates: GoalState[],
  storeGroupId: string
): Goal[] => {
  return goalStates.map(
    (goalState) =>
      ({
        id: goalState.goalId,
        created: '',
        modified: '',
        title: goalState.goalName,
        description: goalState.goalDescription,
        result: GOAL_RESULT_TEXT[goalState.goalType][goalState.state],
        points: goalState.goalPoints,
        completed: goalState.state === CompletionState.Complete,
        goal_type: goalState.goalType,
        icon: '',
        frequency: 'daily',
        store_id: '',
        group_id: storeGroupId,
        result_status: GOAL_RESULT_STATUS[goalState.goalType][goalState.state],
        parent: null,
        expires_at: '',
        testing: false,
        gp_testing_status: null,
        source: 'goal_service'
      }) as Goal
  )
}

export const removeDuplicatedGoals = ({
  goalsToMap,
  goalsToUpdate
}: {
  goalsToMap: GoalState[]
  goalsToUpdate: Goal[]
}): Goal[] => {
  const uniqueKaiserGoals = goalsToUpdate.filter((kaiserGoal) => {
    const idx = goalsToMap.findIndex((gsGoal) => kaiserGoal.id === gsGoal.kaiserGoalId)
    return idx === -1
  })
  return uniqueKaiserGoals
}

/*
  This function is temporary. We will use this only during the transition
  between Goal Service and Kaiser Service goals. We need this function to
  update points for Goal Service goals if the goal has zero points. So, we
  will need to update it using the existing Kaiser Service goal points.
*/
export const updateGoalStatePoints = ({
  goalsToMatch,
  goalStatesToUpdate
}: {
  goalsToMatch: Goal[]
  goalStatesToUpdate: GoalState[]
}): GoalState[] => {
  const goalsToUpdateCopy = [...goalStatesToUpdate]
  goalsToUpdateCopy.forEach((goalState) => {
    if (!goalState.goalPoints) {
      const matchingGoal = goalsToMatch.find((goal) => goal.id === goalState.kaiserGoalId)
      goalState.goalPoints = matchingGoal?.points ?? goalState.goalPoints
    }
  })
  return goalsToUpdateCopy
}

export const removeGoalsWithZeroPoints = (goals: (Goal | GoalState)[]) => {
  return goals.filter(
    (goal) => (goal as Goal).points > 0 || (goal as GoalState).goalPoints > 0
  )
}

export default {
  getOneGoalVersion,
  getGoalVersionsPerParent,
  convertGoalStatesForEmployeeToGoals,
  removeDuplicatedGoals,
  updateGoalStatePoints,
  removeGoalsWithZeroPoints
}
