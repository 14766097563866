import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { postRecord } from 'api'

import { getAuthTokens } from 'slices/auth'
import { getImpersonator, getImpersonatorLoading } from 'slices/impersonation'

export const useSessionTrack = () => {
  const { access, refresh } = useSelector(getAuthTokens)
  const impersonator = useSelector(getImpersonator)
  const impersonationLoading = useSelector(getImpersonatorLoading)

  const hasAuthedArgs = !!access && !!refresh
  const isImpersonating = impersonator || impersonationLoading

  useEffect(() => {
    if (!isImpersonating && hasAuthedArgs) {
      postRecord<void, void>({ access, refresh }, 'sessionTrack', 'v1')
    }
  }, [access, refresh, isImpersonating])
}
