import { DateTime } from 'luxon'

import { useMemo } from 'react'

export interface FormSelectOptions {
  label?: string
  value?: string | number
}
export interface FormSelectParams<T> {
  options: T[] | null
  getLabel: (option: T) => FormSelectOptions['label']
  getValue: (option: T) => FormSelectOptions['value']
}

export const formatSelectValues = <T>({
  options,
  getLabel,
  getValue
}: FormSelectParams<T>): FormSelectOptions[] => {
  if (!options?.length) return []
  return options.map((option) => ({
    label: getLabel(option),
    value: getValue(option)
  }))
}

export const getMemoizedSelectValues = <T>({
  options,
  getLabel,
  getValue
}: FormSelectParams<T>) =>
  useMemo(
    () =>
      formatSelectValues({
        options,
        getLabel,
        getValue
      }),
    [options]
  )

export const formatFormDate = (dateString: string) =>
  DateTime.fromISO(dateString).toFormat('yyyy-MM-dd')
export const formatAPIDate = (dateString: string) =>
  DateTime.fromISO(dateString as string).toISO()

export const getTodaysDate = () => formatFormDate(DateTime.now().toISO())
export const getYesterdaysDate = () => formatFormDate(DateTime.now().minus({ days: 1 }).toISO())
export const getPreviousDate = (startDate: string) => formatFormDate((DateTime.fromISO(startDate)).minus({ days: 1 }).toISO())
export const getNextDate = (startDate: string) => formatFormDate((DateTime.fromISO(startDate)).plus({ days: 1 }).toISO())

export const isToday = (dateString: string) =>
  DateTime.fromISO(dateString as string).hasSame(DateTime.now(), 'day')

export const canMoveForward = (dateString: string) => {
  return isToday(dateString);
}
