import { BorderRadius, getSpacing } from 'theme'

import { darken } from '@mui/material'

export const MuiTabConfig = (
  inactiveColor: string,
  darkTextColor: string,
  secondaryColor: string,
  getContrastTextColor: (color: string) => string
) => ({
  styleOverrides: {
    root: {
      border: `1px solid ${darken(inactiveColor, 0.2)}`,
      boxShadow: 'none',
      borderRadius: BorderRadius.large,
      minHeight: '32px',
      textTransform: 'initial' as any,
      minWidth: '120px',
      color: darkTextColor,
      '&:first-of-type': {
        marginRight: getSpacing(3)
      },
      '&.Mui-selected': {
        background: secondaryColor,
        color: getContrastTextColor(secondaryColor),
        borderColor: 'transparent'
      }
    },
    wrapped: {
      fontSize: '12px',
      lineHeight: '24px'
    }
  }
})
