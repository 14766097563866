import {
  Collapse,
  IconButton,
  styled,
  Typography,
  TypographyTypeMap
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import PageWithModal from 'layouts/pageWithModal'

export const StyledAdminContainer = styled(PageWithModal)({
  '& section': {
    minHeight: '500px !important',
    // this pushes the content up to avoid it hiding behind the footer
    paddingBottom: 98 + 10 // 98 footer tabbar + 10 of padding
  },
  '& .RowItem:hover': {
    transform: 'none'
  },
  '&.storybook .MuiBottomNavigation-root': {
    // Needed for footer to stay in storybook canvas
    position: 'absolute',
    left: 0
  }
})

export const StyledAdminContainerSubheader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showFilterToggle'
})<{
  showFilterToggle: boolean
}>(({ showFilterToggle, theme }) => ({
  display: 'grid',
  gridTemplateColumns: showFilterToggle ? '1fr 36px' : '1fr',
  marginBottom: theme.spacing(4)
}))

export const StyledAdminContainerFilters = styled('div')(({ theme }) => ({
  display: 'flex',
  gridGap: theme.spacing(3),
  flexWrap: 'wrap',
  width: '100%',
  marginBottom: 15,
  position: 'relative',
  '& > div': {
    flex: 1,
    minWidth: '200px'
  }
}))

export const StyledAdminContainerFilterContent = styled(Collapse)(({ theme }) => ({
  marginTop: -theme.spacing(4),
  marginBottom: theme.spacing(2)
}))

export const StyledAdminContainerHeaderTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }>
  >
>(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  textTransform: 'capitalize'
}))

export const StyledAdminContainerFilterIcon = styled(IconButton)({
  padding: 0
})
