// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Colors } from 'theme'

import { useContext, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StepContext } from 'pages/onboarding'

import useAppDispatch from 'hooks/useAppDispatch'

import {
  Step,
  StyledOnboardingStepBody,
  StyledOnboardingStepTitle
} from '../../components'

import { brandsSelectors, fetchUserStoreBrandsThunk } from 'slices/brands'

const StepLanguage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isOnSmallDevice, firstName } = useContext(StepContext)
  const userBrandNames = useSelector(brandsSelectors.selectAll).map((brand) => brand.name)

  const welcomingTitle = firstName ? (
    <Trans i18nKey="pages.onboarding.welcome.title" t={t} values={{ name: firstName }} />
  ) : (
    t('pages.onboarding.welcome.initialTitle')
  )
  const welcomingBodyI18NKey =
    userBrandNames.length > 1
      ? 'pages.onboarding.welcome.bodyWithBrandNames'
      : 'pages.onboarding.welcome.body'

  const brandName: string =
    userBrandNames.join(', ') || t('pages.onboarding.welcome.initialBrandName')

  useEffect(() => {
    dispatch(fetchUserStoreBrandsThunk())
  }, [])

  return (
    <Step backgroundColor={Colors.black}>
      <StyledOnboardingStepTitle variant="h1" data-testid="OnboardingLanguageStep-Title">
        {welcomingTitle}
      </StyledOnboardingStepTitle>
      <StyledOnboardingStepBody
        variant="body1"
        data-testid="OnboardingLanguageStep-Body"
        isOnSmallDevice={isOnSmallDevice}
      >
        <Trans
          components={{ bold: <b /> }}
          i18nKey={welcomingBodyI18NKey}
          t={t}
          values={{ brandNames: brandName }}
        />
      </StyledOnboardingStepBody>
    </Step>
  )
}

export default StepLanguage
