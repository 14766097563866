import { Colors } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

export default function TeamLeaderOnboardingTextLoader(
  props: ComponentProps<typeof ContentLoader> = {}
) {
  return (
    <ContentLoader
      width={144}
      height={72}
      style={{ width: '100%', ...(props?.style || {}) }}
      viewBox="0 0 144 72"
      backgroundColor={Colors.inactive}
      foregroundColor={Colors.blue}
      {...props}
    >
      <rect x="0" y="24" rx="3" ry="3" width="125" height="20" />
      <rect x="0" y="48" rx="3" ry="3" width="100" height="20" />
    </ContentLoader>
  )
}
