// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RewardInfo, RewardDetail } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { RequestState, requestState, PERKS } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchPerksThunk, fetchPerkThunk } from './actions'

import utils from '../utils'

export const perksAdapter = createEntityAdapter<RewardInfo>({
  selectId: (perk) => perk.id,
  sortComparer: utils.sortPerks
})

interface PerksState extends RequestState {
  selectedPerk: RewardDetail
}

const initialPerksState: PerksState = {
  selectedPerk: {} as RewardDetail,
  ...requestState
}

export const perksSlice = createSlice({
  name: PERKS,
  initialState: perksAdapter.getInitialState(initialPerksState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPerksThunk.fulfilled, (state, action) => {
        perksAdapter.setAll(state, action.payload)
        handleRequestFulfilled(state)
      })
      .addCase(fetchPerkThunk.fulfilled, (state, action) => {
        state.selectedPerk = action.payload
        handleRequestFulfilled(state)
      })
      .addCase(fetchPerksThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPerksThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
  }
})

export default perksSlice.reducer
