import { CSSProperties, ReactElement, ReactNode } from 'react'

interface PlaceholderProps {
  isLoading: boolean
  loader: () => ReactElement
  children?: ReactNode
  amount?: number
  style?: CSSProperties
}

export default function Placeholder({
  children,
  isLoading,
  loader: Loader,
  amount = 1,
  ...otherProps
}: PlaceholderProps) {
  if (isLoading) {
    return (
      <>
        {Array.from({ length: amount }).map((_, i) => (
          <Loader key={`placeholder-${i}`} {...otherProps} />
        ))}
      </>
    )
  }

  return <>{children}</>
}

export { default as CircleLoader } from './loaders/circleLoader'
export { default as PerkItemLoader } from './loaders/perkItemLoader'
export { default as PrizeItemLoader } from './loaders/prizeItemLoader'
export { default as RowItemLoader } from './loaders/rowItemLoader'
export { default as TeamLeaderOnboardingTextLoader } from './loaders/teamLeaderOnboardingTextLoader'
export { default as JackpotTicketLineItemLoader } from './loaders/jackpotTicketLineItemLoader'
