import clsx from 'clsx'

import { Slide, SlideProps } from '@mui/material'

import { ForwardedRef, forwardRef, Ref } from 'react'

import { StyledBottomModal } from './styles'

import { BaseModalProps } from '../baseModal'

const Transition = forwardRef(function Transition(props: SlideProps, ref: Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default forwardRef(function BottomModal(
  props: Omit<BaseModalProps, 'TransitionComponent'>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <StyledBottomModal
      {...props}
      className={clsx('BottomModal', props?.className)}
      baseModalRef={ref}
      TransitionComponent={Transition}
    />
  )
})
