import i18n from 'i18next'
import { PreferredLanguage } from 'types'

import { initReactI18next } from 'react-i18next'

import englishTranslation from './en/translation.json'
import spanishTranslation from './es/translation.json'

export const resources = {
  [PreferredLanguage.english]: {
    translation: englishTranslation
  },
  [PreferredLanguage.spanish]: {
    translation: spanishTranslation
  }
} as const

// https://www.i18next.com/overview/configuration-options
i18n.use(initReactI18next).init({
  fallbackLng: PreferredLanguage.english,
  lng: PreferredLanguage.english,
  resources,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
})

export default i18n
