import { Colors } from 'theme'

import { useTranslation } from 'react-i18next'

import { ReactComponent as GoalsIcon } from 'assets/icons/AppIcons/GoalsIcon.svg'

import { useNavigation } from 'hooks/useNavigation'

import { ChildPaths } from 'utils/helpers'

import {
  CheckTodaysGoalsContainer,
  CheckTodaysGoalsTitle,
  RightArrowIcon
} from './styles'

export const CheckTodaysGoalItem = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  return (
    <CheckTodaysGoalsContainer onClick={() => navigation.push(ChildPaths.goalsToday)}>
      <GoalsIcon stroke={Colors.purple} height={36} width={36} />
      <CheckTodaysGoalsTitle>
        {' '}
        <strong>
          {t('pages.home.section.goalProgress.carousel.checkTodaysGoals')}
        </strong>{' '}
      </CheckTodaysGoalsTitle>
      <RightArrowIcon fill={Colors.black} />
    </CheckTodaysGoalsContainer>
  )
}
