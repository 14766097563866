import { camelCase } from 'lodash'

import { RootPaths } from 'utils/helpers'

export const getTextFromPath = (path: string) => {
  if (!path.startsWith('/')) return path
  return camelCase(path.slice(1))
}

export const humanizeText = (text: string) => {
  return text.replace(/\W+/, ' ')
}

export const getMatchedPaths = () => {
  const basePathsRegex = Object.values(RootPaths).join('|')
  return location.pathname.match(
    new RegExp(`^(?<rootPath>${basePathsRegex})/?(?<nestedRoutes>.+)?`)
  )
}
