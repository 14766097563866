import { Colors } from 'theme'

export const MuiButtonConfig = (
  accentColor: string,
  primaryColor: string,
  secondaryColor: string
) => ({
  styleOverrides: {
    root: {
      borderRadius: '100px',
      minHeight: '40px',
      fontSize: '14px',
      lineHeight: '24px',
      textTransform: 'capitalize' as any,
      padding: '8px 20px',
      fontWeight: 'bold'
    },
    contained: {
      boxShadow: 'none'
    },
    containedPrimary: {
      backgroundColor: accentColor
    },
    containedSecondary: {
      backgroundColor: secondaryColor
    },
    outlined: {
      borderColor: Colors.gray,
      color: Colors.gray
    },
    outlinedPrimary: {
      borderColor: accentColor,
      color: accentColor
    },
    outlinedSecondary: {
      borderColor: primaryColor,
      color: primaryColor
    },
    sizeSmall: {
      minHeight: '32px',
      fontSize: '12px',
      lineHeight: '18px'
    },
    sizeLarge: {
      minHeight: '40px',
      fontSize: '16px',
      lineHeight: '32px'
    },
    startIcon: {
      marginRight: '5px'
    }
  },
  // TODO: Refactor above styles into variants for finer-grain control
  variants: [
    {
      // Note: MUI v5 removed the "default" black color and made "primary default"
      // This simply overrides it to previous colors
      props: { variant: 'text' as any, color: 'primary' as any },
      style: { color: accentColor }
    }
  ]
})
