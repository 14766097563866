import { sortBy } from 'lodash'

import { createSelector } from '@reduxjs/toolkit'

import { ADMIN_STORES } from 'slices/constants'

import { adminStoresAdapter } from './reducer'

import { RootState } from 'reduxStore'

export const selectAdminStoresLoading = (state: RootState) =>
  state[ADMIN_STORES].isLoading

export const adminStoresSelectors = adminStoresAdapter.getSelectors<RootState>(
  (state) => state[ADMIN_STORES]
)

export const selectAdminStores = createSelector(
  adminStoresSelectors.selectAll,
  (store) => sortBy(store, ['created']) // NOTE: lodash's sortBy return's a new array to avoid mutation
)

export const getAdminStoresFilteredByStoreGroup = createSelector(
  selectAdminStores,
  (_state: RootState, storeGroupId: string | undefined | null) => storeGroupId,
  (stores, storeGroupId) => {
    if (!storeGroupId) return stores
    return stores.filter((store) => store.group_id === storeGroupId)
  }
)
