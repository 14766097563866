import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { BackButton } from 'components/BackButton'
import { HeaderContainer } from 'components/styled/headerContainer'

import { fetchPointTotalsThunk, getPointsBalance } from 'slices/points'

import {
  StyledPerkHeader,
  StyledPerkHeaderContent,
  StyledPerkHeaderPoints
} from './styles'

export default function PerkHeader() {
  const { t } = useTranslation()
  const pointsBalance = useSelector(getPointsBalance)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPointTotalsThunk({ getCachedResults: true }))
  }, [])

  return (
    <HeaderContainer>
      <StyledPerkHeader>
        <BackButton />
        <StyledPerkHeaderContent variant="body1" />
      </StyledPerkHeader>
      <StyledPerkHeaderPoints variant="h3">
        {t('pages.perk.perkHeader.title', { amount: pointsBalance })}
      </StyledPerkHeaderPoints>
    </HeaderContainer>
  )
}
