// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import clsx from 'clsx'
import { ThemeType } from 'theme'

import { useScrollTrigger } from '@mui/material'

import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { BackButton, BackButtonProps } from 'components/BackButton'
import Footer from 'components/footer'
import Header from 'components/header'
import Layout from 'components/layout'
import LayoutContent from 'components/layoutContent'
import ScrollToTop from 'components/scrollToTop'

import { getIsOpen } from 'slices/storeSwitcher'

import { StyledSubheaderLayoutHeader, StyledSubheaderLayoutSubheader } from './styles'

export interface PageWithSubheaderProps {
  backButtonProps?: BackButtonProps
  children: ReactNode
  className?: string
  contentClass?: string
  headerClass?: string
  showBackButton?: boolean
  subheaderClass?: string
  subheaderContent?: ReactNode
  themeType?: ThemeType
  title?: string
}

export default function PageWithSubheader({
  backButtonProps = {},
  children,
  className,
  contentClass,
  headerClass,
  showBackButton = false,
  subheaderClass,
  subheaderContent,
  themeType = 'light',
  title
}: PageWithSubheaderProps) {
  const hasScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 25
  })
  const isStoreSwitcherOpen = useSelector(getIsOpen)

  const iconColor = ['dark', 'orange', 'light'].includes(themeType) ? 'white' : 'black'
  return (
    <Layout className={clsx('LayoutPageWithSubheader', className)} themeType={themeType}>
      <ScrollToTop />
      <StyledSubheaderLayoutHeader
        themeType={themeType}
        isStoreSwitcherOpen={isStoreSwitcherOpen}
        showBackButton={showBackButton}
        className={clsx(
          'LayoutPageWithSubheader__header',
          headerClass,
          subheaderContent &&
            hasScrolled &&
            'LayoutPageWithSubheader__header--hasScrolled'
        )}
      >
        <Header title={title} onBack={backButtonProps?.onBack}>
          {showBackButton ? (
            <BackButton color={iconColor} onlyIcon {...backButtonProps} />
          ) : null}
        </Header>
        {subheaderContent && (
          <StyledSubheaderLayoutSubheader
            component="section"
            elevation={0}
            className={clsx('LayoutPageWithSubheader__subheader', subheaderClass)}
          >
            {subheaderContent}
          </StyledSubheaderLayoutSubheader>
        )}
      </StyledSubheaderLayoutHeader>
      <LayoutContent
        className={clsx('LayoutPageWithSubheader__content', contentClass)}
        themeType={themeType}
        showHeaderEffect
      >
        {children}
      </LayoutContent>
      <Footer />
    </Layout>
  )
}
