import clsx from 'clsx'

import { useTranslation } from 'react-i18next'

import { StyledContactUsWidget, StyledContactUsWidgetTitle } from './widget.styles'

import ContactUsButton from '../button'

export interface ContactUsWidgetProps {
  className?: string
  title?: string
  cta?: string
  messageBody?: string
}

export function ContactUsWidget({
  className,
  title,
  cta,
  messageBody
}: ContactUsWidgetProps) {
  const { t } = useTranslation()
  const titleEl = title && (
    <StyledContactUsWidgetTitle data-testid="title" variant="body2">
      {title}
    </StyledContactUsWidgetTitle>
  )
  return (
    <StyledContactUsWidget className={clsx(className)}>
      {titleEl}
      <ContactUsButton
        fullWidth
        variant="contained"
        color="primary"
        messageBody={messageBody}
      >
        {cta || t('contactUs.button.title')}
      </ContactUsButton>
    </StyledContactUsWidget>
  )
}

export default ContactUsWidget
