import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SnackbarOptions } from 'components/snackbar/interfaces'

import { SNACKBAR } from 'slices/constants'

interface SnackbarState {
  snackbar: SnackbarOptions
}

const initialState: SnackbarState = {
  snackbar: {
    open: false,
    type: 'info',
    message: '',
    autoHideDuration: 6000
  }
}

export const snackbarSlice = createSlice({
  name: SNACKBAR,
  initialState,
  reducers: {
    setSnackbar(state, action: PayloadAction<SnackbarOptions>) {
      const snackbarOptions = action.payload
      state.snackbar = snackbarOptions
    }
  }
})

export default snackbarSlice.reducer
