import { Collapse } from '@mui/material'

import { useState } from 'react'

import { HelpArticle } from 'types'

import {
  StyledHelpArticle,
  StyledHelpArticleTrigger,
  StyledHelpArticleTitle,
  StyledHelpArticleDescription,
  StyledHelpArticleTriggerIcon
} from './styles'

interface HelpArticleItemProps {
  article: HelpArticle
}

export default function HelpArticleItem({ article }: HelpArticleItemProps) {
  const [isOpen, setIsOpen] = useState(false)

  const onClickTrigger = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <StyledHelpArticle>
      <StyledHelpArticleTrigger variant="text" onClick={onClickTrigger} disableRipple>
        <StyledHelpArticleTriggerIcon isOpen={isOpen} />
        <StyledHelpArticleTitle variant="body1">{article.title}</StyledHelpArticleTitle>
      </StyledHelpArticleTrigger>
      <Collapse in={isOpen}>
        <StyledHelpArticleDescription variant="body1">
          {article.description} {article.link && <a href={article.link}>Click here</a>}
        </StyledHelpArticleDescription>
      </Collapse>
    </StyledHelpArticle>
  )
}
