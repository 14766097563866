import { Button } from '@mui/material'

import { ComponentProps, ReactNode, Reducer, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { removeUndefinedFromObject } from 'utils/helpers'

import {
  StyledGlobalBottomModal,
  StyledGlobalModalActionButton,
  StyledGlobalSimpleModal
} from './GlobalModalProvider.styles'

import { GlobalModalContext, GlobalModalTypes } from '.'

type GlobalModalType = keyof typeof GlobalModalTypes

interface BaseActionProps {
  showCTA?: boolean
  closeButtonText?: string
  showCloseButton?: boolean
  title?: string | null
  body?: ReactNode
  modalType?: GlobalModalType
  header?: ReactNode
  displayCloseButtonInHeader?: boolean
  onClose?: () => void
}

interface GlobalModalOpenActionPropsWithoutCTA extends BaseActionProps {
  showCTA?: false
}

interface CTAProps extends Omit<ComponentProps<typeof Button>, 'children'> {
  // Require children to show button text
  children: NonNullable<ComponentProps<typeof Button>['children']>
}

interface GlobalModalOpenActionPropsWithCTA extends BaseActionProps {
  showCTA?: true // override value
  ctaProps: CTAProps // available only if showCTA is true
}

// Disjoint Union to allow type safety when conditionally displaying footer
export type GlobalModalOpenActionProps =
  | GlobalModalOpenActionPropsWithCTA
  | GlobalModalOpenActionPropsWithoutCTA

interface BaseStateProps {
  isOpen: boolean

  showCTA: boolean
  closeButtonText: string
  showCloseButton: boolean
  title: string | null
  body: ReactNode
  modalType: GlobalModalType
  header: ReactNode
  displayCloseButtonInHeader: boolean
  onClose?: () => void
}
interface GlobalModalProviderStateWithoutCTA extends BaseStateProps {
  showCTA: false
}

interface GlobalModalProviderStateWithCTA extends BaseStateProps {
  showCTA: true // override this value
  ctaProps: CTAProps // available only if showCTA is true
}

// Disjoint Union to allow type safety when conditionally displaying footer
export type GlobalModalProviderState =
  | GlobalModalProviderStateWithoutCTA
  | GlobalModalProviderStateWithCTA

interface GlobalModalProviderProps {
  children: ReactNode
}

export default function GlobalModalProvider({ children }: GlobalModalProviderProps) {
  const { t } = useTranslation()
  const [state, setState] = useReducer<
    Reducer<GlobalModalProviderState, Partial<GlobalModalProviderState>>
  >((state, newState) => ({ ...state, ...removeUndefinedFromObject(newState) }), {
    isOpen: false,
    closeButtonText: t('modals.global.closeButtonText'),
    title: t('modals.global.title'),
    body: t('modals.global.body'),
    modalType: 'simple',
    showCloseButton: false,
    header: null,
    displayCloseButtonInHeader: true,
    ctaProps: { children: t('modals.global.actionText')! },
    showCTA: true
  })

  const {
    isOpen,
    closeButtonText,
    title,
    body,
    modalType,
    showCloseButton,
    header,
    displayCloseButtonInHeader,
    showCTA,
    onClose
  } = state

  const ModalComponent = (() => {
    switch (modalType) {
      case 'simple':
        return StyledGlobalSimpleModal
      case 'bottom':
        return StyledGlobalBottomModal
      default:
        return StyledGlobalSimpleModal
    }
  })()

  const closeGlobalModal = () => {
    setState({
      isOpen: false
    })
    if(onClose) onClose()
  }

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (showCTA) {
      state.ctaProps.onClick?.(event)
    }
    closeGlobalModal()
  }

  const openGlobalModal = (props?: GlobalModalOpenActionProps) => {
    setState({
      ...(props || {}),
      isOpen: true
    })
  }

  const footer = (
    <>
      {showCloseButton && (
        <StyledGlobalModalActionButton
          onClick={closeGlobalModal}
          variant="outlined"
          color="error"
        >
          {closeButtonText}
        </StyledGlobalModalActionButton>
      )}
      {showCTA && (
        <StyledGlobalModalActionButton
          variant="outlined"
          color="primary"
          {...(state.ctaProps || {})}
          onClick={handleActionClick}
        >
          {state.ctaProps.children}
        </StyledGlobalModalActionButton>
      )}
    </>
  )

  return (
    <GlobalModalContext.Provider
      value={{
        openGlobalModal,
        closeGlobalModal
      }}
    >
      <>
        {children}
        <ModalComponent
          data-testid="GlobalModal"
          isOpen={isOpen}
          onClose={closeGlobalModal}
          title={title}
          content={body}
          footer={footer}
          header={header}
          displayCloseButtonInHeader={displayCloseButtonInHeader}
        />
      </>
    </GlobalModalContext.Provider>
  )
}
