import { Card, styled, Typography } from '@mui/material'

export const StyledContactUsWidget = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  textAlign: 'center',
  backgroundColor: theme.branding.secondaryColor
}))

export const StyledContactUsWidgetTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1, 3),
  fontWeight: 'bold',
  '& + button': {
    color: theme.palette.getContrastTextColor(theme.branding.secondaryColor)
  }
}))
