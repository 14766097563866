import { HTMLAttributes, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useNavigation } from 'hooks/useNavigation'

import { StyledFooter, StyledFooterItem } from './styles'

import routeConfig, { FooterRoute } from './footerRouteConfig'
import { findURLmatchingConfig, getFooterPath } from './utils'

export type FooterProps = Partial<HTMLAttributes<HTMLDivElement>>

export function Footer({ ...props }: FooterProps) {
  const location = useLocation()
  const navigation = useNavigation()
  const { t } = useTranslation()
  const [route, setRoute] = useState<FooterRoute>()

  // Sync active tab with URL changes
  useEffect(() => {
    const newConfig = findURLmatchingConfig(routeConfig, location.pathname)
    if (newConfig) setRoute(newConfig.value)
  }, [location.pathname])

  const handleChange = (_event: SyntheticEvent, newValue: FooterRoute) => {
    const config = routeConfig.find((rc) => rc.value === newValue)
    if (config) {
      const newPath = getFooterPath(config)
      if (location.pathname !== newPath) {
        setRoute(newValue)
        navigation.push(newPath)
      }
    }
  }

  return (
    <StyledFooter {...props} value={route} onChange={handleChange} showLabels>
      {routeConfig.map(({ translatedLabelPath, icon, value }) => (
        <StyledFooterItem
          key={`${value}-${route}`}
          label={t(translatedLabelPath)}
          icon={icon}
        />
      ))}
    </StyledFooter>
  )
}

export default Footer
