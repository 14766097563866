import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ReactComponent as AvatarIconSVG } from 'assets/icons/AppIcons/AvatarIcon.svg'
import { ReactComponent as OnarollIconSVG } from 'assets/icons/AppIcons/OnarollIcon.svg'
import { ReactComponent as StoreIconSVG } from 'assets/icons/AppIcons/StoreIcon.svg'

import { BackButton } from 'components/BackButton'

export const BrandLogo = styled('img')({
  width: 'auto',
  objectFit: 'contain',
  maxWidth: '100%',
  height: '22px'
})

export const HeaderContainer = styled(AppBar)({
  minHeight: 'auto',
  backgroundColor: 'inherit'
})

export const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(3.2),
  paddingRight: theme.spacing(3.2),
  backgroundColor: theme.palette.colors.white
}))

export const HeaderTitleContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 5)
}))

export const HeaderTitle = styled(Typography)({
  textTransform: 'capitalize'
})

export const HeaderIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: 0,
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(2)
  }
}))

export const HeaderIcon = styled(AvatarIconSVG)(({ theme }) => ({
  height: '100%',
  width: '100%',
  maxHeight: '32px',
  maxWidth: '32px',
  '&': {
    fill: theme.branding.secondaryColor
  },
  '& > g > circle': {
    stroke: theme.palette.getContrastTextColor(theme.branding.secondaryColor)
  }
}))

export const StoreIcon = styled(StoreIconSVG)(({ theme }) => ({
  height: '100%',
  width: '100%',
  maxHeight: '32px',
  maxWidth: '32px',
  '& > path': {
    stroke: theme.branding.secondaryColor
  }
}))

export const HeaderBackButton = styled(BackButton)(({ theme }) => ({
  '& .MuiTypography-root': {
    ...theme.customTypography.display2
  }
}))

export const HeaderChildren = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 5)
}))

export const OnarollIcon = styled(OnarollIconSVG)(({ theme }) => ({
  height: '100%',
  width: '100%',
  maxHeight: '32px',
  maxWidth: '48px',
  fill: theme.palette.colors.black
}))
