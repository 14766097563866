import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Goal } from 'types'

import Page from 'layouts/page'

import Placeholder, { RowItemLoader } from 'placeholder'

import useAppDispatch from 'hooks/useAppDispatch'

import { fetchAchievedGoals, getAchievedGoals, getGoalsLoading } from 'slices/goals'

import { StyledAchievedGoalsContainer } from './styles'

import AchievedGoalItem from './achievedGoalItem'

export default function AchievedGoals() {
  const dispatch = useAppDispatch()
  const achievedGoals = useSelector(getAchievedGoals) ?? []
  const isLoading = useSelector(getGoalsLoading)

  useEffect(() => {
    dispatch(
      fetchAchievedGoals({
        getCachedResults: true
      })
    )
  }, [])

  return (
    <Page>
      <StyledAchievedGoalsContainer>
        <Placeholder isLoading={isLoading} loader={RowItemLoader} amount={4}>
          {achievedGoals.map((goal: Goal, i: number) => (
            <AchievedGoalItem key={`${goal.title}-achievedGoal-${i}`} goal={goal} />
          ))}
        </Placeholder>
      </StyledAchievedGoalsContainer>
    </Page>
  )
}
