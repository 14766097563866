import clsx from 'clsx'
import JsBarcode from 'jsbarcode'

import { Typography } from '@mui/material'

import { useEffect, useState, useRef, MouseEvent, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { onA11yKeyDown } from 'utils/helpers'

import { BarcodePin, BarcodeSVG, BarcodeSVGContainer, BarcodeCopyText } from './styles'

export interface BarcodeProps {
  title?: string
  value: string
  className?: string
  pin?: string
}

export function Barcode({
  className,
  title = 'Redemption Info',
  pin,
  value,
  ...otherProps
}: BarcodeProps) {
  const ref = useRef<SVGSVGElement>(null)
  const [copied, setCopied] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (ref?.current && value) {
      JsBarcode(ref.current, value, { width: 4 })
    }
  }, [value])

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null
    if (copied) {
      timer = setTimeout(() => setCopied(false), 2000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [copied])

  const onClick = async (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    // This will allow us to render this button within another clickable container
    event.stopPropagation()
    try {
      await navigator?.clipboard.writeText(value)
      setCopied(true)
    } catch (error) {
      // eslint-disable-next-line
      console.error('Async: Could not copy text: ', error, value)
    }
  }

  const copyText = copied ? (
    <span>{t('pages.perk.redemption.barcode.copied')}</span>
  ) : (
    <span>{t('pages.perk.redemption.barcode.clickToCopy')}</span>
  )

  return (
    <BarcodeSVGContainer
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(event) => onA11yKeyDown(event, onClick)}
      className={clsx('Barcode', className)}
      {...otherProps}
    >
      <Typography variant="body1">
        {title}
        <BarcodeCopyText copied={Boolean(copied)}>({copyText})</BarcodeCopyText>
      </Typography>
      <BarcodeSVG ref={ref} />
      {pin && (
        <BarcodePin variant="body1">
          Pin: <span>{pin}</span>
        </BarcodePin>
      )}
    </BarcodeSVGContainer>
  )
}

export default Barcode
