import { RewardDiscount, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { PERKS, ONBOARDING_PERKS } from 'slices/constants'

import { getDefaultSelectedStore } from 'slices/storeSwitcher'
import { authenticatedThunkCreator } from 'slices/utils'

import { conditionalObject } from 'utils/helpers'

import { RootState } from 'reduxStore'

export const fetchOnboardingPerksThunk = authenticatedThunkCreator<
  RewardDiscount[],
  FetchCachedThunkProps | undefined
>(`${PERKS}/fetchOnboardingPerksThunk`, async (authedDTO, { getState }) => {
  const state = getState() as RootState
  const selectedStoreId = getDefaultSelectedStore(state)?.id
  return await fetchRecords(
    {
      ...authedDTO,
      ...conditionalObject(
        {
          queryParams: { store_id: selectedStoreId }
        },
        selectedStoreId
      )
    },
    ONBOARDING_PERKS
  )
})
