import { Colors, getSpacing } from 'theme'

import { alpha, Button, Link, styled, Typography } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledOnboardingStepsFooter = styled('footer')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  backgroundColor: theme.palette.colors.black
}))

export const StyledOnboardingStepButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isLastPage'
})<{ isLastPage: boolean }>(({ isLastPage, theme }) => {
  const backgroundColor = isLastPage
    ? theme.palette.colors.purple
    : theme.palette.colors.yellow
  const color = isLastPage ? theme.palette.common.white : theme.palette.common.black
  return {
    backgroundColor,
    color,
    marginBottom: theme.spacing(4),
    ':hover': {
      backgroundColor: alpha(backgroundColor, 0.8)
    }
  }
})

export const StyledPageIndices = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice' && prop !== 'isOnTinyDevice'
})<{ isOnSmallDevice?: boolean; isOnTinyDevice?: boolean }>(
  ({ isOnSmallDevice, isOnTinyDevice }) => ({
    height: 50,
    ...conditionalObject({ height: 42 }, isOnSmallDevice && !isOnTinyDevice),
    ...conditionalObject({ height: 15 }, isOnTinyDevice)
  })
)

export const StyledOnboardingFooterTermsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.white,
  marginBottom: theme.spacing(6)
}))

export const StyledOnboardingFooterTermsLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.yellow,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  ':hover': {
    color: theme.palette.colors.purple
  }
}))

export const footerStyles = {
  padding: getSpacing(5),
  paddingTop: 0
} as React.CSSProperties

export const fixedFooterStyles = {
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0
} as React.CSSProperties

export const disabledButtonStyles = {
  background: Colors.grayDisabled,
  color: Colors.white
} as React.CSSProperties
