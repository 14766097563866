import { HTMLAttributes } from 'react'

import { ReactComponent as Checkmark } from 'assets/icons/AppIcons/OnboardingCheckmarkIcon.svg'

import { FavoritePerk } from 'types'

import {
  StyledOnboardingPerkItem,
  StyledOnboardingPerkItemCheckmarkWrapper,
  StyledOnboardingPerkItemImage,
  StyledOnboardingPerkItemImageText
} from './styles'

interface PerkItemProps {
  perk: FavoritePerk
  handleSelected: (favPerk: FavoritePerk) => void
  canBeSelected: boolean
  isSelected: boolean
  style?: HTMLAttributes<HTMLDivElement>['style']
}

const PerkItem = ({
  perk,
  canBeSelected,
  isSelected,
  handleSelected,
  ...restProps
}: PerkItemProps) => {
  const { id, title, image_url } = perk

  const onClick = () => {
    if (canBeSelected) {
      handleSelected(perk)
    }
  }

  return (
    <StyledOnboardingPerkItem
      title={title}
      canBeSelected={canBeSelected}
      onClick={onClick}
      key={id}
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.03 }}
      data-testid={`OnboardingPerkStep-GridItem${id}`}
      {...restProps}
    >
      {isSelected && (
        <StyledOnboardingPerkItemCheckmarkWrapper
          data-testid={`OnboardingPerkStep-PerkItemCheckmark${id}`}
        >
          <Checkmark />
        </StyledOnboardingPerkItemCheckmarkWrapper>
      )}
      {image_url ? (
        <StyledOnboardingPerkItemImage
          src={image_url}
          isSelected={isSelected}
          data-testid={`OnboardingPerkStep-PerkItemImage${id}`}
        />
      ) : (
        <StyledOnboardingPerkItemImageText variant="h3">
          {title}
        </StyledOnboardingPerkItemImageText>
      )}
    </StyledOnboardingPerkItem>
  )
}

export default PerkItem
