import { createSelector } from '@reduxjs/toolkit'

import { AdminListFilters } from 'types'

import { ADMIN_TEAM_MEMBERS } from 'slices/constants'

import { RootState } from 'reduxStore'

import { TeamMembersAdapter } from '.'
import { sortByMissingPhone } from './utils'

export const teamMemberSelectors = TeamMembersAdapter.getSelectors<RootState>(
  (state) => state[ADMIN_TEAM_MEMBERS]
)

export const getAllActiveTeamMembers = createSelector(
  teamMemberSelectors.selectAll,
  (tms) => tms.filter((tm) => tm?.active && tm?.onboarded_at)
)

export const getAllPendingTeamMembers = createSelector(
  teamMemberSelectors.selectAll,
  (tms) => tms.filter((tm) => tm?.active && !tm?.onboarded_at).sort(sortByMissingPhone)
)

export const getTeamMembersResponse = (state: RootState) =>
  state[ADMIN_TEAM_MEMBERS].teamMembersResponse

export const getTeamMembersLoading = (state: RootState) =>
  state[ADMIN_TEAM_MEMBERS].isLoading

export const getTeamMembersQuery = (state: RootState) => state[ADMIN_TEAM_MEMBERS].query

export const getTeamMembersSearch = createSelector(
  getTeamMembersQuery,
  (query: AdminListFilters | undefined) => {
    return query?.search || ''
  }
)

export const getTeamMembersIds = (state: RootState) => state[ADMIN_TEAM_MEMBERS].ids
