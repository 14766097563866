import Colors from './Colors'

export const ACTIVE_RING_COLOR = {
  green: '#00D78A',
  orange: '#E16C00',
  red: '#A02448',
  blue: '#3F46FF',
  hot_pink: '#F125FF',
  brown: '#6F5234',
  deep_magenta: '#704177',
  olive_green: '#57741C',
  dark_green: '#128450',
  purple: '#354080'
}

export const INACTIVE_RING_COLOR = {
  green: '#E6F9F2',
  orange: '#FFF5EC',
  red: '#FED5E3',
  blue: '#E9F2FF',
  hot_pink: '#FEF1FF',
  brown: '#E9DECE',
  deep_magenta: '#F4DAFF',
  olive_green: '#E4F6B8',
  dark_green: '#E6F9F2',
  purple: '#DDE1FA'
}

export const ACTIVE_RING_COLORS = [
  ACTIVE_RING_COLOR.green,
  ACTIVE_RING_COLOR.orange,
  ACTIVE_RING_COLOR.red,
  ACTIVE_RING_COLOR.blue,
  ACTIVE_RING_COLOR.hot_pink,
  ACTIVE_RING_COLOR.brown,
  ACTIVE_RING_COLOR.deep_magenta,
  ACTIVE_RING_COLOR.olive_green,
  ACTIVE_RING_COLOR.dark_green,
  ACTIVE_RING_COLOR.purple
]

export const INACTIVE_RING_COLORS = [
  INACTIVE_RING_COLOR.green,
  INACTIVE_RING_COLOR.orange,
  INACTIVE_RING_COLOR.red,
  INACTIVE_RING_COLOR.blue,
  INACTIVE_RING_COLOR.hot_pink,
  INACTIVE_RING_COLOR.brown,
  INACTIVE_RING_COLOR.deep_magenta,
  INACTIVE_RING_COLOR.olive_green,
  INACTIVE_RING_COLOR.dark_green,
  INACTIVE_RING_COLOR.purple
]

export type ActiveRingColor = (typeof ACTIVE_RING_COLORS)[number]
export type InactiveRingColor = (typeof INACTIVE_RING_COLORS)[number]

export interface CustomRingColor {
  active: ActiveRingColor
  inactive: InactiveRingColor
}

export interface RingTheme {
  defaultInactive: string
  color1: CustomRingColor
  color2: CustomRingColor
  color3: CustomRingColor
  color4: CustomRingColor
  color5: CustomRingColor
}

export const DefaultRing = {
  defaultInactive: Colors.inactive,
  color1: {
    active: ACTIVE_RING_COLOR.green,
    inactive: INACTIVE_RING_COLOR.green
  },
  color2: {
    active: ACTIVE_RING_COLOR.red,
    inactive: INACTIVE_RING_COLOR.red
  },
  color3: {
    active: ACTIVE_RING_COLOR.orange,
    inactive: INACTIVE_RING_COLOR.orange
  },
  color4: {
    active: ACTIVE_RING_COLOR.blue,
    inactive: INACTIVE_RING_COLOR.blue
  },
  color5: {
    active: ACTIVE_RING_COLOR.hot_pink,
    inactive: INACTIVE_RING_COLOR.hot_pink
  }
}
