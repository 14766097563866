import clsx from 'clsx'

import { ReactNode } from 'react'

import { StyledJackpotSection } from './styles'

interface JackpotSectionProps {
  children: ReactNode
  className?: string
  moreSpace?: boolean
}

export interface JackpotSectionStyleProps {
  moreSpace: boolean
}

export default function JackpotSection({
  children,
  className,
  moreSpace
}: JackpotSectionProps) {
  return (
    <StyledJackpotSection moreSpace={Boolean(moreSpace)} className={clsx(className)}>
      {children}
    </StyledJackpotSection>
  )
}
