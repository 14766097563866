// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { JackpotDrawing } from 'types'

import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { DRAWINGS, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchJackpotCurrentDrawingThunk } from './actions'

export const drawingsAdapter = createEntityAdapter<JackpotDrawing>({
  selectId: (drawing) => drawing.id,
  sortComparer: (a, b) => Date.parse(a.drawing_at) - Date.parse(b.drawing_at)
})

export const drawingsSlice = createSlice({
  name: DRAWINGS,
  initialState: drawingsAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          fetchJackpotCurrentDrawingThunk.fulfilled,
        ),
        (state, action) => {
          if (action.payload.drawing.id) {
            drawingsAdapter.setOne(state, action.payload.drawing)
          }
          handleRequestFulfilled(state)
        }
      )
      .addMatcher(
        isAnyOf(
          fetchJackpotCurrentDrawingThunk.pending,
        ),
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          fetchJackpotCurrentDrawingThunk.rejected,
        ),
        (state, action) => {
          handleRequestRejected(state, action)
        }
      )
  }
})

export default drawingsSlice.reducer
