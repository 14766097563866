import { STORES } from 'slices/constants'

import { storesAdapter } from './reducer'

import { RootState } from 'reduxStore'

export const selectStoresLoading = (state: RootState) => state[STORES].isLoading

export const selectStoresHasLoaded = (state: RootState) => state.stores.hasLoaded

export const getStoresError = (state: RootState) => state.stores.error

export const storesSelectors = storesAdapter.getSelectors<RootState>(
  (state) => state.stores
)
