import { useRef, useEffect } from 'react'

interface MutableRefObject<T> {
  current: T
}

export function usePrevious<T = string>(
  value: T,
  defaultValue?: T
): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T | undefined>(defaultValue)

  useEffect(() => {
    ref.current = value
  }, [value])

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
