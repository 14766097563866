import { fetchRecords, postRecord } from 'api'

import { FavoritePerk, UpsertThunkProps } from 'types'

import { PERKS } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

export const fetchFavoritePerksThunk = authenticatedThunkCreator<FavoritePerk[]>(
  `${PERKS}/fetchFavoritePerksThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, 'favoriteRewards')
)

export const postFavoritePerksThunk = authenticatedThunkCreator<
  FavoritePerk[],
  UpsertThunkProps<{ reward_ids?: string[] }>
>(
  `${PERKS}/postFavoritePerksThunk`,
  async (authedDTO) => await postRecord(authedDTO, 'favoriteRewards')
)
