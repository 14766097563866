import { Typography } from '@mui/material'

import { Goal } from 'types'

import CollapsibleRowItem from 'components/collapsibleRowItem'
import GoalIcon, { GoalIconTypes } from 'components/icons/GoalIcon/GoalIcon'

import { StyledAchievedGoalItemTitle, StyledAchievedGoalItemDescription } from './styles'

interface AchievedGoalItemProps {
  goal: Goal
}

export default function AchievedGoalItem({ goal }: AchievedGoalItemProps) {
  if (!goal) return null

  const { title, icon, description, points } = goal

  const content = (
    <>
      <StyledAchievedGoalItemTitle variant="body2">{title}</StyledAchievedGoalItemTitle>
      <StyledAchievedGoalItemDescription variant="body2">
        {description}
      </StyledAchievedGoalItemDescription>
    </>
  )

  return (
    <CollapsibleRowItem
      item={goal}
      image={<GoalIcon type={icon as GoalIconTypes} />}
      content={content}
    >
      <Typography variant="body1">Points: {`${points} Pts`}</Typography>
    </CollapsibleRowItem>
  )
}
