import { Snackbar, SnackbarCloseReason, SnackbarProps } from '@mui/material'
import { Alert } from '@mui/material'

import { SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { getSnackbar, setSnackbar } from 'slices/snackbar'

export type SnackBarProps = SnackbarProps & Omit<JSX.IntrinsicElements['div'], 'ref'>
export function SnackBar({ ...props }: SnackBarProps) {
  const dispatch = useAppDispatch()

  const snackbar = useSelector(getSnackbar)

  const { open, type, message, autoHideDuration } = snackbar

  const handleSnackbarClose = (
    _event: Event | SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(setSnackbar({ open: false, type, message }))
  }

  const handleAlertClose = () => {
    dispatch(setSnackbar({ open: false, type, message }))
  }

  return (
    <Snackbar
      {...props}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={autoHideDuration === undefined ? 6000 : autoHideDuration}
      onClose={handleSnackbarClose}
    >
      <Alert elevation={6} variant="filled" onClose={handleAlertClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar
