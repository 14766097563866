import { FeatureFlag, FeatureFlags } from 'components/FeatureFlag'
import UpsertFormContainer, { FormTextField } from 'components/form'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'
import useAppDispatch from 'hooks/useAppDispatch'
import { useNavigation } from 'hooks/useNavigation'
import { RootState } from 'reduxStore'
import {
  fetchTeamMemberThunk,
  getTeamMembersLoading,
  teamMemberSelectors,
  updateTeamMemberThunk
} from 'slices/admin'
import { TeamMemberV2 } from 'types'
import { TeamLeaderPaths } from 'utils/helpers'
import { UrlParamTypes } from 'utils/url'

import { useEffect, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Redirect, generatePath, useParams } from 'react-router-dom'

import { StyledForm, SubmitButton } from '../shared/styles'

interface TeamMemberFormProps {
  entityType: string
  headerTitle?: string
}

interface FormValues {
  first_name: string
  last_name: string
  phone: string
  birthday: string
}
const formValueFields: (keyof FormValues)[] = ['first_name', 'last_name', 'phone', 'birthday']

export default function TeamMemberEdit({ entityType, headerTitle }: TeamMemberFormProps) {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  const { id: memberIdFromParams } = useParams<UrlParamTypes>()

  const allTeamMembers = useSelector(teamMemberSelectors.selectAll)

  const selectedTeamMember = useSelector((state: RootState) =>
    teamMemberSelectors.selectById(state, memberIdFromParams as string)
  )
  const isSelectedTeamMemberLoading = useSelector(getTeamMembersLoading)

  useEffect(() => {
    if (!selectedTeamMember) dispatch(fetchTeamMemberThunk({ pk: memberIdFromParams! }))
  }, [selectedTeamMember])

  const allPhoneNumbers = useMemo(() => {
    //Ex: ['+19525002213'] => ['19525002213']
    // TODO: Revisit - If the need arises where we need to do this in multiple files,
    // we should probably move this over into its own selector
    return allTeamMembers.filter((tm) => tm?.phone).map((tm) => tm.phone.replace('+', ''))
  }, [allTeamMembers])

  const validatePhone = (value: string) => {
    // Looks like phones come prepended with "+"
    const formattedPhone = value ? value.replace('+', '') : ''
    // Dirty way to check for 10 digit phone or phone with area code
    // or if the phone number already exists within the store/group
    const isValidLength = [10, 11].includes(formattedPhone.length)
    return !isValidLength || allPhoneNumbers.includes(value)
      ? 'Verify if the phone number is correct'
      : undefined
  }

  const getInitialValues = useMemo(() => {
    if (!selectedTeamMember) return null
    const { first_name, last_name, phone, birthday } = selectedTeamMember as TeamMemberV2

    return {
      first_name,
      last_name,
      phone,
      birthday
    }
  }, [allTeamMembers, isSelectedTeamMemberLoading])

  const formatValues = (values: FormValues) => {
    const { first_name, last_name, phone, birthday } = values

    return {
      first_name,
      last_name,
      phone,
      birthday
    }
  }

  const getFormSubmissionErrors = (stringifiedErr: string) => {
    const errObj = JSON.parse(stringifiedErr)
    // Filter errObj for errors related to the form only
    return Object.keys(errObj).reduce((memo, key) => {
      if (formValueFields.includes(key as keyof FormValues)) {
        return { ...memo, [key]: errObj[key] }
      }
      return memo
    }, {})
  }

  const onSubmit = async (values: FormValues) => {
    const data = formatValues(values)
    return new Promise((resolve) => {
      if (memberIdFromParams) {
        dispatch(updateTeamMemberThunk({ id: memberIdFromParams as string, data }))
          .unwrap()
          .then(() => {
            const pathname = generatePath(TeamLeaderPaths.teamMembers, { id: memberIdFromParams })
            resolve(navigation.push({ pathname }))
          })
          .catch((err) => {
            resolve(getFormSubmissionErrors(err))
          })
      } else {
        resolve(undefined)
      }
    })
  }

  const renderNameFields = () => (
    <>
      <Field<string> required name="first_name" component={FormTextField} />
      <Field<string> required name="last_name" component={FormTextField} />
    </>
  )

  const renderPhoneNumberField = () => (
    <Field<string>
      required
      name="phone"
      type="tel"
      component={FormTextField}
      validate={validatePhone}
    />
  )

  const renderBirthdayField = () => (
    <Field<string> name="birthday" type="date" shrinkLabel={true} component={FormTextField} />
  )

  const actionType = 'Update'

  const form = (
    <Form
      subscription={{
        submitting: true,
        pristine: true,
        submitError: true,
        hasValidationErrors: true
      }}
      onSubmit={onSubmit}
      initialValues={getInitialValues}
      render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
        <StyledForm onSubmit={handleSubmit}>
          {renderNameFields()}
          {renderPhoneNumberField()}
          {renderBirthdayField()}
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting || pristine || hasValidationErrors}
            fullWidth
          >
            {actionType}
          </SubmitButton>
        </StyledForm>
      )}
    />
  )

  const handleBack = () => {
    let pathname: string = TeamLeaderPaths.teamMembers
    const id = memberIdFromParams
    const { phone } = selectedTeamMember as TeamMemberV2
    if (phone && id) {
      pathname = generatePath(TeamLeaderPaths.teamMemberView, { id })
    }
    navigation.push({ pathname, state: { from: location.pathname } })
  }

  const getContent = () => {
    return (
      <UpsertFormContainer
        actionType={actionType}
        headerTitle={headerTitle}
        entityType={entityType}
        form={form}
        containerProps={{
          themeType: 'neutral',
          showBackButton: true,
          backButtonProps: {
            onBack: handleBack
          }
        }}
      />
    )
  }

  return (
    <FeatureFlag flag={FeatureFlags.allowTlxEditAccess} defaultChildren={<Redirect to={'404'} />}>
      {isSelectedTeamMemberLoading ? <HeartLoadingIndicator fullPage /> : getContent()}
    </FeatureFlag>
  )
}
