import { Paper, PaperTypeMap, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { LayoutContentStyleProps } from '.'

export const StyledLayoutContent = styled<
  OverridableComponent<PaperTypeMap<{ component: 'section' }, typeof Paper>>
>(Paper, {
  shouldForwardProp: (prop) => prop !== 'showHeaderEffect' && prop !== 'themeType'
})<LayoutContentStyleProps>(({ theme, themeType, showHeaderEffect }) => ({
  padding: theme.layout.padding,
  paddingBottom: theme.layout.bottomSpacing,
  ...(showHeaderEffect && {
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
      boxShadow: `-50px -50px 0 0 ${theme.customTheme[themeType].primaryColor}`,
      content: '""',
      display: 'block',
      width: '100px',
      height: '100px',
      position: 'absolute',
      borderRadius: theme.borderRadius.half,
      top: 0,
      left: 0
    }
  })
}))
