import { TeamMemberEmployment, UpsertThunkProps } from 'types'

import { putRecord } from 'api'

import { ADMIN_TEAM_EMPLOYMENTS } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

const EMPLOYMENT_ENDPOINT = 'employments'

export const updateEmploymentThunk = authenticatedThunkCreator<
  TeamMemberEmployment,
  UpsertThunkProps<Partial<TeamMemberEmployment>>
>(`${ADMIN_TEAM_EMPLOYMENTS}/updateEmploymentThunk`, async (authedDTO) => {
  const employment: TeamMemberEmployment = await putRecord(authedDTO, EMPLOYMENT_ENDPOINT)
  return employment
})
