import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'

import useHTTPS from 'hooks/useHTTPS'
import useCustomRedirect from 'hooks/useRedirect'

import { getIsAuthenticated } from 'slices/auth'

import { RootPaths } from 'utils/helpers'

export default function PublicRoute({ path, ...props }: RouteProps) {
  useHTTPS()

  const isAuthenticated = useSelector(getIsAuthenticated)
  const redirectToHome = useCustomRedirect(RootPaths.home)

  if (path === RootPaths.welcome && isAuthenticated) {
    return redirectToHome({
      returnAsComponent: true
    })!
  }

  return <Route {...props} />
}
