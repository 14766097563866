import clsx from 'clsx'
import { ThemeType } from 'theme'

import { Key, ReactNode } from 'react'

import { BackButton, BackButtonProps } from 'components/BackButton'
import Footer from 'components/footer'
import Layout from 'components/layout'
import LayoutContent from 'components/layoutContent'
import ScrollToBottomButtonOnSmallDevice from 'components/scrollToBottomButtonOnSmallDevice'
import ScrollToTop from 'components/scrollToTop'

import { StyledModalPageHeaderContainer } from './styles'

// TODO: We have the same props in pageWithSubheader
// Maybe extract it to its own subHeader component in the header dir
// along with its types?

export interface PageWithModalProps {
  children: ReactNode
  className?: string
  headerContent: ReactNode
  headerImage?: ReactNode
  themeType?: ThemeType
  showBackButton?: boolean
  showHeaderImage?: boolean
  backButtonProps?: BackButtonProps
  contentStyle?: Record<Key, string>
}

export default function PageWithModal({
  children,
  className,
  headerContent,
  headerImage,
  themeType = 'light',
  showBackButton = false,
  showHeaderImage = true,
  backButtonProps = {},
  contentStyle = {}
}: PageWithModalProps) {
  return (
    <>
      <ScrollToBottomButtonOnSmallDevice />
      <Layout className={clsx('LayoutPageWithModal', className)} themeType={themeType}>
        <ScrollToTop />
        <StyledModalPageHeaderContainer className="LayoutPageWithModal__container">
          {showBackButton ? <BackButton onlyIcon {...backButtonProps} /> : null}
          <header className="LayoutPageWithModal__header">{headerContent}</header>
        </StyledModalPageHeaderContainer>
        {showHeaderImage && headerImage}
        <LayoutContent themeType={themeType} extraStyles={contentStyle} showHeaderEffect>
          {children}
        </LayoutContent>
        <Footer />
      </Layout>
    </>
  )
}
