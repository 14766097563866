import { Card, styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const ErrorBoundaryCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(5, 1),
  padding: theme.spacing(4, 3, 5),
  textAlign: 'center',
  backgroundColor: 'white'
}))

export const ErrorBoundaryText = styled<
  OverridableComponent<TypographyTypeMap<{ component: 'div' }, typeof Typography>>
>(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1, 3),
  fontWeight: 'bold'
}))
