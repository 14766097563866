import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { BackButton } from 'components/BackButton'
import { HeaderContainer } from 'components/styled/headerContainer'

import {
  fetchJackpotCurrentTicketInfoThunk,
  getJackpotCurrentTicketCount
} from 'slices/jackpot'

import {
  StyledJackpotPrizeHeaderContent,
  StyledJackpotPrizeHeaderName,
  StyledJackpotPrizeHeaderTickets
} from './styles'

export default function JackpotPrizeHeader() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const currentTickets = useSelector(getJackpotCurrentTicketCount)

  useEffect(() => {
    dispatch(fetchJackpotCurrentTicketInfoThunk({ getCachedResults: true }))
  }, [])

  return (
    <HeaderContainer>
      <StyledJackpotPrizeHeaderContent>
        <BackButton />
        <StyledJackpotPrizeHeaderName variant="body1" />
      </StyledJackpotPrizeHeaderContent>
      <StyledJackpotPrizeHeaderTickets variant="h3">
        {t('pages.jackpotPrize.header.title', { amount: currentTickets })}
      </StyledJackpotPrizeHeaderTickets>
    </HeaderContainer>
  )
}
