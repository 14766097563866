import { createSelector } from '@reduxjs/toolkit'

import { ticketsAdapter } from './reducer'

import { getSortObjectsByDateString } from 'utils/dateUtils'

import { RootState } from 'reduxStore'

export const ticketsSelectors = ticketsAdapter.getSelectors<RootState>(
  (state) => state.jackpot.tickets
)

export const getSortedTicketsHistory = createSelector(
  ticketsSelectors.selectAll,
  (tickets) => tickets.sort(getSortObjectsByDateString((ticket) => ticket.created))
)

const selectTickets = (state: RootState) => state.jackpot.tickets

export const getJackpotCurrentTicketCount = createSelector(
  selectTickets,
  (tickets) => tickets.total || 0
)

export const getTicketsLoading = (state: RootState) => state.jackpot.tickets.isLoading

export const getCurrentTicketInfoHasLoaded = (state: RootState) =>
  state.jackpot.tickets.hasLoaded

export const getTicketsHistoryHasLoaded = (state: RootState) =>
  state.jackpot.tickets.jackpotHistoryLoaded
