import {
  StyledHomeSection,
  StyledHomeSectionTitle,
  StyledHomeSectionSubTitle
} from './homeSection.styles'

interface HomeSectionProps {
  children: React.ReactNode
  title: string
  subTitle?: string
}
export default function HomeSection({ children, title, subTitle }: HomeSectionProps) {
  return (
    <StyledHomeSection>
      <StyledHomeSectionTitle variant="h3">{title}</StyledHomeSectionTitle>
      {subTitle && (
        <StyledHomeSectionSubTitle variant="body1">{subTitle}</StyledHomeSectionSubTitle>
      )}
      {children}
    </StyledHomeSection>
  )
}
