import { DateTime } from 'luxon'

export const getSortObjectsByDateString = <T>(
  extractDateString: (a: T) => string,
  chronological = false
) => {
  return (a: T, b: T) => {
    const date_a = DateTime.fromISO(extractDateString(a))
    const date_b = DateTime.fromISO(extractDateString(b))
    return (date_b.toMillis() - date_a.toMillis()) * (chronological ? -1 : 1)
  }
}
