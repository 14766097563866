import { Grid, Link } from '@mui/material'

import {
  FormEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'

import { sendOTPV2 } from 'api'
import { LoginStateParams } from 'types'
import { LoginType, OTPChannel } from 'pages/login/constants'
import useAppDispatch from 'hooks/useAppDispatch'

import { LoginHelp } from '../../components'
import { StyledPhoneContainer } from '../../components/styles'

import { useNavigation } from 'hooks/useNavigation'
import { setSnackbar } from 'slices/snackbar'

import { formatPhoneNumber, getLoginWithTypePath, RootPaths } from 'utils/helpers'

import {
  StyledOTPErrorText,
  StyledOTPHeader,
  StyledOTPHeaderText,
  StyledOTPInput,
  StyledOTPResendCode,
  StyledOTPText
} from './otp.styles'

import LoginFormLayout from '../LoginFormLayout'
import { useOTPInput } from './useOTPInput'
import { useFirebase } from './useFirebase'
import { useTwilioAuth } from './useTwilioAuth'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FeatureFlags, isFeatureFlagOn } from '../../../../components/FeatureFlag'

export default function OTPLoginForm() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const flags = useFlags()
  const shouldUseFirebase = isFeatureFlagOn(flags, FeatureFlags.useFirebaseLoginTmx)

  const [resendingOTP, setResendingOTP] = useState(false)
  const location = useLocation<Partial<LoginStateParams>>()

  const navigation = useNavigation()
  const { handleTwilioSubmit, submittingTwilio, loginError, setLoginError } = useTwilioAuth()
  const { handleFirebaseSubmit, submittingFirebase, firebaseLoginError } = useFirebase()

  const hasError = Boolean(loginError) || Boolean(firebaseLoginError)
  const isSubmitting = submittingTwilio || submittingFirebase
  const isResendDisabled = resendingOTP || isSubmitting

  const loginType: LoginStateParams['loginType'] =
    location?.state?.loginType || LoginType.phone
  const loginValue = location?.state?.loginValue
  const isEmail = loginType === LoginType.email

  const {
    otpValue,
    resetOTPValue,
    NUM_INPUTS,
    activeInputIndex, handleInputChange, onPaste, onKeyDown
  } = useOTPInput(inputRef)

  useEffect(() => {
    if (otpValue && hasError) {
      // remove error if we changed any input
      setLoginError(null)
    }
  }, [otpValue, loginError])

  useEffect(() => {
    if (otpValue.length === NUM_INPUTS && !isSubmitting) {
      handleSubmit()
    }
  }, [otpValue, submittingFirebase, submittingTwilio])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (resendingOTP) {
      timer = setTimeout(() => {
        setResendingOTP(false)
      }, 10000)
    }
    return () => clearTimeout(timer)
  }, [resendingOTP])

  if (!loginValue) {
    // Redirect to the first login screen if there is no state available
    // To avoid ppl manually going to this route via the URL with no data
    return <Redirect to={RootPaths.welcome} />
  }

  const handleSubmit = async () => {
    if (shouldUseFirebase) {
      await handleFirebaseSubmit(otpValue, resetOTPValue)
    } else {
      await handleTwilioSubmit(otpValue, loginValue, loginType, resetOTPValue)
    }
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  const generateInputs = () => {
    return Array.from({ length: NUM_INPUTS }, (_, index) => (
      <Grid key={index} item xs={12 / NUM_INPUTS}>
        <StyledOTPInput
          ref={index === activeInputIndex ? inputRef : null}
          onChange={handleInputChange}
          disabled={isSubmitting}
          error={hasError}
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
            pattern: '\\d*',
            autoComplete: 'one-time-code',
            autoFocus: index === 0,
            //@ts-expect-error e is fine
            onKeyDown: (e) => onKeyDown(e, onSubmit),
            onPaste,
            min: 0,
            max: 9,
            step: 1,
            value: otpValue[index] ?? ''
          }}
        />
      </Grid>
    ))
  }

  const resendOTP = async () => {
    // User should not be able to spam resend
    if (isResendDisabled || !loginValue) return

    setResendingOTP(true)

    if (shouldUseFirebase) {
      await handleFirebaseSubmit(otpValue, resetOTPValue)
    } else {
      const channel = isEmail ? OTPChannel.email : OTPChannel.phone
      const res = await sendOTPV2({ channel, to: loginValue })
      if (!res?.ok) {
        setLoginError(`pages.login.form.errors.${loginType}NotFoundError`)
      }
    }

    dispatch(
      setSnackbar({
        open: true,
        type: 'success',
        message: t(`pages.login.form.otp.toasts.resendSuccess${loginType}`)
      })
    )

  }

  const handleGoBack = () => {
    navigation.replace({
      pathname: getLoginWithTypePath(loginType)
    })
  }

  return (
    <LoginFormLayout handleGoBack={handleGoBack}>
      <StyledOTPHeader>
        <StyledOTPHeaderText bold>
          {t('pages.login.form.otp.enterCode')}
        </StyledOTPHeaderText>
        {loginValue && (
          <StyledOTPHeaderText>{formatPhoneNumber(loginValue)}</StyledOTPHeaderText>
        )}
        <StyledOTPText>
          {t('pages.login.form.otp.validPhoneNotice')}
        </StyledOTPText>
      </StyledOTPHeader>
      <Grid container alignItems="center" justifyContent="center" textAlign="center">
        <Grid item xs={12} md={8}>
          <form onSubmit={onSubmit}>
            <StyledPhoneContainer error={hasError}>
              <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                style={{ padding: '25px 0' }}
              >
                {generateInputs()}
              </Grid>
              <StyledOTPResendCode disabled={isResendDisabled}>
                <Trans
                  i18nKey="pages.login.form.otp.resend"
                  t={t}
                  components={[
                    <Link component="button" key="resendOtp" onClick={resendOTP} />
                  ]}
                />
              </StyledOTPResendCode>
            </StyledPhoneContainer>
            {hasError && <StyledOTPErrorText>{t(loginError!)}</StyledOTPErrorText>}
            <LoginHelp />
          </form>
        </Grid>
      </Grid>
    </LoginFormLayout>
  )
}
