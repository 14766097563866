import { CSSProperties } from 'react'

export interface CustomTypographySize {
  fontSize: CSSProperties['fontSize']
  lineHeight: CSSProperties['lineHeight']
  textAlign?: CSSProperties['textAlign']
  fontWeight?: CSSProperties['fontWeight']
}

export type CustomTypographyType =
  | 'h1Size'
  | 'h2Size'
  | 'h3Size'
  | 'h4Size'
  | 'h5Size'
  | 'body1Size'
  | 'body2Size'
  | 'body3Size'
  | 'display'
  | 'display2'
  | 'display3'

export const FontWeight = {
  bold: 'bold',
  normal: 'normal'
}

export const FontStyle = {
  normal: 'normal'
}

/**
 * Uses a given factor to calculate font size in rem
 *
 * Multiply the factor by 8 to get the size value in pixels
 *
 * Divide the desired pixel value by 8 to get the factor
 *  * @param factor
 * @returns
 */
export const getFontSize = (factor: number) => `${factor * 0.5}rem`

export const CustomTypographySizes: Record<CustomTypographyType, CustomTypographySize> = {
  h1Size: {
    fontSize: getFontSize(5), // 40px => 40(pixel value) / 8(0.5 rem factor) = 5 => 5 * 0.5(rem unit) = 2.5rem = 40px
    lineHeight: 1.2 // 48px => 48 / 40(font size) = 1.12 ratio
  },
  h2Size: {
    fontSize: getFontSize(4), // 32px => 32 / 8 = 4 => 4 * 0.5 = 2rem = 32px
    lineHeight: 1.25 // 40px => 40 / 32 = 1.25 ratio
  },
  h3Size: {
    fontSize: getFontSize(3), // 24px => 24 / 8 = 3 => 3 * 0.5 = 1.5rem = 24px
    lineHeight: 1.3333 // 32px => 32 / 24 = 1.3333 ratio
  },
  h4Size: {
    fontSize: getFontSize(1.75), // 14px => 14 / 8 = 1.75 => 1.75 * 0.5 = 0.875rem = 14px
    lineHeight: 1.1428 // 16px => 16 / 14 = 1.1428 ratio
  },
  h5Size: {
    fontSize: getFontSize(1.5), // 12px => 12 / 8 = 1.5 => 1.5 * 0.5 = 0.75rem = 12px
    lineHeight: 1.3333 // 16px => 16 / 12 = 1.3333 ratio
  },
  body1Size: {
    fontSize: getFontSize(2), // 16px => 16 / 8 = 2 => 2 * 0.5 = 1rem = 16px
    lineHeight: 1.5 // 24px => 24 / 16 = 1.5 ratio
  },
  body2Size: {
    fontSize: getFontSize(2), // 16px => 16 / 8 = 2 => 2 * 0.5 = 1rem = 16px
    lineHeight: 1.25 // 20px => 20 / 16 = 1.25 ratio
  },
  body3Size: {
    fontSize: getFontSize(1.75), // 14px => 14 / 8 = 1.75 => 1.75 * 0.5 = 0.875rem = 14px
    lineHeight: 1.4285 // 20px => 20 / 14 = 1.4285 ratio
  },
  display: {
    fontSize: getFontSize(9), // 72px => 72 / 8 = 9 => 9 * 0.5 = 4.5rem = 72px
    lineHeight: 1, // 72px => 72 / 72 = 1 ratio
    fontWeight: FontWeight.bold,
    textAlign: 'center'
  },
  display2: {
    fontSize: getFontSize(3), // 24px => 24 / 8 = 3 => 3 * 0.5 = 1.5rem = 24px
    lineHeight: 1.3333, // 32px => 32 / 24 = 1.3333 ratio
    fontWeight: FontWeight.bold
  },
  display3: {
    fontSize: getFontSize(4), // 32px => 32 / 8 = 4 => 4 * 0.5 = 2rem = 32px
    lineHeight: 1, // 32px => 32 / 32 = 1 ratio
    fontWeight: FontWeight.bold
  }
}

export type CustomTypographyDisplay = Pick<
  CSSProperties,
  'fontWeight' | 'fontSize' | 'lineHeight' | 'textAlign'
>

export const FONT_FAMILY = 'Poppins, sans-serif'

export const DefaultTypography = {
  fontFamily: FONT_FAMILY,
  h1: {
    fontStyle: FontStyle.normal,
    fontWeight: FontWeight.bold,
    letterSpacing: '-0.02em',
    ...CustomTypographySizes.h1Size
  },
  h2: {
    fontStyle: FontStyle.normal,
    fontWeight: FontWeight.bold,
    ...CustomTypographySizes.h2Size
  },
  h3: {
    fontStyle: FontStyle.normal,
    fontWeight: FontWeight.bold,
    ...CustomTypographySizes.h3Size
  },
  h4: {
    fontStyle: FontStyle.normal,
    fontWeight: FontWeight.bold,
    ...CustomTypographySizes.h4Size
  },
  h5: {
    fontStyle: FontStyle.normal,
    fontWeight: 500,
    ...CustomTypographySizes.h5Size
  },
  body1: {
    fontStyle: FontStyle.normal,
    fontWeight: FontWeight.normal,
    ...CustomTypographySizes.body1Size
  },
  body2: {
    fontStyle: FontStyle.normal,
    fontWeight: FontStyle.normal,
    ...CustomTypographySizes.body2Size
  }
}
