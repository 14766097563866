import { Colors } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

export default function JackpotTicketLineItemLoader(
  props: ComponentProps<typeof ContentLoader> = {}
) {
  return (
    <ContentLoader
      width={310}
      height={78}
      style={{
        width: '100%',
        borderBottom: `1px solid ${Colors.disabled}`,
        ...(props?.style || {})
      }}
      backgroundColor={Colors.inactive}
      foregroundColor={Colors.blue}
      {...props}
    >
      {/* date */}
      <rect x="0" y="10" rx="3" ry="3" width="150" height="20" />
      {/* description */}
      <rect x="0" y="45" rx="3" ry="3" width="150" height="20" />
      {/* right content */}
      <rect x="calc(100% - 150px)" y="45" rx="3" ry="3" width="150" height="20" />
    </ContentLoader>
  )
}
