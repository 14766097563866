// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CSVSchema, PaginatedCSVSchemas } from 'types'

import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
  ADMIN_CSV_SCHEMAS,
  paginatedResponse,
  requestState,
  RequestState
} from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import {
  createCSVSchemaThunk,
  fetchAllCSVSchemasThunk,
  fetchCSVSchemaThunk,
  updateCSVSchemaThunk
} from '.'

interface AdminCSVSchemasState extends RequestState {
  csvSchemaResponse: PaginatedCSVSchemas
}

const initialCSVSchemasState: AdminCSVSchemasState = {
  csvSchemaResponse: paginatedResponse,
  ...requestState
}

export const CSVSchemasAdapter = createEntityAdapter<CSVSchema>({
  selectId: (schema) => schema.id
})

export const adminCSVSchemasSlice = createSlice({
  name: ADMIN_CSV_SCHEMAS,
  initialState: CSVSchemasAdapter.getInitialState(initialCSVSchemasState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCSVSchemasThunk.fulfilled, (state, action) => {
      const { next, previous, results = [] } = action.payload
      state.csvSchemaResponse = { next, previous }
      const isFresh = Boolean(action.meta.arg?.isInitial)
      if (isFresh) {
        CSVSchemasAdapter.setAll(state, results)
      } else {
        CSVSchemasAdapter.setMany(state, results)
      }
      handleRequestFulfilled(state)
    })
    builder.addCase(fetchCSVSchemaThunk.fulfilled, (state, action) => {
      const csvSchema = action.payload
      CSVSchemasAdapter.setOne(state, csvSchema)
      handleRequestFulfilled(state)
    })
    builder.addCase(updateCSVSchemaThunk.fulfilled, (state, action) => {
      const csvSchema = action.payload
      CSVSchemasAdapter.setOne(state, csvSchema)
      handleRequestFulfilled(state)
    })
    builder.addCase(createCSVSchemaThunk.fulfilled, (state, action) => {
      const csvSchema = action.payload
      CSVSchemasAdapter.addOne(state, csvSchema)
      handleRequestFulfilled(state)
    })
    builder.addMatcher(
      isAnyOf(
        fetchAllCSVSchemasThunk.pending,
        fetchCSVSchemaThunk.pending,
        updateCSVSchemaThunk.pending,
        createCSVSchemaThunk.pending
      ),
      (state) => {
        state.isLoading = true
      }
    )
    builder.addMatcher(
      isAnyOf(
        fetchAllCSVSchemasThunk.rejected,
        fetchCSVSchemaThunk.rejected,
        updateCSVSchemaThunk.rejected,
        createCSVSchemaThunk.rejected
      ),
      (state, action) => {
        handleRequestRejected(state, action)
      }
    )
  }
})

export default adminCSVSchemasSlice.reducer
