import useGlobalModal from "hooks/useGlobalModal"
import { useEffect, useMemo } from "react"
import BirthdayReminderModal from "./birthdayReminderModal"
import { useSelector } from "react-redux"
import { getWhoami, updateUserSettingsV4Thunk } from "slices/whoami"
import { useAppDispatch } from "hooks"
import { setSnackbarError, setSnackbarSuccess } from "utils/helpers"
import { useTranslation } from "react-i18next"

// 15 day difference
const TIMER_DIFFERENCE = 15 * 24 * 60 * 60 * 1000

const TIMER_KEY = "persist:birthday-reminder-modal-dismissed"
const getTimerValue = () => localStorage.getItem(TIMER_KEY)
const resetTimer = () => localStorage.setItem(TIMER_KEY, Date.now().toString())

export default function BirthdayReminder() {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const whoami = useSelector(getWhoami)

  const lastOpened = useMemo(() => getTimerValue(), [])
  const timerWaiting = lastOpened && Number(lastOpened) > Date.now() - TIMER_DIFFERENCE

  const hasBirthday = whoami?.has_birthday

  const onConfirm = (birthday: string) => {
    dispatch(
      updateUserSettingsV4Thunk({
        id: whoami?.user_profile_id,
        data: { birthday }
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setSnackbarSuccess(t('pages.userProfile.form.success')))
      })
      .catch(() => {
        dispatch(setSnackbarError(t('pages.userProfile.form.error')))
      })
    closeGlobalModal()
  }

  const onCancel = () => {
    resetTimer()
    closeGlobalModal()
  }

  useEffect(() => {
    if(hasBirthday || timerWaiting) return
    openGlobalModal({
      title: "",
      body: <BirthdayReminderModal onConfirm={onConfirm} onCancel={onCancel} />,
      showCloseButton: false,
      showCTA: false,
      displayCloseButtonInHeader: false,
      onClose: onCancel
    })
  }, [])

  return null
}