// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ThemeType } from 'theme'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ListChildComponentProps } from 'react-window'

import { CelebrationPoint } from 'types'

import Placeholder, { RowItemLoader } from 'placeholder'

import useAppDispatch from 'hooks/useAppDispatch'

import { AdminContainer } from 'components/AdminContainer'
import InfiniteList from 'components/InfiniteList'

import { useNavigation } from 'hooks/useNavigation'

import {
  celebrationPointsSelectors,
  fetchAllCelebrationPointsThunk,
  getCelebrationPointsLoading,
  getCelebrationPointsResponse
} from 'slices/admin'

import { TeamLeaderPaths } from 'utils/helpers'

import OutboxItem from './outboxItem'

export default function CelebrateOutbox() {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const celebrationPointsIsLoading = useSelector(getCelebrationPointsLoading)
  const celebrationPointsResponse = useSelector(getCelebrationPointsResponse)
  const celebrationPoints = useSelector(celebrationPointsSelectors.selectAll)

  const themeType: ThemeType = 'neutral'

  useEffect(() => {
    dispatch(fetchAllCelebrationPointsThunk({ isInitial: true }))
  }, [])

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const celebrationPoint = celebrationPoints[index]
    if (!celebrationPoint?.id) return null
    style = { ...style }
    return (
      <OutboxItem
        key={celebrationPoint.id}
        item={celebrationPoint}
        onSelectItem={() => {
          return false
        }}
        style={style}
      />
    )
  }

  const nextPage = celebrationPointsResponse?.next
  const loadNextPage = () => dispatch(fetchAllCelebrationPointsThunk())

  return (
    <AdminContainer
      headerTitle={t('pages.admin.celebrateOutbox.header')}
      containerProps={{
        showBackButton: true,
        themeType,
        backButtonProps: {
          onBack: () => navigation.replace(TeamLeaderPaths.teamMembers)
        }
      }}
    >
      <Placeholder
        isLoading={celebrationPointsIsLoading}
        loader={RowItemLoader}
        amount={6}
      >
        <InfiniteList<CelebrationPoint>
          hasNextPage={!!nextPage}
          isNextPageLoading={celebrationPointsIsLoading}
          loadNextPage={loadNextPage}
          items={celebrationPoints}
          renderRow={renderRow}
        />
      </Placeholder>
    </AdminContainer>
  )
}
