import { GetGoalStatesForEmployeeOnDateQuery, GoalState } from 'generated/graphql'

import { createSlice } from '@reduxjs/toolkit'

import { Goal } from 'types'

import { GOALS, RequestState, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import {
  fetchAchievedGoals,
  fetchGoalStatesForEmployeeOnDate,
  fetchGoalsOnDateThunk
} from './actions'

import { getTodaysDate } from 'utils/form'

import {
  getGoalVersionsPerParent,
  convertGoalStatesForEmployeeToGoals,
  removeDuplicatedGoals,
  updateGoalStatePoints,
  removeGoalsWithZeroPoints
} from './utils'

interface GoalsState extends RequestState {
  // combined goal progress of kaiser and goal service
  combinedGoalProgressByDate: Record<string, Goal[]>
  // kaiser goals
  goalProgressByDate: Record<string, Goal[]>
  // goal service goals
  goalStatesForEmployeeOnDate: Record<string, Goal[]>
  inFlightRequestsOnDate: Record<string, boolean>
  achievedGoals: Goal[]
}
const initialState: GoalsState = {
  combinedGoalProgressByDate: {},
  goalProgressByDate: {},
  goalStatesForEmployeeOnDate: {},
  inFlightRequestsOnDate: {},
  achievedGoals: [],
  ...requestState
}

export const goalsSlice = createSlice({
  name: GOALS,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAchievedGoals.fulfilled, (state, action) => {
        state.achievedGoals = action.payload as Goal[]
        handleRequestFulfilled(state)
      })
      .addCase(fetchGoalsOnDateThunk.pending, (state, action) => {
        state.inFlightRequestsOnDate[action.meta.arg?.date] = true
        state.isLoading = true
      })
      .addCase(fetchGoalsOnDateThunk.rejected, (state, action) => {
        delete state.inFlightRequestsOnDate[action.meta.arg?.date]
        handleRequestRejected(state, action)
      })
      .addCase(fetchGoalsOnDateThunk.fulfilled, (state, action) => {
        const date = action.meta.arg?.date || getTodaysDate()
        const goalsOnDate: Goal[] = action.payload as Goal[]

        const convertedGoalsOnDate = getGoalVersionsPerParent(goalsOnDate)

        state.goalProgressByDate[date] = convertedGoalsOnDate
        state.combinedGoalProgressByDate[date] = convertedGoalsOnDate

        delete state.inFlightRequestsOnDate[date]

        handleRequestFulfilled(state)
      })
      .addCase(fetchGoalStatesForEmployeeOnDate.pending, (state, action) => {
        state.isLoading = true

        const date = action.meta.arg.date
        state.combinedGoalProgressByDate[date] = state.goalProgressByDate[date]
      })
      .addCase(fetchGoalStatesForEmployeeOnDate.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(fetchGoalStatesForEmployeeOnDate.fulfilled, (state, action) => {
        const goalStatesForEmployeeOnDateQueryResp =
          action.payload as GetGoalStatesForEmployeeOnDateQuery

        const date = action.meta.arg.date
        let prevGoalProgressByDate = state.goalProgressByDate[date]
        const goalStates =
          goalStatesForEmployeeOnDateQueryResp.getGoalStatesForEmployeeOnDate

        let updatedGoalStates = updateGoalStatePoints({
          goalsToMatch: prevGoalProgressByDate,
          goalStatesToUpdate: goalStates
        })

        prevGoalProgressByDate = removeGoalsWithZeroPoints(prevGoalProgressByDate) as Goal[]
        updatedGoalStates = removeGoalsWithZeroPoints(updatedGoalStates) as GoalState[]

        prevGoalProgressByDate = removeDuplicatedGoals({
          goalsToMap: updatedGoalStates,
          goalsToUpdate: prevGoalProgressByDate
        })

        const goalStatesForEmployeesToGoals: Goal[] = convertGoalStatesForEmployeeToGoals(
          updatedGoalStates,
          action.meta.arg.storeGroupId
        )

        state.goalStatesForEmployeeOnDate[date] = goalStatesForEmployeesToGoals
        state.combinedGoalProgressByDate[date] = [
          ...prevGoalProgressByDate,
          ...goalStatesForEmployeesToGoals
        ]
        handleRequestFulfilled(state)
      })
  }
})

export default goalsSlice.reducer
