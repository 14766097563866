import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'
import { getSpacing } from 'theme'

import { Alert } from '@mui/material'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import { ReactComponent as CircleAlertIcon } from 'assets/icons/AppIcons/CircleAlertIcon.svg'

import { OnarollRewardProductType, RedeemedPerk, RewardType } from 'types'

import PageWithModal from 'layouts/pageWithModal'

import useAppDispatch from 'hooks/useAppDispatch'

import { FeatureFlags } from 'components/FeatureFlag'
import { isFeatureFlagOn } from 'components/FeatureFlag/utils'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'
import { CenteredItemContainer } from 'components/styled/flexCentered'

import {
  fetchJackpotCurrentTicketInfoThunk,
  fetchJackpotTicketHistoryThunk
} from 'slices/jackpot'
import {
  fetchPerkThunk,
  selectSelectedPerk,
  redeemPerkThunk,
  selectPerksLoading
} from 'slices/perks'
import { fetchPointTotalsThunk, getPointsBalance } from 'slices/points'
import { setSnackbar } from 'slices/snackbar'
import { getDefaultSelectedStore } from 'slices/storeSwitcher'
import { getWhoami } from 'slices/whoami'

import {
  amountText,
  amountToPoints,
  dollarToPoints,
  isRewardRedeemable,
  RootPaths
} from 'utils/helpers'
import { UrlParamTypes } from 'utils/url'

import {
  ContentPusher,
  StyledPerkError,
  StyledPerkPointsLeft,
  StyledPerkRedeemButton
} from './styles'

import { RootState } from 'reduxStore'

import ConfirmationModal from './ConfirmationModal'
import DenominationForm from './DenominationForm/DenominationForm'
import DenominationsMenu from './denominationsMenu'
import { getPerkDenominations, isNumber, mapRedeemableAtToBooleans } from './helpers'
import PerkCard from './perkCard'
import PerkHeader from './perkHeader'
import PerkRedeemMethods from './perkRedeemMethods'
import SnagPerkModal from './snagPerkModal'

const CASH_MINIMUM = 5
const SUGGEST_PERK_PAGE_ID = 'suggest'

const Perk = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const whoami = useSelector(getWhoami)

  const { id: idFromParams } = useParams<UrlParamTypes>()

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isRedeemingPerk, setIsRedeemingPerk] = useState(false)
  // amountOfPerk is in units of the perk. eg, for gift cards and cash,
  // the units are dollars, and 1 dollar = 100 points
  const [amountOfPerk, setAmountOfPerk] = useState<number>()
  const [redeemError, setRedeemError] = useState<string | null>()
  const [selectedDenomination, setSelectedDenomination] = useState<string | null>(null)
  const [redeemedPerk, setRedeemedPerk] = useState<RedeemedPerk>()

  const perksLoading = useSelector(selectPerksLoading)
  const selectedStore = useSelector(getDefaultSelectedStore)
  const rewardDetail = useSelector((state) => selectSelectedPerk(state as RootState))
  const pointsBalance = useSelector(getPointsBalance)

  const isOptedOut = whoami?.is_opted_out

  const flags = useFlags()
  const disableArrearsAccountRedemptions = isFeatureFlagOn(
    flags,
    FeatureFlags.disableArrearsAccountRedemptions
  )
  const blockRedemptionsByUser = isFeatureFlagOn(
    flags,
    FeatureFlags.blockRedemptionsByUser
  )

  const showForm = useMemo(
    () =>
      rewardDetail?.reward_type === RewardType.cash || !isNumber(selectedDenomination),
    [rewardDetail?.reward_type, selectedDenomination]
  )

  const totalPoints = useMemo(() => {
    if (amountOfPerk) {
      // if the amount is not in dollars, use config value
      const costInPoints = Number(rewardDetail?.points_per_unit_cost)

      const convertedPoints = isNaN(costInPoints)
        ? dollarToPoints(amountOfPerk)
        : amountToPoints(amountOfPerk, costInPoints)
      // Note: We need to account for large numbers - E.g. 1,000 or 2,500,000 with commas
      // Thankfully due to our number formatter, we only have to worry about `en-US` number formats
      // since commas in numbers mean different things in different locations/cultures. E.g. German => 123.456,789
      return Number(convertedPoints)
    }
    return 0
  }, [amountOfPerk, rewardDetail?.points_per_unit_cost])

  const discount = useMemo(() => {
    if (!rewardDetail?.discounts?.length) return null

    return rewardDetail?.discounts.find((d) => d.store_id === selectedStore?.id)
  }, [rewardDetail?.discounts?.length, selectedStore?.id])

  const discountedPoints = useMemo(() => {
    if (!discount || !amountOfPerk || !totalPoints) return null

    const { percent_discount } = discount

    return Math.round((100 - percent_discount) * Number(totalPoints))
  }, [totalPoints, discount, amountOfPerk])

  // We want to use useCallback for both of these below to keep the memoized
  // DenominationsMenu from rendering unecessarily when this main component re-renders.
  const selectDenomination = useCallback((value: string) => {
    setSelectedDenomination(value)

    if (isNumber(value)) updateAmount(Number(value))
  }, [])

  const updateAmount = useCallback((value: number) => setAmountOfPerk(value), [])

  useEffect(() => {
    const isSuggestedPerkPage = idFromParams === SUGGEST_PERK_PAGE_ID

    if (idFromParams && !isSuggestedPerkPage) {
      dispatch(fetchPerkThunk({ pk: idFromParams }))
    }
  }, [idFromParams])

  useEffect(() => {
    if (!rewardDetail || !amountOfPerk) return

    const { min_value, max_value } = rewardDetail

    const minimumAmount =
      rewardDetail?.reward_type === RewardType.cash ? CASH_MINIMUM : min_value || 1
    const maximumAmount = max_value || 2000

    const canRedeem = isRewardRedeemable(
      rewardDetail,
      pointsBalance,
      amountOfPerk,
      discountedPoints
    )

    if (!canRedeem) {
      setRedeemError(t('pages.perk.errors.lowPts'))
    } else if (amountOfPerk && amountOfPerk < minimumAmount) {
      setRedeemError(t('pages.perk.errors.minPts', { minimumAmount }))
    } else if (amountOfPerk && amountOfPerk > maximumAmount) {
      setRedeemError(t('pages.perk.errors.minPts', { maximumAmount }))
    } else {
      // Clear the errors on successfull validation
      setRedeemError(null)
    }
    return () => {
      setRedeemError(null)
    }
  }, [amountOfPerk, rewardDetail, pointsBalance, discountedPoints])

  const menuDenominations = useMemo(() => {
    if (!rewardDetail) return [] as string[]

    return getPerkDenominations(rewardDetail, selectedStore?.id)
  }, [rewardDetail, selectedStore])

  // Weird edge case where we have not fetched the rewardDetail yet
  // but we do have the id from the url. In this case, we should show a loader
  // to let the user know we are about to fetch the data
  if (
    perksLoading ||
    (!rewardDetail && idFromParams) ||
    rewardDetail?.id !== idFromParams
  ) {
    return <HeartLoadingIndicator fullPage />
  }

  if (!rewardDetail) return <Redirect to={RootPaths.perks} />

  const getRemainingDistributionPoints = () => {
    return pointsBalance - totalPoints
  }

  const getPointsHelperText = () => {
    if (isOptedOut) return t('pages.perk.pointsHelperText.optedOut')

    return !redeemError
      ? t('pages.perk.pointsHelperText.redeemable', {
          points: getRemainingDistributionPoints()
        })
      : t('pages.perk.pointsHelperText.notRedeemable')
  }

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false)
  }

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
  }

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true)
  }

  const onRedeemPerk = async () => {
    setRedeemedPerk(undefined)

    if (!redeemError) {
      setIsRedeemingPerk(true)
      closeConfirmationModal()

      const perk = rewardDetail
      const discountPk = discount?.id ?? null

      try {
        const redeemedPerk = await dispatch(
          redeemPerkThunk({
            data: {
              reward: perk.id,
              discount: discountPk,
              points: String(totalPoints)
            }
          })
        ).unwrap()

        setRedeemedPerk(redeemedPerk)
        setIsSuccessModalOpen(true)

        dispatch(fetchPointTotalsThunk({}))

        if (
          redeemedPerk.reward.configuration?.product_type ===
          OnarollRewardProductType.jackpot_ticket
        ) {
          dispatch(fetchJackpotCurrentTicketInfoThunk({ getCachedResults: false }))
          dispatch(fetchJackpotTicketHistoryThunk({ getCachedResults: false }))
        }

        setIsRedeemingPerk(false)
      } catch (err) {
        setIsRedeemingPerk(false)

        const error_message = err
          ? (JSON.parse(err).error ?? t('pages.perk.errors.general'))
          : t('pages.perk.errors.general')

        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: error_message
          })
        )
      }
    }
  }

  const displayHelperText = () => {
    if (redeemError) {
      return (
        <StyledPerkError component="small" variant="body1">
          <>
            <CircleAlertIcon
              width={20}
              height={20}
              style={{ marginRight: getSpacing(0.5) }}
            />
            {redeemError}
          </>
        </StyledPerkError>
      )
    }

    if (isEmpty(discount)) {
      return (
        <StyledPerkPointsLeft component="small" variant="body1">
          {getPointsHelperText()}
        </StyledPerkPointsLeft>
      )
    }

    return null
  }

  const getPerkContent = () => {
    const { reward_type, redeemable_at, configuration } = rewardDetail

    const isCash = reward_type === RewardType.cash
    const isDisabled = Boolean(
      !amountOfPerk ||
        redeemError ||
        isRedeemingPerk ||
        isOptedOut ||
        disableArrearsAccountRedemptions ||
        blockRedemptionsByUser
    )
    const isFree = discount?.percent_discount == 100
    const isDollars = !configuration?.unit

    const perkRedemptionProps = mapRedeemableAtToBooleans(redeemable_at)

    const getRedeemText = () => {
      if (isRedeemingPerk) {
        return t('pages.perk.cta.active')
      }
      if (isFree) return t('pages.perk.cta.forFree')

      if (amountOfPerk) {
        return t('pages.perk.cta.redeemWithPoints', {
          amount: discountedPoints ? discountedPoints : totalPoints
        })
      }

      return t('pages.perk.cta.default')
    }

    return (
      <ContentPusher>
        {!isCash && (
          <DenominationsMenu
            isDollars={isDollars}
            selected={selectedDenomination}
            selectDenomination={selectDenomination}
            denominations={menuDenominations}
            isFree={isFree}
          />
        )}
        {showForm && (
          <DenominationForm
            isDollars={isDollars}
            error={Boolean(redeemError)}
            perk={rewardDetail}
            updateAmount={updateAmount}
          />
        )}
        {reward_type === RewardType.gift_card && (
          <PerkRedeemMethods {...perkRedemptionProps} />
        )}
        <CenteredItemContainer>
          <StyledPerkRedeemButton
            disableRipple
            disabled={isDisabled}
            variant="contained"
            color="primary"
            size="large"
            onClick={isFree ? onRedeemPerk : openConfirmationModal}
            isRedeemingPerk
          >
            {getRedeemText()}
          </StyledPerkRedeemButton>
        </CenteredItemContainer>
        {displayHelperText()}
      </ContentPusher>
    )
  }

  return (
    <>
      {disableArrearsAccountRedemptions && (
        <Alert severity="error" variant="filled" style={{ borderRadius: '0' }}>
          Unable to redeem Points: Please contact your company leader to resolve issues.
        </Alert>
      )}
      {blockRedemptionsByUser && (
        <Alert severity="error" variant="filled" style={{ borderRadius: '0' }}>
          You need to work a shift in order to redeem your points.
        </Alert>
      )}
      <PageWithModal
        headerContent={<PerkHeader />}
        contentStyle={{ padding: '0' }}
        headerImage={
          <PerkCard
            rewardDetail={rewardDetail}
            points={totalPoints}
            discountedPoints={discountedPoints}
            percent_discount={discount?.percent_discount}
          />
        }
      >
        {getPerkContent()}
      </PageWithModal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        onRedeemPerk={onRedeemPerk}
        amountString={amountText(amountOfPerk ?? 0, rewardDetail, t)}
        points={discountedPoints ?? totalPoints}
        isRedeemingPerk={isRedeemingPerk}
      />
      {!isEmpty(redeemedPerk) && (
        <SnagPerkModal
          isOpen={isSuccessModalOpen}
          onClose={closeSuccessModal}
          perk={redeemedPerk!}
        />
      )}
    </>
  )
}

export default Perk
