import { orderBy } from 'lodash'
import { createSelector } from 'reselect'
import { AdminGoal } from 'types'

import { ADMIN_GOALS } from 'slices/constants'

import { RootState } from 'reduxStore'

import { AdminGoalsAdapter } from '.'
import utils from './utils'

export const adminGoalsSelectors = AdminGoalsAdapter.getSelectors<RootState>(
  (state) => state[ADMIN_GOALS]
)

/* ideally this ordering would be achieved by using entityAdapter's sortComparer
function during the adapter initialization in the reducer. However, the addOne method on
the adapter during a creation action handler does not seem to respect the adapter's
ordering when adding to the list. As such we're just doing it manually here. */
export const selectAdminGoals = createSelector(
  adminGoalsSelectors.selectAll,
  (goals: AdminGoal[]) => orderBy(goals, ['effective_at', 'created'], ['desc', 'desc']) // NOTE: lodash's orderBy return's a new array to avoid mutation
)

export const getAdminGoalsResponse = (state: RootState) =>
  state[ADMIN_GOALS].adminGoalsResponse

export const selectGoalsLoading = (state: RootState) => state[ADMIN_GOALS].isLoading

export const selectGoalVersions = createSelector(
  [selectAdminGoals, (_: RootState, goal: AdminGoal | undefined) => goal],
  (goals: AdminGoal[], goal: AdminGoal | undefined) => {
    if (!goal) return []
    const parentId = goal?.parent
    if (!parentId) return [goal]
    return goals.filter(({ parent }) => parent === parentId)
  }
)

export const selectAdminGoalPerParent = createSelector(
  selectAdminGoals,
  (goals: AdminGoal[]) => {
    const accumulator = { arr: [] as AdminGoal[], parents: {} as Record<string, boolean> }
    const fn = (acc: typeof accumulator, goal: AdminGoal) => {
      const { parents, arr } = acc
      const { parent } = goal
      if (!parent || !parents[parent]) {
        parents[parent as string] = true
        arr.push(goal)
      }
      return acc
    }
    return goals.reduce(fn, accumulator).arr
  }
)

export const selectGoalVersionsForForm = createSelector(
  adminGoalsSelectors.selectById,
  utils.getGoalVersionsForForm
)
