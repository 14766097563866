import { alpha, styled } from '@mui/material'

import RowItem from 'components/rowItem'
import { PointsExtraInfoProps } from 'components/rowItem/extraInfo/points'

export const StyledOutboxItem = styled(RowItem, {
  shouldForwardProp: (prop) => prop !== 'item'
})<PointsExtraInfoProps>(({ item: { color, completed }, theme }) => ({
  padding: 0,
  '.RowItem__wrapper': {
    padding: theme.spacing(3)
  },
  '.RowItem__extraInfo': {
    position: 'relative',
    flexShrink: 0,
    backgroundColor: completed ? color : alpha(color, 0.1)
  }
}))
