import { Typography } from '@mui/material'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Placeholder from 'placeholder'
import CountdownLoader from 'placeholder/loaders/countdownLoader'

import useAppDispatch from 'hooks/useAppDispatch'

import {
  fetchJackpotCurrentDrawingThunk,
  getDrawingsLoading,
  getJackpotCurrentDrawing
} from 'slices/jackpot'

import { JackpotDateCountdown } from './CurrentDrawingDate/CurrentDrawingDate'
import JackpotHeaderTitle from './jackpotHeaderTitle'
import JackpotSection from './jackpotSection'

export default function JackpotCurrentDrawingCountdown() {
  const dispatch = useAppDispatch()
  const drawingsLoading = useSelector(getDrawingsLoading)
  const jackpotCurrentDrawing = useSelector(getJackpotCurrentDrawing)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchJackpotCurrentDrawingThunk({ getCachedResults: true }))
  }, [])

  const drawingTime = jackpotCurrentDrawing?.drawing_at

  return (
    <JackpotSection moreSpace>
      <JackpotHeaderTitle title={t('pages.jackpot.drawingIn')} />
      <Placeholder isLoading={drawingsLoading} loader={CountdownLoader}>
        {!drawingTime ? (
          <Typography variant="h3" component="p">
            {t('pages.jackpot.noActiveDrawing')}
          </Typography>
        ) : (
          <JackpotDateCountdown endAtDateTime={drawingTime} />
        )}
      </Placeholder>
    </JackpotSection>
  )
}
