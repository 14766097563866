import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { getAuth } from 'firebase/auth'

import { enrichToken, fetchImpersonatedAuth } from 'api'

import { ImpersonationArgs, AuthTokensDTO, FirebaseImpersonation } from 'types'

import { AUTH } from 'slices/constants'

import { getIsImpersonator } from './selectors'

import { RootState } from 'reduxStore'

// note this deliberately is importing from actions vs index to avoid circular dep
import { firebaseTokensThunk } from 'slices/auth/actions'


export const impersonateWorkerThunk = createAsyncThunk(
  `${AUTH}/impersonateWorker`,
  async (impersonationArgs: ImpersonationArgs) => {
    const tokens: AuthTokensDTO = await fetchImpersonatedAuth(impersonationArgs)
    return tokens
  },
  {
    condition: (impersonationArgs: ImpersonationArgs, { getState }) => {
      const state = getState() as RootState
      const alreadyImpersonating = getIsImpersonator(state)
      if (alreadyImpersonating) return false
    },
    dispatchConditionRejection: true
  }
)

export const setImpersonation = createAction<FirebaseImpersonation>("impersonation/setImpersonation")

export const updateToken = createAsyncThunk(
  "impersonation/updateToken",
  async ( tokenArgs: FirebaseImpersonation, { getState, dispatch }) => {
    const state = getState() as RootState
    const auth = getAuth()
    const impersonateUserProfileId = tokenArgs.user_profile_id;

    const currentToken = state.auth.accessToken
    const res = await enrichToken({ idToken: currentToken, impersonateUserProfileId: impersonateUserProfileId })
    if (!res.ok) {
      throw new Error('unauthorized')
    }

    const idToken = await auth.currentUser?.getIdToken(true)
    const refreshToken = auth.currentUser?.refreshToken

    if (idToken?.length && refreshToken?.length) {

      await dispatch(firebaseTokensThunk({ idToken: idToken, refreshToken: impersonateUserProfileId === null ? refreshToken : '' }))

      dispatch(setImpersonation({ user_profile_id: impersonateUserProfileId }))
    }
  }
)
