import { styled, alpha, IconButton } from '@mui/material'

export const StyledUrgencyAlert = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.colors.yellow, 0.2),
  padding: theme.spacing(3),
  borderRadius: theme.borderRadius.small,
  flexWrap: 'wrap',
  margin: `${theme.spacing(2)} 0`,
  'svg, svg path': {
    fill: theme.palette.common.black
  },
  '.MuiTypography-root': {
    fontWeight: 'inherit'
  }
}))

export const StyledUrgencyAlertCta = styled('div')(({ theme }) => ({
  margin: `0 ${theme.spacing(1.5)}`,
  flexShrink: 0,
  maxWidth: 'fit-content',
  '& button': {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    fontWeight: 'inherit'
  }
}))

export const StyledUrgencyAlertClose = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  flexShrink: 0
}))

export const StyledUrgencyAlertContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  flex: 1,
  margin: `${theme.spacing(2)} 0`,
  '& > svg:first-of-type': {
    marginRight: theme.spacing(3), // Matches the left margin of container
    flexShrink: 0
  }
}))
