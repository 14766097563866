import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import useAppDispatch from './useAppDispatch'

import { logoutAction } from 'slices/auth'
import { getWhoami } from 'slices/whoami'

import { RootPaths } from 'utils/helpers'
import { logWarning } from 'utils/logger'

import useCustomRedirect from './useRedirect'

export default function useLogoutTerminatedUser() {
  const whoami = useSelector(getWhoami)
  const redirectToLogin = useCustomRedirect(RootPaths.welcome)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (whoami?.is_terminated) {
      logWarning(
        `User ${whoami?.display_name} (${whoami?.user_profile_id}) is terminated. Logging them out of the app.`,
        {
          whoami
        }
      )
      dispatch(logoutAction())
      redirectToLogin()
    }
  }, [whoami])
}
