import { uniq } from 'lodash'

import { createSelector } from '@reduxjs/toolkit'

import { getPointsBalance } from 'slices/points'

import { perksAdapter } from './reducer'

import { RootState } from 'reduxStore'

import { sortByRedeemable } from '../utils'

const TRENDING_CATEGORY = 'Trending'

export const perksSelectors = perksAdapter.getSelectors((state: RootState) => state.perks)

export const selectPerksLoading = (state: RootState) => state.perks.isLoading

// Selected perk is the perk you click on the perks list page
// which then takes you to the detail page
export const selectSelectedPerk = createSelector(
  (state: RootState) => state.perks.selectedPerk,
  (perk) => {
    // Check for the mandatory id field since an empty perk is {} (!!{} => true)
    if (!perk || !perk?.id) return null
    return perk
  }
)

export const perksByRedeemable = createSelector(
  perksSelectors.selectAll,
  getPointsBalance,
  (perks, pointsBalance) => sortByRedeemable(perks, pointsBalance)
)

export const getPerkCategories = createSelector(perksSelectors.selectAll, (perks) => {
  const categories = uniq(perks.map((rewardInfo) => rewardInfo.categories).flat())

  // We want the "Trending" category to always appear first in the list
  categories.sort(function (a, b) {
    return a == TRENDING_CATEGORY ? -1 : b == TRENDING_CATEGORY ? 1 : 0
  })

  return categories
})
