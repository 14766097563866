import { styled } from "@mui/material/styles";

export const DrawingOnDateContainer = styled('div')`
  background-color: ${({ theme }) => theme.branding.cardColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(3)}
`

export const DrawingOnDateIcon = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(2)}
`

export const DrawingOnDateInfo = styled('div')`
  display: flex;
  flex-direction: column;
`

export const DrawingOnDateTitle = styled('span')`
  color: ${({ theme }) => theme.branding.primaryColor};
  ${({ theme }) => theme.typography.h3}
`

export const DrawingOnDateSubtitle = styled('span')``
