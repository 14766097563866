import { verifyOTP } from 'api'
import { AUTH_PATH_REGEX } from 'constants/auth'
import { useState } from 'react'

export const useTwilioAuth = () => {
    const [submittingTwilio, setSubmittingTwilio] = useState(false)
    const [loginError, setLoginError] = useState<string | null>()
  const handleTwilioSubmit = async (otpValue: string, loginValue: string, loginType: "email" | "phone", resetOTPValue: () => void) => {
     // Do not allow any submits if we are in the middle of submitting the form or we do not have data to submit
    if (submittingTwilio || !loginValue || !otpValue) {
      return
    }

    setSubmittingTwilio(true)

    try {
      const body = await verifyOTP(loginValue, otpValue, loginType)
      const tokenizedLink = body['tokenized_link']
      const pathMatches = tokenizedLink.match(AUTH_PATH_REGEX)
      if (pathMatches?.length) {
        const path = pathMatches[0]
        window.location.pathname = path
      }
    } catch (error) {
      setLoginError('pages.login.form.errors.incorrectCode')
    } finally {
      setSubmittingTwilio(false)
      resetOTPValue()
    }
  }
  return {
    handleTwilioSubmit,
    submittingTwilio,
    loginError,
    setLoginError
  }
}
