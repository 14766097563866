import { AdminListFilters, PaginatedStores, FetchWithParamsThunk } from 'types'

import { fetchRecords } from 'api'

import { ADMIN_STORES } from 'slices/constants'

import { authenticatedThunkCreator, formatParamsRequest } from 'slices/utils'

export const fetchAllStoresThunk = authenticatedThunkCreator<
  PaginatedStores,
  FetchWithParamsThunk<AdminListFilters>
>(`${ADMIN_STORES}/fetchAllStoresThunk`, async (authedDTO) => {
  const request = formatParamsRequest(authedDTO)
  return await fetchRecords(request, 'allStores')
})
