import { isEmpty } from 'lodash'

import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDenominationItem, StyledDenominationsMenu } from './styles'

import { OTHER } from './helpers'
import { isNumber } from './helpers'

interface SelectDenomination {
  selectDenomination: (value: string) => void
  isFree: boolean
  isDollars?: boolean
}

export interface DenominationsMenuProps extends SelectDenomination {
  selected?: string | null
  denominations: string[]
}

export interface DenominationListItemProps extends SelectDenomination {
  value: string
  isSelected: boolean
}

export const DenominationItem = ({
  value,
  isSelected,
  selectDenomination,
  isFree,
  isDollars = true
}: DenominationListItemProps) => {
  const { t } = useTranslation()
  const floatValue = parseFloat(value)
  const isOther = value === OTHER
  const disabled = !value || isFree
  return (
    <StyledDenominationItem
      styleChangers={{ isSelected, isFree, disabled, isOther }}
      onClick={() => (disabled ? null : selectDenomination(value as string))}
      key={value}
    >
      {floatValue
        ? isDollars
          ? `$${floatValue.toFixed(2)}`
          : `${floatValue}`
        : isOther
        ? t('pages.perk.denominationMenu.other')
        : value}
    </StyledDenominationItem>
  )
}

const DenominationsMenu = ({
  selected,
  selectDenomination,
  denominations,
  isFree = false,
  isDollars = true
}: DenominationsMenuProps) => {
  const selectAmount = useCallback(
    (value: string) => {
      selectDenomination(value)
    },
    [selectDenomination]
  )

  useEffect(() => {
    if (isEmpty(denominations)) return
    const defaultSelected = isNumber(denominations[0])
      ? denominations[0]
      : denominations[1]
    selectAmount(defaultSelected as string)
  }, [denominations])

  const denominationsList = useMemo(
    () => (
      <>
        {denominations?.map((denomination, i) => (
          <DenominationItem
            isDollars={isDollars}
            value={denomination}
            isSelected={selected === denomination}
            selectDenomination={selectAmount}
            key={`${denomination}-${i}`}
            isFree={isFree}
          />
        ))}
      </>
    ),
    [denominations, selected, isDollars]
  )

  return <StyledDenominationsMenu>{denominationsList}</StyledDenominationsMenu>
}

export default DenominationsMenu
