import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactNode } from 'react'

import { FeatureFlags } from './FeatureFlags.constants'
import { isFeatureFlagOn, isValidFeatureFlag } from './utils'

export interface FeatureFlagProps {
  children: ReactNode
  flag: keyof typeof FeatureFlags
  renderWhenFlagIsFalse?: boolean
  defaultChildren?: ReactNode
}

export const FeatureFlag = ({
  children,
  flag,
  renderWhenFlagIsFalse,
  defaultChildren = null
}: FeatureFlagProps) => {
  const flags = useFlags()
  if (!children || !isValidFeatureFlag(flags, flag)) {
    if (renderWhenFlagIsFalse) {
      return <>{children || null}</>
    }
    return <>{defaultChildren}</>
  }

  const isOn = isFeatureFlagOn(flags, flag)
  const renderChildren = renderWhenFlagIsFalse ? !isOn : isOn
  return renderChildren ? <>{children}</> : <>{defaultChildren}</>
}
