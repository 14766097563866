import { Typography } from '@mui/material'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { fetchDailyQuestionThunk, getDailyQuestion } from 'slices/dailyQuestion'

import QuestionWidget from './questionWidget'

export default function OnarollDaily() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchDailyQuestionThunk())
  }, [])

  const dailyQuestion = useSelector(getDailyQuestion)
  const { t } = useTranslation()

  if (!dailyQuestion) return null

  const header = <Typography variant="h4">{t('onaroll.daily.title')}</Typography>
  return <QuestionWidget header={header} question={dailyQuestion} />
}
