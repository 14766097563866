/* eslint react/prop-types: 0 */
import clsx from 'clsx'

import { alpha } from '@mui/material'

import { PieChart } from 'react-minimal-pie-chart'

import Placeholder, { CircleLoader } from 'placeholder'

import { Colors } from 'theme'

export interface CircularProgressBarProps {
  progressValue: number
  fillColor?: string
  labelColor?: string
  label?: string
  className?: string
  totalValue?: number
  circleSize: number
  isLoading?: boolean
}

export function CircularProgressBar({
  className,
  fillColor = Colors.purple,
  label,
  labelColor = Colors.purple,
  progressValue = 0,
  totalValue = 100,
  circleSize,
  isLoading = false
}: CircularProgressBarProps) {
  const data = [{ key: 1, value: progressValue, color: fillColor }]

  return (
    <div className={clsx(className)}>
      <Placeholder
        isLoading={isLoading}
        loader={() => <CircleLoader size={circleSize} />}
        amount={1}
      >
        <PieChart
          totalValue={totalValue}
          startAngle={90}
          data={data}
          label={(props) => {
            return label ?? `${props.dataEntry.value} / ${totalValue}`
          }}
          labelStyle={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            fill: labelColor
          }}
          labelPosition={0}
          lineWidth={20}
          animate
          background={alpha(Colors.purple, 0.4)}
          rounded
        />
      </Placeholder>
    </div>
  )
}

export default CircularProgressBar
