import { ReactNode } from 'react'

import { BrandedLogo, LanguageButton } from '../../components'
import { AnimatedContent, VerticalAnimationDirection } from 'components/AnimatedContent'
import { BackButton } from 'components/BackButton'

import { StyledFooter, StyledHeader, StyledPage } from './LoginFormLayout.styles'

export interface LoginFormLayoutProps {
  children: ReactNode
  handleGoBack?: () => void
  animationDirection?: VerticalAnimationDirection
}

export default function LoginFormLayout({
  children,
  handleGoBack,
  animationDirection = VerticalAnimationDirection.UP
}: LoginFormLayoutProps) {
  const onBack = handleGoBack || undefined

  return (
    <StyledPage>
      <div>
        <StyledHeader>
          <BackButton onlyIcon onBack={onBack} />
          <BrandedLogo />
        </StyledHeader>
        <AnimatedContent verticalAnimationDirection={animationDirection}>
          {children}
        </AnimatedContent>
      </div>
      <StyledFooter verticalAnimationDirection={animationDirection}>
        <LanguageButton />
      </StyledFooter>
    </StyledPage>
  )
}
