import { TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

interface TextFieldProps {
  isSubField: boolean
}
interface SelectFieldProps {
  isSubField: boolean
}

export const TextFieldContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSubField'
})<Partial<TextFieldProps>>(({ isSubField }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: isSubField ? 15 : 0
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}))

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isSubField'
})<TextFieldProps>(({ isSubField }) => ({
  flex: isSubField ? 11 : 'none',
  marginLeft: isSubField ? 10 : 0
}))

export const StyledDatepicker = styled(TextField)(({ theme }) => ({
  input: {
    paddingLeft: theme.spacing(2)
  }
}))

export const SelectFieldContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSubField'
})<SelectFieldProps>(({ isSubField }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: isSubField ? 15 : 0
}))

export const StyledErrorMessage = styled('small', {
  shouldForwardProp: (prop) => prop !== 'isSubField'
})<Partial<SelectFieldProps>>(({ theme, isSubField }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.colors.error,
  marginLeft: isSubField ? 15 : 0,
  marginBottom: theme.spacing(3)
}))

export default makeStyles(() =>
  createStyles({
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    }
  })
)
