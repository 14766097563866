// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RewardDiscount } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { ONBOARDING_PERKS, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchOnboardingPerksThunk } from './actions'

export const onboardingPerksAdapter = createEntityAdapter<RewardDiscount>({
  selectId: (discount) => discount.id
})

export const onboardingPerksSlice = createSlice({
  name: ONBOARDING_PERKS,
  initialState: onboardingPerksAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingPerksThunk.fulfilled, (state, action) => {
        onboardingPerksAdapter.setAll(state, action.payload)
        handleRequestFulfilled(state)
      })
      .addCase(fetchOnboardingPerksThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOnboardingPerksThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
  }
})

export default onboardingPerksSlice.reducer
