import { useContext } from 'react'

import { StepContext } from 'pages/onboarding'

import { AnimatedContent } from 'components/AnimatedContent'
import { VerticalAnimationDirection } from 'components/AnimatedContent/utils'
import { StyledConfettiBackground } from 'components/confettiBackground/styles'

import { onboardingConfettiPositionStyles } from './styles'

interface OnboardingConfettiBackgroundProps {
  shouldShowConfetti: boolean
}

export const OnboardingConfettiBackground = ({
  shouldShowConfetti
}: OnboardingConfettiBackgroundProps) => {
  const { isOnSmallDevice, isOnTinyDevice } = useContext(StepContext)
  return (
    <AnimatedContent
      verticalAnimationDirection={VerticalAnimationDirection.NONE}
      motionDivProps={{
        style: onboardingConfettiPositionStyles,
        initial: { opacity: shouldShowConfetti ? 0 : 1 },
        transition: { duration: shouldShowConfetti ? 0.75 : 0.5 },
        animate: { opacity: shouldShowConfetti ? 1 : 0 }
      }}
      id="OuterAnimatedWrapper"
    >
      {!isOnSmallDevice ? (
        <>
          <StyledConfettiBackground
            style={{ zIndex: 0 }}
            data-testid="OnboardingConfettiBackground1"
          />
          <StyledConfettiBackground
            style={{ zIndex: 0, top: 75 }}
            data-testid="OnboardingConfettiBackground2"
          />
        </>
      ) : (
        <StyledConfettiBackground
          style={{ zIndex: 0, height: isOnTinyDevice ? 200 : undefined }}
          data-testid="OnboardingConfettiBackground-SmallDevice"
        />
      )}
    </AnimatedContent>
  )
}
