import { Whoami, GenericDTO } from 'types'

import { fetchRecords, putOnboardingFinished } from 'api'

import { ONBOARDING } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

export const onboardingModalSeenThunk = authenticatedThunkCreator<Whoami, GenericDTO>(
  `${ONBOARDING}/onboardingModalSeenThunk`,
  async (authedDTO) => {
    await putOnboardingFinished(authedDTO)
    const whoami: Whoami = await fetchRecords(authedDTO, 'whoami')
    return whoami
  }
)
