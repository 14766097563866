import { useTranslation } from 'react-i18next'

import perksIcon from '../../assets/images/perks-unlocked.svg'

import { JackpotPrize } from 'types'

import { useNavigation } from 'hooks/useNavigation'

import {
  StyledJackpotEmptyPrizeItemDescription,
  StyledJackpotEmptyPrizeItemTitle,
  StyledJackpotNextPrizeItem,
  StyledJackpotNextPrizeItemContent,
  StyledJackpotNextPrizeItemImg,
  StyledJackpotNextPrizeItemTagline,
  StyledJackpotNextPrizeItemTitle,
  StyledJackpotPrizeItem,
  StyledJackpotPrizeItemCash,
  StyledJackpotPrizeItemDetails,
  StyledJackpotPrizeItemImg,
  StyledJackpotPrizeItemScope,
  StyledJackpotPrizeItemTitle
} from './styles'

import { onA11yKeyDown, RootPaths } from '../../utils/helpers'
import { getTranslationsFromJackpotPrize } from './utils'

interface PrizeItemProps {
  prize: JackpotPrize
}

export function NextPrizeItem({ prize }: PrizeItemProps) {
  const { image, title, tagline } = prize

  return (
    <StyledJackpotNextPrizeItem elevation={0}>
      <StyledJackpotNextPrizeItemImg
        src={image || perksIcon}
        alt={`picture of the ${title} prize`}
      />
      <StyledJackpotNextPrizeItemContent>
        <StyledJackpotNextPrizeItemTitle variant="body1">
          {title}
        </StyledJackpotNextPrizeItemTitle>
        <StyledJackpotNextPrizeItemTagline variant="body2">
          {tagline}
        </StyledJackpotNextPrizeItemTagline>
      </StyledJackpotNextPrizeItemContent>
    </StyledJackpotNextPrizeItem>
  )
}

interface EmptyPrizeItemProps {
  title: string
  description?: string
}

export function EmptyPrizeItem({ title, description }: EmptyPrizeItemProps) {
  return (
    <StyledJackpotPrizeItem elevation={0}>
      <div>
        <StyledJackpotEmptyPrizeItemTitle variant="body1">
          {title}
        </StyledJackpotEmptyPrizeItemTitle>
        {description && (
          <StyledJackpotEmptyPrizeItemDescription variant="body2" component="p">
            {description}
          </StyledJackpotEmptyPrizeItemDescription>
        )}
      </div>
    </StyledJackpotPrizeItem>
  )
}

export default function PrizeItem({ prize }: PrizeItemProps) {
  const navigation = useNavigation()
  const { t } = useTranslation()

  const { id, is_cash_prize, cash_value, image } = prize
  const { title, scope, tagline } = getTranslationsFromJackpotPrize(prize, t)

  const navigateToJackpotPrize = () => {
    return navigation.push(`${RootPaths.jackpot}/${id}`)
  }

  return (
    <StyledJackpotPrizeItem
      elevation={0}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => onA11yKeyDown(event, navigateToJackpotPrize)}
      onClick={navigateToJackpotPrize}
      key={id}
    >
      <div>
        <StyledJackpotPrizeItemTitle variant="h3">{title}</StyledJackpotPrizeItemTitle>
        <StyledJackpotPrizeItemScope variant="h4" component="p">
          {scope}
        </StyledJackpotPrizeItemScope>
        {is_cash_prize ? (
          <StyledJackpotPrizeItemCash variant="h2" component="p">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: cash_value > 9999 ? 0 : 2,
              maximumFractionDigits: cash_value > 9999 ? 0 : 2
            }).format(cash_value)}
          </StyledJackpotPrizeItemCash>
        ) : (
          <StyledJackpotPrizeItemDetails variant="h4" component="p">
            {tagline}
          </StyledJackpotPrizeItemDetails>
        )}
      </div>
      <StyledJackpotPrizeItemImg
        src={image || perksIcon}
        alt={`picture of the ${title} prize`}
      />
    </StyledJackpotPrizeItem>
  )
}
