import { debounce } from 'lodash'
import { ACTIVE_RING_COLORS } from 'theme'

import { Key, KeyboardEvent } from 'react'
import { TFunction } from 'react-i18next'
import { generatePath } from 'react-router'

import {
  Goal,
  LoginStateParams,
  OnarollRewardProductType,
  RewardDetail,
  RewardInfo,
  RewardType
} from 'types'

import { GoalItemSlice } from 'pages/goals/goalsItem'

import { setSnackbar } from 'slices/snackbar'

import { GenericAsyncThunk, OnarollThunkDispatch } from 'reduxStore'

import env from './runtimeEnv'

export enum APIVersion {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
  v4 = 'v4'
}

// Keep this seperate from nested routes
// This is all the high level root routes especially for the footer
export enum RootPaths {
  loginToken = '/a/:loginToken',
  impersonate = '/impersonate/:employment_id/:user_id',
  verifyEmail = '/verify-email',
  goals = '/goals',
  perks = '/perks',
  jackpot = '/jackpot',
  jackpotHistory = '/jackpot-ticket-history',
  news = '/news',
  help = '/help',
  userProfile = '/profile',
  redeemedPerks = '/perks-wallet',
  achievedGoals = '/achieved-goals',
  welcome = '/login',
  home = '/',
  loginWithType = '/login/:type(phone|email)',
  verifyOTP = '/login/otp/verify'
}

export enum AdminPaths {
  manageAllGoals = '/tlx/goals',
  manageUpsertGoal = '/tlx/goals/:id',

  manageCSV = '/tlx/data',
  manageUpsertCSV = '/tlx/data/:id'
}

export enum TeamLeaderPaths {
  teamMembers = '/team',
  teamMemberView = '/team/:id/view',
  teamMemberEdit = '/team/:id/edit',

  teamMemberCelebrate = '/team/celebrate',
  teamMemberCelebrateOutbox = '/team/celebrate/outbox'
}

export const ChildPaths = {
  goalsOnDate: `${RootPaths.goals}/:date`,
  goalsToday: `${RootPaths.goals}/today`,
  goalsYesterday: `${RootPaths.goals}/yesterday`,
  suggestPerk: `${RootPaths.perks}/suggest`
} as const

export const getGoalsOnDatePath = (date: string) => {
  return generatePath(ChildPaths.goalsOnDate, { date })
}

export const getLoginWithTypePath = (type: LoginStateParams['loginType']) =>
  generatePath(RootPaths.loginWithType, {
    type
  })

// 1100 -> 1.1K up until the billions
export const abbreviateNumber = (n: number): string => {
  if (n < 0) return '0'
  const stringifiedNum = String(n)
  if (n < 1e3) return stringifiedNum
  else if (n < 1e6) return `${(n / 1e3).toFixed(1)}K`
  else if (n < 1e9) return `${(n / 1e6).toFixed(1)}M`
  else if (n < 1e12) return `${(n / 1e9).toFixed(1)}B`
  else return stringifiedNum
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal'
})

export const pointsToDollar = (points: number) => pointsToAmount(points, 100)

export const pointsToAmount = (points: number, pointsPrice: number) =>
  pointsPrice !== 0 ? points / pointsPrice : 1

export const formatDollar = (dollars: number) => currencyFormatter.format(dollars)

export const dollarToPoints = (amount: number) => amountToPoints(Number(amount), 100)

export const formatPoints = (points: number) => decimalFormatter.format(points)

export const amountToPoints = (amount: number, pointsPrice: number) =>
  Number((amount * pointsPrice).toFixed(0))

export const totalPointsWithDiscount = (totalPoints: number, percent_discount: number) =>
  Math.round(((100 - percent_discount) / 100) * totalPoints)

export const amountText = (
  amount: number,
  rewardDetail: RewardDetail | RewardInfo,
  t: TFunction
) => {
  const rewardType = rewardDetail.reward_type
  const unit = rewardDetail.configuration?.unit

  const isJackpotTicket =
    rewardDetail.configuration?.product_type === OnarollRewardProductType.jackpot_ticket
  const perkType = isJackpotTicket
    ? OnarollRewardProductType.jackpot_ticket
    : rewardDetail.reward_type

  return rewardType === RewardType.item
    ? `${amount} ${t(`perks.perkType.${perkType}`, {
        unit: unit,
        plural: amount === 1 ? '' : 's'
      })}`
    : formatDollar(amount)
}

export const isRewardRedeemable = (
  reward: RewardInfo | RewardDetail,
  pointsBalance: number,
  amountOfPerk?: number,
  discountedPoints?: number | null
) => {
  let cost = null
  if (amountOfPerk) cost = amountToPoints(amountOfPerk, reward.points_per_unit_cost)
  const isFree = discountedPoints === 0
  const isDiscounted = !!discountedPoints && pointsBalance >= discountedPoints
  const isFullPrice = cost !== null && pointsBalance >= cost

  return isFree || isDiscounted || isFullPrice
}

// For keyboard accessibility
// Listens for any spacebar or enter key press
export const onA11yKeyDown = <T,>(
  event: KeyboardEvent<T>,
  cb: (event: KeyboardEvent<T>) => void
) => {
  switch (event.key) {
    case ' ': // space
    case 'Spacebar':
    case 'Enter': // enter
      cb?.(event)
      break
    default:
      break
  }
}

export const toTitleCase = (text: string) =>
  text
    .toLowerCase()
    .split(/[\s-_]/)
    .map((str) => `${str.charAt(0).toUpperCase()}${str.substring(1)}`)
    .join(' ')

// Useful for reading from params to make sure
// we have one consistent format
export const ensureAsArray = (item: null | string | string[]) => {
  if (!item) return []
  if (typeof item === 'string') return [item]
  return item
}

export const aggregateValues = <T,>(arr: T[], keyInArray: keyof T) =>
  arr.reduce((memo, item) => memo + (Number(item[keyInArray]) ?? 0), 0)

export const groupBy = <T, K extends string | number | symbol>(
  arr: T[],
  getKey: (item: T) => K
) =>
  arr.reduce(
    (memo, item) => {
      const value = getKey(item)
      return {
        ...memo,
        [value]: [...(memo[value] || []), item]
      }
    },
    {} as Record<K, T[]>
  )

export const isDevENV = () => {
  return process.env.NODE_ENV === 'development'
}

export const getEnvAPIHost = (): string => {
  if (isDevENV()) {
      return env.REACT_APP_API_HOST ?? 'http://localhost:8000'
  }
  if (process.env.REACT_APP_API_HOST) { // Vercel
    return process.env.REACT_APP_API_HOST
  }
  return env.REACT_APP_API_HOST // Heroku
}

export const getEnvGraphQLAPIHost = (): string =>
  isDevENV()
    ? process.env.REACT_APP_GRAPHQL_API_HOST ??
      'https://goal-service-staging-3c32681ee39d.herokuapp.com/graphql'
    : process.env.REACT_APP_GRAPHQL_API_HOST ?? ''

export const getRMSApiHost = (): string => isDevENV()
    ? process.env.REACT_APP_RMS_API_HOST ??
      'http://localhost:8080'
    : process.env.REACT_APP_RMS_API_HOST ?? ''

export const getGoalDataWithColors = (goals: Goal[]) => {
  const data: GoalItemSlice[] = goals.map((goal, i) => {
    return {
      ...goal,
      value: goal.points,
      color: ACTIVE_RING_COLORS[i % ACTIVE_RING_COLORS.length]
    }
  })
  return data
}

/* Since we cannot pass type arguments through debounceFetch to debounce,
  this is as specific as we can get for the function typing here */
const fetchOptions = (
  dispatch: OnarollThunkDispatch,
  func: GenericAsyncThunk,
  options: Record<Key, unknown>
) => dispatch(func(options))

export const debounceFetch = debounce(fetchOptions, 300, {
  leading: false,
  trailing: true
})

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

// 19177956519 => +1 917 795 6519
export const formatPhoneNumberWithSpace = (phone?: string): string => {
  const cleaned = (phone || '').replace(/\D+/g, '')
  const validPhoneLength = 10
  if (cleaned?.length >= validPhoneLength) {
    const digits = cleaned.slice(cleaned.length - validPhoneLength, cleaned.length)
    const areaCodeLength = cleaned.length - validPhoneLength
    const areaCode = cleaned.slice(0, areaCodeLength)
    const prefix = areaCode ? `+${areaCode} ` : ''
    return `${prefix}${digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`
  } else {
    // Not formed correctly - just return as is
    return cleaned
  }
}

// { hi: "hello", ...(false && { hello: "hi" }) } => { hi: "hello" }
export const conditionalObject = (
  object: Record<string, unknown>,
  condition: unknown
) => {
  return {
    ...(Boolean(condition) && object)
  }
}

// Strips undefined from the object to allow objects to be merged and retain null values
// Ex: null == we intentionally dont want a value so make sure to overwrite the previous value if merged
// with another object and undefined == we never passed this value in, so DONT overwrite anything when merged
// Ex: Merging { name: "DL", age: 5, sex: "male"} with removeUndefinedFromObject({ name: "BM", age: null, sex: undefined})
// should retain age but NOT sex => { name: "DL", age: null, sex: "male" }
export const removeUndefinedFromObject = <T extends object>(obj: T) => {
  return Object.keys(obj).reduce(
    (acc, key) =>
      obj[key as keyof T] === undefined
        ? { ...acc }
        : { ...acc, [key]: obj[key as keyof T] },
    {}
  )
}

export const setSnackbarSuccess = (message: string) =>
  setSnackbar({
    open: true,
    type: 'success',
    message: message
  })

export const setSnackbarError = (message: string) =>
  setSnackbar({
    open: true,
    type: 'error',
    message: message
  })
