import { motion } from 'framer-motion'

import { Box, styled, Typography } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledOnboardingPerkItemImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected?: boolean }>(({ isSelected }) => ({
  height: '100%',
  width: '100%',
  filter: 'grayscale(100%)',
  ...conditionalObject({ filter: 'none' }, isSelected)
}))

export const StyledOnboardingPerkItem = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'canBeSelected'
})<{ canBeSelected?: boolean }>(({ canBeSelected, theme }) => ({
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.colors.white,
  padding: theme.spacing(3),
  cursor: 'not-allowed',
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  ...conditionalObject({ cursor: 'pointer' }, canBeSelected)
}))

export const StyledOnboardingPerkItemCheckmarkWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-1),
  right: theme.spacing(-1),
  backgroundColor: theme.palette.colors.purple,
  height: theme.spacing(5),
  width: theme.spacing(5),
  borderRadius: theme.borderRadius.half,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1)
}))

export const StyledOnboardingPerkItemImageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.black
}))

export const StyledOnboardingGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridAutoRows: `minmax(80px, 100%)`,
  textAlign: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    width: '50%'
  },
  [theme.breakpoints.down('md')]: {
    width: '75%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  padding: theme.spacing(0, 2)
}))
