// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useSelector } from 'react-redux'
import { RouteProps } from 'react-router-dom'

import ErrorNotAuthorized from '../pages/error/errorNotAuthorized'

import { getAuthIsLoading } from 'slices/auth'
import { getWhoami, getWhoamiIsLoading } from 'slices/whoami'

import ProtectedRoute from './ProtectedRoute'
import HeartLoadingIndicator from './heartLoadingIndicator'

export default function TeamLeaderRoute({ children, ...restProps }: RouteProps) {
  const isAuthLoading = useSelector(getAuthIsLoading)
  const isWhoamiLoading = useSelector(getWhoamiIsLoading)
  const whoami = useSelector(getWhoami)

  const renderContent = () => {
    if (isAuthLoading || isWhoamiLoading) {
      return <HeartLoadingIndicator fullPage />
    }

    if (whoami?.is_onaroll_staff || whoami?.team_leader_stores?.length) {
      return children
    }

    return <ErrorNotAuthorized />
  }

  return <ProtectedRoute {...restProps}>{renderContent()}</ProtectedRoute>
}
