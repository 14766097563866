import { LDProvider } from 'launchdarkly-react-client-sdk'

import useSetUpUser from 'hooks/useSetUpUser'
import { ReactElement } from 'react'
import { getIsAuthenticated } from 'slices/auth'
import { useSelector } from 'react-redux'

export const LaunchDarklyProvider = ({ children }: { children: ReactElement[] }) => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const { getLDUserContext } = useSetUpUser(isAuthenticated)

  return (
    <LDProvider
      clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || ''}
      deferInitialization={true}
      context={getLDUserContext()}>
      {children}
    </LDProvider>)
}