import { isEmpty } from 'lodash'

import { createSelector } from '@reduxjs/toolkit'

import { STORE_SWITCHER } from 'slices/constants'

import { brandsSelectors } from 'slices/brands'
import { storesSelectors } from 'slices/stores'
import { getWhoami } from 'slices/whoami'

import { RootState } from 'reduxStore'

export const getPageIndex = (state: RootState) => state[STORE_SWITCHER].pageIndex

export const getSelectedStore = createSelector(
  getPageIndex,
  storesSelectors.selectAll,
  (pageIndex, stores) => {
    return stores[pageIndex % stores.length]
  }
)

export const getIsOpen = (state: RootState) => state[STORE_SWITCHER].open

export const getDefaultSelectedStore = createSelector(
  getSelectedStore,
  getWhoami,
  (selectedStore, whoAmI) => {
    return selectedStore || whoAmI?.default_selected_store
  }
)

export const getSelectedStoreBrand = createSelector(
  getSelectedStore,
  brandsSelectors.selectAll,
  getWhoami,
  (selectedStore, brands, whoami) => {
    if (!selectedStore || isEmpty(brands)) {
      return brands?.find(
        (brand) => brand.id === whoami?.default_selected_store?.brand_id
      )
    }
    return brands?.find((brand) => brand.id === selectedStore?.brand_id)
  }
)
