import { fetchRecords } from 'api'

import { Brand, FetchCachedThunkProps } from 'types'

import { BRANDS } from 'slices/constants'

import { authenticatedThunkCreator, shouldRefetch } from 'slices/utils'

import { RootState } from 'reduxStore'

import { brandsSelectors, selectBrandsLoading } from '.'

export const fetchUserStoreBrandsThunk = authenticatedThunkCreator<
  Brand[],
  FetchCachedThunkProps | undefined
>(
  `${BRANDS}/fetchUserStoreBrandsThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, BRANDS),
  {
    condition: (options = {}, { getState }) => {
      const state = getState() as RootState
      const isUpdating = selectBrandsLoading(state)
      const prevBrandsList = brandsSelectors.selectAll(state)
      return shouldRefetch(isUpdating, prevBrandsList, null, options)
    }
  }
)
