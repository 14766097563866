// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Grid } from '@mui/material'
import useTheme from 'hooks/useTheme'

import { useSelector } from 'react-redux'

import { ReactComponent as GoalsIcon } from 'assets/icons/AppIcons/GoalsIcon.svg'

import Placeholder, { TeamLeaderOnboardingTextLoader } from 'placeholder'

import { getTeamMembersLoading } from 'slices/admin'

import { onA11yKeyDown } from 'utils/helpers'
import { Colors } from 'theme'

import {
  StyledCTATextWrapper,
  StyledGoalButton,
  StyledGoalsButtonWrapper,
  StyledGoalsContainer,
  StyledNotOnboardedTeamMemberText,
  StyledGoalsArrowIcon
} from './home.styles'
import {
  StyledTeamLeaderGoalOverviewCTAText,
  StyledTeamLeaderGoalOverviewSubText,
  StyledTeamLeaderGoalOverviewText,
  StyledTeamLeaderGoalOverviewTextContainer,
  StyledTeamLeaderGoalsOverviewContent,
  StyledTeamLeaderOnboardingProgress
} from './teamLeaderGoalDashboard.styles'

interface TeamLeaderGoalDashboardProps {
  goToTeamLeaderTeamPage: () => void
  goToTodaysGoalsPage: () => void
  onboardedCount: number
  notOnboardedCount: number
  teamMembersCount: number
}

const TeamLeaderGoalDashboard = ({
  goToTeamLeaderTeamPage,
  goToTodaysGoalsPage,
  onboardedCount = 0,
  notOnboardedCount = 0,
  teamMembersCount = 0
}: TeamLeaderGoalDashboardProps) => {
  const isLoading = useSelector(getTeamMembersLoading)
  const allTeamMembersOnboarded: boolean = teamMembersCount === onboardedCount
  const theme = useTheme()
  return (
    <>
      <Grid item xs={12}>
        <StyledGoalsContainer component="article" elevation={2}>
          <StyledTeamLeaderGoalsOverviewContent
            role="button"
            tabIndex={0}
            onClick={goToTeamLeaderTeamPage}
            onKeyDown={(event) => onA11yKeyDown(event, goToTeamLeaderTeamPage)}
          >
            <StyledTeamLeaderOnboardingProgress
              progressValue={onboardedCount}
              totalValue={teamMembersCount}
              circleSize={120}
              isLoading={isLoading}
            />
            <StyledTeamLeaderGoalOverviewTextContainer>
              <StyledTeamLeaderGoalOverviewText variant="body1">
                Team Members
              </StyledTeamLeaderGoalOverviewText>
              <Placeholder
                isLoading={isLoading}
                loader={TeamLeaderOnboardingTextLoader}
                amount={1}
              >
                <StyledNotOnboardedTeamMemberText>
                  <StyledTeamLeaderGoalOverviewSubText variant="body1">
                    {allTeamMembersOnboarded ? '' : `${notOnboardedCount} more to go`}
                  </StyledTeamLeaderGoalOverviewSubText>
                  <StyledCTATextWrapper role="button">
                    <StyledTeamLeaderGoalOverviewCTAText variant="body1">
                      {allTeamMembersOnboarded ? 'Your team' : 'Add now!'}
                    </StyledTeamLeaderGoalOverviewCTAText>
                    <StyledGoalsArrowIcon fill={theme.branding.primaryColor} />
                  </StyledCTATextWrapper>
                </StyledNotOnboardedTeamMemberText>
              </Placeholder>
            </StyledTeamLeaderGoalOverviewTextContainer>
          </StyledTeamLeaderGoalsOverviewContent>
        </StyledGoalsContainer>
      </Grid>
      <Grid item xs={12}>
        <StyledGoalsButtonWrapper>
          <StyledGoalButton
            variant="contained"
            color="primary"
            onClick={goToTodaysGoalsPage}
          >
            <GoalsIcon stroke={Colors.white} />
            Goals
          </StyledGoalButton>
        </StyledGoalsButtonWrapper>
      </Grid>
    </>
  )
}

export default TeamLeaderGoalDashboard
