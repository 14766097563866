import { motion } from 'framer-motion'
import { FONT_FAMILY } from 'theme'

import { styled, Typography } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledInput = styled('input')(({ theme }) => ({
  fontFamily: FONT_FAMILY,
  display: 'block',
  outline: 'none',
  backgroundColor: 'inherit',
  fontSize: theme.fontSize(3),
  '::placeholder': {
    color: theme.palette.colors.disabled
  },
  color: theme.palette.colors.white,
  width: '100%',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  border: 'none',
  borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.colors.blue}`,
  // the below three styles are needed to remove border rounding + shadows on ios devices
  borderRadius: 0,
  WebkitAppearance: 'none',
  WebkitBorderRadius: 0,
  // aligns the date type input to the left instead of center
  '::-webkit-date-and-time-value': {
    textAlign: 'left'
  }
}))

export const StyledInputContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  borderRadius: theme.spacing(4),
  background: theme.palette.colors.lightBlack,
  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  marginTop: theme.spacing(2),
  width: '100%',
  ...conditionalObject(
    {
      padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
      marginTop: theme.spacing(1)
    },
    isOnSmallDevice
  )
}))

export const StyledInputTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.blue
}))

export const StyledInputWrapper = styled(motion.div, {
  shouldForwardProp: (prop) =>
    prop !== 'fullWidth' &&
    prop !== 'marginBottom' &&
    prop !== 'marginTop' &&
    prop !== 'marginRight' &&
    prop !== 'marginLeft'
})<{
  fullWidth?: boolean
  marginBottom: number
  marginTop: number
  marginLeft: number
  marginRight: number
}>(({ fullWidth, marginBottom, marginTop, marginRight, marginLeft, theme }) => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...conditionalObject({ width: '100%' }, fullWidth),
  ...conditionalObject(
    { marginBottom: theme.spacing(marginBottom) },
    Boolean(marginBottom)
  ),
  ...conditionalObject({ marginTop: theme.spacing(marginTop) }, Boolean(marginTop)),
  ...conditionalObject({ marginRight: theme.spacing(marginRight) }, Boolean(marginRight)),
  ...conditionalObject({ marginLeft: theme.spacing(marginLeft) }, Boolean(marginLeft))
}))
