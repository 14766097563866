import { Button, styled } from '@mui/material'

import { BottomModal, SimpleModal } from 'components/modals'

export const StyledGlobalModalActionButton = styled(Button)(({ theme }) => ({
  minWidth: '132px',
  margin: theme.spacing(1),
  '&.MuiButton-outlinedError': {
    color: theme.palette.colors.error,
    borderColor: theme.palette.colors.error
  }
}))

export const StyledGlobalSimpleModal = styled(SimpleModal)(({ theme }) => ({
  textAlign: 'center',
  '.MuiDialog-container>.MuiPaper-root': {
    padding: theme.spacing(5)
  },
  '.MuiDialog-paper': {
    maxWidth: '90%',
    backgroundColor: theme.palette.common.white
  },
  '.BaseModal__actions': {
    flexWrap: 'wrap'
  },
  '.BaseModal__title + .BaseModal__content': {
    padding: `${theme.spacing(4)} 0`
  },
  '.MuiDialog-container .BaseModal__actions': {
    margin: 0
  }
}))

export const StyledGlobalBottomModal = styled(BottomModal)(({ theme }) => ({
  textAlign: 'center',
  '.MuiDialog-container>.MuiPaper-root': {
    padding: theme.spacing(5),
    minHeight: 0
  },
  '.MuiDialog-paper': {
    maxWidth: '90%',
    backgroundColor: theme.palette.common.white
  },
  '.BaseModal__actions': {
    flexWrap: 'wrap'
  },
  '.BaseModal__content': {
    padding: `${theme.spacing(4)} 0`
  }
}))
