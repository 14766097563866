import { IconButton, Input, styled } from '@mui/material'
import { Colors } from '../../theme'

export const DatePickerContainer = styled('div')(() => ({
  display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

export const StyledGoalsDatePicker = styled(Input)(({ theme }) => ({
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.disabled}`,
  borderRadius: '8px',
  padding: `${theme.spacing(2.4)} ${theme.spacing(4.8)}`

}))

export const DatePickerArrow = styled(IconButton)(() => ({
  color: Colors.white,
  ':active': {
    stroke: Colors.black,
    fill: Colors.black
  },
  '&.Mui-disabled svg': {
    '& circle': {
      stroke: 'rgba(0, 0, 0, 0.1)',
      color: Colors.gray200
    },
    '& path': {
      fill: 'rgba(0, 0, 0, 0.1)'
    }
  }
}))
