import { Goal, GoalFrequencyOption } from 'types'

import {
  GoalsHeaderContainer,
  GoalsHeaderTitle,
  GoalsHeaderSubcopy,
  GoalsHeaderPointsContainer,
  GoalsHeaderPointsSum,
  GoalsHeaderPointsText
} from './GoalsHeader.styles'

import { FREQUENCY_SUBCOPY } from './constants'

interface GoalsContainerProps {
  frequency: GoalFrequencyOption
  values: Goal[]
}

const sumPoints = (values: Goal[]) => {
  return values.reduce((acc: number, curr: Goal) => acc + curr.points, 0)
}

const GoalsHeader = ({ frequency, values }: GoalsContainerProps) => {
  return (
    <GoalsHeaderContainer>
      <div>
        <GoalsHeaderTitle variant="h3" component="p">
          {frequency}
        </GoalsHeaderTitle>
        <GoalsHeaderSubcopy>{FREQUENCY_SUBCOPY[frequency]}</GoalsHeaderSubcopy>
      </div>
      <GoalsHeaderPointsContainer>
        <GoalsHeaderPointsSum>{sumPoints(values)}</GoalsHeaderPointsSum>
        <GoalsHeaderPointsText>Points</GoalsHeaderPointsText>
      </GoalsHeaderPointsContainer>
    </GoalsHeaderContainer>
  )
}

export default GoalsHeader
