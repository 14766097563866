import { createSlice } from '@reduxjs/toolkit'

import { FavoritePerk } from 'types'

import { FAVORITE_PERKS, RequestState, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchFavoritePerksThunk, postFavoritePerksThunk } from './actions'

/**
 * TODO for the future engineer that adds favorite perks to the perk page in app
 * https://github.com/onarollonaroll/onaroll-app/pull/371#issuecomment-1310927299
 */
export interface FavoritePerksState extends RequestState {
  favoritePerks: FavoritePerk[]
  postFavoritePerksError: unknown
}

const initialState: FavoritePerksState = {
  favoritePerks: [],
  postFavoritePerksError: null,
  ...requestState
}

export const favoritePerksSlice = createSlice({
  name: FAVORITE_PERKS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoritePerksThunk.fulfilled, (state, { payload }) => {
        state.favoritePerks = payload as FavoritePerk[]
        handleRequestFulfilled(state)
      })
      .addCase(fetchFavoritePerksThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFavoritePerksThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(postFavoritePerksThunk.fulfilled, (state) => {
        handleRequestFulfilled(state)
      })
      .addCase(postFavoritePerksThunk.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(postFavoritePerksThunk.rejected, (state, action) => {
        state.postFavoritePerksError = action.error
        state.isLoading = false
      })
  }
})

export default favoritePerksSlice.reducer
