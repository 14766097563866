import { createContext } from 'react'

import { GlobalModalOpenActionProps } from './GlobalModalProvider'

export interface GlobalModalContextType {
  openGlobalModal: (props?: GlobalModalOpenActionProps) => void
  closeGlobalModal: () => void
}

export const GlobalModalContext = createContext<GlobalModalContextType>({
  openGlobalModal: () => {},
  closeGlobalModal: () => {}
})
