import Splash from 'components/splash'

import useAuthenticateUser from 'hooks/useAuthenticateUser'
import { useParams } from 'react-router-dom'
import { LoginToken } from '../../types'
import { useSelector } from 'react-redux'
import { getIsAuthenticated, getLoginToken } from '../../slices/auth'
import { useQueryParams } from 'hooks/useQueryParams'
import { UrlParams } from 'constants/UrlParams'

export default function AuthPage() {
  const { loginToken } = useParams<{ loginToken: LoginToken }>()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const storedLoginToken = useSelector(getLoginToken)
  const queryParams = useQueryParams()
  const userProfileId = queryParams.get(UrlParams.userProfileId)
  useAuthenticateUser(isAuthenticated, loginToken, storedLoginToken, userProfileId)

  return <Splash />
}
