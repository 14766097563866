import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { AuthTokensDTO, Impersonator } from 'types'

import { IMPERSONATOR, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { impersonateWorkerThunk, setImpersonation, updateToken } from './actions'


export interface ImpersonatorState extends RequestState {
  impersonator: Impersonator | null
}

const initialState: ImpersonatorState = {
  impersonator: null,
  ...requestState
}

export const impersonatorSlice = createSlice({
  name: IMPERSONATOR,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(impersonateWorkerThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(impersonateWorkerThunk.fulfilled, (state, action) => {
        const { impersonator } = action.payload as AuthTokensDTO & {
          impersonator: Impersonator
        }
        state.impersonator = impersonator
        handleRequestFulfilled(state)
      })
      .addCase(impersonateWorkerThunk.rejected, (state, action) => {
        const payload = state.impersonator?.encrypted_id
          ? 'You are already impersonating someone. Please drop the impersonation and try again.'
          : state.error
        handleRequestRejected(state, { ...action, payload })
      })
      .addCase(setImpersonation, (state, action) => {
        const impersonatedUser = action.payload.user_profile_id

        if (impersonatedUser) {
          state.impersonator = {
            impersonatee: {
              user_profile_id: impersonatedUser,
              firebase: true,
            }
          }
        } else {
          state.impersonator = null
        }
        handleRequestFulfilled(state)
      })
      .addCase(updateToken.pending, (state, _action) => {
        state.isLoading = true
        // deliberately not setting hasLoaded = false here
        // to prevent extra reloads when dropping or changing
        // impersonation
      })
      // while in "normal" operation we hit this on set, we need
      // to handle edge cases here so we don't get stuck in a bad state
      .addMatcher(isAnyOf(
        updateToken.fulfilled,
        updateToken.rejected,
      ),
      (state, _action) => {
        handleRequestFulfilled(state)
      }
    )
  }
})

export default impersonatorSlice.reducer
