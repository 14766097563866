import { CategoryTypes } from 'types'

import { ReactElement } from 'react'

import { ReactComponent as AllCategoryIcon } from 'assets/icons/CategoryIcons/AllCategoriesIcon.svg'
import { ReactComponent as BeautyCategoryIcon } from 'assets/icons/CategoryIcons/BeautyCategoryIcon.svg'
import { ReactComponent as CharityCategoryIcon } from 'assets/icons/CategoryIcons/CharityCategoryIcon.svg'
import { ReactComponent as ClothesCategoryIcon } from 'assets/icons/CategoryIcons/ClothesCategoryIcon.svg'
import { ReactComponent as CoffeeCategoryIcon } from 'assets/icons/CategoryIcons/CoffeeCategoryIcon.svg'
import { ReactComponent as EntertainmentCategoryIcon } from 'assets/icons/CategoryIcons/EntertainmentCategoryIcon.svg'
import { ReactComponent as FashionCategoryIcon } from 'assets/icons/CategoryIcons/FashionCategoryIcon.svg'
import { ReactComponent as FoodCategoryIcon } from 'assets/icons/CategoryIcons/FoodCategoryIcon.svg'
import { ReactComponent as GamingCategoryIcon } from 'assets/icons/CategoryIcons/GamingCategoryIcon.svg'
import { ReactComponent as LifestyleCategoryIcon } from 'assets/icons/CategoryIcons/LifestyleCategoryIcon.svg'
import { ReactComponent as MoviesCategoryIcon } from 'assets/icons/CategoryIcons/MoviesCategoryIcon.svg'
import { ReactComponent as MusicCategoryIcon } from 'assets/icons/CategoryIcons/MusicCategoryIcon.svg'
import { ReactComponent as RestaurantCategoryIcon } from 'assets/icons/CategoryIcons/RestaurantCategoryIcon.svg'
import { ReactComponent as SportsCategoryIcon } from 'assets/icons/CategoryIcons/SportsCategoryIcon.svg'
import { ReactComponent as TechCategoryIcon } from 'assets/icons/CategoryIcons/TechCategoryIcon.svg'
import { ReactComponent as TravelCategoryIcon } from 'assets/icons/CategoryIcons/TravelCategoryIcon.svg'

import { Colors } from 'theme'

import CategoryIconWrapper from './CategoryIconWrapper'

const CategoriesMap: { [key: string]: [ReactElement, StrokeOrFill] } = {
  [CategoryTypes.all]: [<AllCategoryIcon key="1" />, 'fill'],
  [CategoryTypes.beauty]: [<BeautyCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.charity]: [<CharityCategoryIcon key="1" />, 'fill'],
  [CategoryTypes.clothes]: [<ClothesCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.coffee]: [<CoffeeCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.entertain]: [<EntertainmentCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.fashion]: [<FashionCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.food]: [<FoodCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.gaming]: [<GamingCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.lifestyle]: [<LifestyleCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.movies]: [<MoviesCategoryIcon key="1" />, 'fill'],
  [CategoryTypes.music]: [<MusicCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.restaurant]: [<RestaurantCategoryIcon key="1" />, 'stroke'],
  [CategoryTypes.sports]: [<SportsCategoryIcon key="1" />, 'fill'],
  [CategoryTypes.tech]: [<TechCategoryIcon key="1" />, 'fill'],
  [CategoryTypes.travel]: [<TravelCategoryIcon key="1" />, 'stroke']
}

export interface CategoryIconProps {
  active?: boolean
  activeBackgroundColor?: string
  color?: string
  type: CategoryTypes
}

type StrokeOrFill = 'stroke' | 'fill'

const getIcon = (type: CategoryTypes): [ReactElement, StrokeOrFill] => {
  const iconAndStrokeFill = CategoriesMap[type]
  return iconAndStrokeFill || CategoriesMap[CategoryTypes.all]
}

export const CategoryIcon = ({
  type,
  color = Colors.black,
  activeBackgroundColor = Colors.yellow,
  active = false
}: CategoryIconProps) => {
  const [icon, strokeOrFill] = getIcon(type)
  return (
    <CategoryIconWrapper
      innerStroke={strokeOrFill === 'stroke' ? color : undefined}
      innerFill={strokeOrFill === 'fill' ? color : undefined}
      backgroundColor={activeBackgroundColor}
      active={active}
    >
      {icon}
    </CategoryIconWrapper>
  )
}

export default CategoryIcon
