import { StyledInfoIcon, StyledNote, StyledNoteMessage } from './styles'

export interface InfoNoteProps {
  text: string
}

export function InfoNote({ text }: InfoNoteProps) {
  return (
    <StyledNote>
      <StyledInfoIcon />
      <StyledNoteMessage>{text}</StyledNoteMessage>
    </StyledNote>
  )
}

export default InfoNote
