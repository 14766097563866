import { Button, styled } from '@mui/material'

export const StyledQuestionForm = styled('form')({
  width: '100%',
  "input[type='text']": {
    textAlign: 'center'
  }
})

export const StyledQuestionFormButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  justifyContent: 'space-evenly',
  alignItems: 'center'
}))

export const StyledQuestionFormButton = styled(Button)(({ theme }) => ({
  minWidth: '130px',
  marginBottom: theme.spacing(1)
}))
