import { UrlParams } from 'constants/UrlParams'

import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useQueryParams } from './useQueryParams'

type ReactRouterArgType = ReturnType<typeof useLocation>

export interface NavigationProps extends Partial<ReactRouterArgType> {
  pathname: string
  preserveParams?: boolean
  state?: any
}

type NavArgs = string | NavigationProps

const convertToHistoryProps = (args: NavArgs): NavigationProps => {
  if (typeof args === 'string') {
    return { pathname: args, preserveParams: true }
  }
  return {
    ...args,
    preserveParams: args.preserveParams === undefined ? true : !!args.preserveParams
  }
}

const getSearchString = (
  preserveParams: boolean,
  oldParams: string,
  newParams: string | undefined
) => {
  return preserveParams
    ? `${oldParams || ''}${(oldParams && newParams && '&') || ''}${newParams || ''}`
    : newParams || ''
}

export const useNavigation = () => {
  const history = useHistory()
  const queryParams = useQueryParams()

  return useMemo(() => {
    //want to preserve impersonation query params across all navigation
    const impersonationId = queryParams.get(UrlParams.encryptedUserId)
    const impersonationEmployment = queryParams.get(UrlParams.impersonationEmploymentId)
    let preservedParamsQueryString = ''
    if (impersonationId && impersonationEmployment) {
      preservedParamsQueryString = `${UrlParams.encryptedUserId}=${impersonationId}&${UrlParams.impersonationEmploymentId}=${impersonationEmployment}`
    }

    return {
      push: (args: NavArgs) => {
        const props = convertToHistoryProps(args)
        history.push({
          pathname: props.pathname,
          search: getSearchString(
            props.preserveParams!,
            preservedParamsQueryString,
            props.search
          ),
          ...(props.state && { state: props.state })
        })
      },
      replace: (args: NavArgs) => {
        const props = convertToHistoryProps(args)
        history.replace({
          pathname: props.pathname,
          search: getSearchString(
            props.preserveParams!,
            preservedParamsQueryString,
            props.search
          ),
          ...(props.state && { state: props.state })
        })
      },
      pushParams: ({
        search,
        preserveParams = true
      }: Pick<NavigationProps, 'search' | 'preserveParams'>) => {
        history.push({
          search: getSearchString(preserveParams, preservedParamsQueryString, search)
        })
      },
      goBack: () => {
        history.goBack()
      }
    }
  }, [history, queryParams])
}
