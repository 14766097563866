// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Schedule, PaginatedSchedules } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { SCHEDULES, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchAllSchedulesThunk } from '.'

interface SchedulesState extends RequestState {
  schedulesResponse: PaginatedSchedules
}

const initialGoalState: SchedulesState = {
  schedulesResponse: {} as PaginatedSchedules,
  ...requestState
}

export const SchedulesAdapter = createEntityAdapter<Schedule>({
  selectId: (schedule) => schedule.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const schedulesSlice = createSlice({
  name: SCHEDULES,
  initialState: SchedulesAdapter.getInitialState(initialGoalState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSchedulesThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllSchedulesThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(fetchAllSchedulesThunk.fulfilled, (state, action) => {
        const { next, previous, results = [] } = action.payload
        state.schedulesResponse = { next, previous }
        SchedulesAdapter.setAll(state, results)
        handleRequestFulfilled(state)
      })
  }
})

export default schedulesSlice.reducer
