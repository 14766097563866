import clsx from 'clsx'

import { CSSProperties } from 'react'

import { ReactComponent as CheckIcon } from 'assets/icons/AppIcons/CheckIcon.svg'
import { ReactComponent as XIcon } from 'assets/icons/AppIcons/CircleXIcon.svg'

import { Goal } from 'types'

import GoalIcon, { GoalIconTypes } from 'components/icons/GoalIcon/GoalIcon'

import {
  StyledGoalsItem,
  StyledGoalsItemDescription,
  StyledGoalsItemFooter,
  StyledGoalsItemIconWrapper,
  StyledGoalsItemPointsValue,
  StyledGoalsItemResult,
  StyledGoalsItemTitle,
  StyledGoalsItemWrapper
} from './goalsItem.styles'
import { Colors } from '../../theme'

export interface GoalItemSlice extends Goal {
  value: number
  color: string
}

interface GoalItemProps {
  item: GoalItemSlice
  onSelectedGoalItem?: (key: Goal) => void
  hideGoalResult?: boolean
  style?: CSSProperties
  className?: string
}

export interface GoalItemStyleProps {
  item: GoalItemSlice
}

interface GoalItemPointsProps {
  item: GoalItemSlice
}

export const GoalItemPoints = ({ item }: GoalItemPointsProps) => {
  const { points, result_status } = item
  return (
    <>
      <StyledGoalsItemIconWrapper>
        {result_status === 'achieved' && <CheckIcon />}
        {result_status === 'not_achieved' && <XIcon fill={Colors.error} />}
      </StyledGoalsItemIconWrapper>
      <StyledGoalsItemPointsValue variant="body1" component="span" item={item}>
        {result_status === 'achieved' && "+" + points}
        {(result_status === 'processing' || result_status === 'not_achieved') && points}
      </StyledGoalsItemPointsValue>
    </>
  )
}

const GoalsItem = ({
  item,
  onSelectedGoalItem,
  style = {},
  hideGoalResult,
  className
}: GoalItemProps) => {
  const { color, icon, title, description, result, testing, gp_testing_status } = item
  const isTesting = '*Testing'

  const content = (
    <>
      <StyledGoalsItemWrapper>
        <StyledGoalsItemTitle variant="h4">{title}</StyledGoalsItemTitle>
        <StyledGoalsItemTitle variant="h5" color={item.color}>
          {testing ? isTesting : null}
        </StyledGoalsItemTitle>
      </StyledGoalsItemWrapper>
      <StyledGoalsItemDescription variant="h4">{description}</StyledGoalsItemDescription>
    </>
  )

  const resultContent = (
    <StyledGoalsItemFooter item={item}>
      <StyledGoalsItemWrapper>
        <StyledGoalsItemResult variant="h4" item={item}>
          {result}
        </StyledGoalsItemResult>
        <StyledGoalsItemResult variant="h5" item={item}>
          {gp_testing_status ? isTesting : null}
        </StyledGoalsItemResult>
      </StyledGoalsItemWrapper>
    </StyledGoalsItemFooter>
  )

  return (
    <StyledGoalsItem
      className={clsx(className)}
      item={item}
      image={<GoalIcon color={color} type={icon as GoalIconTypes} />}
      content={content}
      extraInfo={<GoalItemPoints item={item} />}
      onClick={(goal) => onSelectedGoalItem?.(goal)}
      style={style}
    >
      <>{hideGoalResult ? null : resultContent}</>
    </StyledGoalsItem>
  )
}

export default GoalsItem
