export const MuiBottomNavConfig = {
  styleOverrides: {
    root: {
      backgroundColor: '#FCFDFF', // one off color for footer
      padding: '10px 0 20px',
      borderTopLeftRadius: 50,
      borderTopRightRadius: 50
    }
  }
}
