import { Button, styled, Typography } from '@mui/material'

export const StyledEmailButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.colors.grayDisabled,
  color: theme.palette.common.white,
  borderRadius: theme.borderRadius.small,
  padding: `${theme.spacing(3.5)} ${theme.spacing(6)}`,
  justifyContent: 'flex-start',
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(2)
  }
}))

export const StyledEmailButtonTitle = styled(Typography)({
  textTransform: 'none',
  textAlign: 'left'
})
