// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isEmpty } from 'lodash'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import perksIcon from 'assets/images/perks-unlocked.svg'

import PageWithModal from 'layouts/pageWithModal'

import { getTranslationsFromJackpotPrize } from 'pages/jackpot/utils'

import useAppDispatch from 'hooks/useAppDispatch'

import HeartLoadingIndicator from 'components/heartLoadingIndicator'

import { useNavigation } from 'hooks/useNavigation'

import { THUNK_CONDITION_ERROR_NAME } from 'slices/constants'

import {
  fetchJackpotCurrentDrawingThunk,
  getDrawingsLoading,
  getJackpotPrize
} from 'slices/jackpot'

import { RootPaths } from 'utils/helpers'
import { logError } from 'utils/logger'
import { UrlParamTypes } from 'utils/url'

import {
  StyledJackpotPrizeBody,
  StyledJackpotPrizeImage,
  StyledJackpotPrizeSubTitle,
  StyledJackpotPrizeTitle,
  StyledPrizeImageInnerContainer,
  StyledPrizeImageOuterContainer
} from './styles'

import { RootState } from 'reduxStore'

import JackpotPrizeHeader from './jackpotPrizeHeader'

export default function JackpotPrize() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id: idFromParams } = useParams<UrlParamTypes>()
  const navigation = useNavigation()

  useEffect(() => {
    dispatch(fetchJackpotCurrentDrawingThunk({ getCachedResults: true }))
      .unwrap()
      .then((drawing) => {
        if (isEmpty(drawing.prizes)) {
          navigation.push(RootPaths.jackpot)
        }
      })
      .catch((e) => {
        if (e.name === THUNK_CONDITION_ERROR_NAME) {
          return
        } else {
          logError(`${e.name}: ${e.message}`)
          navigation.push(RootPaths.jackpot)
        }
      })
  }, [])

  const drawingsLoading = useSelector(getDrawingsLoading)

  const jackpotPrize = useSelector((state: RootState) =>
    getJackpotPrize(state, idFromParams as string)
  )

  if (drawingsLoading) {
    return <HeartLoadingIndicator fullPage />
  }

  if (!jackpotPrize) return <></>

  const renderFormattedDescription = (description: string) => {
    let formattedDescription = description
    if (!formattedDescription.endsWith('\n')) {
      formattedDescription = formattedDescription.concat('\n')
    }
    const matches = formattedDescription.match(/(?<sentence>.+)[\n\r]/gi)
    return matches?.map((sentence) => (
      <StyledJackpotPrizeBody key={sentence} variant="body1">
        {sentence}
      </StyledJackpotPrizeBody>
    ))
  }

  const getJackpotContent = () => {
    const { description, scope, title } = getTranslationsFromJackpotPrize(jackpotPrize, t)

    return (
      <>
        <StyledJackpotPrizeTitle variant="h2" component="h1">
          {title}
        </StyledJackpotPrizeTitle>
        <StyledJackpotPrizeSubTitle variant="body2" component="h2">
          {scope}
        </StyledJackpotPrizeSubTitle>
        {renderFormattedDescription(description)}
      </>
    )
  }

  return (
    <>
      <PageWithModal
        themeType="dark"
        headerContent={<JackpotPrizeHeader />}
        headerImage={
          <>
            <StyledPrizeImageOuterContainer>
              <StyledPrizeImageInnerContainer>
                <StyledJackpotPrizeImage
                  src={jackpotPrize.image || perksIcon}
                  alt="Prize image"
                />
              </StyledPrizeImageInnerContainer>
            </StyledPrizeImageOuterContainer>
          </>
        }
      >
        {getJackpotContent()}
      </PageWithModal>
    </>
  )
}
