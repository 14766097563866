import { useSelector } from 'react-redux'
import { getImpersonator } from 'slices/impersonation'
import { selectStoresHasLoaded, storesSelectors } from 'slices/stores'
import { getWhoami } from 'slices/whoami'
import { useChangeUserLanguage, useLogoutTerminatedUser } from './index'
import { useSessionTrack } from './useSessionTrack'
import { fetchStoreGroupsThunk } from '../slices/admin'
import useAppDispatch from './useAppDispatch'

export default function useSetUpUser(isAuthenticated: boolean) {
  const impersonator = useSelector(getImpersonator)
  const whoami = useSelector(getWhoami)
  const hasStoresLoaded = useSelector(selectStoresHasLoaded)
  const stores = useSelector(storesSelectors.selectAll)
  const storeGroupIds = [...new Set(stores.map(store => store.group_id))]
  const { changeLanguage } = useChangeUserLanguage()
  const dispatch = useAppDispatch()

  useLogoutTerminatedUser()
  useSessionTrack()

  const getLDUserContext = () => {
    if (isAuthenticated && whoami && hasStoresLoaded) {
      const {
        user_profile_id,
        display_name,
        onboarded_at,
        phone,
        language,
        is_onaroll_staff
      } = whoami
      if (is_onaroll_staff) {
        dispatch(fetchStoreGroupsThunk({ getCachedResults: true }))
      }
      changeLanguage(whoami)
      return {
        kind: 'user',
        key: user_profile_id, // LD needs this
        name: display_name, // LD needs this
        user_profile_id,
        display_name,
        onboarded_at,
        phone,
        impersonator: impersonator?.email,
        language,
        is_onaroll_staff: String(is_onaroll_staff),
        store_group_ids: storeGroupIds
      }
    }
    return {
      kind: 'user',
      key: 'anonymous-user', // LD needs this
      name: 'anonymous-user' // LD needs this
    }
  }

  return {
    getLDUserContext
  }

}
