import {
  StyledExtraInfoIcon,
  StyledExtraInfoPointsValue
} from '../styles'

interface PointsExtraInfoDefaultProps {
  completed: boolean
  points: number
  color: string
  text?: string
}

export interface PointsExtraInfoProps<
  T extends PointsExtraInfoDefaultProps = PointsExtraInfoDefaultProps
> {
  item: T
}

// TODO: Refactor StyledGoalsItem to use this component instead
export const PointsExtraInfo = <
  T extends PointsExtraInfoDefaultProps = PointsExtraInfoDefaultProps
>({
  item
}: PointsExtraInfoProps<T>) => {
  const { completed, points } = item
  // TODO: THis is correctly broken. Check out the storybook to see it in action
  // WE are relying on components that implement this component to "hack" the styles and
  // fix the css issues with this component
  // A couple things I found wrong with it:
  // 1- looks like the check icon is not changing color regardless of the passed prop
  // 2- The check icon has `absolute` positioning, but this component has no relative parent
  // 3- Looks like the text changes color to a light theme by default and cannot be overridden
  return (
    <>
      {completed && (
        <StyledExtraInfoIcon data-testid="PointsExtraInfoIcon" />
      )}
      <StyledExtraInfoPointsValue variant="body1" component="span" item={item}>
        {points}
      </StyledExtraInfoPointsValue>
    </>
  )
}
