import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OnboardingContactUsWidget } from 'pages/onboarding/components'

import {
  getFavoritePerks,
  getIsFavoritePerksError,
  getIsFavoritePerksLoading
} from 'slices/perks'

import LoadingPerksGrid from './LoadingPerksGrid'
import PerksGrid from './PerksGrid'

export default function OnboardingPerksGrid() {
  const { t } = useTranslation()

  const favoritePerks = useSelector(getFavoritePerks)
  const isFavoritePerksLoading = useSelector(getIsFavoritePerksLoading)
  const isFavoritePerksError = useSelector(getIsFavoritePerksError)

  const renderFavoritePerks = () => {
    if (isFavoritePerksLoading || favoritePerks?.length === 0) {
      return <LoadingPerksGrid />
    }
    return <PerksGrid />
  }

  if (isFavoritePerksError) {
    return (
      <OnboardingContactUsWidget
        i18nKey="pages.onboarding.errors.fetchFavoritePerks.title"
        messageBody={t('pages.onboarding.errors.fetchFavoritePerks.messageBody')}
      />
    )
  }

  return renderFavoritePerks()
}
