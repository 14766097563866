import { TextField } from '@mui/material'

import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DailyFormProps {
  onChange: (answer: string) => void
}

export default function OnarollDailyForm({ onChange }: DailyFormProps) {
  const { t } = useTranslation()
  const [answerValue, setAnswerValue] = useState('')

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setAnswerValue(value)
    onChange(value)
  }

  return (
    <TextField
      required
      id="trivia-answer"
      name="trivia-answer"
      fullWidth
      value={answerValue}
      onChange={onInputChange}
      inputProps={{
        'aria-label': t('onaroll.question.form.input.ariaLabel'),
        placeholder: t('onaroll.question.form.input.placeholder')
      }}
    />
  )
}
