import { ReactNode, useState } from 'react'

import { ReactComponent as FilterIcon } from 'assets/icons/AppIcons/FilterIcon.svg'

import { PageWithModalProps } from 'layouts/pageWithModal'

import {
  StyledAdminContainer,
  StyledAdminContainerFilterContent,
  StyledAdminContainerFilterIcon,
  StyledAdminContainerFilters,
  StyledAdminContainerHeaderTitle,
  StyledAdminContainerSubheader
} from './styles'

interface AdminContainerCommonProps {
  children: ReactNode
  headerTitle: string
  containerProps?: Omit<PageWithModalProps, 'children' | 'headerContent'>
}

interface AdminContainerConditionalRequiredProps extends AdminContainerCommonProps {
  search: ReactNode
  filters: ReactNode
}

interface AdminContainerConditionalOptionalProps extends AdminContainerCommonProps {
  search?: never
  filters?: never
}

export type AdminContainerProps = AdminContainerCommonProps &
  (AdminContainerConditionalRequiredProps | AdminContainerConditionalOptionalProps)

const AdminContainer = ({
  children,
  headerTitle,
  filters,
  search,
  containerProps = {}
}: AdminContainerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const header = (
    <StyledAdminContainerHeaderTitle variant="h2" component="span">
      {headerTitle}
    </StyledAdminContainerHeaderTitle>
  )

  const onClick = () => setIsOpen((prevState) => !prevState)

  const searchContent = () => {
    if (!search) return null
    return search
  }

  const filterToggle = () => {
    if (!filters) return null
    return (
      <StyledAdminContainerFilterIcon
        onClick={onClick}
        size="large"
        data-testid="AdminContainerFilterIcon"
      >
        <FilterIcon />
      </StyledAdminContainerFilterIcon>
    )
  }

  const filterContent = () => {
    if (!filters) return null
    return (
      <StyledAdminContainerFilterContent
        in={isOpen}
        data-testid="AdminContainerFilterContent"
      >
        <StyledAdminContainerFilters>{filters}</StyledAdminContainerFilters>
      </StyledAdminContainerFilterContent>
    )
  }

  const customContainerProps = {
    ...containerProps,
    showHeaderImage: false // Never show header image in this layout
  }

  return (
    <StyledAdminContainer headerContent={header} {...customContainerProps}>
      {(search || filters) && (
        <StyledAdminContainerSubheader
          data-testid="AdminContainerSubheader"
          showFilterToggle={Boolean(filters)}
        >
          {searchContent()}
          {filterToggle()}
        </StyledAdminContainerSubheader>
      )}
      {filterContent()}
      {children}
    </StyledAdminContainer>
  )
}

export default AdminContainer
