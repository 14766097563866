// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { GoalFrequency } from 'types'

import { createSlice } from '@reduxjs/toolkit'

import { ADMIN_GOAL_FREQUENCIES, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchAllGoalFrequenciesThunk } from './actions'

interface AdminGoalFrequenciesState extends RequestState {
  allGoalFrequencies: GoalFrequency[] | null
}

const initialState: AdminGoalFrequenciesState = {
  allGoalFrequencies: null,
  ...requestState
}

export const adminGoalFrequenciesSlice = createSlice({
  name: ADMIN_GOAL_FREQUENCIES,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllGoalFrequenciesThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllGoalFrequenciesThunk.rejected, (state, action) => {
      handleRequestRejected(state, action)
    })
    builder.addCase(fetchAllGoalFrequenciesThunk.fulfilled, (state, action) => {
      state.allGoalFrequencies = action.payload
      handleRequestFulfilled(state)
    })
  }
})

export default adminGoalFrequenciesSlice.reducer
