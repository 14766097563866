import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Goal, GoalFrequencyOption } from 'types'

import Placeholder, { RowItemLoader } from 'placeholder'

import { SimpleModal } from 'components/modals'

import { getGoalsLoading } from 'slices/goals'
import { getWhoami } from 'slices/whoami'

import { getGoalDataWithColors, groupBy } from 'utils/helpers'

import {
  GoalsModalContainer,
  StyledGoalsContainer,
  StyledGoalsItemContainer
} from './goalsContainer.styles'

import GoalsHeader from './GoalsHeader'
import GoalsDatePicker from './goalsDatePicker'
import GoalsItem from './goalsItem'

interface GoalsContainerProps {
  goals: Goal[]
  isTodaysGoals?: boolean
  date?: string
  setCurrentDate: React.Dispatch<React.SetStateAction<string>>
}

const GoalsContainer = ({
  isTodaysGoals,
  goals,
  date,
  setCurrentDate
}: GoalsContainerProps) => {
  const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null)

  const isLoading = useSelector(getGoalsLoading)
  const whoami = useSelector(getWhoami)

  const getFrequencySortingLevel = (key: GoalFrequencyOption) => {
    switch (key) {
      case GoalFrequencyOption.daily:
        return 1
      case GoalFrequencyOption.weekly:
        return 2
      case GoalFrequencyOption.monthly:
        return 3
      default:
        return 0
    }
  }

  const data = getGoalDataWithColors(goals)
  const groupedData = groupBy(data, (goal) => goal.frequency)

  const sortedGroupedData = Object.entries(groupedData).sort(([aKey], [bKey]) => {
    const aLevel = getFrequencySortingLevel(aKey as GoalFrequencyOption)
    const bLevel = getFrequencySortingLevel(bKey as GoalFrequencyOption)
    if (aLevel < bLevel) {
      return -1
    }
    if (aLevel > bLevel) {
      return 1
    }
    return 0
  })

  const onGoalItemClick = (goal: Goal) => {
    setSelectedGoal(goal)
  }

  const onGoalItemModalClose = () => {
    setSelectedGoal(null)
  }

  const GoalModalContent = () => (
    <GoalsModalContainer>
      <h3>{selectedGoal?.title}</h3>
      <p>{selectedGoal?.description}</p>
    </GoalsModalContainer>
  )

  return (
    <>
      <StyledGoalsContainer>
        <GoalsDatePicker
          setCurrentDate={setCurrentDate}
          minimumDate={whoami?.history_start_date}
          currentDate={date}
        />
      </StyledGoalsContainer>
      {sortedGroupedData.map(([frequency, values]) => (
        <div key={frequency}>
          <GoalsHeader frequency={frequency as GoalFrequencyOption} values={values} />
          <StyledGoalsItemContainer>
            <Placeholder isLoading={isLoading} loader={RowItemLoader} amount={4}>
              {values.map((goalSlice) => (
                <GoalsItem
                  key={goalSlice.id}
                  item={goalSlice}
                  hideGoalResult={
                    isTodaysGoals && goalSlice.result_status === 'processing'
                  }
                  onSelectedGoalItem={onGoalItemClick}
                />
              ))}
            </Placeholder>
          </StyledGoalsItemContainer>
        </div>
      ))}
      <SimpleModal
        data-testid="GoalInfoModal"
        isOpen={!!selectedGoal}
        onClose={onGoalItemModalClose}
        content={<GoalModalContent />}
      />
    </>
  )
}

export default GoalsContainer
