import { useTranslation } from 'react-i18next'

import Page from 'layouts/page'

import withStoreDataRefresh from 'hoc/withStoreDataRefresh'

import GoalsOnDate from './goalsOnDate'

const Goals = () => {
  const { t } = useTranslation()
  return (
    <Page title={t('pages.goals.title')}>
      <GoalsOnDate />
    </Page>
  )
}

export default withStoreDataRefresh(Goals)
