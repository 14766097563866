import { isEqual as _isEqual } from 'lodash'
import {
  AdminListFilters,
  PaginatedTeamMembersV2,
  PaginatedTeamResponse,
  TeamMemberV2,
  FetchOneDTO,
  FetchWithParamsAndLazyLoadProps,
  UpsertThunkProps
} from 'types'

import { fetchRecord, fetchRecords, putRecord } from 'api'

import { ADMIN_TEAM_MEMBERS } from 'slices/constants'

import { authenticatedThunkCreator, getTransformedParams } from 'slices/utils'

import { APIVersion } from 'utils/helpers'

import { RootState } from 'reduxStore'

import { getTeamMembersIds, getTeamMembersQuery, getTeamMembersResponse } from '.'

const TEAM_MEMBERS_ENDPOINT = 'teamMembers'

export const fetchAllTeamMembersThunk = authenticatedThunkCreator<
  PaginatedTeamMembersV2,
  FetchWithParamsAndLazyLoadProps<AdminListFilters>
>(
  `${ADMIN_TEAM_MEMBERS}/fetchAllTeamMembersThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const prevTeamMembers = getTeamMembersResponse(state)
    const params = getTransformedParams(prevTeamMembers, authedDTO)

    const allTeamMembers: PaginatedTeamResponse<TeamMemberV2> = await fetchRecords(
      {
        ...authedDTO,
        queryParams: params
      },
      'teamMembers',
      APIVersion.v2
    )
    return allTeamMembers
  },
  {
    condition: (options, { getState }) => {
      const queryParams = options?.queryParams
      if (queryParams?.next || options?.isInitial || !options?.getCachedResults)
        return true
      const state = getState() as RootState
      const query = getTeamMembersQuery(state)
      const isEqual = _isEqual(queryParams, query)
      const hasTMs = getTeamMembersIds(state).length
      if (isEqual && hasTMs) return false
    }
  }
)

export const fetchTeamMemberThunk = authenticatedThunkCreator<TeamMemberV2, FetchOneDTO>(
  `${ADMIN_TEAM_MEMBERS}/fetchTeamMemberThunk`,
  async (options) => {
    const teamMember: TeamMemberV2 = await fetchRecord(
      options,
      'teamMembers',
      APIVersion.v2
    )
    return teamMember
  }
)

export const updateTeamMemberThunk = authenticatedThunkCreator<
  TeamMemberV2,
  Required<UpsertThunkProps<Partial<TeamMemberV2>>>
>(`${ADMIN_TEAM_MEMBERS}/updateTeamMemberThunk`, async (authedDTO) => {
  const teamMember: TeamMemberV2 = await putRecord(
    authedDTO,
    TEAM_MEMBERS_ENDPOINT,
    APIVersion.v2
  )
  return teamMember
})
