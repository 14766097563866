import { createSelector } from '@reduxjs/toolkit'

import { WHOAMI } from 'slices/constants'

import { RootState } from 'reduxStore'

export const getWhoami = (state: RootState) => state[WHOAMI].whoami
export const getWhoamiIsLoading = (state: RootState) => state[WHOAMI].isLoading
export const getWhoamiHasLoaded = (state: RootState) => state.whoami.hasLoaded
export const getWhoamiHasError = (state: RootState) => state[WHOAMI].error

export const getUserSettingsIsLoading = (state: RootState) =>
  state.whoami.isUpdateUserSettingsLoading
export const getUserSettingsV4HasError = (state: RootState) =>
  state[WHOAMI].updateUserSettingsV4Error

export const getShowOnboarding = createSelector(getWhoami, (whoami) => {
  return !!whoami && !whoami.onboarded_at
})

export const getIsOnarollStaff = createSelector(getWhoami, (whoami) => {
  return !!whoami?.is_onaroll_staff
})

export const getIsTeamLeader = createSelector(getWhoami, (whoami) => {
  return !!whoami?.team_leader_stores?.length
})
