import { Card, styled } from '@mui/material'

export const FaqContainer = styled(Card)`
  padding: ${({ theme }) => theme.spacing(4, 3)};
  text-align: center;
  background-color: ${({ theme }) => theme.branding.secondaryColor};
`
export const RewardsTileContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
