import { Button, styled, Typography } from '@mui/material'

import { ReactComponent as CaretDownIcon } from 'assets/icons/AppIcons/CaretDownIcon.svg'

import PageWithSubheader from 'layouts/pageWithSubheader'

export const StyledHelpHeader = styled(PageWithSubheader)(({ theme }) => ({
  '.LayoutPageWithSubheader': {
    '&__header': {
      background: 'inherit'
    },
    '&__content': {
      background: theme.branding.lightTextColor
    },
    '&__subheader': {
      alignItems: 'unset'
    }
  }
}))

export const StyledHelpArticle = styled('article')(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(3)
  }
}))

export const StyledHelpArticleTriggerIcon = styled(CaretDownIcon, {
  shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(({ isOpen }) => ({
  minWidth: '16px',
  ...(!isOpen && { transform: 'rotateZ(-90deg)' })
}))

export const StyledHelpArticleTrigger = styled(Button)({
  padding: 0,
  textAlign: 'left',
  minHeight: 0
})

export const StyledHelpArticleTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontWeight: 'bold'
}))

export const StyledHelpArticleDescription = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.branding.subTextColor
}))

export const StyledExploreOnarollLink = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3)
}))
