export enum FETCH_STATUS_TYPE {
  idle = 'idle',
  loading = 'loading',
  hasLoaded = 'hasLoaded',
  error = 'error'
}

// Record & State Types
export const AUTH = 'auth'
export const IMPERSONATOR = 'impersonator'
export const ONBOARDING = 'onboarding'
export const USER_INFO = 'userInfo'
export const SNACKBAR = 'snackbar'
export const BRANDS = 'brands'
export const ADMIN_GOALS = 'admin/goals'
export const ADMIN_TEAM_MEMBERS = 'admin/teamMembers'
export const ADMIN_GOAL_POINT_GROUPS = 'admin/goalPointGroups'
export const ADMIN_GOAL_FREQUENCIES = 'admin/goalFrequencies'
export const ADMIN_CSV_SCHEMAS = 'admin/csvSchemas'
export const ADMIN_TEAM_EMPLOYMENTS = 'admin/employments'
export const ADMIN_STORES = 'admin/stores'
export const ADMIN_CELEBRATION_POINTS = 'admin/celebrationPoints'
export const ADMIN_EMPLOYMENTS = 'admin/employments'
export const DAILY_QUESTION = 'dailyQuestion'
export const STORE_GROUPS = 'storeGroups' // TODO: Change to admin
export const LOCATIONS = 'locations'
export const SCHEDULES = 'schedules'
export const GOALS = 'goals'
export const JACKPOT = 'jackpot'
export const DRAWINGS = 'drawings'
export const WINNERS = 'winners'
export const TICKETS = 'tickets'
export const PERKS = 'perks'
export const ONBOARDING_PERKS = 'onboardingPerks'
export const REDEEMED_PERKS = 'redeemedPerks'
export const SUGGESTED_PERK = 'suggestedReward'
export const FAVORITE_PERKS = 'favoritePerks'
export const POINTS = 'points'
export const TEAM_ROLES = 'teamRoles'
export const STORES = 'stores'
export const STORE_SWITCHER = 'storeSwitcher'
export const WHOAMI = 'whoami'
export const SMS = 'sms'

// reusable state
export interface RequestState {
  isLoading: boolean
  hasLoaded: boolean
  error: unknown
}
export const requestState: RequestState = {
  isLoading: false,
  error: null,
  hasLoaded: false
}

export const THUNK_CONDITION_ERROR_NAME = 'ConditionError'

export const paginatedResponse = { next: '', previous: '', results: [] }
export const paginatedTeamResponse = {
  not_onboarded_count: 0,
  onboarded_count: 0,
  team_members_count: 0,
  ...paginatedResponse
}
