import { RedeemableAt, RewardDetail, RewardInfo, RewardType } from 'types'

export const isNumber = (value: string | number | null) => {
  return !!Number(value)
}

export interface PerkRedeemMethodOptions {
  isRedeemableOnline: boolean
  isRedeemableInApp: boolean
  isRedeemableInStore: boolean
}

export const mapRedeemableAtToBooleans = (
  redeemable_at: RedeemableAt | null
): PerkRedeemMethodOptions => {
  let isRedeemableOnline = false
  let isRedeemableInApp = false
  let isRedeemableInStore = false

  switch (redeemable_at) {
    case RedeemableAt.inApp:
      isRedeemableInApp = true
      break
    case RedeemableAt.inRestaurant:
    case RedeemableAt.inStore:
      isRedeemableInStore = true
      break
    case RedeemableAt.inStoreAndOnline:
      isRedeemableInStore = true
      isRedeemableOnline = true
      break
    case RedeemableAt.online:
      isRedeemableOnline = true
      break
    default:
      break
  }

  return { isRedeemableOnline, isRedeemableInApp, isRedeemableInStore }
}

const getFreePerkValue = (perk: RewardInfo | RewardDetail, storeId?: string) => {
  // TODO: this should not account for store id. if it's free across any
  // store for which the user has an employment, then use that.
  let freePerkValue

  // Perk is of type RewardInfo, and only one discount was returned
  if ('discount' in perk && perk.discount?.percent_discount == 100) {
    freePerkValue = perk.discount.points
  }
  // Perk is of type RewardDetail, and a list of discounts was returned
  else if ('discounts' in perk && perk.discounts?.length && storeId) {
    // Determine the correct discount for the given store id
    const selectedStoreDiscount = perk?.discounts.find((d) => d.store_id === storeId)
    if (selectedStoreDiscount?.percent_discount == 100) {
      freePerkValue = selectedStoreDiscount.points
    }
  }

  return freePerkValue
}

export const OTHER = 'Other'
const DEFAULT_DENOMINATIONS = [OTHER, '5', '10', '15', '20']
const DEFAULT_LENGTH = DEFAULT_DENOMINATIONS.length

export const getPerkDenominations = (
  perk: RewardInfo | RewardDetail,
  storeId?: string
) => {
  const { min_value, denominations } = perk

  if (perk.reward_type === RewardType.cash) {
    return [] as string[]
  }

  const free_perk_points_value = getFreePerkValue(perk, storeId)
  if (free_perk_points_value) {
    return [(free_perk_points_value / perk.points_per_unit_cost).toFixed(0)]
  }

  if (denominations?.length) {
    if (denominations.length > DEFAULT_LENGTH) {
      return denominations.slice(0, DEFAULT_LENGTH).map((denom) => String(denom))
    }
    return denominations.map((denom) => String(denom))
  } else if (min_value > 5) {
    const stringDenominations = [OTHER]
    let last = 0
    for (let i = DEFAULT_LENGTH; i > 0; i--) {
      last += min_value
      stringDenominations.push(String(last))
    }
    return stringDenominations
  } else {
    return DEFAULT_DENOMINATIONS
  }
}
