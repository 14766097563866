import { Store, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { STORES } from 'slices/constants'

import { getImpersonator } from 'slices/impersonation'
import { authenticatedThunkCreator, shouldRefetch } from 'slices/utils'

import { selectStoresLoading, storesSelectors } from './selectors'

import { RootState } from 'reduxStore'

export const fetchStoresThunk = authenticatedThunkCreator<
  Store[],
  FetchCachedThunkProps | undefined
>(
  `${STORES}/fetchStoresThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, STORES),
  {
    condition: (options = {}, { getState }) => {
      const state = getState() as RootState
      const isUpdating = selectStoresLoading(state)
      const impersonator = getImpersonator(state)
      const prevStoresList = storesSelectors.selectAll(state)
      return shouldRefetch(isUpdating, prevStoresList, impersonator, options)
    }
  }
)
