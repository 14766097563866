import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFooter = styled(BottomNavigation)(({ theme }) => ({
  minHeight: '98px',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  padding: `0 ${theme.spacing(3)}`,
  filter: 'drop-shadow(0px 0px 20px rgba(53, 49, 66, 0.2))',
  borderRadius: 0
}))

export const StyledFooterItem = styled(BottomNavigationAction)({
  minWidth: 55
})
