// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Location, PaginatedLocations } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOCATIONS, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchAllLocationsThunk } from '.'

interface LocationsState extends RequestState {
  locationsResponse: PaginatedLocations
}

const initialGoalState: LocationsState = {
  locationsResponse: {} as PaginatedLocations,
  ...requestState
}

export const LocationsAdapter = createEntityAdapter<Location>({
  selectId: (location) => location.id,
  sortComparer: (a, b) => a.zipcode.localeCompare(b.zipcode)
})

export const locationsSlice = createSlice({
  name: LOCATIONS,
  initialState: LocationsAdapter.getInitialState(initialGoalState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocationsThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllLocationsThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(fetchAllLocationsThunk.fulfilled, (state, action) => {
        const { next, previous, results = [] } = action.payload
        state.locationsResponse = { next, previous }
        LocationsAdapter.setAll(state, results)
        handleRequestFulfilled(state)
      })
  }
})

export default locationsSlice.reducer
