import clsx from 'clsx'

import { Slide, SlideProps } from '@mui/material'

import { forwardRef, Ref } from 'react'

import { StyledSimpleModal } from './styles'

import { BaseModalProps } from '../baseModal'

const Transition = forwardRef(function Transition(props: SlideProps, ref: Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SimpleModal(props: Omit<BaseModalProps, 'TransitionComponent'>) {
  return (
    <StyledSimpleModal
      {...props}
      className={clsx('SimpleModal', props?.className)}
      paperProps={{ elevation: 1 }}
      TransitionComponent={Transition}
    />
  )
}
