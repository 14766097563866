import { Button, OutlinedInput, Typography } from "@mui/material";
import styled from "styled-components";
import { BorderRadius, Colors } from "theme";

export const BirthdayReminderTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: 500,
  margin: "20px 0"
})

export const InputWrapper = styled.div({
  textAlign: "left"
})

export const BirthdayInput = styled(OutlinedInput)({
  fontSize: "16px",
  background: Colors.gray200,
  borderRadius: BorderRadius.small,
  width: "100%",
  minWidth: "300px",
  color: Colors.black,
  "& .MuiOutlinedInput-notchedOutline": {
    display: "none"
  }
})

export const BirthdayInputLabel = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: Colors.black,
  marginTop: "20px",
  marginBottom: "8px"
})

export const BirthdayModalActionButton = styled(Button)({
  width: "100%",
  marginTop: "20px",
  fontWeight: 500,
  color: Colors.gray500
})

export const BirthdayModalConfirmButton = styled(BirthdayModalActionButton)({
  background: Colors.purple,
  color: Colors.white
})

export const CalendarIcon = styled('img')({
  width: '80px',
  height: 'auto'
})