import { PreferredLanguage } from "types"
import GoalIcon, { GoalIconTypes } from "components/icons/GoalIcon/GoalIcon"
import useTheme from "hooks/useTheme"
import { DateTime } from 'luxon'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getWhoami } from "slices/whoami"
import { DrawingOnDateContainer, DrawingOnDateIcon, DrawingOnDateInfo, DrawingOnDateSubtitle, DrawingOnDateTitle } from "./styles"


interface CountdownProps {
  endAtDateTime: string
}

export const JackpotDateCountdown = ({ endAtDateTime}: CountdownProps ) => {
  const theme = useTheme()
  const { t } = useTranslation();
  const whoami = useSelector(getWhoami);
  const isSpanish = whoami?.language === PreferredLanguage.spanish;
  const endDate = isSpanish? DateTime.fromISO(endAtDateTime).setLocale(PreferredLanguage.spanish) : DateTime.fromISO(endAtDateTime)
  const daysLeft = Math.floor(endDate.diff(DateTime.now(), 'days').days)
  return (
    <DrawingOnDateContainer>
      <DrawingOnDateIcon>
        <GoalIcon type={GoalIconTypes.schedule} color={theme.branding.primaryColor} />
      </DrawingOnDateIcon>
      <DrawingOnDateInfo>
        <DrawingOnDateTitle>{endDate.toLocaleString({ month: 'long', day: 'numeric'})}</DrawingOnDateTitle>
        <DrawingOnDateSubtitle>{daysLeft} {t('pages.jackpot.daysLeft')}</DrawingOnDateSubtitle>
      </DrawingOnDateInfo>
    </DrawingOnDateContainer>
  )
}
