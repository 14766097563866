import { useTranslation } from 'react-i18next'

import { HelpArticle } from 'types'

import { StyledHelpHeader } from './styles'

import HelpArticleItem from './helpArticleItem'
import ContactUsWidget from 'components/contactUs'
import ExploreOnarollLink from './ExploreOnarollLink'

export default function Help() {
  const { t } = useTranslation()

  const helpArticles = t('pages.help.articles', {
    returnObjects: true,
    defaultValue: []
  }) as HelpArticle[]

  return (
    <StyledHelpHeader themeType="neutral">
      {helpArticles?.map((article) => (
        <HelpArticleItem key={article.title} article={article} />
      ))}
      <br />
      <ContactUsWidget />
      <ExploreOnarollLink />
    </StyledHelpHeader>
  )
}
