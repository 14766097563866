// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AdminGoal, PaginatedAdminGoals } from 'types'

import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { ADMIN_GOALS, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { createGoalThunk, fetchAllGoalsThunk, fetchGoalThunk, updateGoalThunk } from '.'

interface AdminGoalsState extends RequestState {
  adminGoalsResponse: PaginatedAdminGoals
}

const initialGoalState: AdminGoalsState = {
  adminGoalsResponse: {} as PaginatedAdminGoals,
  ...requestState
}

export const AdminGoalsAdapter = createEntityAdapter<AdminGoal>({
  selectId: (goal) => goal.id
})

export const adminGoalsSlice = createSlice({
  name: ADMIN_GOALS,
  initialState: AdminGoalsAdapter.getInitialState(initialGoalState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllGoalsThunk.fulfilled, (state, action) => {
      const { next, previous, results = [] } = action.payload
      state.adminGoalsResponse = { next, previous }
      const isFresh = Boolean(action.meta.arg?.isInitial)
      if (isFresh) {
        AdminGoalsAdapter.setAll(state, results)
      } else {
        AdminGoalsAdapter.setMany(state, results)
      }
      handleRequestFulfilled(state)
    })
    builder.addCase(fetchGoalThunk.fulfilled, (state, action) => {
      const goal = action.payload
      AdminGoalsAdapter.setOne(state, goal)
      handleRequestFulfilled(state)
    })
    builder.addCase(updateGoalThunk.fulfilled, (state, action) => {
      const goal = action.payload
      AdminGoalsAdapter.setOne(state, goal)
      handleRequestFulfilled(state)
    })
    builder.addCase(createGoalThunk.fulfilled, (state, action) => {
      const goal = action.payload
      AdminGoalsAdapter.addOne(state, goal)
      handleRequestFulfilled(state)
    })
    builder.addMatcher(
      isAnyOf(
        fetchAllGoalsThunk.pending,
        fetchGoalThunk.pending,
        updateGoalThunk.pending,
        createGoalThunk.pending
      ),
      (state) => {
        state.isLoading = true
      }
    )
    builder.addMatcher(
      isAnyOf(
        fetchAllGoalsThunk.rejected,
        fetchGoalThunk.rejected,
        updateGoalThunk.rejected,
        createGoalThunk.rejected
      ),
      (state, action) => {
        handleRequestRejected(state, action)
      }
    )
  }
})

export default adminGoalsSlice.reducer
