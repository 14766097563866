import styled from 'styled-components'

interface GoalIconWrapperProps {
  strokeOrFill: 'stroke' | 'fill'
  color?: string
  size?: string
}

const GoalIconWrapper = styled.div<GoalIconWrapperProps>`
  display: flex;
  svg {
    width: ${({ size }) => size || '48'}px;
    height: ${({ size }) => size || '48'}px;
    rect {
      fill: ${({ color }) => color};
    }
    path {
      fill: ${({ color, strokeOrFill }) => strokeOrFill === 'fill' && color};
      stroke: ${({ color, strokeOrFill }) => strokeOrFill === 'stroke' && color};
    }
    circle {
      stroke: ${({ color }) => color};
    }
    .fillPath {
      fill: ${({ color }) => color};
      stroke: none;
    }
  }
`

export default GoalIconWrapper
