import clsx from 'clsx'

import { useTranslation } from 'react-i18next'

import { ReactComponent as ArrowIcon } from 'assets/icons/AppIcons/ArrowIcon.svg'

import { useNavigation } from 'hooks/useNavigation'

import { onA11yKeyDown } from 'utils/helpers'

import { StyledBackButton, StyledBackButtonIcon, StyledBackButtonText } from './styles'

export interface BackButtonProps {
  buttonContentClassName?: string
  className?: string
  onlyIcon?: boolean
  onBack?: () => void
  text?: string
  color?: string
  backgroundColor?: string
}

function BackButton({
  buttonContentClassName,
  className,
  onlyIcon,
  onBack,
  text,
  color,
  backgroundColor
}: BackButtonProps) {
  const navigation = useNavigation()
  const { t } = useTranslation()

  const goBack = () => {
    if (onBack) {
      onBack()
    } else {
      navigation.goBack()
    }
  }

  const defaultGoBackText = t('backButton.text')
  const goBackAriaLabel = t('backButton.ariaLabel')

  if (onlyIcon) {
    return (
      <StyledBackButtonIcon
        className={clsx(className)}
        onClick={goBack}
        onKeyDown={(event) => onA11yKeyDown(event, goBack)}
        aria-label={goBackAriaLabel}
        id="BackButton"
        size="large"
      >
        <ArrowIcon fill={color} />
      </StyledBackButtonIcon>
    )
  }

  return (
    <StyledBackButton
      variant="text"
      className={clsx(className)}
      onClick={goBack}
      onKeyDown={(event) => onA11yKeyDown(event, goBack)}
      id="BackButton"
      style={backgroundColor ? { backgroundColor } : {}}
    >
      <ArrowIcon fill={color} />
      <StyledBackButtonText
        className={clsx(buttonContentClassName)}
        variant="body1"
        aria-label={goBackAriaLabel}
        color={color}
        data-testid="BackButtonText"
      >
        {text || defaultGoBackText}
      </StyledBackButtonText>
    </StyledBackButton>
  )
}

export default BackButton
