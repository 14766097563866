// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { StyleProps } from 'theme'

import { Paper, PaperTypeMap, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import blackStarsBackground from '../../assets/images/bg-stars-black.svg'
import yellowStarsBackground from '../../assets/images/bg-stars-yellow.svg'

export const StyledSubheaderLayoutHeader = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'themeType' && prop !== 'isStoreSwitcherOpen' && prop !== 'showBackButton'
})<StyleProps & { isStoreSwitcherOpen: boolean; showBackButton: boolean }>(
  ({ theme, themeType, isStoreSwitcherOpen, showBackButton }) => {
    // Store switcher component and back button both push the layout down
    // This, in turn, causes the background image to be pushed down
    // This offset should take these two into consideration and center the image accordingly
    const backgroundOffset = (() => {
      const originalPosition = 78 // Perfect number to avoid image being cut off by header
      const storeSwitcherOffset = 54
      const backButtonOffset = 24
      if (isStoreSwitcherOpen && showBackButton) return `${originalPosition * 2}px`
      if (isStoreSwitcherOpen) {
        return `${originalPosition + storeSwitcherOffset}px`
      } else if (showBackButton) {
        return `${originalPosition + backButtonOffset}px`
      } else {
        return `${originalPosition}px`
      }
    })()

    // TODO: Figure out how to refactor similar styles from components/layout/styles
    return {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto',
      backgroundImage: `url(${
        themeType === 'dark' ? yellowStarsBackground : blackStarsBackground
      })`,
      backgroundPositionY: backgroundOffset,
      '&.LayoutPageWithSubheader__header--hasScrolled': {
        '& .MuiAppBar-colorPrimary, header': {
          backgroundColor: theme.customTheme[themeType].primaryColor,
          color: theme.palette.getContrastTextColor(
            theme.customTheme[themeType].primaryColor
          ),
          '& #BackButton': {
            color: theme.palette.getContrastTextColor(
              theme.customTheme[themeType].primaryColor
            ),
            '.MuiTypography-root': {
              color: 'inherit' // inherit above button color
            },
            '& svg > path': {
              fill: theme.palette.getContrastTextColor(
                theme.customTheme[themeType].primaryColor
              )
            }
          }
        }
      }
    }
  }
)

export const StyledSubheaderLayoutSubheader = styled<
  OverridableComponent<
    PaperTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'section'>
  >
>(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  padding: theme.spacing(4, 0),
  backgroundColor: 'inherit',
  color: theme.branding.lightTextColor
}))
