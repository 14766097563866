import { useSelector } from 'react-redux'

import { BrandLogo } from 'components/brandLogo'

import { getSelectedStoreBrand } from 'slices/storeSwitcher'

const HeaderBrandLogo = () => {
  const brand = useSelector(getSelectedStoreBrand)

  return <BrandLogo brand={brand} />
}

export default HeaderBrandLogo
