import { useTranslation } from 'react-i18next'

import { AppIconTypes } from 'types'

import AppIcon from 'components/icons/AppIcon'

import { useNavigation } from 'hooks/useNavigation'

import { getLoginWithTypePath } from 'utils/helpers'

import { StyledEmailButton, StyledEmailButtonTitle } from './EmailButton.styles'

export default function EmailButton() {
  const { t } = useTranslation()
  const navigation = useNavigation()

  const goToEmailForm = () => navigation.push(getLoginWithTypePath('email'))

  return (
    <StyledEmailButton
      variant="outlined"
      fullWidth
      startIcon={<AppIcon type={AppIconTypes.email} />}
      onClick={goToEmailForm}
    >
      <StyledEmailButtonTitle variant="body1">
        {t('pages.login.form.emailInputTitle')}
      </StyledEmailButtonTitle>
    </StyledEmailButton>
  )
}
