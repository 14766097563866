import { Typography, useMediaQuery } from '@mui/material'

import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import PageWithSubheader from 'layouts/pageWithSubheader'

import Placeholder, { PerkItemLoader, RowItemLoader } from 'placeholder'

import useAppDispatch from 'hooks/useAppDispatch'

import Search from 'components/search'

import useFilteredPerks from 'hooks/useFilteredPerks'
import { useNavigation } from 'hooks/useNavigation'

import withStoreDataRefresh from 'hoc/withStoreDataRefresh'

import { fetchPerksThunk, perksSelectors, selectPerksLoading } from 'slices/perks'
import { fetchPointTotalsThunk, getPointsBalance } from 'slices/points'

import { RootPaths } from 'utils/helpers'

import {
  StyledPerksGrid,
  StyledPerksSubheaderTitle,
  StyledPerksWalletCTA
} from './styles'

import PerkCategories from './perkCategories'
import PerkItem, { SuggestPerkItem } from './perkItem'

const Perks = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPerksThunk({ getCachedResults: true }))
    dispatch(fetchPointTotalsThunk({ getCachedResults: true }))
  }, [])

  const { t } = useTranslation()
  const isOnSmallDevice = useMediaQuery('only screen and (max-device-width: 374px)')
  const perksLoading = useSelector(selectPerksLoading)
  const [searchValue, setSearchValue] = useState('')
  const pointsBalance = useSelector(getPointsBalance)
  const filteredPerks = useFilteredPerks()
  const navigation = useNavigation()
  const allPerks = useSelector(perksSelectors.selectAll) || []

  const filteredAllPerksWithSearch = useMemo(
    () =>
      allPerks.filter((perk) =>
        perk.title.toLocaleLowerCase().includes(searchValue.toLowerCase())
      ),
    [allPerks, searchValue]
  )

  const filteredOrSearchedPerks = searchValue ? filteredAllPerksWithSearch : filteredPerks

  const onSearchChange = (val: string) => {
    setSearchValue(val)
  }

  const onSearchClear = () => {
    setSearchValue('')
  }

  const subheaderContent = (
    <>
      <StyledPerksSubheaderTitle>{pointsBalance}</StyledPerksSubheaderTitle>
      <Typography variant="body1" component="small">
        {t('pages.perks.header.title')}
      </Typography>
      <StyledPerksWalletCTA
        onClick={() => navigation.push(RootPaths.redeemedPerks)}
        variant="contained"
        color="secondary"
      >
        {t('pages.perks.header.cta')}
      </StyledPerksWalletCTA>
    </>
  )

  return (
    <PageWithSubheader subheaderContent={subheaderContent}>
      <Search onChange={onSearchChange} onClear={onSearchClear} value={searchValue} />
      <PerkCategories />
      <StyledPerksGrid isOnSmallDevice={isOnSmallDevice}>
        <Placeholder
          isLoading={perksLoading}
          loader={
            isOnSmallDevice ? RowItemLoader : (PerkItemLoader as () => ReactElement)
          }
          amount={4}
        >
          {filteredOrSearchedPerks?.map((rewardInfo) => {
            return (
              <PerkItem
                key={rewardInfo.id}
                isOnSmallDevice={isOnSmallDevice}
                perk={rewardInfo}
                pointsBalance={pointsBalance}
              />
            )
          })}
          <SuggestPerkItem isOnSmallDevice={isOnSmallDevice} />
        </Placeholder>
      </StyledPerksGrid>
    </PageWithSubheader>
  )
}

export default withStoreDataRefresh(Perks)
