import { styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import { Colors } from '../../theme/colors/Colors'

export const GoalsHeaderContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4.8), // '24px'
  display: 'flex',
  justifyContent: 'space-between'
}))

export const GoalsHeaderTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'capitalize',
  fontSize: theme.fontSize(3),
  lineHeight: theme.fontSize(4)
}))

export const GoalsHeaderSubcopy = styled('div')(({ theme }) => ({
  color: Colors.gray600,
  fontSize: theme.fontSize(1.5),
  lineHeight: theme.fontSize(2)
}))

export const GoalsHeaderPointsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.8, 3.2), // '4px 16px'
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.borderRadius.small,
  textAlign: 'center'
}))

export const GoalsHeaderPointsSum = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(0.8), // '4px'
  fontWeight: 600,
  fontSize: theme.fontSize(2.5),
  lineHeight: theme.fontSize(3)
}))

export const GoalsHeaderPointsText = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(0.8), // '4px'
  color: Colors.gray600,
  fontSize: theme.fontSize(1.25)
}))
