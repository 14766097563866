import { alpha, Grid, styled } from '@mui/material'

// TODO: Get contrasted color to themeType (checkout layout/styles/ts) instead of secondaryColor
// TODO: This component pushes down the content in the subheader page. Need to fix the positioning of the background for it
export const StyledStoreSwitcherGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
  margin: `0 0 ${theme.spacing(2)}`,
  backgroundColor: alpha(theme.branding.secondaryColor, 0.9),
  color: theme.palette.getContrastText(alpha(theme.branding.secondaryColor, 0.9)),
  '& svg path': {
    stroke: theme.palette.getContrastText(alpha(theme.branding.secondaryColor, 0.9))
  }
}))

export const StyledContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden'
})
