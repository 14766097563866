import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { fetchAuthToken, refreshToken } from 'api'

import { AuthTokensDTO, LoginToken } from 'types'

import { AUTH } from 'slices/constants'

import { RootState } from '../../reduxStore'

import { setupApp } from '../../utils/firebase'

export const logoutAction = () => ({
  type: 'LOGOUT'
})

export const fetchAuthTokenThunk = createAsyncThunk(
  `${AUTH}/fetchAuthToken`,
  async (loginToken: LoginToken) => {
    const tokens: AuthTokensDTO = await fetchAuthToken(loginToken)
    return tokens
  }
)

export const refreshTokenThunk = createAsyncThunk(
  `${AUTH}/refreshAuthToken`,
  async (_, { getState }) => {
    const state = getState() as RootState
    const token = state.auth.refreshToken
    const tokens: AuthTokensDTO = await refreshToken(token as string)
    return tokens
  }
)

interface FirebaseTokens {
  idToken: string
  refreshToken: string
}

const firebaseTokens = (action: PayloadAction<FirebaseTokens>) => {
  return action.payload
}

export const firebaseTokensThunk = createAsyncThunk(
  'firebaseTokensThunk',
  ({ idToken, refreshToken }: { idToken: string; refreshToken: string }) => {
    return firebaseTokens({
      payload: {
        idToken,
        refreshToken
      },
      type: 'firebaseTokensThunk'
    })
  }
)

export const refreshFirebaseTokensThunk = createAsyncThunk(
  'refreshFirebaseTokensThunk',
  async (_, { getState }) => {
    const { auth } = setupApp()
    // first check the impersonation use case where refreshToken is ''
    const state = getState() as RootState
    if (state.auth.refreshToken.length < 1) {
      return {
        refresh: '',
        access: state.auth.accessToken,
      }
    }

    const res = await auth?.currentUser?.getIdToken(true)
    return {
      refresh: auth?.currentUser?.refreshToken || '',
      access: res || ''
    }
  }
)
