import { createTheme, getContrastRatio } from '@mui/material'

import { Brand } from 'types'

import { MuiAppBarConfig } from './mui-config/MuiAppBarConfig'

import { BorderRadius } from './borders/BorderRadius'
import { AcceptedColorTypes, BrandThemeOptions } from './colors/Brand'
import { Colors } from './colors/Colors'
import { OnarollColorPalette } from './colors/OnarollColorPalette'
import { DefaultRing } from './colors/RingColors'
import { getBrandThemeOptions } from './colors/utils'
import { MuiBottomNavActionConfig } from './mui-config/MuiBottomNavActionConfig'
import { MuiBottomNavConfig } from './mui-config/MuiBottomNavConfig'
import { MuiButtonConfig } from './mui-config/MuiButtonConfig'
import { MuiCardConfig } from './mui-config/MuiCardConfig'
import { MuiFormControlConfig } from './mui-config/MuiFormControlConfig'
import { MuiPaperConfig } from './mui-config/MuiPaperConfig'
import { MuiSelectConfig } from './mui-config/MuiSelectConfig'
import { MuiTabConfig } from './mui-config/MuiTabConfig'
import { MuiTextFieldConfig } from './mui-config/MuiTextFieldConfig'
import { getSpacing } from './spacing/utils'
import {
  CustomTypographySizes,
  DefaultTypography,
  getFontSize
} from './typography/Typography'

export const getTheme = (brandPalette?: BrandThemeOptions) => {
  const {
    primaryColor,
    secondaryColor,
    accentColor,
    backgroundColor,
    darkTextColor,
    lightTextColor,
    subTextColor,
    inactiveColor,
    cardColor
  } = getBrandThemeOptions(brandPalette)

  const getContrastTextColor = (bgColor: AcceptedColorTypes) => {
    // this threshold number comes from WCAG: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
    const guidelineThresholdWCAG = 7
    return getContrastRatio(bgColor, darkTextColor) >= guidelineThresholdWCAG
      ? darkTextColor
      : lightTextColor
  }

  return createTheme({
    spacing: getSpacing,
    fontSize: getFontSize,
    branding: {
      primaryColor,
      secondaryColor,
      accentColor,
      backgroundColor,
      darkTextColor,
      lightTextColor,
      subTextColor,
      inactiveColor,
      cardColor
    },
    customTheme: {
      light: {
        primaryColor,
        secondaryColor,
        backgroundColor
      },
      dark: {
        primaryColor: brandPalette?.accentColor || OnarollColorPalette.accentColor,
        secondaryColor,
        backgroundColor
      },
      neutral: {
        primaryColor:
          brandPalette?.backgroundColor || OnarollColorPalette.backgroundColor,
        secondaryColor: brandPalette?.primaryColor || OnarollColorPalette.primaryColor,
        backgroundColor
      },
      white: {
        primaryColor: brandPalette?.primaryColor || Colors.white,
        secondaryColor: brandPalette?.secondaryColor || OnarollColorPalette.accentColor,
        backgroundColor
      },
      orange: {
        primaryColor: brandPalette?.primaryColor || Colors.orange,
        secondaryColor:
          brandPalette?.secondaryColor || OnarollColorPalette.secondaryColor,
        backgroundColor
      }
    },
    palette: {
      mode: 'light', // TODO: make dark mode https://mui.com/material-ui/customization/dark-mode/
      ring: DefaultRing,
      colors: Colors,
      getContrastTextColor,
      common: {
        black: darkTextColor,
        white: lightTextColor
      },
      primary: {
        main: primaryColor,
        dark: darkTextColor,
        light: lightTextColor
      },
      secondary: {
        main: secondaryColor,
        dark: darkTextColor,
        light: lightTextColor
      },
      text: {
        primary: darkTextColor,
        secondary: subTextColor,
        disabled: Colors.disabled
      },
      background: {
        default: backgroundColor,
        paper: backgroundColor
      }
    },
    borderRadius: BorderRadius,
    layout: {
      padding: getSpacing(4),
      bottomSpacing: getSpacing(11.2 + 8 + 4) // 56px is the footer tab bar + 40px of padding + theme spacing to seperate
    },
    customTypography: CustomTypographySizes,
    typography: DefaultTypography,
    components: {
      MuiAppBar: MuiAppBarConfig,
      MuiPaper: MuiPaperConfig(primaryColor),
      MuiTab: MuiTabConfig(
        inactiveColor,
        darkTextColor,
        secondaryColor,
        getContrastTextColor
      ),
      MuiBottomNavigation: MuiBottomNavConfig,
      MuiBottomNavigationAction: MuiBottomNavActionConfig(accentColor),
      MuiCard: MuiCardConfig(cardColor),
      MuiTextField: MuiTextFieldConfig,
      MuiFormControl: MuiFormControlConfig,
      MuiSelect: MuiSelectConfig,
      MuiButton: MuiButtonConfig(accentColor, primaryColor, secondaryColor)
    }
  })
}

export const getThemeFromBrand = (brand?: Brand) => {
  if (!brand) {
    return getTheme()
  }
  const {
    primary_color: primaryColor,
    secondary_color: secondaryColor,
    accent_color: accentColor,
    background_color: backgroundColor,
    dark_text_color: darkTextColor,
    light_text_color: lightTextColor,
    sub_text_color: subTextColor,
    inactive_color: inactiveColor,
    card_color: cardColor,
    use_custom_theme
  } = brand

  if (!use_custom_theme) {
    return getTheme()
  }

  return getTheme({
    primaryColor,
    secondaryColor,
    accentColor,
    backgroundColor,
    darkTextColor,
    lightTextColor,
    subTextColor,
    inactiveColor,
    cardColor
  })
}
