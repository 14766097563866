// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Store } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { STORES, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchStoresThunk } from '.'

export const storesAdapter = createEntityAdapter<Store>({
  selectId: (store) => store.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const storesSlice = createSlice({
  name: STORES,
  initialState: storesAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoresThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchStoresThunk.rejected, (state, action) => {
      handleRequestRejected(state, action)
    })
    builder.addCase(fetchStoresThunk.fulfilled, (state, action) => {
      storesAdapter.setAll(state, action.payload)
      handleRequestFulfilled(state)
    })
  }
})

export default storesSlice.reducer
