import { Alert, Button, styled } from '@mui/material'

export const StyledImpersonationError = styled(Alert)({
  height: '3em',
  marginBottom: '5px',
  '& .MuiAlert-icon': {
    flex: 1,
    justifyContent: 'flex-end'
  },
  '& .MuiAlert-message': {
    textAlign: 'center'
  },
  '& .MuiAlert-action': {
    flex: 1
  }
})

export const StyledImpersonationBanner = styled('div')(({ theme }) => ({
  color: 'rgb(102, 60, 0)',
  width: '100%',
  borderRadius: theme.borderRadius.small,
  backgroundColor: 'rgb(255, 244, 229)',
  textAlign: 'center',
  verticalAlign: 'middle',
  display: 'inline-block',
  alignSelf: 'center',
  '& svg': {
    height: '1em',
    width: '1em',
    marginRight: '1rem'
  }
}))

export const StyledImpersonationBannerButton = styled(Button)({
  margin: '10px 0 10px'
})
