import { GoalPointGroup, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { ADMIN_GOAL_POINT_GROUPS } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

import { getAllGoalPointGroups, getAllGoalPointGroupsIsLoading } from './selectors'

import { RootState } from 'reduxStore'

export const fetchAllGoalPointGroupsThunk = authenticatedThunkCreator<
  GoalPointGroup[],
  FetchCachedThunkProps
>(
  `${ADMIN_GOAL_POINT_GROUPS}/fetchAllGoalPointGroupsThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const prevGoalPointGroups = getAllGoalPointGroups(state)

    if (prevGoalPointGroups && authedDTO?.getCachedResults) return prevGoalPointGroups

    const allGoalPointGroups: GoalPointGroup[] = await fetchRecords(
      authedDTO,
      'allGoalPointGroups'
    )
    return allGoalPointGroups
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState
      const isLoading = getAllGoalPointGroupsIsLoading(state)
      if (isLoading) {
        // Cancel execution if a request is already in progress
        return false
      }
    }
  }
)
