import Carousel from "components/carousel";
import useAppDispatch from "hooks/useAppDispatch";
import Placeholder, {PrizeItemLoader} from "placeholder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  fetchJackpotWinnersThunk,
  getJackpotWinners,
  getWinnersLoading
} from 'slices/jackpot'
import JackpotSection from "./jackpotSection";
import { StyledCurrentDrawing, StyledCurrentDrawingTitle, StyledCurrentDrawingIndex } from "./styles";
import WinnerItem, { EmptyWinnerItem } from "./winnerItem";

export default function JackpotWinners() {
  const dispatch = useAppDispatch()
  const [pageIndex, setPageIndex] = useState(0)
  const winnersLoading = useSelector(getWinnersLoading)
  const winners = useSelector(getJackpotWinners)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchJackpotWinnersThunk({ getCachedResults: true }))
  }, [])

  const onSwipe = (index: number) => {
    setPageIndex(index)
  }

  const renderWinners = !winners?.length 
  ? <EmptyWinnerItem
    description={t("pages.jackpot.emptyWinner.description")}
  /> 
  : <Carousel onSwipe={onSwipe} showNavButtons={winners.length > 1} itemMaxWidth="">
    {winners.map((winner) => (
      <WinnerItem key={winner.id} winner={winner} />
    ))}
  </Carousel>

  return (
    <JackpotSection>
      <StyledCurrentDrawing>
        <StyledCurrentDrawingTitle moreSpace title={t("pages.jackpot.lastJackpotWinners")} />
        {winners.length > 0 && (
          <StyledCurrentDrawingIndex
            lessProminent
            title={`${pageIndex + 1} of ${winners.length}`}
          />
        )}
      </StyledCurrentDrawing>
      <Placeholder isLoading={winnersLoading} loader={PrizeItemLoader}>
        {renderWinners}
      </Placeholder>
    </JackpotSection>
  )
}
