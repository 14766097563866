import { GoalFrequency, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { ADMIN_GOAL_FREQUENCIES } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

import { getAllGoalFrequencies, selectGoalFrequenciesUpdating } from './selectors'

import { RootState } from 'reduxStore'

export const fetchAllGoalFrequenciesThunk = authenticatedThunkCreator<
  GoalFrequency[],
  FetchCachedThunkProps
>(
  `${ADMIN_GOAL_FREQUENCIES}/fetchAllGoalFrequenciesThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const prevGoalFrequencies = getAllGoalFrequencies(state)

    if (prevGoalFrequencies && authedDTO?.getCachedResults) return prevGoalFrequencies

    const allGoalFrequencies: GoalFrequency[] = await fetchRecords(
      authedDTO,
      'allGoalFrequencies'
    )
    return allGoalFrequencies
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState
      const isLoading = selectGoalFrequenciesUpdating(state)
      if (isLoading) {
        // Cancel execution if a request is already in progress
        return false
      }
    }
  }
)
