import clsx from 'clsx'

import { Key, ReactNode, useLayoutEffect, useRef } from 'react'

import { ThemeType } from 'theme'

import { StyledLayoutContent } from './styles'

export interface LayoutContentProps {
  children: ReactNode
  themeType: ThemeType
  className?: string
  showHeaderEffect?: boolean
  extraStyles?: Record<Key, string>
}

export interface LayoutContentStyleProps {
  showHeaderEffect: boolean
  themeType: ThemeType
}

export function LayoutContent({
  children,
  className,
  showHeaderEffect,
  themeType,
  extraStyles
}: LayoutContentProps) {
  const ref = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const element = ref?.current
    if (element) {
      const { top } = element.getBoundingClientRect()
      if (top > 0) {
        // This sets the height of the content container to fill the screen
        // A.k.a 'pushing the content down' to avoid just adjusting to the size of the content
        // Note: Maybe restructure to find a CSS alternative ?
        const { innerHeight: height } = window
        element.style.minHeight = `${Math.round(height - top - 6)}px`
      }
    }
  }, [])

  return (
    <StyledLayoutContent
      ref={ref}
      square
      component="section"
      elevation={0}
      showHeaderEffect={Boolean(showHeaderEffect)}
      themeType={themeType}
      className={clsx('LayoutContent', className)}
      style={extraStyles}
    >
      {children}
    </StyledLayoutContent>
  )
}

export default LayoutContent
