import clsx from 'clsx'
import { ThemeType } from 'theme'

import { ReactNode } from 'react'

import Footer from 'components/footer'
import Header from 'components/header'
import Layout from 'components/layout'
import LayoutContent from 'components/layoutContent'
import ScrollToBottomButtonOnSmallDevice from 'components/scrollToBottomButtonOnSmallDevice'
import ScrollToTop from 'components/scrollToTop'

export interface PageProps {
  children: ReactNode
  className?: string
  altHeader?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  themeType?: ThemeType
  wrapperClass?: string
  title?: string
}

export default function Page({
  children,
  className,
  altHeader,
  hideHeader,
  hideFooter,
  themeType = 'neutral',
  wrapperClass,
  title
}: PageProps) {
  return (
    <>
      <ScrollToBottomButtonOnSmallDevice />
      <Layout className={clsx('LayoutPage', className)} themeType={themeType}>
        <ScrollToTop />
        {!hideHeader && <Header title={title} alt={altHeader} />}
        <LayoutContent
          className={clsx('LayoutPage__wrapper', wrapperClass)}
          themeType={themeType}
        >
          {children}
        </LayoutContent>
        {!hideFooter && <Footer />}
      </Layout>
    </>
  )
}
