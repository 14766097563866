import { Button, styled, Typography } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledOnboardingLogoImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isOnTinyDevice'
})<{ isOnTinyDevice?: boolean }>(({ isOnTinyDevice }) => ({ theme }) => ({
  backgroundColor: theme.palette.colors.black,
  padding: theme.spacing(3, 1, 3, 0),
  borderRadius: theme.spacing(0, 10, 10, 0),
  ...conditionalObject(
    { height: 120, width: 220, padding: theme.spacing(1, 0, 1, 0) },
    isOnTinyDevice
  )
}))

export const StyledOnboardingSkipButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.colors.black,
  padding: 0
}))

export const StyledOnboardingSkipText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.colors.white,
  fontWeight: 'bold'
}))

export const StyledOnboardingHeaderButtonsWrapper = styled('div')({
  alignItems: 'center',
  display: 'flex'
})

export const onboardingLogoImagePositionStyles = {
  position: 'absolute',
  top: 40,
  left: 0
} as React.CSSProperties

export const onboardingConfettiPositionStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0
} as React.CSSProperties
