import { validate } from 'uuid'

import { UrlParams } from 'constants/UrlParams'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { fetchStoreGroupsThunk } from 'slices/admin'
import { getIsAuthenticated } from 'slices/auth'
import { fetchUserStoreBrandsThunk } from 'slices/brands'
import { getImpersonator, getImpersonatorLoading, impersonateWorkerThunk, updateToken } from 'slices/impersonation'
import { fetchStoresThunk, selectStoresHasLoaded } from 'slices/stores'
import { fetchWhoamiThunk, getWhoami } from 'slices/whoami'

import { setDDUserSessionId } from 'utils/session'

import { useAppDispatch, useQueryParams } from './index'
import useSetUpUser from './useSetUpUser'

export const useSetUpProtectedRoute = () => {
  const ldClient = useLDClient()
  const dispatch = useAppDispatch()
  const query = useQueryParams()
  const impersonator = useSelector(getImpersonator)
  const isImpersonator = !!impersonator
  const isAuthenticated = useSelector(getIsAuthenticated)
  const whoami = useSelector(getWhoami)
  const hasStoresLoaded = useSelector(selectStoresHasLoaded)
  const isImpersonatorLoading = useSelector(getImpersonatorLoading)

  const [isLdContextSet, setIsLdContextSet] = useState(false)
  const { getLDUserContext } = useSetUpUser(isAuthenticated)
  const hasImpersonationParams = useMemo(() => {
    return (
      query.get(UrlParams.impersonationEmploymentId) &&
      query.get(UrlParams.encryptedUserId)
    )
  }, [query])

  const impersonateUserProfileId = useMemo(() => {
    return (
      query.get(UrlParams.userProfileId)
    )
  }, [query])

  const fetchStartupWithImpersonation = async () => {
    if (isAuthenticated || hasImpersonationParams) {

      const shouldUpdateFirebaseToken = (
        impersonateUserProfileId
        && validate(impersonateUserProfileId)
        && !isImpersonatorLoading
        && (
          !impersonator
          || impersonator.impersonatee?.user_profile_id !== impersonateUserProfileId
        )
      )

      if (hasImpersonationParams && !isImpersonator) {
        const impersonation_employment = query.get(UrlParams.impersonationEmploymentId)
        const encrypted_user_id = query.get(UrlParams.encryptedUserId)
        if (impersonation_employment && encrypted_user_id) {
          await dispatch(
            impersonateWorkerThunk({
              employment_id: impersonation_employment,
              user_id: encrypted_user_id
            })
          )
        }
      } else if (shouldUpdateFirebaseToken) {
        await dispatch(updateToken({
          user_profile_id: impersonateUserProfileId,
        }))
      }

      await dispatch(fetchWhoamiThunk({}))
      await dispatch(fetchUserStoreBrandsThunk({ getCachedResults: false }))
      await dispatch(fetchStoreGroupsThunk({ getCachedResults: false }))
      await dispatch(fetchStoresThunk({ getCachedResults: true }))
    }
    setDDUserSessionId()
  }

  const syncUserWithLaunchDarkly = async () => {
    const ldContext = ldClient?.getContext()
    if (ldContext && ldContext?.key === 'anonymous-user') {
      await ldClient.identify(getLDUserContext(), null)
      setIsLdContextSet(true)
    }
  }

  useEffect(() => {
    const runEffect = async () => {
      if (isLdContextSet) return

      if (isAuthenticated && whoami && hasStoresLoaded && !isLdContextSet) {
        await syncUserWithLaunchDarkly()
      }
      await fetchStartupWithImpersonation()
    }
    runEffect()
  }, [isAuthenticated, whoami, hasStoresLoaded, isLdContextSet])

  return {
    fetchStartupWithImpersonation
  }
}
