import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Goal } from 'types'

import { useGetGoalsOnDate } from 'hooks'
import { useNavigation } from 'hooks/useNavigation'

import { getCombinedGoalsOnDate as getCombinedGoalsOnDateSelector } from 'slices/goals'
import { getDefaultSelectedStore } from 'slices/storeSwitcher'

import { getTodaysDate, getYesterdaysDate, isToday } from 'utils/form'
import { ChildPaths, getGoalsOnDatePath } from 'utils/helpers'

import { RootState } from '../../reduxStore'

import { TODAY, YESTERDAY } from './constants'
import GoalsContainer from './goalsContainer'

const GoalsOnDate = () => {
  const navigation = useNavigation()

  const { getGoalsOnDate, shouldFetchGoalServiceGoals } = useGetGoalsOnDate()

  const today = getTodaysDate()
  const yesterday = getYesterdaysDate()

  const selectedStore = useSelector(getDefaultSelectedStore)

  const [currentDate, setCurrentDate] = useState(today)

  useEffect(() => {
    const pathSegments = location.pathname.split('/')
    const dateString = pathSegments[pathSegments.length - 1]

    if (dateString === YESTERDAY) {
      setCurrentDate(getYesterdaysDate())
      return
    }
    if (dateString === TODAY) {
      setCurrentDate(getTodaysDate())
      return
    }
    setCurrentDate(dateString)

  }, [])

  const goals: Goal[] =
    useSelector((state: RootState) =>
      getCombinedGoalsOnDateSelector(state, currentDate)
    ) ?? []

  const isTodaysGoals = isToday(currentDate)

  useEffect(() => {
    if (currentDate === today) {
      navigation.push(ChildPaths.goalsToday)
    }
    else if (currentDate === yesterday) {
      navigation.push(ChildPaths.goalsYesterday)
    }
    else {
      const goalsOnDatePath = getGoalsOnDatePath(currentDate)
      navigation.push(goalsOnDatePath)
    }

    getGoalsOnDate({ date: currentDate, getCachedResults: true })
  }, [currentDate, selectedStore, shouldFetchGoalServiceGoals])

  return (
    <GoalsContainer
      isTodaysGoals={isTodaysGoals}
      date={currentDate}
      goals={goals}
      setCurrentDate={setCurrentDate}
    />
  )
}

export default React.memo(GoalsOnDate)
