// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AdminListFilters, PaginatedTeamMembersV2, TeamMemberV2 } from 'types'

import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
  ADMIN_TEAM_MEMBERS,
  paginatedTeamResponse,
  RequestState,
  requestState
} from 'slices/constants'

import {
  handleRequestFulfilled,
  handleRequestRejected,
  handleUpdateManyState
} from 'slices/utils'

import { fetchAllTeamMembersThunk, fetchTeamMemberThunk, updateTeamMemberThunk } from '.'
import { updateEmploymentThunk } from '../employments'

export const defaultTeamMembersQuery = {
  active: 'true',
  group_id: '',
  search: '',
  store_id: ''
}

interface AdminTeamMembersState extends RequestState {
  teamMembersResponse: PaginatedTeamMembersV2
  query?: AdminListFilters
}

const initialTeamMemberState: AdminTeamMembersState = {
  teamMembersResponse: paginatedTeamResponse,
  query: defaultTeamMembersQuery,
  ...requestState
}

export const TeamMembersAdapter = createEntityAdapter<TeamMemberV2>({
  selectId: (teamMember) => teamMember.id
})

export const adminTeamMembersSlice = createSlice({
  name: ADMIN_TEAM_MEMBERS,
  initialState: TeamMembersAdapter.getInitialState(initialTeamMemberState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTeamMembersThunk.fulfilled, (state, action) => {
        const {
          next,
          previous,
          not_onboarded_count,
          onboarded_count,
          team_members_count
        } = action.payload
        state.teamMembersResponse = {
          next,
          previous,
          not_onboarded_count,
          onboarded_count,
          team_members_count
        }
        state.query = action.meta.arg?.queryParams
        handleUpdateManyState(TeamMembersAdapter, state, action)
      })
      .addCase(fetchTeamMemberThunk.fulfilled, (state, action) => {
        const { payload: teamMember } = action
        TeamMembersAdapter.upsertOne(state, teamMember)
        handleRequestFulfilled(state)
      })
      .addCase(updateEmploymentThunk.fulfilled, (state, action) => {
        const { user_profile_id, termination_date } = action.payload
        if (termination_date) TeamMembersAdapter.removeOne(state, user_profile_id)
        handleRequestFulfilled(state)
      })
      .addMatcher(
        isAnyOf(fetchAllTeamMembersThunk.pending, fetchTeamMemberThunk.pending),
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          fetchAllTeamMembersThunk.rejected,
          fetchTeamMemberThunk.rejected,
          updateTeamMemberThunk.rejected
        ),
        (state, action) => {
          handleRequestRejected(state, action)
        }
      )
  }
})

export default adminTeamMembersSlice.reducer
