import { styled } from '@mui/material'

export const CenteredItemContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})

export const CenteredItems = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto'
})
