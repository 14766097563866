import { AuthTokensDTO, UpsertThunkProps, AnswerResponse, DailyQuestion } from 'types'

import { fetchOnarollDaily, postRecord } from 'api'

import { POINTS } from 'slices/constants'

import { getDefaultSelectedStore } from 'slices/storeSwitcher'
import { authenticatedThunkCreator } from 'slices/utils'

import { conditionalObject } from 'utils/helpers'

import { RootState } from 'reduxStore'

export const fetchDailyQuestionThunk = authenticatedThunkCreator<DailyQuestion>(
  `${POINTS}/fetchDailyQuestionThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const selectedStoreId = getDefaultSelectedStore(state)?.id
    return await fetchOnarollDaily({
      ...(authedDTO as AuthTokensDTO),
      ...conditionalObject(
        {
          queryParams: { store_id: selectedStoreId }
        },
        selectedStoreId
      )
    })
  }
)

//todo: shouldn't really be a thunk, does not affect redux state
export const answerDailyQuestionThunk = authenticatedThunkCreator<
  AnswerResponse,
  UpsertThunkProps<{ question_id: string; answer: string }>
>(`${POINTS}/answerDailyQuestionThunk`, async ({ data, ...authedDTO }, { getState }) => {
  const state = getState() as RootState
  const selectedStoreId = getDefaultSelectedStore(state)?.id
  return await postRecord(
    {
      ...(authedDTO as AuthTokensDTO),
      data: {
        ...data,
        ...conditionalObject({ store_id: selectedStoreId }, selectedStoreId)
      }
    },
    'onarollDailyAnswer'
  )
})
