import { Component, ErrorInfo } from 'react'

import { ContactUsButton } from 'components/contactUs'

import { logError } from 'utils/logger'

import { ErrorBoundaryCard, ErrorBoundaryText } from './styled'

export interface ErrorBoundaryProps {
  children: JSX.Element
}

interface ErrorBoundaryState {
  hasError: boolean
}

export const ErrorBoundaryFallback = () => (
  <ErrorBoundaryCard data-testid="ErrorBoundary">
    <ErrorBoundaryText variant="body2" component="div">
      <h3>
        Yikes!{' '}
        <span role="img" aria-label="worried-face">
          😟
        </span>
      </h3>
      {
        "This is an unexpected error. The Onaroll team has been alerted, but feel free to reach out to us directly if you'd like!"
      }
    </ErrorBoundaryText>
    <ContactUsButton
      fullWidth
      variant="contained"
      color="primary"
      data-testid="ErrorBoundaryContactUs"
    >
      Get in touch
    </ContactUsButton>
  </ErrorBoundaryCard>
)

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorType = 'error boundary'
    logError(`Yikes! Looks like there was an error: ${error?.message}`, {
      error,
      errorInfo,
      type: errorType
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorBoundaryFallback />
    }
    return this.props.children
  }
}

export default ErrorBoundary
