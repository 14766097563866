import { Colors } from 'theme'

import { ButtonProps } from '@mui/material'

import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { StepContext } from 'pages/onboarding'

import { AnimatedContent } from 'components/AnimatedContent'
import { VerticalAnimationDirection } from 'components/AnimatedContent/utils'
import { NavIndexDots } from 'components/NavigationIndexDots'

import {
  StyledOnboardingStepsFooter,
  StyledOnboardingStepButton,
  StyledPageIndices,
  footerStyles,
  fixedFooterStyles,
  disabledButtonStyles
} from './styles'

import { FooterLinks } from '.'
import { MAX_INDEX, MAX_PERKS_SELECTED } from '../../steps/utils'
import { OnboardingLanguageButton } from '../LanguageButton'

export interface OnboardingFooterProps {
  isInitialPage: boolean
  isLastPage: boolean
  activePageIndex: number
  stepButtonOnClick: () => void
}

const OnboardingFooter = ({
  isInitialPage,
  isLastPage,
  stepButtonOnClick,
  activePageIndex
}: OnboardingFooterProps) => {
  const { t } = useTranslation()
  const { isOnSmallDevice, isOnTinyDevice, selectedPerkIds } = useContext(StepContext)

  const isDisabledOnPerksPage =
    activePageIndex === 1 &&
    (selectedPerkIds.length > MAX_PERKS_SELECTED || selectedPerkIds.length === 0)

  const isButtonDisabled = isDisabledOnPerksPage

  const stepButtonText = useMemo(() => {
    if (isInitialPage) return t('pages.onboarding.buttonText.getStarted')
    if (isLastPage) return t('pages.onboarding.buttonText.createProfile')
    return t('pages.onboarding.buttonText.next')
  }, [activePageIndex, t])

  const buttonSizeVariant: ButtonProps['size'] = useMemo(() => {
    if (isOnSmallDevice && !isOnTinyDevice) return 'medium'
    if (isOnTinyDevice) return 'small'
    return 'large'
  }, [isOnSmallDevice, isOnTinyDevice])

  return (
    <AnimatedContent
      verticalAnimationDirection={VerticalAnimationDirection.UP}
      motionDivProps={{
        transition: { delay: 0.5 },
        style: isInitialPage ? fixedFooterStyles : undefined
      }}
    >
      <StyledOnboardingStepsFooter
        style={isInitialPage ? footerStyles : undefined}
        data-testid="OnboardingFooter"
      >
        <FooterLinks isLastPage={isLastPage} />
        <StyledOnboardingStepButton
          onClick={stepButtonOnClick}
          fullWidth
          size={buttonSizeVariant}
          data-testid="OnboardingFooterButton"
          disabled={isButtonDisabled}
          isLastPage={isLastPage}
          style={isButtonDisabled ? disabledButtonStyles : undefined}
        >
          {stepButtonText}
        </StyledOnboardingStepButton>
        {isInitialPage ? (
          <div>
            <OnboardingLanguageButton buttonSizeVariant={buttonSizeVariant} />
          </div>
        ) : (
          <StyledPageIndices
            isOnSmallDevice={isOnSmallDevice}
            isOnTinyDevice={isOnTinyDevice}
            data-testid="OnboardingFooterPageIndices"
          >
            <NavIndexDots
              activeIndex={activePageIndex}
              numDots={MAX_INDEX}
              defaultColor={Colors.lightBlack}
              activeColor={Colors.blue}
            />
          </StyledPageIndices>
        )}
      </StyledOnboardingStepsFooter>
    </AnimatedContent>
  )
}

export default OnboardingFooter
