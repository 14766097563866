import { Button, styled } from '@mui/material'

import { SubmitButton } from '../shared/styles'

export const StyledCelebrateTeamForm = styled('form')(({ theme }) => ({
  '& .MuiFormControl-root': {
    margin: theme.spacing(2),
    minWidth: '170px'
  },
  '& .MuiInputLabel-root': {
    top: '-6px',
    color: theme.branding.subTextColor,
    ...theme.customTypography.body1Size
  },
  '& .MuiInputBase-root': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'bold'
  }
}))

export const StyledCelebrateTeamFormSubmit = styled(SubmitButton)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

export const StyledCelebrateTeamSubheaderCTA = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

export const StyledCelebrateFormHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: theme.spacing(2),
  '.MuiTypography-root': {
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.getContrastTextColor(theme.branding.backgroundColor)
  },
  '& svg': {
    marginRight: theme.spacing(2),
    '& > path': {
      stroke: theme.palette.getContrastTextColor(theme.branding.backgroundColor)
    }
  }
}))
