import { useTranslation } from 'react-i18next'

import { JackpotWinner } from 'types'

import {
  StyledJackpotWinnerItem,
  StyledJackpotWinnerItemDetails,
  StyledJackpotCongratulations,
  StyledJackpotEmptyWinnerItemDescription,
} from './styles'

import { US_STATES } from 'constants/us_states'

interface EmptyWinnerItemProps {
  description: string
}

export function EmptyWinnerItem({ description }: EmptyWinnerItemProps) {
  return (
    <StyledJackpotWinnerItem elevation={0}>
      <div>
        <StyledJackpotEmptyWinnerItemDescription variant="body2" component="p">
          {description}
        </StyledJackpotEmptyWinnerItemDescription>
      </div>
    </StyledJackpotWinnerItem>
  )
}

interface WinnerItemProps {
  winner: JackpotWinner
}

export default function WinnerItem({ winner }: WinnerItemProps) {
  const { t } = useTranslation()

  const { id, first_name, last_name_initial, brand, city, state, amount, use } = winner
  const full_state_name = US_STATES[state]

  const brandText = t('pages.jackpot.jackpotWinnerInfo.brand', { first_name, last_name_initial, brand })
  const stateAndCitylocationText = state && city ? t('pages.jackpot.jackpotWinnerInfo.stateAndCity', { city, state: full_state_name }) : ""
  const stateLocationText = !stateAndCitylocationText && state ? t('pages.jackpot.jackpotWinnerInfo.state', { state: full_state_name }) : ""
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(amount)
  const amountInfo = t('pages.jackpot.jackpotWinnerInfo.amount', { amount: formattedAmount })
  const useInfo = use ? t('pages.jackpot.jackpotWinnerInfo.use', { first_name, use }) : ""
  const winnerInfo = [brandText, stateAndCitylocationText, stateLocationText, amountInfo, useInfo].filter(Boolean).join(" ")

  return (
    <StyledJackpotWinnerItem
      elevation={0}
      role="button"
      tabIndex={0}
      key={id}
    >
      <div>
        <StyledJackpotWinnerItemDetails variant="h4" component="p">
            {winnerInfo}
        </StyledJackpotWinnerItemDetails>
        <StyledJackpotCongratulations variant="h4" component="p">
          {t('pages.jackpot.jackpotWinnerInfo.congratulations', { first_name })}
        </StyledJackpotCongratulations>
      </div>
    </StyledJackpotWinnerItem>
  )
}




