import { Colors } from 'theme'

import {
  alpha,
  Button,
  Input,
  InputLabel,
  styled,
  Typography,
  TypographyTypeMap
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import confettiBackgroundImage from '../../assets/images/confetti.svg'

import { BottomModal } from 'components/modals'
import RowItem from 'components/rowItem'
import { CenteredItemContainer, CenteredItems } from 'components/styled/flexCentered'

import { conditionalObject } from 'utils/helpers'

// Perk

export const StyledPerkModalItemLogo = styled('img')({
  width: '48px',
  height: '48px'
})

export const StyledPerkRedeemButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isRedeemingPerk'
})<{ isRedeemingPerk: boolean }>(({ isRedeemingPerk, theme }) => ({
  textTransform: 'none',
  marginTop: theme.spacing(9),
  ...contentItemWidth,
  ...(isRedeemingPerk && {
    '&.Mui-disabled': {
      backgroundColor: theme.branding.primaryColor,
      color: theme.branding.lightTextColor,
      opacity: 0.5,
      '&:hover': {
        backgroundColor: theme.branding.primaryColor
      }
    }
  })
}))

export const StyledPerkPointsLeft = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'small'>
  >
>(Typography)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  color: theme.branding.subTextColor,
  marginTop: theme.spacing(3)
}))

export const StyledPerkError = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'small'>
  >
>(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(3),
  color: theme.palette.colors.error
}))

export const StyledPerkImageOuterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '200px',
  position: 'absolute',
  minWidth: '230px',
  padding: `0 ${theme.spacing(5)}`
}))

export const StyledPerkImageInnerContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 0px 25px rgba(53, 49, 66, 0.1)',
  borderRadius: theme.borderRadius.medium,
  width: '100px',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '-25px',
  left: '25px',
  zIndex: 1,
  position: 'absolute'
}))

// PerkHeader

export const StyledPerkHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const StyledPerkHeaderContent = styled(Typography)({
  textTransform: 'capitalize'
})

export const StyledPerkHeaderPoints = styled(Typography)({
  lineHeight: '30px',
  textAlign: 'end',
  color: Colors.white,
  fontSize: '20px',
  alignSelf: 'center'
})

// SnagPerkModal
export const StyledPerkBottomModal = styled(BottomModal, {
  shouldForwardProp: (prop) => prop !== 'height'
})<{ height: number }>(({ height }) => ({
  backgroundImage: `url(${confettiBackgroundImage})`,
  backgroundRepeat: 'repeat-x',
  backgroundPosition: `center calc(100vh - ${height + 40}px)`,
  '.BaseModal__title': {
    textAlign: 'left'
  }
}))

export const StyledSnagPerkModalContent = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 2, 1)
}))

export const StyledSnagPerkModalFooter = styled('div')(({ theme }) => ({
  //TODO: Refactor - We are also using this in selectCategoriesModal
  display: 'grid',
  gridGap: theme.spacing(4),
  gridTemplateColumns: 'repeat(auto-fit, 135px)',
  width: '100%',
  justifyContent: 'center',
  // TODO: Refactor these styles into outlined-secondary on the theme
  '& button:hover': {
    color: theme.branding.primaryColor,
    border: `1px solid ${alpha(theme.branding.primaryColor, 0.5)}`
  }
}))

// PerkModalItem
const bold32 = {
  fontSize: '32px',
  fontWeight: 'bold'
}

const contentItemWidth = {
  width: '90%',
  maxWidth: '800px'
}

export const StyledPerkModalItem = styled(RowItem)(({ theme }) => ({
  margin: theme.spacing(4, 0, 5),
  '.RowItem__image': {
    background: 'rgba(115, 92, 221, 0.15)'
  }
}))

export const StyledPerkModalItemTitle = styled(Typography)({
  fontWeight: 'bold'
})

export const StyledPerkReedemMethod = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4)
}))

export const StyledRedeemExplainer = styled('p')({
  fontWeight: 'bold',
  margin: 'auto',
  marginBottom: '10px',
  ...contentItemWidth
})

export const StyledPerkReedemMethodContainer = styled(CenteredItems)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: 'white',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  ...contentItemWidth
}))

export const StyledPerkReedemCheckItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isRedeemable'
})<{ isRedeemable?: boolean }>(({ theme, isRedeemable }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  textAlign: 'center',
  paddingTop: isRedeemable ? '0px' : '8px'
}))

export const StyledDenominationForm = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(5)} auto ${theme.spacing(3)}`,
  display: 'flex',
  flexDirection: 'column',
  ...contentItemWidth
}))

export const StyledDenominationFormLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.h4,
  lineHeight: '24px',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  whiteSpace: 'normal'
}))

export const StyledDenominationFormInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error: boolean }>(({ error, theme }) => ({
  ...theme.customTypography.display3,
  lineHeight: '24px',
  ...conditionalObject({ color: theme.palette.colors.error }, error)
}))

export const StyledDenominationsMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  marginTop: theme.spacing(7),
  width: '100%',
  maxWidth: '800px',
  height: '50px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

export const StyledDenominationItem = styled(CenteredItemContainer, {
  shouldForwardProp: (prop) => prop !== 'styleChangers'
})(
  (props: {
    styleChangers: {
      isSelected: boolean
      isFree: boolean
      isOther?: boolean
      disabled?: boolean
    }
  }) => {
    const { isSelected, isOther, disabled, isFree } = props.styleChangers
    return {
      width: '100%',
      maxWidth: '84px',
      minWidth: '84px',
      margin: '0 5px',
      height: '50px',
      border: `1px ${isOther || disabled ? `dashed` : 'solid'} ${
        isSelected ? 'transparent' : disabled ? Colors.gray : 'black'
      }`,
      borderRadius: '100px',
      textAlign: 'center',
      flexDirection: 'column',
      backgroundColor: isSelected
        ? Colors.yellow
        : isFree
        ? Colors.inactive
        : 'transparent',
      opacity: isFree && !isSelected ? 0.5 : 1,
      fontWeight: 700,
      cursor: 'pointer'
    }
  }
)

export const StyledPerkCard = styled('div')({
  position: 'relative',
  maxWidth: '500px',
  minHeight: '200px',
  maxHeight: '300px',
  margin: 'auto',
  background: 'white',
  borderRadius: '20px',
  top: '-50px',
  zIndex: 1
})

export const StyledPerkImage = styled('img')(({ theme }) => ({
  margin: `${theme.spacing(5)} 0`,
  height: '40px',
  width: '40px'
}))

export const ContentPusher = styled('div')({
  paddingTop: '140px',
  paddingBottom: '140px'
})

export const StyledPerkCardTopRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0 ${theme.spacing(5)}`
}))

export const StyledPerkCardBottomRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'end',
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  paddingBottom: '10px'
}))

export const StyledColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

export const StyledBoldCardText = styled('span')({
  alignSelf: 'flex-end',
  ...bold32
})

export const StyledBoldDiscountText = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.branding.primaryColor,
  whiteSpace: 'nowrap'
}))

export const StyledStrikethroughDiscountText = styled('span')({
  textDecoration: 'line-through'
})

const StyledCardSubText = styled('div')({
  color: Colors.gray
})

export const StyledPoints = styled(StyledCardSubText)({
  alignSelf: 'flex-end'
})

export const StyledSubTitle = styled(StyledCardSubText)(() => ({
  margin: `0`
}))

export const StyledPerkTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  lineHeight: '35px',
  ...bold32,
  '@media (max-width: 360px)': {
    fontSize: '25px',
    lineHeight: '28px'
  },
  '@media (max-width: 350px)': {
    fontSize: '20px',
    lineHeight: '22px'
  }
}))

export const StyledConfirmationValuesContainer = styled(StyledColumnContainer)({
  textAlign: 'center',
  minHeight: '100px',
  justifyContent: 'space-around',
  marginTop: '40px'
})

export const StyledConfirmationAmount = styled('span')({
  fontSize: '48px',
  fontWeight: 'bold'
})

