import { ACTIVE_RING_COLORS } from 'theme'

import { FormEvent, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DailyQuestion, Goal } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import GoalIcon, { GoalIconTypes } from 'components/icons/GoalIcon/GoalIcon'
import { PointsExtraInfo } from 'components/rowItem'

import { useGetGoalsOnDate, useOpenOnarollDailyResponseModal } from 'hooks'

import { fetchDailyQuestionThunk, answerDailyQuestionThunk } from 'slices/dailyQuestion'
import { getTodaysGoals } from 'slices/goals'
import { fetchPointTotalsThunk } from 'slices/points'

import { getTodaysDate } from 'utils/form'
import { logError } from 'utils/logger'

import {
  StyledQuestionWidget,
  StyledQuestionWidgetAnsweredText,
  StyledQuestionWidgetContent,
  StyledQuestionWidgetCTA,
  StyledQuestionWidgetSubtitle
} from './questionWidget.styles'

import OnarollDailyForm from './onarollDailyForm'
import OnarollTriviaForm from './onarollTriviaForm'
import QuestionFormContainer from './questionFormContainer'

const TRIVIA_QUESTION_TYPE = 'trivia'
const QUESTION_GOAL_TYPE = 'question'

interface QuestionWidgetProps {
  header?: ReactNode
  points?: number
  question: DailyQuestion
}

export default function QuestionWidget({
  header,
  points = 0,
  question
}: QuestionWidgetProps) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { getGoalsOnDate, shouldFetchGoalServiceGoals } = useGetGoalsOnDate()

  const [isOpen, setIsOpen] = useState(false)
  const [answer, setAnswer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const goals: Goal[] = useSelector(getTodaysGoals) ?? []
  const openOnarollDailyResponseModal = useOpenOnarollDailyResponseModal()

  const hasQuestionText = Boolean(question?.question_text)
  const hasCompletedOnarollDaily = Boolean(question?.submitted_answer)
  const preventSubmit = isSubmitting || !answer

  useEffect(() => {
    getGoalsOnDate({ date: getTodaysDate(), getCachedResults: true })
  }, [shouldFetchGoalServiceGoals])

  if (!hasQuestionText) {
    logError(
      `Error: question_text missing from question with id: ${question?.question_id}`,
      { question }
    )
  }

  const openForm = () => {
    setIsOpen(true)
  }

  const closeForm = () => {
    setIsOpen(false)
  }

  const getPointsForAnswer = () => {
    const goal = goals.find((goal) => goal.goal_type === QUESTION_GOAL_TYPE)
    if (!goal) return points
    return goal.points
  }

  const onFormChange = (newAnswer: string) => {
    setAnswer(newAnswer)
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (preventSubmit) return false

    setIsSubmitting(true)

    try {
      const response = await dispatch(
        answerDailyQuestionThunk({ data: { question_id: question.question_id, answer } })
      ).unwrap()

      if (response.is_correct) {
        openOnarollDailyResponseModal({
          isCorrect: true,
          isTrivia: Boolean(response.correct_choice_id),
          points: getPointsForAnswer()
        })
      } else {
        openOnarollDailyResponseModal({
          isCorrect: false,
          correctAnswer: response.correct_choice_text || 'N/A'
        })
      }

      await dispatch(fetchDailyQuestionThunk())
      await dispatch(fetchPointTotalsThunk({}))

      getGoalsOnDate({ date: getTodaysDate(), getCachedResults: false })
    } catch (error) {
      // Something happened registering answer - try again
      //TODO: Maybe display some time of error telling the user to try again?
    } finally {
      closeForm()
      setIsSubmitting(false)
    }
  }

  const renderForm = () => {
    const form =
      question?.question_type === TRIVIA_QUESTION_TYPE ? (
        <OnarollTriviaForm onChange={onFormChange} question={question} />
      ) : (
        <OnarollDailyForm onChange={onFormChange} />
      )
    return (
      <QuestionFormContainer
        onSubmit={onSubmit}
        closeForm={closeForm}
        disabled={preventSubmit}
      >
        {form}
      </QuestionFormContainer>
    )
  }

  const answeredView = (
    <StyledQuestionWidgetAnsweredText variant="body2" component="p">
      {t('onaroll.question.alreadyAnswered')}
    </StyledQuestionWidgetAnsweredText>
  )

  const unansweredView = (
    <>
      <StyledQuestionWidgetContent variant="body2">
        {hasQuestionText
          ? question.question_text
          : t('onaroll.question.missingQuestionTitle')}
      </StyledQuestionWidgetContent>
      {!isOpen ? (
        <StyledQuestionWidgetCTA
          variant="contained"
          color="primary"
          onClick={openForm}
          disabled={!hasQuestionText}
        >
          {t('onaroll.question.cta')}
        </StyledQuestionWidgetCTA>
      ) : (
        renderForm()
      )}
    </>
  )

  const content = hasCompletedOnarollDaily ? answeredView : unansweredView

  const onarollDailySlice = {
    points: getPointsForAnswer(),
    color: ACTIVE_RING_COLORS[2],
    completed: hasCompletedOnarollDaily
  }

  const rowItemContent = (
    <>
      {header}
      <StyledQuestionWidgetSubtitle variant="subtitle2">
        {t('onaroll.question.subtitle')}
      </StyledQuestionWidgetSubtitle>
    </>
  )

  return (
    <>
      <StyledQuestionWidget
        item={onarollDailySlice}
        image={<GoalIcon type={GoalIconTypes.questionMark} />}
        extraInfo={<PointsExtraInfo item={onarollDailySlice} />}
        content={rowItemContent}
      >
        {content}
      </StyledQuestionWidget>
    </>
  )
}
