import { styled } from '@mui/material'

import Page from 'layouts/page'

export const JackpotHistoryPage = styled(Page)`
  .jackpot-history-ledger {
    margin-top: ${({ theme }) => theme.spacing(5)};
    background: ${({ theme }) => theme.palette.colors.white};
  }
`

export const JackpotHistoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const JackpotHistorySupportMessage = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(5)};
  color: ${({ theme }) => theme.palette.colors.gray};
`
