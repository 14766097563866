import { useIsOnSmallDevice } from 'hooks/mediaQueries'
import useTogglePreferredLanguage from 'hooks/useToggleLanguage'

import { StyledLanguageButton } from './languageButton.styles'

export const LanguageButton = () => {
  const isOnSmallDevice = useIsOnSmallDevice()
  const [{ nextLanguage }, toggleLanguage] = useTogglePreferredLanguage()

  return (
    <StyledLanguageButton
      variant="outlined"
      size={isOnSmallDevice ? 'small' : 'medium'}
      onClick={toggleLanguage}
      data-testid="LanguageButton"
      isOnSmallDevice={isOnSmallDevice}
    >
      {nextLanguage.toUpperCase()}
    </StyledLanguageButton>
  )
}
