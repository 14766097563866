import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import ErrorPage from 'layouts/errorPage'

import { RootPaths } from 'utils/helpers'

export default function ErrorNotAuthorized() {
  const { t } = useTranslation()
  return (
    <ErrorPage
      title={t('pages.notAuthorized.title')}
      description={t('pages.notAuthorized.description')}
      ctaButtonProps={{
        component: RouterLink,
        to: RootPaths.home
      }}
      ctaButtonText={t('pages.notAuthorized.cta')!}
    />
  )
}
