// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { PointTotal } from 'types'

import { createSlice } from '@reduxjs/toolkit'

import { POINTS, RequestState, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchPointTotalsThunk } from '.'

interface PointsState extends RequestState {
  totals: PointTotal
}

const initialState: PointsState = {
  totals: {} as PointTotal,
  ...requestState
}

export const pointsSlice = createSlice({
  name: POINTS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPointTotalsThunk.fulfilled, (state, action) => {
        state.totals = action.payload
        handleRequestFulfilled(state)
      })
      .addCase(fetchPointTotalsThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPointTotalsThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
  }
})

export default pointsSlice.reducer
