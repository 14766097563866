import { styled } from '@mui/material/styles'

export const BannerSC = styled('div')(({ theme }) => {
  const { error: errorColor } = theme.palette.colors
  return {
    width: '100%',
    backgroundColor: errorColor,
    color: theme.palette.getContrastTextColor(errorColor),
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    position: 'absolute',
    zIndex: 99999
  }
})
