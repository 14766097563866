import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as MonitorRedeemCheckIcon } from 'assets/icons/AppIcons/MonitorRedeemCheckIcon.svg'
import { ReactComponent as MonitorRedeemIcon } from 'assets/icons/AppIcons/MonitorRedeemIcon.svg'
import { ReactComponent as PhoneRedeemCheckIcon } from 'assets/icons/AppIcons/PhoneRedeemCheckIcon.svg'
import { ReactComponent as PhoneRedeemIcon } from 'assets/icons/AppIcons/PhoneRedeemIcon.svg'
import { ReactComponent as StoreRedeemCheckIcon } from 'assets/icons/AppIcons/StoreRedeemCheckIcon.svg'
import { ReactComponent as StoreRedeemIcon } from 'assets/icons/AppIcons/StoreRedeemIcon.svg'

import {
  StyledPerkReedemCheckItem,
  StyledPerkReedemMethod,
  StyledPerkReedemMethodContainer,
  StyledRedeemExplainer
} from './styles'

import { PerkRedeemMethodOptions } from './helpers'

export default function PerkRedeemMethods({
  isRedeemableOnline,
  isRedeemableInApp,
  isRedeemableInStore
}: PerkRedeemMethodOptions) {
  const { t } = useTranslation()

  const redeemableOnline = (isRedeemable: boolean) => (
    <StyledPerkReedemCheckItem isRedeemable={isRedeemable}>
      <div>{isRedeemable ? <MonitorRedeemCheckIcon /> : <MonitorRedeemIcon />}</div>
      <Typography variant="caption">{t('pages.perk.redemption.online')}</Typography>
    </StyledPerkReedemCheckItem>
  )
  const redeemableInApp = (isRedeemable: boolean) => (
    <StyledPerkReedemCheckItem isRedeemable={isRedeemable}>
      <div>{isRedeemable ? <PhoneRedeemCheckIcon /> : <PhoneRedeemIcon />}</div>
      <Typography variant="caption">{t('pages.perk.redemption.inApp')}</Typography>
    </StyledPerkReedemCheckItem>
  )
  const redeemableInStore = (isRedeemable: boolean) => (
    <StyledPerkReedemCheckItem isRedeemable={isRedeemable}>
      <div>{isRedeemable ? <StoreRedeemCheckIcon /> : <StoreRedeemIcon />}</div>
      <Typography variant="caption">{t('pages.perk.redemption.inStore')}</Typography>
    </StyledPerkReedemCheckItem>
  )
  return (
    <StyledPerkReedemMethod>
      <StyledRedeemExplainer>
        {t('pages.perk.redemption.redeemable')}
      </StyledRedeemExplainer>
      <StyledPerkReedemMethodContainer>
        {redeemableOnline(isRedeemableOnline)}
        {redeemableInApp(isRedeemableInApp)}
        {redeemableInStore(isRedeemableInStore)}
      </StyledPerkReedemMethodContainer>
    </StyledPerkReedemMethod>
  )
}
