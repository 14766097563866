export enum VerticalAnimationDirection {
  UP = 'up',
  DOWN = 'down',
  NONE = 'none'
}

export enum HorizontalAnimationDirection {
  NONE = 'none',
  LEFT = 'left',
  RIGHT = 'right'
}

export const getInitialYCoord = (
  verticalAnimationDirection: VerticalAnimationDirection
) => {
  switch (verticalAnimationDirection) {
    case VerticalAnimationDirection.DOWN:
      return -100
    case VerticalAnimationDirection.UP:
      return 100
    case VerticalAnimationDirection.NONE:
      return 0
    default:
      return 0
  }
}

export const getInitialXCoord = (
  horizontalAnimationDirection: HorizontalAnimationDirection
) => {
  switch (horizontalAnimationDirection) {
    case HorizontalAnimationDirection.LEFT:
      return -100
    case HorizontalAnimationDirection.RIGHT:
      return 100
    case HorizontalAnimationDirection.NONE:
      return 0
    default:
      return 0
  }
}
