import clsx from 'clsx'

import { Typography } from '@mui/material'

import { StyledDivider } from './Divider.styles'

interface DividerProps {
  text?: string
  useGradient?: boolean
  className?: string
}
export default function Divider({ className, text, useGradient }: DividerProps) {
  return (
    <StyledDivider
      className={clsx('Divider', className)}
      useGradient={useGradient}
      hasText={Boolean(text)}
    >
      {text && <Typography variant="body1">{text}</Typography>}
    </StyledDivider>
  )
}
