import { ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'

import GoalsItem from 'pages/goals/goalsItem'

import Carousel from 'components/carousel'

import { useGetGoalsOnDate } from 'hooks'

import { getTodaysGoals, getYesterdaysAchievedGoals } from 'slices/goals'

import { getTodaysDate, getYesterdaysDate } from 'utils/form'
import { getGoalDataWithColors } from 'utils/helpers'

import { GoalCarouselContainer } from './styles'

import { CheckTodaysGoalItem } from './CheckTodaysGoalsItem'

export const TeamMemberGoalCarousel = () => {
  const { getGoalsOnDate, shouldFetchGoalServiceGoals } = useGetGoalsOnDate()

  const achievedGoalsYesterday = useSelector(getYesterdaysAchievedGoals)
  const goalsToday = useSelector(getTodaysGoals)

  let carouselItems: ReactElement[] = []

  useEffect(() => {
    getGoalsOnDate({ date: getTodaysDate(), getCachedResults: true })
    getGoalsOnDate({ date: getYesterdaysDate(), getCachedResults: true })
  }, [shouldFetchGoalServiceGoals])

  if (achievedGoalsYesterday?.length) {
    const goals = achievedGoalsYesterday
    carouselItems = getGoalDataWithColors(goals).map((goal) => (
      <GoalsItem key={goal.id} item={goal} hideGoalResult />
    ))
    carouselItems.push(<CheckTodaysGoalItem key="0" />)
  } else if (goalsToday?.length) {
    const goals = goalsToday || []
    carouselItems = getGoalDataWithColors(goals).map((goal) => (
      <GoalsItem key={goal.id} item={goal} hideGoalResult />
    ))
  }

  return (
    <GoalCarouselContainer>
      {!!carouselItems.length && (
        <Carousel showNavButtons itemMaxWidth="100%">
          {carouselItems}
        </Carousel>
      )}
    </GoalCarouselContainer>
  )
}
