import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ImpersonationArgs } from 'types'

import useAppDispatch from './useAppDispatch'

import { THUNK_CONDITION_ERROR_NAME } from 'slices/constants'

import { impersonateWorkerThunk } from 'slices/impersonation'

import { RootPaths } from 'utils/helpers'
import { logInfo, logWarning, logError } from 'utils/logger'

import useCustomRedirect from './useRedirect'

interface RejectedThunkByConditionError {
  name: string
  message: string
}

export default function useImpersonateUser() {
  const dispatch = useAppDispatch()
  const impersonationArgs = useParams<ImpersonationArgs>()
  const redirectToHome = useCustomRedirect()
  const redirectToLogin = useCustomRedirect(RootPaths.welcome)

  useEffect(() => {
    if (impersonationArgs) {
      const impersonateUser = async () => {
        try {
          // see https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
          await dispatch(impersonateWorkerThunk(impersonationArgs)).unwrap()
          logInfo(
            `User has successfully impersonated a team member! Attemping to redirect to home page`,
            { impersonationArgs }
          )
          redirectToHome({
            search: `?impersonation_employment_id=${impersonationArgs.employment_id}&encrypted_user_id=${impersonationArgs.user_id}`
          })
        } catch (error) {
          if (
            (error as RejectedThunkByConditionError)?.name === THUNK_CONDITION_ERROR_NAME
          ) {
            logWarning(
              `You are already impersonating someone. Please drop the impersonation and try again.`,
              { error }
            )
            redirectToHome({
              search: `?impersonation_employment_id=${impersonationArgs.employment_id}&encrypted_user_id=${impersonationArgs.user_id}`
            })
          } else {
            logError(
              `Unable to impersonate user! Redirecting to login... ${
                (error as Error)?.message
              }`,
              {
                error
              }
            )
            redirectToLogin()
          }
        }
      }
      impersonateUser()
    }
  }, [impersonationArgs])
}
