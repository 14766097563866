import clsx from 'clsx'

import { DialogProps, IconButton } from '@mui/material'

import { ForwardedRef, ReactNode } from 'react'

import { AppIconTypes } from 'types'

import AppIcon from 'components/icons/AppIcon'

import {
  StyledBaseModal,
  StyledBaseModalActions,
  StyledBaseModalButton,
  StyledBaseModalCloseButtonHeader,
  StyledBaseModalContent,
  StyledBaseModalHeader,
  StyledBaseModalTitle
} from './styles'

export interface DefaultBaseModalProps {
  isOpen: boolean
  onClose: () => void
}

export interface BaseModalProps extends DefaultBaseModalProps {
  content: ReactNode
  title?: string | null
  TransitionComponent: DialogProps['TransitionComponent']
  className?: string
  contentContainerClassName?: string
  contentWrapperClassName?: string
  ctaButtonClassName?: string
  ctaText?: string
  ctaWrapperClassName?: string
  onActionClicked?: () => void
  titleClassName?: string
  footer?: ReactNode
  baseModalRef?: ForwardedRef<HTMLDivElement>
  paperProps?: DialogProps['PaperProps']
  header?: ReactNode
  headerClassName?: string
  displayCloseButtonInHeader?: boolean
  disableActionButton?: boolean
}

export default function BaseModal({
  isOpen,
  className,
  content,
  contentContainerClassName,
  contentWrapperClassName,
  ctaButtonClassName,
  ctaText,
  ctaWrapperClassName,
  onActionClicked,
  onClose,
  title,
  titleClassName,
  footer,
  TransitionComponent,
  paperProps = {},
  baseModalRef,
  header,
  headerClassName,
  displayCloseButtonInHeader = false,
  disableActionButton = false
}: BaseModalProps) {
  const onDialogAction = () => {
    if (onActionClicked) {
      onActionClicked()
    } else {
      onClose()
    }
  }

  return (
    <StyledBaseModal
      open={isOpen}
      onClose={onClose}
      TransitionComponent={TransitionComponent}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={clsx('BaseModal', className)}
      PaperProps={paperProps}
      ref={baseModalRef}
      data-testid="BaseModal"
    >
      {displayCloseButtonInHeader && (
        <StyledBaseModalCloseButtonHeader>
          <IconButton onClick={onClose}>
            <AppIcon type={AppIconTypes.close} />
          </IconButton>
        </StyledBaseModalCloseButtonHeader>
      )}
      {header && (
        <StyledBaseModalHeader
          id="alert-dialog-slide-header"
          className={clsx('BaseModal__header', headerClassName)}
        >
          {header}
        </StyledBaseModalHeader>
      )}
      {title && (
        <StyledBaseModalTitle
          id="alert-dialog-slide-title"
          className={clsx('BaseModal__title', titleClassName)}
        >
          {title}
        </StyledBaseModalTitle>
      )}
      <StyledBaseModalContent
        className={clsx('BaseModal__content', contentContainerClassName)}
      >
        <div
          id="alert-dialog-slide-description"
          className={clsx('BaseModal__wrapper', contentWrapperClassName)}
        >
          {content}
        </div>
      </StyledBaseModalContent>
      <StyledBaseModalActions className={clsx('BaseModal__actions', ctaWrapperClassName)}>
        {footer || (
          <StyledBaseModalButton
            onClick={onDialogAction}
            variant="outlined"
            color="secondary"
            data-testid="BaseModalFooterCTAButton"
            className={clsx('BaseModal__actionButton', ctaButtonClassName)}
            disabled={disableActionButton}
          >
            {ctaText || 'Done'}
          </StyledBaseModalButton>
        )}
      </StyledBaseModalActions>
    </StyledBaseModal>
  )
}
