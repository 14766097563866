import { Button, styled } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledLanguageButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  borderColor: theme.palette.colors.gray,
  color: theme.palette.colors.white,
  ...conditionalObject({ marginBottom: theme.spacing(4) }, isOnSmallDevice)
}))
