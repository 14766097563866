// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TeamMemberEmployment } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { ADMIN_TEAM_EMPLOYMENTS, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { updateEmploymentThunk } from './actions'

export const EmploymentAdapter = createEntityAdapter<TeamMemberEmployment>({
  selectId: (employment) => employment.id
})

export const adminEmploymentSlice = createSlice({
  name: ADMIN_TEAM_EMPLOYMENTS,
  initialState: EmploymentAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateEmploymentThunk.fulfilled, (state) => {
        handleRequestFulfilled(state)
      })
      .addCase(updateEmploymentThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateEmploymentThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
  }
})

export default adminEmploymentSlice.reducer
