import { StyledOnboardingStepWrapper } from './styles'

interface StepProps {
  children: React.ReactNode
  backgroundColor?: string
}

const Step = ({ children, backgroundColor = 'transparent' }: StepProps) => {
  return (
    <StyledOnboardingStepWrapper style={{ backgroundColor }} data-testid="OnboardingStep">
      {children}
    </StyledOnboardingStepWrapper>
  )
}

export default Step
