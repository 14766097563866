import startCase from 'lodash/startCase'
import { Colors } from 'theme'

import { ReactElement } from 'react'

import { AdminContainer, AdminContainerProps } from 'components/AdminContainer'

import { StyledTitle } from './styles'

interface UpsertFormProps {
  actionType?: string
  headerTitle?: string
  entityType: string
  form: ReactElement
  containerProps?: AdminContainerProps['containerProps']
}

const UpsertFormContainer = ({
  actionType = 'Create',
  headerTitle,
  entityType,
  form,
  containerProps = {}
}: UpsertFormProps & AdminContainerProps['containerProps']) => {
  const entity = startCase(entityType)
  const action = startCase(actionType)
  const title = `${startCase(action)} a ${entity}!`
  const themeType = containerProps?.themeType ?? 'light'
  const customContainerProps = { ...containerProps, themeType }
  const color = themeType === 'orange' ? Colors.orange : undefined

  return (
    <AdminContainer
      headerTitle={headerTitle || entity}
      containerProps={customContainerProps}
    >
      <StyledTitle variant="h3" color="primary" style={{ color }}>
        {title}
      </StyledTitle>
      {form}
    </AdminContainer>
  )
}

export default UpsertFormContainer
export { default as AutoCompleteField } from './AutoCompleteField'
export { default as AutoSuggestField } from './AutoSuggestField'
export { default as FormDateTimeField } from './FormDateTimeField'
export { default as FormSelectField } from './FormSelectField'
export { default as FormTextField } from './FormTextField'
