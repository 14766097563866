import { TextField, styled, alpha, Typography } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledOTPInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(({ theme, error }) => ({
  minWidth: '90%',
  padding: 0,
  margin: 0,
  '& .MuiInput-root': {
    '&:before': {
      borderBottomColor: alpha(theme.palette.common.white, 0.5),
      ...conditionalObject({ borderBottomColor: theme.palette.colors.error }, error)
    },
    '&:after, &:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.common.white,
      ...conditionalObject({ borderBottomColor: theme.palette.colors.error }, error)
    }
  },
  input: {
    color: 'white',
    fontSize: theme.fontSize(3),
    fontWeight: 'bold',
    textAlign: 'center',
    "&[type='number']": {
      MozAppearance: 'textfield',
      '&::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    }
  }
}))

export const StyledOTPHeader = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(6)} 0`
}))

export const StyledOTPHeaderText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bold'
})<{ bold?: boolean }>(({ theme, bold }) => ({
  color: theme.palette.common.white,
  fontSize: '24px',
  lineHeight: '32px',
  textAlign: 'center',
  marginBottom: theme.spacing(1),
  ...conditionalObject({ fontWeight: 'bold' }, bold)
}))

export const StyledOTPResendCode = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled'
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  fontSize: `${theme.fontSize(1.75)}`, // 14px
  lineHeight: 1.7142, // 24px
  color: theme.palette.common.white,
  margin: `${theme.spacing(1)} 0 ${theme.spacing(3)}`,
  '& a, & button': {
    fontSize: `${theme.fontSize(1.75)}`, // 14px
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(0.3),
    verticalAlign: 'baseline',
    ':hover': {
      color: theme.palette.colors.yellow
    },
    ...conditionalObject(
      { color: theme.palette.colors.gray, pointerEvents: 'none' },
      disabled
    )
  }
}))

export const StyledOTPErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.error,
  fontSize: theme.fontSize(2), // 16px
  lineHeight: 1.25, // 20px
  margin: theme.spacing(2)
}))

export const StyledOTPText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: theme.fontSize(2),
  textAlign: 'center',
  margin: theme.spacing(2)
}))
