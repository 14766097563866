import { styled } from '@mui/material'

export const JackpotTicketLineItemContainer = styled('div')`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
  padding: ${({ theme }) => theme.spacing(2)} 0;
`

export const JackpotTicketLineItemTitle = styled('div')`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`
export const JackpotTicketLineItemDetail = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNegative'
})<{ isNegative: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${({ theme, isNegative }) => (isNegative ? theme.branding.primaryColor : '')};
`

export const JackpotTicketLineItemLeft = styled('div')`
  flex: 1 1 0;
`

export const JackpotTicketLineItemRight = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const JackpotTicketLineItemNumTickets = styled('span')`
  margin-right: ${({ theme }) => theme.spacing(4)};
`
