import { Button, styled } from '@mui/material'

interface StyledFloatingButtonProps {
  top: number
  left: number
}

const StyledFloatingButton = styled(Button)<StyledFloatingButtonProps>(
  ({ top, left }) => ({
    opacity: 0.9,
    position: 'fixed',
    zIndex: 9999,
    borderRadius: 10,
    top,
    left
  })
)

export default StyledFloatingButton
