import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from 'assets/icons/AppIcons/CloseIcon.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/AppIcons/SearchIcon.svg'

import {
  StyledSearch,
  StyledSearchCloseIcon,
  StyledSearchCloseIconButton,
  StyledSearchIcon,
  StyledSearchInput
} from './styles'

export interface SearchProps {
  onChange: (key: string) => void
  onClear: () => void
  value: string
  condensed?: boolean
}

export interface SearchStyleProps {
  condensed: boolean
}

export function Search({ condensed, onChange, onClear, value }: SearchProps) {
  const { t } = useTranslation()

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value
    onChange(val)
  }

  return (
    <StyledSearch condensed={Boolean(condensed)}>
      <StyledSearchIcon>
        <SearchIcon />
      </StyledSearchIcon>
      <StyledSearchInput
        placeholder={t('search.placeholder')}
        value={value}
        onChange={onInputChange}
        inputProps={{
          'aria-label': t('search.ariaLabel'),
          'data-testid': 'SearchTextInput'
        }}
      />
      {value && (
        <StyledSearchCloseIcon>
          <StyledSearchCloseIconButton onClick={onClear} size="large">
            <CloseIcon />
          </StyledSearchCloseIconButton>
        </StyledSearchCloseIcon>
      )}
    </StyledSearch>
  )
}

export default Search
