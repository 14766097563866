import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Page from '../../layouts/page'

import Placeholder, { RowItemLoader } from '../../placeholder'

import useAppDispatch from 'hooks/useAppDispatch'

import {
  fetchRedeemedPerksThunk,
  redeemedPerksSelectors,
  selectRedeemedPerksLoading
} from 'slices/perks'

import { RedeemedPerksContainer } from './styles'

import RedeemedPerkItem from './redeemedPerkItem'

export default function RedeemedPerks() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const redeemedPerks = useSelector(redeemedPerksSelectors.selectAll)
  const redeemedPerksLoading = useSelector(selectRedeemedPerksLoading)

  useEffect(() => {
    dispatch(
      fetchRedeemedPerksThunk({
        getCachedResults: true
      })
    )
  }, [])

  return (
    <Page altHeader={true} title={t('pages.perksWallet.title')}>
      <RedeemedPerksContainer>
        <Placeholder isLoading={redeemedPerksLoading} loader={RowItemLoader} amount={4}>
          {redeemedPerks.map((perk, i) => (
            <RedeemedPerkItem key={`${perk.reward.id}-redeemedPerk-${i}`} perk={perk} />
          ))}
        </Placeholder>
      </RedeemedPerksContainer>
    </Page>
  )
}
