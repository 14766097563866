import { alpha, IconButton, styled } from '@mui/material'

import ContactUsWidget from 'components/contactUs'

export const StyledOnboardingContactUsWidgetContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8)
}))

export const StyledOnboardingContactUsWidget = styled(ContactUsWidget)(({ theme }) => ({
  color: theme.palette.colors.white,
  backgroundColor: alpha(theme.palette.colors.error, 0.5),
  border: `1px solid ${theme.palette.colors.error}`,
  '& a.MuiButton-root': {
    border: `1px solid ${alpha(theme.palette.colors.black, 0.5)}`,
    backgroundColor: alpha(theme.palette.colors.black, 0.4),
    color: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: alpha(theme.palette.colors.black, 0.6)
    }
  }
}))

export const StyledOnboardingErrorCloseIconContainer = styled(IconButton)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.colors.error}`,
    backgroundColor: alpha(theme.palette.colors.error, 0.5),
    '&:hover': {
      backgroundColor: alpha(theme.palette.colors.error, 0.8)
    }
  })
)
