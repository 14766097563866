import { styled, Typography } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledOnboardingStepWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.borderRadius.large
}))

export const StyledOnboardingStepTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOnTinyDevice'
})<{ isOnTinyDevice?: boolean }>(({ isOnTinyDevice }) => ({ theme }) => ({
  textAlign: 'center',
  paddingBottom: theme.spacing(3),
  color: theme.palette.colors.white,
  ...conditionalObject({ paddingBottom: theme.spacing(2) }, isOnTinyDevice)
}))

export const StyledOnboardingStepBody = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice }) => ({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.colors.white,
  ...conditionalObject({ fontSize: theme.fontSize(2.3) }, isOnSmallDevice)
}))
