/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { startCase, omit, isEqual } from 'lodash'

import { CircularProgress, Paper, AutocompleteRenderInputParams } from '@mui/material'
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  default as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps
} from '@mui/material/Autocomplete'
import TextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import {
  AutocompleteValue,
  UseAutocompleteProps as MuiUseAutocompleteProps
} from '@mui/material/useAutocomplete'
import { AsyncThunk } from '@reduxjs/toolkit'

import { useState, useEffect, useMemo, HTMLAttributes, SyntheticEvent } from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { useSelector } from 'react-redux'

import {
  CommonResponse,
  FetchWithParamsThunk,
  FetchWithParamsAndLazyLoadProps,
  FetchCachedThunkProps
} from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import { usePrevious } from 'hooks/usePrevious'

import { debounceFetch } from 'utils/helpers'

import { RootState } from 'reduxStore'

export interface AutocompleteData {
  [key: string]: any | null
}

type AutoCompletePropType = Omit<Required<CommonResponse>, 'created' | 'modified'>

export interface AutocompleteProps<
  T extends AutoCompletePropType,
  DataType,
  FetchType,
  Arg,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
      MuiUseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    // Note: We are removing these two because we are implementing these in-house below
    'renderInput' | 'options'
  > {
  name: string
  label?: string
  helperText?: string
  required?: boolean
  getOptionValue?: (option: T) => DataType
  getOptionLabel?: (option: T) => string
  fieldProps?: Partial<FieldProps<any, any>>
  textFieldProps?: Partial<MuiTextFieldProps>
  handleFetchOptions:
    | AsyncThunk<FetchType, FetchWithParamsThunk<Arg>, {}>
    | AsyncThunk<FetchType, FetchWithParamsAndLazyLoadProps<Arg>, {}>
    | AsyncThunk<FetchType, FetchCachedThunkProps, {}>
  selectFetchedResults: (state: RootState) => T[]
  selectResultsLoading: (state: RootState) => boolean
  filterConstraints?: Partial<T>
}

export default function AutoCompleteField<
  T extends AutoCompletePropType,
  DataType,
  FetchType,
  Arg,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteProps<
    T,
    DataType,
    FetchType,
    Arg,
    Multiple,
    DisableClearable,
    FreeSolo
  >
): JSX.Element {
  const { name, fieldProps, ...additionalProps } = props

  return (
    <Field<DataType>
      name={name}
      render={(fieldRenderProps) => (
        <AutocompleteWrapper name={name} {...fieldRenderProps} {...additionalProps} />
      )}
      {...fieldProps}
    />
  )
}

interface AutocompleteWrapperProps<
  T extends AutoCompletePropType,
  DataType,
  FetchType,
  Arg,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<
    T,
    DataType,
    FetchType,
    Arg,
    Multiple,
    DisableClearable,
    FreeSolo
  > {
  label?: string
  required?: boolean
  textFieldProps?: Partial<MuiTextFieldProps>
}

function AutocompleteWrapper<
  T extends AutoCompletePropType,
  DataType,
  FetchType,
  Arg,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteWrapperProps<
    T,
    DataType,
    FetchType,
    Arg,
    Multiple,
    DisableClearable,
    FreeSolo
  > &
    FieldRenderProps<MuiTextFieldProps>
): JSX.Element {
  const {
    input: { name, value, onChange },
    label,
    required,
    multiple,
    textFieldProps,
    getOptionValue,
    getOptionLabel,
    placeholder,
    onChange: onChangeCallback,
    handleFetchOptions,
    selectFetchedResults,
    selectResultsLoading,
    filterConstraints,
    ...additionalProps
  } = props

  const dispatch = useAppDispatch()
  const [options, setOptions] = useState<T[]>([])
  const [inputValue, setInputValue] = useState('')
  const previousInputValue = usePrevious<string>(inputValue, '')
  const results = useSelector(selectFetchedResults)
  const previousResults = usePrevious(results)
  const isLoading = useSelector(selectResultsLoading)

  const getValueForOption = useMemo(
    () => getOptionValue ?? ((option: T) => option.id),
    [getOptionValue]
  )

  const getLabelForOption = useMemo(
    () => getOptionLabel ?? ((option: T) => option.name),
    [getOptionLabel]
  )

  useEffect(() => {
    const handleFetch = handleFetchOptions as AsyncThunk<
      FetchType,
      FetchCachedThunkProps,
      {}
    >
    dispatch(
      handleFetch({
        getCachedResults: false
      })
    )
  }, [])

  useEffect(() => {
    if (!isEqual(previousInputValue, inputValue)) {
      debounceFetch(dispatch, handleFetchOptions, {
        queryParams: { search: inputValue }
      })
    }
  }, [inputValue, previousInputValue, handleFetchOptions])

  useEffect(() => {
    if (!isEqual(previousResults, results)) {
      setOptions(results)
    }
  }, [results])

  function getValue(values: any) {
    return multiple
      ? values
        ? values.map(getValueForOption)
        : null
      : values
      ? getValueForOption(values)
      : null
  }

  // make sure to remove options from props since we control them internally
  const { helperText, ...restOfAutoCompleteWrapperProps } = omit(additionalProps, [
    'options'
  ])
  const { variant, ...restTextFieldProps } = textFieldProps || {}

  /* We need this in order to make this component "controlled"
   * even though we do not handle the value directly in order to allow
   * the form to accept initial values
   */
  let defaultValue = null as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>

  /* If we have a value(string from being passed in most likely through initial values)
   * Loop through all our available options and find the associated record
   * If found, that means we should set it as the default value to load on the form
   * Note: We can probably move this over to a useEffect but then we will need to handle the `value`
   * and support the logic everywhere it is needed.
   * See https://github.com/onarollonaroll/onaroll-app/pull/50#discussion_r789140352
   */
  if (value) {
    options.forEach((option) => {
      const optionValue = getValueForOption(option)
      if (multiple) {
        defaultValue = [] as unknown as AutocompleteValue<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >
        const passedValue = value as DataType[]
        passedValue.forEach((v) => {
          if (v === optionValue) {
            const passedDefaultVal = defaultValue as T[]
            passedDefaultVal.push(option)
          }
        })
      } else if (value === optionValue) {
        defaultValue = option as AutocompleteValue<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >
      }
    })
  }

  const onInputChange = (_event: SyntheticEvent<Element, Event>, newValue: string) =>
    setInputValue(newValue)

  const onChangeFunc = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    const newValue = getValue(value)
    onChange(newValue)

    if (onChangeCallback && reason === 'selectOption') {
      onChangeCallback(event, value, reason, details)
    }
  }

  const filterOptions = (options: T[]) => {
    let filteredOptions = options
    if (filterConstraints) {
      const keys = Object.keys(filterConstraints) as (keyof T)[]
      keys.forEach((constraint) => {
        const constraintValue = filterConstraints[constraint]
        if (constraintValue) {
          // only filter if we have an actual value
          filteredOptions = filteredOptions.filter(
            (result) => result[constraint] === constraintValue
          )
        }
      })
    }
    return filteredOptions
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      data-testid="MuiAutoComplete-TextInput"
      label={inputLabel}
      required={required}
      helperText={helperText}
      name={name}
      placeholder={placeholder || inputLabel}
      variant={variant}
      {...params}
      {...restTextFieldProps}
      InputProps={{
        ...params.InputProps,
        ...restTextFieldProps.InputProps,
        ...(restTextFieldProps.InputProps?.startAdornment && {
          startAdornment: (
            <>
              {restTextFieldProps.InputProps.startAdornment}
              {params.InputProps?.startAdornment}
            </>
          )
        }),
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps?.endAdornment}
            {restTextFieldProps.InputProps?.endAdornment}
          </>
        )
      }}
    />
  )

  const renderOpenDropdownContainer = (props: HTMLAttributes<HTMLElement>) => (
    <Paper sx={{ boxShadow: 8 }} {...props} />
  )

  const areOptionsEqual = (option: T, value: T) =>
    getValueForOption(option) === getValueForOption(value)

  const inputLabel = label || startCase(name)

  return (
    <MuiAutocomplete
      data-testid="MuiAutoComplete"
      clearOnBlur
      multiple={multiple}
      onChange={onChangeFunc}
      options={options}
      value={defaultValue}
      getOptionLabel={getLabelForOption}
      onInputChange={onInputChange}
      loading={isLoading}
      isOptionEqualToValue={areOptionsEqual}
      PaperComponent={renderOpenDropdownContainer}
      filterOptions={filterConstraints && filterOptions}
      renderInput={renderInput}
      {...restOfAutoCompleteWrapperProps}
    />
  )
}
