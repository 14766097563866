import { ReactNode, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import useAppDispatch from 'hooks/useAppDispatch'

import { BackButton } from 'components/BackButton'
import SwipeableStoreSwitcher from 'components/swipeableStoreSwitcher'

import { useNavigation } from 'hooks/useNavigation'

import { toggleOpen } from 'slices/storeSwitcher'
import { fetchStoresThunk, storesSelectors } from 'slices/stores'
import { getWhoami } from 'slices/whoami'

import { RootPaths } from 'utils/helpers'

import {
  HeaderContainer,
  HeaderBackButton,
  HeaderChildren,
  HeaderIcon,
  HeaderIconButton,
  HeaderTitle,
  HeaderTitleContainer,
  HeaderToolbar,
  StoreIcon,
  OnarollIcon
} from './styles'

import HeaderBrandLogo from './headerBrandLogo'
import { getMatchedPaths, getTextFromPath, humanizeText } from './utils'

// https://material-ui.com/components/app-bar/#fixed-placement

interface Props {
  children?: ReactNode
  title?: string
  alt?: boolean
  onBack?: () => void
}
const Header = ({ children, title, onBack, alt = false }: Props) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const location = useLocation()
  const { i18n, t } = useTranslation()
  const whoami = useSelector(getWhoami)
  const stores = useSelector(storesSelectors.selectAll)

  const brand = 'Onaroll'

  useEffect(() => {
    dispatch(fetchStoresThunk({ getCachedResults: true }))
  }, [])

  const goToUserProfile = () => {
    navigation.push(RootPaths.userProfile)
  }

  const goToRelativeRoot = (rootPath: string) => {
    navigation.replace(rootPath)
  }

  const getHeaderTitleText = () => {
    if (['/', RootPaths.home].includes(location.pathname)) return brand

    const match = getMatchedPaths()
    if (!match?.groups) return brand

    const rootPath = match.groups.rootPath
    const rootPathText = getTextFromPath(rootPath)
    // Check if it is a footer path that we have a translation for
    const tabTranslationKey = `footer.tab.${rootPathText}`
    return i18n.exists(tabTranslationKey)
      ? t(tabTranslationKey)
      : humanizeText(rootPathText)
  }

  const toggleOpenStoreSwitcher = useCallback(() => {
    dispatch(toggleOpen())
  }, [])

  const renderHeaderStoreSwitcher = () => {
    if (stores?.length < 2) return null
    return (
      <HeaderIconButton
        aria-label="switch currently selected store"
        aria-controls="store-switcher"
        aria-haspopup="true"
        color="inherit"
        title="Select store"
        onClick={toggleOpenStoreSwitcher}
        size="large"
      >
        <StoreIcon />
      </HeaderIconButton>
    )
  }

  const renderBackButton = (text?: string) => {
    const match = getMatchedPaths()
    if (match?.groups?.nestedRoutes) {
      const rootPath = match.groups.rootPath
      const navBack = () => (onBack ? onBack() : goToRelativeRoot(rootPath))

      if (alt) return <BackButton onBack={navBack} />

      return <HeaderBackButton text={text} onBack={navBack} />
    }

    if (!alt) return text
  }

  const renderTitleAndBackButton = () => {
    const text = getHeaderTitleText()

    return (
      <>
        <HeaderBrandLogo />
        <HeaderTitle variant="h3">
          {title || (text === brand ? <OnarollIcon /> : renderBackButton(text))}
        </HeaderTitle>
      </>
    )
  }

  const renderAltTitle = () => {
    if (!alt) return null
    return (
      <HeaderTitleContainer>
        <HeaderTitle variant="h3">{title || getHeaderTitleText()}</HeaderTitle>
      </HeaderTitleContainer>
    )
  }

  return (
    <HeaderContainer position="sticky">
      <HeaderToolbar>
        {alt ? renderBackButton() : renderTitleAndBackButton()}
        <div>
          {renderHeaderStoreSwitcher()}
          <HeaderIconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            title={whoami?.display_name}
            onClick={goToUserProfile}
            size="large"
          >
            <HeaderIcon />
          </HeaderIconButton>
        </div>
      </HeaderToolbar>
      {renderAltTitle()}
      <SwipeableStoreSwitcher />
      <HeaderChildren>{children}</HeaderChildren>
    </HeaderContainer>
  )
}

export default Header
