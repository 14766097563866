import { map, orderBy } from 'lodash'
import { AdminGoal } from 'types'

const getGoalLabelValue = (goal: AdminGoal) => {
  const date = goal?.effective_at ? new Date(goal?.effective_at) : new Date()
  const title = goal?.title || 'Untitled'
  return {
    label: `${title} (${date.toLocaleString()})`,
    value: goal?.id
  }
}
const getGoalVersionsForForm = (goal: AdminGoal | undefined) => {
  if (!goal?.versions) return []
  const versions = goal.versions.length ? goal.versions : [{ ...goal }]
  const sorted = orderBy(versions, ['effective_at'], ['desc'])
  return map(sorted, getGoalLabelValue)
}

export default { getGoalVersionsForForm }
