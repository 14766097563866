import {
  AdminGoal,
  AdminListFilters,
  GoalWithCsvSchema,
  PaginatedAdminGoals,
  FetchOneDTO,
  FetchWithParamsAndLazyLoadProps,
  UpsertThunkProps
} from 'types'

import { fetchRecord, fetchRecords, postRecord, putRecord } from 'api'

import { ADMIN_GOALS } from 'slices/constants'

import { authenticatedThunkCreator, getTransformedParams } from 'slices/utils'

import { RootState } from 'reduxStore'

import { getAdminGoalsResponse, selectGoalsLoading } from '.'

const GOALS_ENDPOINT = 'allGoals'

export const fetchAllGoalsThunk = authenticatedThunkCreator<
  PaginatedAdminGoals,
  FetchWithParamsAndLazyLoadProps<AdminListFilters>
>(
  `${ADMIN_GOALS}/fetchAllGoalsThunk`,
  async (options, { getState }) => {
    const state = getState() as RootState
    const prevGoals = getAdminGoalsResponse(state)
    const params = getTransformedParams(prevGoals, options)

    const allGoals: PaginatedAdminGoals = await fetchRecords(
      {
        ...options,
        queryParams: params
      },
      GOALS_ENDPOINT
    )
    return allGoals
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState
      const isLoading = selectGoalsLoading(state)
      if (isLoading) {
        // Cancel execution if a request is already in progress
        return false
      }
    }
  }
)

export const fetchGoalThunk = authenticatedThunkCreator<GoalWithCsvSchema, FetchOneDTO>(
  `${ADMIN_GOALS}/fetchGoalThunk`,
  async (options) => {
    const goal: GoalWithCsvSchema = await fetchRecord(options, 'allGoals')
    return goal
  }
)

export const updateGoalThunk = authenticatedThunkCreator<
  AdminGoal,
  Required<UpsertThunkProps<Partial<AdminGoal>>>
>(`${ADMIN_GOALS}/updateGoalThunk`, async (authedDTO) => {
  const { id } = authedDTO
  const goal: AdminGoal = id
    ? await putRecord(authedDTO, GOALS_ENDPOINT)
    : await postRecord(authedDTO, GOALS_ENDPOINT)
  return goal
})

export const createGoalThunk = authenticatedThunkCreator<
  AdminGoal,
  UpsertThunkProps<Partial<AdminGoal>>
>(`${ADMIN_GOALS}/createGoalThunk`, async (authedDTO) => {
  const goal: AdminGoal = await postRecord(authedDTO, GOALS_ENDPOINT)
  return goal
})
