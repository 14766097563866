import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import SwipeableViews from 'react-swipeable-views'
import { Colors } from '../../theme'

interface CarouselStyleProps {
  itemMaxWidth: string
  amountOfItems: number
  spaceBetween: string
}

export const StyledCarousel = styled(SwipeableViews, {
  shouldForwardProp: (prop) =>
    prop !== 'itemMaxWidth' && prop !== 'amountOfItems' && prop !== 'spaceBetween'
})<CarouselStyleProps>(({ theme, itemMaxWidth, spaceBetween }) => ({
  maxWidth: itemMaxWidth, // max size (width) of one of the items
  borderRadius: theme.borderRadius.medium,
  '& [data-swipeable]:not(:first-of-type)': {
    marginLeft: spaceBetween
  }
}))

export const StyledCarouselButtons = styled('div', {
  shouldForwardProp: (prop) => prop !== 'itemMaxWidth' && prop !== 'amountOfItems'
})<Omit<CarouselStyleProps, 'spaceBetween'>>(
  ({ theme, amountOfItems, itemMaxWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    // Center nav buttons aligned with the total max width of every item
    // This will cause the buttons to ALWAYS be centered, but if the screen
    // is wide enough, it should center to the size of the items NOT the screen
    // TODO: Remove this once we have a tablet/desktop view for this page
    maxWidth: `calc(${itemMaxWidth} * ${amountOfItems} + ${theme.spacing(4)})`
  })
)

export const StyledCarouselIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  color: Colors.white,
  '&.Mui-disabled svg': {
    '& circle': {
      stroke: 'rgba(0, 0, 0, 0.1)',
      color: Colors.gray200
    },
    '& path': {
      fill: 'rgba(0, 0, 0, 0.1)'
    }
  }
}))
