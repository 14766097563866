import { useTranslation } from 'react-i18next'

import { PreferredLanguage } from 'types'

type PreferredLanguageReturnType = [
  { currentLanguage: PreferredLanguage; nextLanguage: PreferredLanguage },
  () => void
]

const languages = [PreferredLanguage.english, PreferredLanguage.spanish]

const getNextLanguage = (currentLanguage: string) => {
  const currentIndex = languages.findIndex((lng) => lng === currentLanguage)
  const nextLanguageIndex = (currentIndex + 1) % languages.length
  return languages[nextLanguageIndex]
}

export default function useTogglePreferredLanguage() {
  const { i18n } = useTranslation()

  const currentLanguage = i18n.language
  const nextLanguage = getNextLanguage(currentLanguage)

  const toggleLanguage = () => i18n.changeLanguage(nextLanguage)

  return [
    {
      currentLanguage,
      nextLanguage
    },
    toggleLanguage
  ] as PreferredLanguageReturnType
}
