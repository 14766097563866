import {
  Input as MuiInput,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormHelperText as MuiFormHelperText
} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

export const Input = styled(MuiInput)(({ theme }) => ({
  background: alpha(theme.palette.common.white, 0.1),
  borderRadius: theme.borderRadius.small,
  color: theme.palette.common.white,
  padding: theme.spacing(3),
  border: '1px solid transparent',
  '&:hover': {
    border: `1px solid ${theme.palette.colors.gray}`
  },
  '&.Mui-focused': {
    border: '1px solid #F1F1F1'
  },
  '& > input': {
    padding: 0,
    "&[type='date']": {
      colorScheme: 'dark'
    },
    '&::placeholder': {
      color: theme.palette.colors.placeholder,
      opacity: 1
    }
  },
  '&.Mui-error': {
    border: `2px solid ${theme.palette.colors.dmRed}`,
    background: alpha(theme.palette.colors.error, 0.05)
  }
}))

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(6)
  }
}))

export const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  color: theme.palette.common.white,
  '&.Mui-error': {
    color: theme.palette.common.white
  },
  '&.Mui-focused': {
    color: theme.palette.common.white
  },
  fontWeight: 600,
  transform: 'translateY(-12px)',
  ...theme.customTypography.h4Size
}))

export const FormHelperText = styled(MuiFormHelperText)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  '&.Mui-error': {
    color: theme.palette.colors.dmRed
  }
}))
