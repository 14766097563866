import { JackpotPrize } from 'types'

import { TFunction } from 'react-i18next'

export function getTranslationsFromJackpotPrize(prize: JackpotPrize, t: TFunction) {
  let { title, scope, tagline, description } = prize

  const translationTitle = `pages.jackpotPrize.jackpotInfo.${title}.title`
  const translationScope = `pages.jackpotPrize.jackpotInfo.${title}.scope`
  const translationTagline = `pages.jackpotPrize.jackpotInfo.${title}.tagline`
  const translationDescription = `pages.jackpotPrize.jackpotInfo.${title}.description`
  const translatedTitle = t(translationTitle)
  const translatedTagline = t(translationTagline)
  const translatedScope = t(translationScope)
  const translatedDescription = t(translationDescription)

  if (translatedTitle !== translationTitle) {
    title = translatedTitle
    tagline = translatedTagline
    scope = translatedScope
    description = translatedDescription
  }

  return { title, tagline, scope, description }
}
