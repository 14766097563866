import { FAVORITE_PERKS } from 'slices/constants'

import { RootState } from 'reduxStore'

export const getFavoritePerks = (state: RootState) => state[FAVORITE_PERKS].favoritePerks

export const getIsFavoritePerksLoading = (state: RootState) =>
  state[FAVORITE_PERKS].isLoading

export const getIsFavoritePerksError = (state: RootState) => state[FAVORITE_PERKS].error
export const getIsPostFavoritePerksError = (state: RootState) =>
  state[FAVORITE_PERKS].postFavoritePerksError
