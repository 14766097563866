import { createSlice } from '@reduxjs/toolkit'

import {
  UserSettingsV2,
  UserSettingsV3,
  UserSettingsV4,
  VerifyEmailResponse,
  Whoami
} from 'types'

import { requestState, RequestState, WHOAMI } from 'slices/constants'

import { onboardingModalSeenThunk } from 'slices/onboarding'
import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import {
  fetchWhoamiThunk,
  updateUserSettingsV2Thunk,
  updateUserSettingsV3Thunk,
  updateUserSettingsV4Thunk,
  verifyEmailThunk
} from './actions'

export interface WhoamiState extends RequestState {
  whoami: Whoami | null
  isUpdateUserSettingsLoading: boolean
  updateUserSettingsError: unknown
  updateUserSettingsV4Error: (UserSettingsV4 & { non_field_errors?: string[] }) | null
}

const initialState: WhoamiState = {
  whoami: null,
  isUpdateUserSettingsLoading: false,
  updateUserSettingsError: null,
  updateUserSettingsV4Error: null,
  ...requestState
}

export const whoamiSlice = createSlice({
  name: WHOAMI,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhoamiThunk.fulfilled, (state, action) => {
        state.whoami = action.payload as Whoami
        handleRequestFulfilled(state)
      })
      .addCase(fetchWhoamiThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(onboardingModalSeenThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(onboardingModalSeenThunk.fulfilled, (state, action) => {
        state.whoami = action.payload as Whoami
        handleRequestFulfilled(state)
      })
      .addCase(updateUserSettingsV2Thunk.pending, (state) => {
        state.isUpdateUserSettingsLoading = true
      })
      .addCase(updateUserSettingsV2Thunk.fulfilled, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = null

        const { language, messages_frequency, birthday, display_name } =
          action.payload as UserSettingsV2
        if (state.whoami) {
          if (language) state.whoami.language = language
          if (messages_frequency) state.whoami.sms_frequency = messages_frequency
          // TODO - properly format this on the backend to match the return style of the whoami endpoint YYYY-MM-DD
          if (birthday)
            state.whoami.birthday = new Date(birthday!)
              .toISOString()
              .split('T')[0]
              .toString()
          if (display_name) state.whoami.display_name = display_name
        }
      })
      .addCase(updateUserSettingsV2Thunk.rejected, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = action.error
      })
      .addCase(updateUserSettingsV3Thunk.pending, (state) => {
        state.isUpdateUserSettingsLoading = true
      })
      .addCase(updateUserSettingsV3Thunk.fulfilled, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = null

        const { language, sms_frequency, birthday, display_name } =
          action.payload as UserSettingsV3

        if (state.whoami) {
          if (language) state.whoami.language = language
          if (sms_frequency) state.whoami.sms_frequency = sms_frequency
          // TODO - properly format this on the backend to match the return style of the whoami endpoint YYYY-MM-DD
          if (birthday)
            state.whoami.birthday = new Date(birthday!)
              .toISOString()
              .split('T')[0]
              .toString()
          if (display_name) state.whoami.display_name = display_name
        }
      })
      .addCase(updateUserSettingsV3Thunk.rejected, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = action.error
      })
      .addCase(updateUserSettingsV4Thunk.pending, (state) => {
        state.isUpdateUserSettingsLoading = true
      })
      .addCase(updateUserSettingsV4Thunk.fulfilled, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = null
        const { birthday, ...restOfPayload } = action.payload as UserSettingsV4
        if (state.whoami) {
          // The BE will respond with ONLY the changed fields so it is safe to override here
          const newUpdate = { ...state.whoami, ...restOfPayload }
          // TODO: - properly format this on the backend to match the return style of the whoami endpoint YYYY-MM-DD
          if ('birthday' in action.payload) {
            newUpdate.birthday = birthday
              ? new Date(birthday).toISOString().split('T')[0].toString()
              : ''
          }
          state.whoami = newUpdate
        }
      })
      .addCase(updateUserSettingsV4Thunk.rejected, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        try {
          const parsedObj = JSON.parse(action.payload as string)
          state.updateUserSettingsV4Error =
            parsedObj as WhoamiState['updateUserSettingsV4Error']
        } catch (_error) {
          state.updateUserSettingsV4Error = null
        }
      })
      .addCase(verifyEmailThunk.pending, (state) => {
        state.isUpdateUserSettingsLoading = true
      })
      .addCase(verifyEmailThunk.fulfilled, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = null
        const { is_email_verified } = action.payload as VerifyEmailResponse
        if (state.whoami && is_email_verified) {
          const newUpdate = { ...state.whoami }
          newUpdate.is_email_verified = is_email_verified
          state.whoami = newUpdate
        }
      })
      .addCase(verifyEmailThunk.rejected, (state, action) => {
        state.isUpdateUserSettingsLoading = false
        state.updateUserSettingsError = action.error
      })
  }
})

export default whoamiSlice.reducer
