import { InputProps as MuiInputProps } from '@mui/material'

import { AppIconTypes } from 'types'

import AppIcon from 'components/icons/AppIcon'

import * as S from './Input.styles'

export interface InputProps extends MuiInputProps {
  label: string
  placeholder: string
  errorText?: string
  name: string
}

export default function Input({
  label,
  placeholder,
  errorText,
  name,
  ...otherInputProps
}: InputProps) {
  const hasError = Boolean(errorText)
  return (
    <S.FormControl
      error={hasError}
      variant="outlined"
      sx={{
        width: otherInputProps?.fullWidth ? '100%' : 'auto'
      }}
    >
      <S.InputLabel htmlFor={`Input-${name}`} shrink>
        {label}
      </S.InputLabel>
      <S.Input
        id={`Input-${name}`}
        disableUnderline
        placeholder={placeholder}
        endAdornment={hasError ? <AppIcon type={AppIconTypes.circleAlert} /> : undefined}
        name={name}
        {...otherInputProps}
      />
      {hasError && <S.FormHelperText>{errorText}</S.FormHelperText>}
    </S.FormControl>
  )
}
