import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import img_en from 'assets/images/onboarding-goal-progress-en.svg'
import img_es from 'assets/images/onboarding-goal-progress-es.svg'

import { PreferredLanguage } from 'types'

import { StepContext } from 'pages/onboarding'

import { Step } from '../../components'
import { StyledOnboardingStepTitle, StyledOnboardingStepBody } from '../../components'

import { StyledGoalProgressImage } from './styles'

import { useTypographyVariantHook } from '..'

const StepGoals = () => {
  const { t, i18n } = useTranslation()
  const { isOnSmallDevice, isOnTinyDevice } = useContext(StepContext)
  const { titleVariant, bodyVariant } = useTypographyVariantHook()

  const isSpanish = i18n.language === PreferredLanguage.spanish

  return (
    <Step>
      <StyledGoalProgressImage
        src={isSpanish ? img_es : img_en}
        alt="onboarding-goal-progress"
        data-testid={`OnboardingGoalProgressLogo-${isSpanish ? 'ES' : 'EN'}`}
        isOnSmallDevice={isOnSmallDevice}
        isOnTinyDevice={isOnTinyDevice}
      />
      <StyledOnboardingStepTitle
        variant={titleVariant}
        isOnTinyDevice={isOnTinyDevice}
        data-testid="OnboardingGoalStep-Title"
      >
        {t('pages.onboarding.goalStep.title')}
      </StyledOnboardingStepTitle>
      <StyledOnboardingStepBody
        variant={bodyVariant}
        isOnSmallDevice={isOnSmallDevice}
        data-testid="OnboardingGoalStep-Body"
      >
        <Trans
          components={{ bold: <b /> }}
          i18nKey="pages.onboarding.goalStep.body"
          t={t}
        />
      </StyledOnboardingStepBody>
    </Step>
  )
}

export default StepGoals
