// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { Brand } from 'types'

import { BRANDS, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchUserStoreBrandsThunk } from '.'

export const brandsAdapter = createEntityAdapter<Brand>({
  selectId: (brand) => brand.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const brandsSlice = createSlice({
  name: BRANDS,
  initialState: brandsAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserStoreBrandsThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserStoreBrandsThunk.rejected, (state, action) => {
      handleRequestRejected(state, action)
    })
    builder.addCase(fetchUserStoreBrandsThunk.fulfilled, (state, action) => {
      brandsAdapter.setAll(state, action.payload)
      handleRequestFulfilled(state)
    })
  }
})

export default brandsSlice.reducer
