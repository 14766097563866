import { Typography } from '@mui/material'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import PageWithSubheader from 'layouts/pageWithSubheader'

import useAppDispatch from 'hooks/useAppDispatch'

import { useNavigation } from 'hooks/useNavigation'

import { fetchDistributionPointsThunk, getDistributionPoints } from 'slices/admin'

import { TeamLeaderPaths } from 'utils/helpers'

import { StyledCelebrateTeamSubheaderCTA } from './styles'

import CelebrateForm from './celebrateForm'

export default function CelebrateTeam() {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const { t } = useTranslation()
  const distributionPoints = useSelector(getDistributionPoints)

  useEffect(() => {
    dispatch(fetchDistributionPointsThunk())
  }, [])

  const onBack = () => navigation.push(TeamLeaderPaths.teamMembers)
  const goToCelebrationOutbox = () =>
    navigation.push(TeamLeaderPaths.teamMemberCelebrateOutbox)

  const subheaderContent = (
    <>
      {distributionPoints !== null && (
        <>
          <Typography variant="h1">{distributionPoints}</Typography>
          <Typography variant="body1" component="small">
            {t('pages.admin.celebrateTeam.header.title')}
          </Typography>
        </>
      )}
      <StyledCelebrateTeamSubheaderCTA
        variant="contained"
        color="secondary"
        onClick={goToCelebrationOutbox}
      >
        {t('pages.admin.celebrateTeam.header.cta')}
      </StyledCelebrateTeamSubheaderCTA>
    </>
  )

  return (
    <PageWithSubheader
      subheaderContent={subheaderContent}
      showBackButton={false}
      backButtonProps={{ onBack }}
    >
      <CelebrateForm />
    </PageWithSubheader>
  )
}
