import { PaginatedSchedules, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { SCHEDULES } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

export const fetchAllSchedulesThunk = authenticatedThunkCreator<
  PaginatedSchedules,
  FetchCachedThunkProps
>(
  `${SCHEDULES}/fetchAllSchedulesThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, SCHEDULES)
)
