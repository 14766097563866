import { Card, styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import PageWithSubheader from 'layouts/pageWithSubheader'

import JackpotHeaderTitle, { JackpotHeaderTitleStyleProps } from './jackpotHeaderTitle'
import JackpotSection, { JackpotSectionStyleProps } from './jackpotSection'

//Jackpot
export const StyledJackpot = styled(PageWithSubheader)({
  '.LayoutPageWithSubheader__content': {
    padding: 0
  }
})

// JackpotCurrentDrawing
export const StyledCurrentDrawing = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

export const StyledCurrentDrawingTitle = styled(JackpotHeaderTitle)(({ theme }) => ({
  color: theme.branding.primaryColor
}))

export const StyledCurrentDrawingIndex = styled(JackpotHeaderTitle)(({ theme }) => ({
  color: theme.branding.subTextColor
}))

// JackpotHeaderTitle
export const StyledJackpotHeaderTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'h2' | 'p'>
  >
>(Typography, {
  shouldForwardProp: (prop) => prop !== 'moreSpace'
})<JackpotHeaderTitleStyleProps>(({ moreSpace, theme }) => ({
  margin: theme.spacing(0, 0, 4),
  fontWeight: 'bold',
  ...(moreSpace && { paddingLeft: theme.spacing(3) })
}))

// JackpotInfo
export const StyledJackpotInfo = styled(JackpotSection)(({ theme }) => ({
  backgroundColor: theme.branding.cardColor
}))

export const StyledJackpotInfoTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'h2'>
  >
>(Typography)(({ theme }) => ({
  letterSpacing: '-0.01em',
  margin: 0,
  marginBottom: theme.spacing(3)
}))

export const StyledJackpotInfoContent = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  margin: 0,
  marginBottom: theme.spacing(5),
  whiteSpace: 'pre-line'
}))

// JackpotSection
export const StyledJackpotSection = styled('section', {
  shouldForwardProp: (prop) => prop !== 'moreSpace'
})<JackpotSectionStyleProps>(({ moreSpace, theme }) => ({
  padding: theme.spacing(5),
  '&:last-of-type': {
    paddingBottom: theme.spacing(25)
  },
  ...(moreSpace && { padding: theme.spacing(5, 8) })
}))

// JackpotSubheader
export const StyledJackpotSubheaderHead = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '& svg > path': {
    fill: theme.branding.secondaryColor
  }
}))

export const StyledJackpotSubheaderHeadTitle = styled(Typography)(({ theme }) => ({
  fontSize: '72px',
  lineHeight: '72px',
  color: theme.branding.secondaryColor,
  marginRight: theme.spacing(1)
}))

export const StyledJackpotSubheaderBody = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4)
}))

// JackpotPrizeItem
const SharedPrizeItemCardStyles = styled(Card)(({ theme }) => ({
  minHeight: '135px',
  padding: theme.spacing(3)
}))

const SharedPrizeItemImgStyles = styled('img')({
  flexShrink: 0,
  maxWidth: '64px',
  maxHeight: '64px',
  height: 'auto',
  width: '100%',
  objectFit: 'cover'
})

export const StyledJackpotPrizeItem = styled(SharedPrizeItemCardStyles)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  marginRight: theme.spacing(3),
  height: '100%',
  '@media only screen and (max-device-width: 374px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  }
}))

export const StyledJackpotWinnerItem = styled(SharedPrizeItemCardStyles)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  marginRight: theme.spacing(3),
  height: '100%',
  '@media only screen and (max-device-width: 374px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  }
}))

export const StyledJackpotPrizeItemTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))

export const StyledJackpotPrizeItemScope = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

export const StyledJackpotPrizeItemCash = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  color: theme.branding.primaryColor
}))

export const StyledJackpotPrizeItemDetails = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: '18px',
  color: theme.branding.subTextColor
}))

export const StyledJackpotWinnerItemDetails = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  lineHeight: '18px',
  color: theme.branding.darkTextColor
}))

export const StyledJackpotCongratulations = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  lineHeight: '36px',
  color: theme.branding.darkTextColor
}))

export const StyledJackpotPrizeItemImg = styled(SharedPrizeItemImgStyles)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1)
  })
)

export const StyledJackpotNextPrizeItem = styled(StyledJackpotPrizeItem)({
  justifyContent: 'flex-start',
  minHeight: 'revert' // Undo composed style here
})

export const StyledJackpotNextPrizeItemImg = styled(SharedPrizeItemImgStyles)(
  ({ theme }) => ({
    marginRight: theme.spacing(3)
  })
)

export const StyledJackpotNextPrizeItemContent = styled('div')(({ theme }) => ({
  color: theme.branding.primaryColor,
  fontWeight: 'bold'
}))

export const StyledJackpotNextPrizeItemTagline = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '14px',
  color: theme.branding.subTextColor
}))

export const StyledJackpotNextPrizeItemTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

export const StyledJackpotEmptyPrizeItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  padding: theme.spacing(1)
}))

export const StyledJackpotEmptyPrizeItemDescription = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.branding.subTextColor,
  padding: theme.spacing(2, 1, 0)
}))

export const StyledJackpotEmptyWinnerItemDescription = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.branding.subTextColor,
  padding: theme.spacing(2, 1, 0)
}))
