import { useTranslation } from 'react-i18next'

import { DefaultBaseModalProps } from 'components/modals'
import { StyledBaseModalButton } from 'components/modals/baseModal/styles'
import { CenteredItemContainer } from 'components/styled/flexCentered'

import useDynamicHeight from 'hooks/useDynamicHeight'

import {
  StyledConfirmationAmount,
  StyledConfirmationValuesContainer,
  StyledPerkBottomModal
} from '../styles'

export interface ConfirmationModalProps extends DefaultBaseModalProps {
  onRedeemPerk: () => void
  amountString: string
  points: number
  isRedeemingPerk: boolean
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  onRedeemPerk,
  amountString,
  points,
  isRedeemingPerk
}: ConfirmationModalProps) => {
  const [height, measuredRef] = useDynamicHeight()
  const { t } = useTranslation()

  const footer = (
    <>
      <StyledBaseModalButton onClick={onClose} variant="outlined" color="secondary">
        {t('pages.perk.confirmationModal.cancelBtn')}
      </StyledBaseModalButton>
      <StyledBaseModalButton onClick={onRedeemPerk} variant="contained" color="primary" disabled={isRedeemingPerk}>
        {t('pages.perk.confirmationModal.redeemBtn')} 🎉
      </StyledBaseModalButton>
    </>
  )

  const content = (
    <CenteredItemContainer>
      <StyledConfirmationValuesContainer>
        <StyledConfirmationAmount>{amountString}</StyledConfirmationAmount>
        <span>
          <b>{t('pages.perk.confirmationModal.value')}</b>: {points} pts
        </span>
      </StyledConfirmationValuesContainer>
    </CenteredItemContainer>
  )

  return (
    <StyledPerkBottomModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('pages.perk.confirmationModal.title')}
      content={content}
      footer={footer}
      height={height as number}
      ref={measuredRef as (node: HTMLDivElement) => void}
    />
  )
}

export default ConfirmationModal
