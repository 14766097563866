import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Placeholder, { JackpotTicketLineItemLoader } from 'placeholder'

import useAppDispatch from 'hooks/useAppDispatch'

import {
  fetchJackpotCurrentTicketInfoThunk,
  fetchJackpotTicketHistoryThunk,
  getCurrentTicketInfoHasLoaded,
  getJackpotCurrentTicketCount,
  getSortedTicketsHistory,
  getTicketsHistoryHasLoaded
} from 'slices/jackpot'

import {
  JackpotHistoryContainer,
  JackpotHistoryPage,
  JackpotHistorySupportMessage
} from './styles'

import { JackpotTicketLineItem } from './JackpotTicketLineItem/JackpotTicketLineItem'

export const JackpotHistory = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      fetchJackpotCurrentTicketInfoThunk({
        getCachedResults: true
      })
    )
    dispatch(
      fetchJackpotTicketHistoryThunk({
        getCachedResults: true
      })
    )
  }, [])

  const hasTicketsHistoryLoaded = useSelector(getTicketsHistoryHasLoaded)
  const hasCurrentTicketInfoLoaded = useSelector(getCurrentTicketInfoHasLoaded)

  const ticketTransactions = useSelector(getSortedTicketsHistory)
  const totalTickets = useSelector(getJackpotCurrentTicketCount)

  const { lineItems } = ticketTransactions.reduce(
    (acc, ticketTransaction) => {
      const event = t(`pages.jackpotHistory.lineItemTypes.${ticketTransaction.source}`)

      const lineItem = (
        <JackpotTicketLineItem
          id={ticketTransaction.id}
          key={ticketTransaction.id}
          date={ticketTransaction.created}
          eventName={event}
          numTickets={ticketTransaction.amount}
          total={acc.currentTotal}
        />
      )
      acc.currentTotal -= ticketTransaction.amount
      acc.lineItems.push(lineItem)
      return acc
    },
    { lineItems: [] as ReactElement[], currentTotal: totalTickets }
  )

  return (
    <JackpotHistoryPage
      altHeader
      title={t('pages.jackpotHistory.title')}
      wrapperClass={'jackpot-history-ledger'}
      data-testid="jackpot-history-page"
    >
      <Placeholder
        isLoading={!(hasTicketsHistoryLoaded && hasCurrentTicketInfoLoaded)}
        loader={JackpotTicketLineItemLoader}
        amount={8}
      >
        {!lineItems.length ? (
          t('pages.jackpotHistory.noTickets')
        ) : (
          <>
            <JackpotHistoryContainer data-testid="jackpot-history-container">
              {lineItems}
            </JackpotHistoryContainer>
            <JackpotHistorySupportMessage>
              {t('pages.jackpotHistory.reachOutForMore')}
            </JackpotHistorySupportMessage>
          </>
        )}
      </Placeholder>
    </JackpotHistoryPage>
  )
}

export default JackpotHistory
