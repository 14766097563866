import Page from 'layouts/page'

import withStoreDataRefresh from 'hoc/withStoreDataRefresh'

import HomeGoalProgressSection from './sections/goalProgressSection'
import HomeHelpSection from './sections/helpSection'
import HomeRewardsSection from './sections/rewardsSection'
import BirthdayReminder from './BirthdayReminder'

const Home = () => {

  return (
    <Page>
      <HomeGoalProgressSection />
      <HomeRewardsSection />
      <HomeHelpSection />
      <BirthdayReminder />
    </Page>
  )
}

export default withStoreDataRefresh(Home)
