// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Lottie from 'lottie-react'
import { Colors } from 'theme'

import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useGlobalModal from 'hooks/useGlobalModal'

import * as S from './useOpenOnarollDailyResponseModal.styles'

import correctAnswerAnimation from './correctAnswerAnimation.json'
import wrongAnswerAnimation from './wrongAnswerAnimation.json'

interface CorrectAnswerProps {
  isCorrect: true
  isTrivia: boolean
  points: number
}

interface IncorrectAnswerProps {
  isCorrect: false
  correctAnswer: string
}

export type UseOpenOnarollDailyResponseModalProps =
  | CorrectAnswerProps
  | IncorrectAnswerProps

const T_PREFIX = 'modals.global.implementations.onarollDaily.answeredResponse'

export default function useOpenOnarollDailyResponseModal() {
  const { openGlobalModal } = useGlobalModal()
  const { t } = useTranslation()

  const openModal = useCallback((props: UseOpenOnarollDailyResponseModalProps) => {
    const { isCorrect } = props
    const animation = isCorrect ? correctAnswerAnimation : wrongAnswerAnimation

    let translationPrefix = T_PREFIX
    if (isCorrect) {
      translationPrefix = translationPrefix.concat('.correct')
      translationPrefix = translationPrefix.concat(
        props.isTrivia ? '.trivia' : '.non-trivia'
      )
    } else {
      translationPrefix = translationPrefix.concat('.incorrect')
    }

    const titleI18nKey = `${translationPrefix}.title`
    const subtitleI18nKey = `${translationPrefix}.subtitle`

    openGlobalModal({
      title: null,
      body: (
        <>
          <S.Card>
            <S.LottieAnimationContainer>
              <Lottie animationData={animation} />
            </S.LottieAnimationContainer>
            <S.ResponseHeading>{t(titleI18nKey)}</S.ResponseHeading>
            <S.ResponseText>
              <Trans
                i18nKey={subtitleI18nKey}
                components={{ span: <span /> }}
                values={{ value: isCorrect ? props.points : props.correctAnswer }}
              />
            </S.ResponseText>
          </S.Card>
        </>
      ),
      ctaProps: {
        children: t(`${T_PREFIX}.ctaText`)!,
        fullWidth: true,
        style: {
          backgroundColor: Colors.purple,
          color: Colors.white,
          borderColor: Colors.purple
        }
      },
      modalType: 'bottom',
      displayCloseButtonInHeader: false
    })
  }, [])

  return openModal
}
