import { Box, Slide, IconButton } from '@mui/material'

import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'

import { ReactComponent as CaretLeftIcon } from 'assets/icons/AppIcons/CaretLeftIcon.svg'
import { ReactComponent as CaretRightIcon } from 'assets/icons/AppIcons/CaretRightIcon.svg'

import useAppDispatch from 'hooks/useAppDispatch'

import {
  goBack,
  goNext,
  goSwipe,
  getPageIndex,
  getIsOpen
} from 'slices/storeSwitcher'
import { fetchStoresThunk, storesSelectors } from 'slices/stores'

import { StyledContainer, StyledStoreSwitcherGrid } from './styles'

export function SwipeableStoreSwitcher() {
  const dispatch = useAppDispatch()
  const stores = useSelector(storesSelectors.selectAll)
  const storeIndex = useSelector(getPageIndex)
  const isOpen = useSelector(getIsOpen)

  const containerRef = useRef(null)

  useEffect(() => {
    dispatch(
      fetchStoresThunk({
        getCachedResults: true
      })
    )
  }, [])

  if (!stores?.length || stores.length <= 1 || !isOpen) {
    return null
  }

  const lastIndex = stores.length - 1

  const onBack = () => {
    dispatch(goBack(lastIndex))
  }

  const onNext = () => {
    dispatch(goNext(lastIndex))
  }

  const onSwipe = (index: number) => {
    dispatch(goSwipe(index))
  }

  return (
    <StyledContainer ref={containerRef}>
      <Slide in={isOpen} container={containerRef.current} appear={false}>
        <StyledStoreSwitcherGrid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton onClick={onBack} size="small">
            <CaretLeftIcon />
          </IconButton>
          <SwipeableViews
            index={storeIndex}
            containerStyle={{ textAlign: 'center' }}
            onChangeIndex={onSwipe}
          >
            {stores.map(({ id, name }) => (
              <Box key={id}>{name}</Box>
            ))}
          </SwipeableViews>
          <IconButton onClick={onNext} size="small">
            <CaretRightIcon />
          </IconButton>
        </StyledStoreSwitcherGrid>
      </Slide>
    </StyledContainer>
  )
}

export default SwipeableStoreSwitcher
