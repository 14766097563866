import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ListChildComponentProps } from 'react-window'

import { ReactComponent as SendIcon } from 'assets/icons/AppIcons/SendIcon.svg'

import { TeamMemberV2 } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import { AdminContainer } from 'components/AdminContainer'
import InfiniteList from 'components/InfiniteList'
import InfoNote from 'components/infoNote'
import Search from 'components/search'
import Tabs from 'components/tabs'

import { useNavigation } from 'hooks/useNavigation'
import { usePrevious } from 'hooks/usePrevious'

import {
  fetchAllTeamMembersThunk,
  getAllActiveTeamMembers,
  getAllPendingTeamMembers,
  getTeamMembersLoading,
  getTeamMembersResponse,
  getTeamMembersSearch,
  teamMemberSelectors
} from 'slices/admin'
import { getWhoami } from 'slices/whoami'

import { debounceFetch, RootPaths, TeamLeaderPaths } from 'utils/helpers'

import { StyledFloatingSendPointsButton } from './styles'

import TeamMemberItem from './teamMemberItem'

export default function TeamMembers() {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>(
    useSelector(getTeamMembersSearch)
  )
  const previousSearchValue = usePrevious<string>(searchValue, '')

  const allTeamMembersIsLoading = useSelector(getTeamMembersLoading)
  const allTeamMembersResponse = useSelector(getTeamMembersResponse)
  const allTeamMembers = useSelector(teamMemberSelectors.selectAll)
  const allActiveTeamMembers = useSelector(getAllActiveTeamMembers)
  const allPendingTeamMembers = useSelector(getAllPendingTeamMembers)
  const whoami = useSelector(getWhoami)

  const activeTmCount = useMemo(() => allActiveTeamMembers.length, [allActiveTeamMembers])
  const pendingTmCount = useMemo(
    () => allPendingTeamMembers.length,
    [allPendingTeamMembers]
  )

  const getFilters = () => ({
    // aggregate all filters here
    active: 'true'
  })

  useEffect(() => {
    const queryParams = { ...getFilters(), search: searchValue }
    const params = { isInitial: true, queryParams }
    // listen to any filter/search changes and fetch new data from BE
    if (previousSearchValue !== searchValue) {
      // If we have changed the search value - then debounce the request
      debounceFetch(
        dispatch,
        fetchAllTeamMembersThunk as typeof fetchAllTeamMembersThunk,
        params
      )
    } else {
      dispatch(fetchAllTeamMembersThunk(params))
    }
  }, [searchValue])

  const hasNoTeamMembers = !allTeamMembers && !allTeamMembersIsLoading
  if (!allTeamMembersResponse || hasNoTeamMembers) return null

  const goToForm = (member?: TeamMemberV2) => {
    if (member) {
      const base = TeamLeaderPaths.teamMembers
      const memberPath = `${base}/${member.id}`
      const path = member?.onboarded_at ? `${memberPath}/view` : `${memberPath}/edit`
      navigation.push(path)
    }
  }

  const goToSendPoints = () => {
    navigation.push(TeamLeaderPaths.teamMemberCelebrate)
  }

  const onSearchChange = (val: string) => {
    setSearchValue(val)
  }

  const onSearchClear = () => {
    setSearchValue('')
  }

  const renderRow = ({
    index,
    style,
    filterType
  }: ListChildComponentProps & { filterType: 'active' | 'pending' }) => {
    let list = allTeamMembers
    if (filterType === 'active') {
      list = allActiveTeamMembers
    } else if (filterType === 'pending') {
      list = allPendingTeamMembers
    }
    const teamMember = list[index]
    if (!teamMember?.id) return null
    style = { ...style }
    return (
      <TeamMemberItem
        key={teamMember.id}
        teamMember={teamMember}
        onSelectItem={goToForm}
        style={style}
      />
    )
  }

  const nextPage = allTeamMembersResponse?.next
  const loadNextPage = () =>
    dispatch(
      fetchAllTeamMembersThunk({
        queryParams: { next: true, ...getFilters(), search: searchValue }
      })
    )

  return (
    <>
      <StyledFloatingSendPointsButton
        getTopOffset={(height) => height * 0.81}
        getLeftOffset={(width) => width - 70}
        title="Send points"
        onClick={goToSendPoints}
      >
        <SendIcon />
      </StyledFloatingSendPointsButton>
      <AdminContainer
        headerTitle={
          whoami
            ? t('pages.admin.team.adminContainer.headerTitle.custom', {
                name: whoami?.display_name
              })
            : t('pages.admin.team.adminContainer.headerTitle.default')
        }
        filters={null}
        containerProps={{
          showBackButton: true,
          themeType: 'neutral',
          backButtonProps: {
            onBack: () => navigation.push(RootPaths.home)
          }
        }}
        search={
          <Search
            condensed
            onChange={onSearchChange}
            onClear={onSearchClear}
            value={searchValue}
          />
        }
      >
        <Tabs
          headerLabels={[
            t('pages.admin.team.tabs.headers.active.text', { count: activeTmCount }),
            t('pages.admin.team.tabs.headers.pending.text', { count: pendingTmCount })
          ]}
        >
          <div>
            <InfoNote
              text={t('pages.admin.team.tabs.content.active.infoNote', {
                count: activeTmCount
              })}
            />
            <InfiniteList<TeamMemberV2>
              hasNextPage={!!nextPage}
              isNextPageLoading={allTeamMembersIsLoading}
              loadNextPage={loadNextPage}
              items={allActiveTeamMembers}
              renderRow={(props) => renderRow({ ...props, filterType: 'active' })}
            />
          </div>
          <div>
            <InfoNote
              text={t('pages.admin.team.tabs.content.pending.infoNote', {
                count: pendingTmCount
              })}
            />
            <InfiniteList<TeamMemberV2>
              hasNextPage={!!nextPage}
              isNextPageLoading={allTeamMembersIsLoading}
              loadNextPage={loadNextPage}
              items={allPendingTeamMembers}
              renderRow={(props) => renderRow({ ...props, filterType: 'pending' })}
            />
          </div>
        </Tabs>
      </AdminContainer>
    </>
  )
}
