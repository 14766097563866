import { useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import OnboardingCoinsTicket from 'assets/images/onboarding-coins-ticket.svg'

import { StepContext } from 'pages/onboarding'

import {
  Step,
  StyledOnboardingStepBody,
  StyledOnboardingStepTitle
} from '../../components'

import { StyledOnboardingJackpotImage } from './styles'

import { useTypographyVariantHook } from '..'

const StepJackpot = () => {
  const { t } = useTranslation()
  const { isOnSmallDevice } = useContext(StepContext)
  const { titleVariant, bodyVariant } = useTypographyVariantHook()

  return (
    <Step>
      <StyledOnboardingJackpotImage
        src={OnboardingCoinsTicket}
        data-testid="OnboardingJackpotStep-Image"
      />
      <StyledOnboardingStepTitle
        variant={titleVariant}
        data-testid="OnboardingJackpotStep-Title"
      >
        {t('pages.onboarding.jackpotStep.title')}
      </StyledOnboardingStepTitle>
      <StyledOnboardingStepBody
        variant={bodyVariant}
        isOnSmallDevice={isOnSmallDevice}
        data-testid="OnboardingJackpotStep-Body"
      >
        <Trans
          components={{ bold: <b /> }}
          i18nKey="pages.onboarding.jackpotStep.body"
          t={t}
        />
      </StyledOnboardingStepBody>
    </Step>
  )
}

export default StepJackpot
