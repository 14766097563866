import { Colors } from 'theme'

import { useContext, useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'

import { AnimatedContent } from 'components/AnimatedContent'
import { VerticalAnimationDirection } from 'components/AnimatedContent/utils'

import { sliderStyles } from './styles'

import { StepContext } from '..'
import { StepGoals } from './StepGoals'
import { StepJackpot } from './StepJackpot'
import { StepLanguage } from './StepLanguage'
import { StepPerks } from './StepPerks'
import { StepTerms } from './StepTerms'

export interface StepsContentProps {
  hasStarted: boolean
  activePageIndex: number
  onSwipe: (index: number) => void
}

const StepsContent = ({ hasStarted, activePageIndex, onSwipe }: StepsContentProps) => {
  const { isOnSmallDevice, isOnTinyDevice } = useContext(StepContext)

  const maxPerksHeight = useMemo(() => {
    if (isOnSmallDevice && !isOnTinyDevice) return 350
    if (isOnTinyDevice) return 250
    return 400
  }, [isOnSmallDevice, isOnTinyDevice])

  return !hasStarted ? (
    <AnimatedContent
      verticalAnimationDirection={VerticalAnimationDirection.DOWN}
      motionDivProps={{ style: { zIndex: 1, backgroundColor: Colors.black } }}
    >
      <StepLanguage />
    </AnimatedContent>
  ) : (
    <SwipeableViews
      enableMouseEvents
      index={activePageIndex}
      onChangeIndex={onSwipe}
      slideStyle={sliderStyles}
      data-testid="OnboardingSteps-SwipeableViews"
    >
      <AnimatedContent verticalAnimationDirection={VerticalAnimationDirection.UP}>
        <StepGoals />
      </AnimatedContent>
      <div
        style={{
          maxHeight: maxPerksHeight
        }}
      >
        <StepPerks />
      </div>
      <StepJackpot />
      <StepTerms />
    </SwipeableViews>
  )
}

export default StepsContent
