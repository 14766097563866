import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'reduxStore'

const selectDrawings = (state: RootState) => state.jackpot.drawings

export const getDrawingsLoading = createSelector(
  selectDrawings,
  (drawings) => drawings.isLoading
)

export const getJackpotCurrentDrawing = createSelector(
  selectDrawings,
  (drawings) => drawings.entities[drawings.ids[0]]
)
