import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RewardDetail, RewardInfo } from 'types'

import {
  StyledDenominationForm,
  StyledDenominationFormInput,
  StyledDenominationFormLabel
} from '../styles'

import { isNumber } from '../helpers'

export interface DenominationFormProps {
  error: boolean
  perk: RewardInfo | RewardDetail
  updateAmount: (value: number) => void
  isDollars?: boolean
}

export default function DenominationForm({
  error,
  perk,
  updateAmount,
  isDollars = true
}: DenominationFormProps) {
  const { t } = useTranslation()
  const [amount, setAmount] = useState<string>()

  /**
   * Transforms the value to allow for right to left filling of decimal value.
   * @param value number
   */

  const setTransformedValue = (value: number) => {
    let transformedValue: string | number = value * (isDollars ? 0.01 : 1)
    if (isDollars) {
      transformedValue = transformedValue.toFixed(2)
    } else {
      transformedValue = String(transformedValue)
    }
    if (value === 0) transformedValue = ''

    setAmount(transformedValue)
    if (isNumber(transformedValue)) updateAmount(Number(transformedValue))
  }

  /**
   * Cancels the change event, and ensures that the value is pruned
   * and set correctly when deleting.
   * @param event
   */
  const handleDelete = (event: KeyboardEvent) => {
    if (amount && (event.key === 'Backspace' || event.key === 'Delete')) {
      event.preventDefault()

      let prunedAmount = amount.split('.').join('')
      prunedAmount = prunedAmount.slice(0, prunedAmount.length - 1)

      const baseValue = Number(prunedAmount)
      setTransformedValue(baseValue)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    const baseValue = Number(value.split('.').join(''))
    setTransformedValue(baseValue)
  }

  return (
    <StyledDenominationForm data-testid="denomination-form">
      <StyledDenominationFormLabel htmlFor="denominationForm">
        {t('pages.perk.denominationForm.title', { perkTitle: perk.title })}
      </StyledDenominationFormLabel>
      <StyledDenominationFormInput
        type="number"
        id="denominationForm"
        data-testid="denomination-form-input"
        value={amount}
        onChange={handleChange}
        onKeyDown={handleDelete}
        startAdornment={isDollars ? '$' : ''}
        error={error}
      />
    </StyledDenominationForm>
  )
}
