import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StepContext } from 'pages/onboarding'
import { OnboardingContactUsWidget, Step } from 'pages/onboarding/components'

import Input from 'components/Input'

import { getUserSettingsV4HasError } from 'slices/whoami/selectors'

import * as S from './styles'

const MIN_DATE = '1900-01-01'

type InputEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
const TERM_STEP_PREFIX = 'pages.onboarding.termStep'

export default function StepTerms() {
  const { t } = useTranslation()
  const { firstName, birthday, email, setFirstName, setBirthday, setEmail } =
    useContext(StepContext)
  const userSettingsError = useSelector(getUserSettingsV4HasError)
  const [shouldShowError, setShouldShowError] = useState(false)

  const handleDateChange = (e: InputEvent) => {
    const dateOfBirthValue = e.target.value ? e.target.value : null
    setBirthday(dateOfBirthValue)
  }
  const handleNameChange = (e: InputEvent) => setFirstName(e.target.value)
  const handleEmailChange = (e: InputEvent) => {
    const emailValue = e.target.value ? e.target.value : null
    setEmail(emailValue)
  }

  const handleErrorWidgetClose = () => setShouldShowError(false)

  useEffect(() => {
    setShouldShowError(Boolean(userSettingsError?.non_field_errors))
  }, [userSettingsError])

  if (shouldShowError) {
    return (
      <OnboardingContactUsWidget
        i18nKey="pages.onboarding.errors.updateUserSettings.title"
        onClose={handleErrorWidgetClose}
        messageBody={t('pages.onboarding.errors.updateUserSettings.messageBody')}
      />
    )
  }

  return (
    <Step>
      <S.StepTitle>{t(`${TERM_STEP_PREFIX}.titleFirstLine`)}</S.StepTitle>
      <S.StepTitle>{t(`${TERM_STEP_PREFIX}.titleSecondLine`)}</S.StepTitle>
      <S.InputsWrapper>
        <Input
          name="firstName"
          label={t(`${TERM_STEP_PREFIX}.inputs.firstName.label`)}
          title={t(`${TERM_STEP_PREFIX}.inputs.firstName.label`)}
          placeholder={t(`${TERM_STEP_PREFIX}.inputs.firstName.placeholder`)}
          onChange={handleNameChange}
          value={firstName}
          errorText={userSettingsError?.display_name}
          fullWidth
          required
        />
        <Input
          name="email"
          type="email"
          label={t(`${TERM_STEP_PREFIX}.inputs.email.label`)}
          title={t(`${TERM_STEP_PREFIX}.inputs.email.label`)}
          placeholder={t(`${TERM_STEP_PREFIX}.inputs.email.placeholder`)}
          onChange={handleEmailChange}
          value={email}
          errorText={userSettingsError?.email ? userSettingsError.email : undefined}
          fullWidth
        />
        <S.DateInput
          name="dateOfBirth"
          type="date"
          label={t(`${TERM_STEP_PREFIX}.inputs.dateOfBirth.label`)}
          title={t(`${TERM_STEP_PREFIX}.inputs.dateOfBirth.label`)}
          placeholder={t(`${TERM_STEP_PREFIX}.inputs.dateOfBirth.placeholder`)}
          inputProps={{
            max: new Date().toISOString().split('T')[0].toString(),
            min: MIN_DATE
          }}
          onChange={handleDateChange}
          value={birthday}
          errorText={userSettingsError?.birthday ? userSettingsError.birthday : undefined}
          fullWidth
        />
      </S.InputsWrapper>
    </Step>
  )
}
