import clsx from 'clsx'

import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { RedeemedPerk } from 'types'

import { ItemSubHeading } from 'pages/admin/shared/styles'

import Barcode from 'components/barcode'
import CopyField from 'components/copyField'

import { PerkRedemptionCodeContainer } from './styles'

export interface PerkRedemptionCodeProps {
  barcodeClass?: string
  perk: RedeemedPerk
}

export const hasPerkRedemptionValue = (perk: RedeemedPerk) =>
  Boolean(perk?.fulfilled_text)

export function PerkRedemptionCode({ barcodeClass, perk }: PerkRedemptionCodeProps) {
  const { t } = useTranslation()
  // Once a perk gets redeemed, it will have a fulfilled_text field set
  // So if there is no perk or it has nothing to redeem, don't render
  if (!perk || !hasPerkRedemptionValue(perk)) return null

  const getGiftCardCode = (fulfilled_text: string) => {
    if (fulfilled_text.includes('card_id=') && !fulfilled_text.includes('card_id=None')) {
      return `${t('perks.perkType.gift_card')}: ${fulfilled_text
        .trim()
        .replace('card_id=', '')}`
    }
    return ''
  }

  const getContent = () => {
    // If it has a code, we know we want a barcode (and maybe a pin associated with it)
    if (perk?.code && perk?.can_show_barcode) {
      return (
        <Barcode
          className={clsx(barcodeClass)}
          value={perk.code}
          pin={perk?.pin}
          title={t('pages.perk.redemption.barcode.title')}
          data-testid="PerkRedemptionCodeBarcode"
        />
      )
    } else if (perk?.redeem_url) {
      // If there is no code, then it's most likely a redemption URL that the user needs to manually visit
      return (
        <PerkRedemptionCodeContainer data-testid="PerkRedemptionCodeRedeemUrl">
          <Typography variant="body1">
            <a href={perk.redeem_url} rel="noopener" target="__blank">
              {t('pages.perk.redemption.enjoy')}
            </a>
          </Typography>
        </PerkRedemptionCodeContainer>
      )
    } else {
      let giftCardId = ''
      let serialCode = null

      const index = perk.fulfilled_text.indexOf('serial=')
      if (index != -1) {
        giftCardId = getGiftCardCode(perk.fulfilled_text.substring(0, index))
        serialCode = (
          <CopyField
            textToCopy={perk.fulfilled_text.substring(index).replace('serial=', '')}
          />
        )
      }

      return (
        <PerkRedemptionCodeContainer data-testid="PerkRedemptionCodeFulfilledText">
          <Typography variant="body1">{t('pages.perk.redemption.info')}</Typography>
          {serialCode ? (
            serialCode
          ) : (
            <Typography variant="body1">{perk.fulfilled_text}</Typography>
          )}
          {giftCardId ? (
            <ItemSubHeading variant="subtitle2">{giftCardId}</ItemSubHeading>
          ) : (
            ''
          )}
        </PerkRedemptionCodeContainer>
      )
    }
  }

  return getContent()
}

export default PerkRedemptionCode
