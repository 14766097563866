import { FunctionComponent, Key, useEffect, useRef, useState } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import Placeholder from 'placeholder'
import rowItemLoader from 'placeholder/loaders/rowItemLoader'

import useWindowDimensions from 'hooks/useWindowDimensions'

import { StyledScrollBarConcealer } from './styles'

import { OnarollThunkDispatch } from '../../reduxStore'

export interface InifiteListRowProps {
  index: number
  style: Record<Key, unknown>
}

interface InfiniteListProps<T> {
  hasNextPage: boolean
  isNextPageLoading: boolean
  items: T[]
  itemSize?: number
  loadNextPage: (
    startIndex: number,
    stopIndex: number
  ) => Promise<ReturnType<OnarollThunkDispatch>> | null
  renderRow: FunctionComponent<ListChildComponentProps>
  disabled?: boolean
}

interface InnerDivState {
  element?: HTMLDivElement | null
  topOffset: number
  offsetHeight: number
  scrollbarWidth: number
}

const InfiniteList = <T,>({
  hasNextPage,
  isNextPageLoading,
  items,
  itemSize,
  loadNextPage,
  renderRow: RenderRow,
  disabled = false
}: InfiniteListProps<T>) => {
  const { height } = useWindowDimensions()

  const infListRef = useRef<HTMLDivElement>(null)
  const [innerDiv, setInnerDiv] = useState<InnerDivState>({
    element: null,
    topOffset: 0,
    offsetHeight: 0,
    scrollbarWidth: 0
  })

  // we want to ensure we set the correct values once the child ref gets rendered
  useEffect(() => {
    const container = infListRef?.current?.parentElement as HTMLDivElement
    setInnerDiv({
      element: container,
      topOffset: container?.offsetTop || 0,
      offsetHeight: container?.offsetHeight || 0,
      scrollbarWidth: (container?.offsetWidth || 0) - (container?.clientWidth || 0) || 15
    })
  }, [infListRef])

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems =
    isNextPageLoading || disabled
      ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_startIndex: number, _lastIndex: number): void => null as unknown as void
      : loadNextPage

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length

  const props = {}

  /* 230 is the bottom menu height + the header height of the AdminContainer;
    this may need to change if we implement this in a container  that doesn't have these elements */
  const listHeight = height - innerDiv.topOffset - 230

  // 10 is the space between right edge of container and client
  const concealerWidth = innerDiv.scrollbarWidth + 10

  const renderRowWithPlaceholder = (listChildProps: ListChildComponentProps) => {
    return (
      <Placeholder
        style={listChildProps.style}
        isLoading={!isItemLoaded(listChildProps.index)}
        loader={rowItemLoader}
      >
        <RenderRow {...listChildProps} />
      </Placeholder>
    )
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={15}
    >
      {({ onItemsRendered, ref }) => (
        <>
          <FixedSizeList
            innerRef={infListRef}
            style={{ overflowX: 'hidden' }}
            height={listHeight}
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            itemSize={itemSize || 90}
            width='100%'
            {...props}
          >
            {renderRowWithPlaceholder}
          </FixedSizeList>
          <StyledScrollBarConcealer
            heightoffset={innerDiv.topOffset}
            height={innerDiv.offsetHeight}
            width={concealerWidth}
          />
        </>
      )}
    </InfiniteLoader>
  )
}

export default InfiniteList
