import { styled, Typography } from '@mui/material'

export const StyledHomeSection = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(5)
  }
}))

export const StyledHomeSectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}))

export const StyledHomeSectionSubTitle = styled(Typography)(({ theme }) => ({
  margin: `-${theme.spacing(1)} 0 ${theme.spacing(4)}`
}))
