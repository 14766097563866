// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { startCase } from 'lodash'
import {
  CommonResponse,
  PaginatedResponse,
  SearchFilter,
  FetchWithParamsThunk
} from 'types'

import { MenuItem, Paper, TextField } from '@mui/material'

import { FormEvent, useMemo, useRef, useState } from 'react'
import Autosuggest, {
  ChangeEvent,
  GetSuggestionValue,
  OnSuggestionSelected,
  RenderInputComponent,
  RenderSuggestion,
  ShouldRenderSuggestions
} from 'react-autosuggest'
import { FieldInputProps } from 'react-final-form'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { formatSelectValues, FormSelectOptions } from 'utils/form'
import { debounceFetch } from 'utils/helpers'

import useStyles from './styles'

import { GenericAsyncThunk, RootState, StoreUpdate } from 'reduxStore'

const NONE_LABEL = 'None'
const NULL_VALUE = ''

export interface AutoSuggestProps<T> {
  input?: FieldInputProps<string>['input']
  required?: boolean
  fieldName: string
  initialValue?: T
  filterConstraints?: T
  handleFetchOptions: unknown
  selectFetchedResults: (state: RootState) => T[]
  onSelect?: (value: unknown, name: string) => void
  suggestionsClass?: string
  containerClass?: string
}

const AutoSuggestField = <T extends CommonResponse>({
  input: { onChange },
  required = false,
  initialValue,
  fieldName,
  filterConstraints,
  handleFetchOptions,
  selectFetchedResults,
  onSelect,
  suggestionsClass,
  containerClass
}: AutoSuggestProps<T>) => {
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const results = useSelector(selectFetchedResults)

  const inputRef = useRef(null)

  const options = useMemo(() => {
    let curated =
      !initialValue?.id || results.find((r) => r.id === initialValue.id)
        ? [...results]
        : [...results, initialValue]
    if (filterConstraints) {
      Object.keys(filterConstraints).forEach(
        (key: string) =>
          (curated = curated.filter(
            (result) =>
              result[key as keyof CommonResponse] ===
              filterConstraints[key as keyof CommonResponse]
          ))
      )
    }
    const selectValues = formatSelectValues<T>({
      options: curated,
      getLabel: ({ name = NULL_VALUE }) => name,
      getValue: ({ id = NULL_VALUE }) => id
    })
    return [{ label: NONE_LABEL, value: NULL_VALUE }, ...selectValues]
  }, [results])

  const [inputText, setInputText] = useState<string>(initialValue?.name || NULL_VALUE)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [showOptions, setShowOptions] = useState<boolean>(true)

  const onInputChange = (search: string) => {
    if (search === inputText) {
      return
    }
    if (!search) {
      onSelect && onSelect(search, search)
    }
    debounceFetch(dispatch, handleFetchOptions as GenericAsyncThunk, {
      queryParams: { search }
    })
    setShowOptions(true)
  }

  const renderInputComponent: RenderInputComponent = (inputProps) => {
    return (
      <TextField
        required={required}
        inputRef={inputRef}
        fullWidth
        label={inputProps?.placeholder}
        inputProps={inputProps}
      />
    )
  }

  const handleSuggestionsFetchRequested = () => {
    return suggestions
  }

  const handleSuggestionsClearRequested = () => {
    return suggestions
  }

  type RequiredFormSelectOptions = Required<FormSelectOptions>

  const onSuggestionSelected: OnSuggestionSelected<RequiredFormSelectOptions> = (
    event,
    autoSuggestOption
  ) => {
    const {
      suggestion: { value },
      suggestionValue
    } = autoSuggestOption
    let searchText = suggestionValue
    if (suggestionValue === NONE_LABEL) {
      searchText = NULL_VALUE
    }
    event.preventDefault()
    onSelect && onSelect(value, searchText)
    onChange && onChange(value)
    setShowOptions(false)
  }

  const getSuggestionValue: GetSuggestionValue<RequiredFormSelectOptions> = (
    suggestionObj
  ) => suggestionObj.label

  const renderSuggestion: RenderSuggestion<RequiredFormSelectOptions> = (suggestion) =>
    suggestion.label === NONE_LABEL ? (
      <MenuItem key={`clearable-select-item-${fieldName}`} value={NULL_VALUE}>
        <em>None</em>
      </MenuItem>
    ) : (
      <MenuItem key={suggestion.value} component="div">
        {suggestion.label}
      </MenuItem>
    )

  const handleChange = (event: FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
    event.preventDefault()
    if (newValue == NONE_LABEL) {
      newValue = NULL_VALUE
    }
    setInputText(newValue)
    onInputChange(newValue)
  }

  const handleFocus = () => {
    setIsFocused(true)
    const handleFetch = handleFetchOptions as (
      options?: FetchWithParamsThunk<SearchFilter>
    ) => StoreUpdate<PaginatedResponse<T>>
    dispatch(handleFetch({ getCachedResults: true }))
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const suggestions = useMemo(() => options.map((option) => option.label), [options])

  const shouldRenderSuggestions: ShouldRenderSuggestions = () => showOptions

  const autosuggestProps = {
    renderInputComponent,
    suggestions: options as readonly Required<FormSelectOptions>[],
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
    shouldRenderSuggestions
  }

  return (
    <Autosuggest
      {...autosuggestProps}
      multiSection={false}
      containerProps={{ className: containerClass }}
      inputProps={{
        placeholder: startCase(fieldName),
        value: inputText,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur
      }}
      theme={{ suggestionsList: classes.suggestionsList }}
      renderSuggestionsContainer={(opts) => (
        <Paper
          {...opts.containerProps}
          elevation={isFocused ? 8 : 0}
          className={suggestionsClass}
        >
          {opts.children}
        </Paper>
      )}
    />
  )
}

export default AutoSuggestField
