import { datadogLogs } from '@datadog/browser-logs'

import { logInfo } from 'utils/logger'
import env from 'utils/runtimeEnv'

/* *
/* Check if correct ENV vars are setup BEFORE we default to dummy placeholders
/*
/* Note: The placeholders are really just there for devs to know that they need to fill it in
/* If DD is initialized with one of these placeholder values and it does not match an actual DD app, then
/* it will error out. These should already be set in STG and PROD, so this is really only targeted to the dev env
/* In the edge case that something happens and it is NOT set in STG or PROD - this will simply skip over it as a no-op
*/

// Datadog initialization
const site = 'datadoghq.com'
const sampleRate = 100
const applicationId = env.REACT_APP_DATADOG_APP_ID ?? ''
const clientToken = env.REACT_APP_DATADOG_CLIENT_TOKEN ?? ''
const service = env.REACT_APP_DATADOG_SERVICE ?? ''
const nodeEnv = env.REACT_APP_DATADOG_ENV ?? 'dev'

const hasEnvSetup = Boolean(applicationId && clientToken && service)

const ddLogsConfig = {
  clientToken,
  site,
  service,
  env: nodeEnv,
  sampleRate,
  forwardErrorsToLogs: true
}

const setupDataDogWithConfig = () => {
  if (hasEnvSetup) {
    datadogLogs.init(ddLogsConfig)
    logInfo('Initialized DD Logs config', ddLogsConfig)
  }
}

export default setupDataDogWithConfig
