import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getWhoami } from 'slices/whoami'

import { Banner } from '../Banner'

export function OptedOutBanner() {
  const whoami = useSelector(getWhoami)
  const { t } = useTranslation()
  const isOptedOut = whoami?.is_opted_out
  if (!isOptedOut) return null
  return <Banner>{t('banners.optedOut.description')}</Banner>
}

export default OptedOutBanner
