import { Colors } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

export default function CountdownLoader(
  props: ComponentProps<typeof ContentLoader> = {}
) {
  return (
    <ContentLoader
      width={300}
      height={80}
      style={{ width: '100%', ...(props?.style || {}) }}
      viewBox="0 0 300 80"
      backgroundColor={Colors.inactive}
      foregroundColor={Colors.blue}
      {...props}
    >
      {/* digits */}
      <rect x="7" y="7" rx="10" ry="10" width="45" height="45" />
      <rect x="55" y="7" rx="10" ry="10" width="45" height="45" />

      <rect x="105" y="7" rx="10" ry="10" width="45" height="45" />
      <rect x="152" y="7" rx="10" ry="10" width="45" height="45" />

      <rect x="202" y="7" rx="10" ry="10" width="45" height="45" />
      <rect x="249" y="7" rx="10" ry="10" width="45" height="45" />

      {/* labels */}
      <rect x="25" y="62" rx="3" ry="3" width="60" height="10" />
      <rect x="120" y="62" rx="3" ry="3" width="60" height="10" />
      <rect x="220" y="62" rx="3" ry="3" width="60" height="10" />
    </ContentLoader>
  )
}
