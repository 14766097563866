import { combineReducers } from '@reduxjs/toolkit'

import drawings from './drawings/reducer'
import prizes from './prizes/reducer'
import tickets from './tickets/reducer'
import winners from './winners/reducer'

export default combineReducers({
  tickets,
  drawings,
  prizes,
  winners
})
