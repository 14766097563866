import { SelectChangeEvent } from '@mui/material'

import { useState } from 'react'

import { AdminListFilters } from 'types'

import Select from 'components/select'

interface GoalFiltersProps {
  onSelect: (filters: AdminListFilters) => void
}

export const GoalFilters = ({ onSelect }: GoalFiltersProps) => {
  const [status, setStatus] = useState<string>('')

  const onChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target
    const newVal = value as string
    setStatus(newVal)
    onSelect({
      goal_status: newVal
    })
  }

  const allItems = ['active', 'inactive']

  const getItemValue = (item: string) => item
  const getItemText = (item: string) => item

  return (
    <Select
      defaultClearableText="All"
      clearable
      allItems={allItems}
      getItemValue={getItemValue}
      getItemText={getItemText}
      label="Status"
      itemVal={status}
      onChange={onChange}
    />
  )
}

export default GoalFilters
