import { useMediaQuery } from '@mui/material'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import BrandLogoWithText from 'components/brandLogoWithText'

import confetti from 'vendor/confetti'

import { fetchFavoritePerksThunk } from 'slices/perks'
import { getWhoami } from 'slices/whoami'

import { StyledOnboarding, StyledOnboardingSpacing } from './styles'

import { StepContext } from '.'
import { Steps } from './steps'

const NA = 'N/A'

const Onboarding = () => {
  const whoami = useSelector(getWhoami)
  const dispatch = useAppDispatch()
  const isOnSmallDevice = useMediaQuery('only screen and (max-device-height: 750px)')
  const isOnTinyDevice = useMediaQuery('only screen and (max-device-height: 670px)')

  const [isGettingStarted, setIsGettingStarted] = useState(true)
  const [selectedPerkIds, setSelectedPerkIds] = useState<string[]>([])
  const [firstName, setFirstName] = useState<string | undefined>(whoami?.display_name)
  const [birthday, setBirthday] = useState<string | null>(
    whoami?.birthday ? whoami.birthday : null
  )
  const [email, setEmail] = useState<string | null>(whoami?.email ? whoami.email : null)

  useEffect(() => {
    dispatch(fetchFavoritePerksThunk())

    const animationSeconds = 1.5
    const secondsBeforeFading = 4

    confetti(animationSeconds)

    const timer = setTimeout(() => setIsGettingStarted(false), secondsBeforeFading * 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    const birthday = whoami?.birthday

    setFirstName(whoami?.display_name ?? '')
    setBirthday(birthday === NA || !birthday ? null : birthday)
  }, [whoami])

  const gettingStartedView = (
    <StyledOnboarding>
      <StyledOnboardingSpacing>
        <BrandLogoWithText />
      </StyledOnboardingSpacing>
    </StyledOnboarding>
  )

  return isGettingStarted ? (
    gettingStartedView
  ) : (
    <StepContext.Provider
      value={{
        isOnSmallDevice,
        isOnTinyDevice,
        selectedPerkIds,
        firstName,
        birthday,
        email,
        setFirstName,
        setBirthday,
        setSelectedPerkIds,
        setEmail
      }}
    >
      <Steps />
    </StepContext.Provider>
  )
}

export default Onboarding
