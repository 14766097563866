import clsx from 'clsx'

import { StyledJackpotHeaderTitle } from './styles'

interface JackpotHeaderTitleProps {
  className?: string
  lessProminent?: boolean
  moreSpace?: boolean
  title: string
}

export interface JackpotHeaderTitleStyleProps {
  moreSpace: boolean
}

export default function JackpotHeaderTitle({
  className,
  lessProminent,
  moreSpace,
  title
}: JackpotHeaderTitleProps) {
  return (
    <StyledJackpotHeaderTitle
      variant="body1"
      component={lessProminent ? 'p' : 'h2'}
      className={clsx(className)}
      moreSpace={Boolean(moreSpace)}
    >
      {title}
    </StyledJackpotHeaderTitle>
  )
}
