import { OnarollRewardProductType, RewardInfo, RewardType } from 'types'

import { amountToPoints, totalPointsWithDiscount } from 'utils/helpers'
import { numSort, strSort } from 'utils/sort'

const isRedeemable = (rewardInfo: RewardInfo, pointsBalance: number) => {
  const percentDiscount = rewardInfo.discount?.percent_discount
  const minPerkPointValue =
    rewardInfo.min_value || rewardInfo.min_value == 0
      ? amountToPoints(rewardInfo.min_value, rewardInfo.points_per_unit_cost)
      : rewardInfo.points_per_unit_cost
  const minPerkPointCost = percentDiscount
    ? totalPointsWithDiscount(minPerkPointValue, percentDiscount)
    : minPerkPointValue
  return Number(minPerkPointCost <= pointsBalance)
}

export const sortByRedeemable = (perks: RewardInfo[], pointsBalance: number) => {
  perks.sort(function (a: RewardInfo, b: RewardInfo) {
    return isRedeemable(b, pointsBalance) - isRedeemable(a, pointsBalance)
  })
  return perks
}

const sortByJackpotTicket = (a: RewardInfo, b: RewardInfo) => {
  return (
    Number(b.configuration?.product_type === OnarollRewardProductType.jackpot_ticket) -
    Number(a.configuration?.product_type === OnarollRewardProductType.jackpot_ticket)
  )
}

const sortByFree = (a: RewardInfo, b: RewardInfo) => {
  const isFree = (r: RewardInfo) => r.discount?.percent_discount === 100
  const toNum = (r: RewardInfo) => Number(isFree(r))
  const [first, second] = [a, b].map(toNum)
  return second - first
}

// Number(true) => 1, Number(false) => 0
// Boolean(undefined | null) => 0
const sortByCash = (a: RewardInfo, b: RewardInfo) =>
  Number(Boolean(b.reward_type === RewardType.cash)) -
  Number(Boolean(a.reward_type === RewardType.cash))

const reverseSortByPopularity = (a: RewardInfo, b: RewardInfo) => {
  return numSort(b, a, 'total_redeemed')
}

const sortByTitle = (a: RewardInfo, b: RewardInfo) => {
  return strSort(a, b, 'title')
}

const sortPerks = (a: RewardInfo, b: RewardInfo) =>
  sortByJackpotTicket(a, b) ||
  sortByCash(a, b) ||
  sortByFree(a, b) ||
  reverseSortByPopularity(a, b) ||
  sortByTitle(a, b)

export default { sortPerks }
