import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { AuthTokensDTO, Impersonator, Whoami } from 'types'

import { AUTH, requestState, RequestState } from 'slices/constants'

import { impersonateWorkerThunk } from 'slices/impersonation/actions'
import { handleRequestFulfilled } from 'slices/utils'

import {
  fetchAuthTokenThunk,
  firebaseTokensThunk,
  refreshFirebaseTokensThunk,
  refreshTokenThunk
} from './actions'

export interface AuthState extends RequestState {
  accessToken: string
  refreshToken: string
  loginToken: string
  whoami: Whoami | null
  prefix: 'JWT' | 'Bearer'
}

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  loginToken: '',
  whoami: null,
  prefix: 'JWT',
  ...requestState
}

export const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    dismissError(state) {
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder
      // impersonateWorkerThunk is only called during old style impersonation
      .addCase(impersonateWorkerThunk.fulfilled, (state, action) => {
        const { access } = action.payload as AuthTokensDTO & {
          impersonator: Impersonator
        }
        state.accessToken = access
        state.refreshToken = '' // explicitly disable refreshing when impersonating
        state.prefix = 'JWT'

        localStorage.setItem('accessToken', access)
        localStorage.setItem('refreshToken', '')
        localStorage.setItem('prefix', 'JWT')

        handleRequestFulfilled(state)
      })
      .addCase(firebaseTokensThunk.pending, (state) => {
        state.prefix = 'Bearer'
        localStorage.setItem('prefix', 'Bearer')
      })
      .addCase(fetchAuthTokenThunk.pending, (state) => {
        state.prefix = 'JWT'
        localStorage.setItem('prefix', 'JWT')
      })
      .addCase(refreshFirebaseTokensThunk.fulfilled, (state, action) => {
        const { payload } = action
        const tokens = payload
        state.loginToken = 'auth'
        state.accessToken = tokens?.access
        state.refreshToken = tokens?.refresh
        localStorage?.setItem('loginToken', 'auth')
        localStorage?.setItem('accessToken', tokens?.access)
        localStorage?.setItem('refreshToken', tokens?.refresh)
        localStorage?.setItem('prefix', 'Bearer')
        handleRequestFulfilled(state)
      })
      .addMatcher(
        isAnyOf(
          fetchAuthTokenThunk.fulfilled,
          refreshTokenThunk.fulfilled,
          firebaseTokensThunk.fulfilled
        ),
        (state, action) => {
          const tokens: any = action.payload
          const arg = action.meta?.arg
          let loginToken = ''
          if (typeof arg === 'string') {
            loginToken = arg
          } else {
            loginToken = state.loginToken || 'fbauth'
          }

          const accessToken = tokens?.access || tokens?.idToken
          const refreshToken = tokens?.refresh || tokens?.refreshToken

          state.loginToken = loginToken
          state.accessToken = accessToken
          state.refreshToken = refreshToken

          localStorage.setItem('loginToken', loginToken)
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('refreshToken', refreshToken)

          handleRequestFulfilled(state)
        }
      )
  }
})

export const { dismissError } = authSlice.actions

export default authSlice.reducer
