import { Children, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'

import { StyledTabsHeaderContainer, StyledTabsRootContainer } from './Tabs.styles'

import { TabsClasses } from './TabsClasses'
import TabHeader from './tabHeader/TabHeader'
import TabView from './tabView/TabView'

interface TabsViewProps {
  children: JSX.Element[]
  headerLabels: string[]
}

export default function Tabs({ children, headerLabels }: TabsViewProps) {
  const [value, setValue] = useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
  }

  const numOfChildren = Children.count(children)
  if (numOfChildren !== headerLabels.length) {
    throw new Error(
      `The number of children you have provided (${numOfChildren}) does NOT match the number of header cells you provided (${headerLabels.length})`
    )
  }

  return (
    <StyledTabsRootContainer
      className={TabsClasses.ROOT_CONTAINER}
      data-testid="TabsRootContainer"
    >
      <StyledTabsHeaderContainer
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        className={TabsClasses.HEADER_CONTAINER}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="Onaroll Custom Tabs"
        data-testid="TabsHeaderContainer"
      >
        {headerLabels.map((label, index) => (
          <TabHeader key={label} label={label} index={index} activeIndex={value} />
        ))}
      </StyledTabsHeaderContainer>
      <SwipeableViews
        index={value}
        className={TabsClasses.VIEW_CONTAINER}
        onChangeIndex={handleChangeIndex}
        data-testid="TabsViewContainer"
      >
        {Children.map(children, (child, index) => {
          return (
            <TabView className={TabsClasses.VIEW} value={value} index={index}>
              {child}
            </TabView>
          )
        })}
      </SwipeableViews>
    </StyledTabsRootContainer>
  )
}
