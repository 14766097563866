import { Button, styled, Typography } from '@mui/material'

import { ReactComponent as OnarollIcon } from 'assets/icons/AppIcons/OnarollIcon.svg'

import Page from 'layouts/page'

export const StyledErrorPage = styled(Page)(({ theme }) => ({
  '& .LayoutPage__wrapper': {
    padding: theme.spacing(16),
    overflow: 'hidden',
    backgroundColor: theme.branding.accentColor,
    color: theme.palette.getContrastTextColor(theme.branding.accentColor),
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg > path': {
      fill: theme.branding.secondaryColor
    }
  }
}))

export const StyledErrorPageTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(16),
  lineHeight: '60px'
}))

export const StyledErrorPageDescription = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(3, 0, 5)
}))

export const StyledErrorPageCTAContainer = styled('div')(({ theme }) => ({
  'button, a': {
    borderColor: theme.branding.secondaryColor,
    color: theme.branding.secondaryColor,
    textAlign: 'center'
  }
}))

export const StyledErrorPageButton = styled(Button)(({ theme }) => ({
  borderColor: theme.branding.secondaryColor,
  color: theme.branding.secondaryColor
}))

export const StyledErrorPageIcon = styled(OnarollIcon)({
  width: '100%',
  height: '100px'
})
