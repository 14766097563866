// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TeamRolesResponse } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOCATIONS, requestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchTeamRolesThunk } from '.'

export const TeamRolesAdapter = createEntityAdapter<TeamRolesResponse>({
  selectId: (location) => location.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const teamRolesSlice = createSlice({
  name: LOCATIONS,
  initialState: TeamRolesAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamRolesThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTeamRolesThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(fetchTeamRolesThunk.fulfilled, (state, action) => {
        TeamRolesAdapter.setAll(state, action.payload)
        handleRequestFulfilled(state)
      })
  }
})

export default teamRolesSlice.reducer
