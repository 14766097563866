import { StyleProps, ThemeType } from 'theme'

import { Paper, PaperTypeMap, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { conditionalObject } from 'utils/helpers'

// TODO: Figure out how to refactor similar styles from layouts/pageWithSubheader/styles
export const StyledRootLayout = styled<
  OverridableComponent<PaperTypeMap<{ component: 'main' }, typeof Paper>>
>(Paper, {
  shouldForwardProp: (prop) => prop !== 'themeType'
})<StyleProps>(({ theme, themeType }) => {
  const neutralThemeType: ThemeType = 'neutral'
  return {
    backgroundColor: theme.customTheme[themeType].primaryColor,
    '& .MuiAppBar-colorPrimary, header': {
      backgroundColor: theme.customTheme[themeType].primaryColor,
      color: theme.palette.getContrastTextColor(
        theme.customTheme[themeType].primaryColor
      ),
      '& #BackButton': {
        color: theme.palette.getContrastTextColor(
          theme.customTheme[themeType].primaryColor
        ),
        '.MuiTypography-root': {
          color: 'inherit' // inherit above button color
        },
        '& svg > path': {
          fill: theme.palette.getContrastTextColor(
            theme.customTheme[themeType].primaryColor
          )
        }
      }
    },
    '.LayoutPageWithModal': {
      '&__container': {
        // Neutral themeTypes have the same color header and background colors which causes awkward spacing.
        // This simply removes it and makes it equal to about the same size as the header text
        ...conditionalObject({ minHeight: 40 }, themeType === neutralThemeType),
        '& > button > svg > path': {
          fill: theme.palette.getContrastTextColor(
            theme.customTheme[themeType].backgroundColor
          )
        }
      },
      '&__header': {
        width: '100%',
        color: theme.palette.getContrastTextColor(
          theme.customTheme[themeType].backgroundColor
        )
      }
    }
  }
})
