import canvasConfetti from 'canvas-confetti'

export const confettiColors = ['#F7CE00', '#FAC000', '#FCB300', '#000000', '#ffffff']

// Set to be above the default z-index for Material-UI drawer
export const confettiZIndex = 1500

export default function confetti(
  duration?: number,
  customFrames?: canvasConfetti.Options[]
) {
  const durationSeconds = duration || 1
  const end = Date.now() + durationSeconds * 1000

  function frame() {
    if (customFrames?.length) {
      customFrames.forEach((option) => {
        const optionWithDefaults = {
          particleCount: 2,
          colors: confettiColors,
          zIndex: confettiZIndex,
          ...option
        }
        canvasConfetti(optionWithDefaults)
      })
    } else {
      canvasConfetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: {
          x: 0
        },
        colors: confettiColors,
        zIndex: confettiZIndex
      })

      canvasConfetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: {
          x: 1
        },
        colors: confettiColors,
        zIndex: confettiZIndex
      })
    }

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  }
  frame()
}
