import { styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

export const StyledJackpotPrizeTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'h1'>
  >
>(Typography)(({ theme }) => ({
  lineHeight: '48px',
  marginTop: theme.spacing(12)
}))

export const StyledJackpotPrizeSubTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'h2'>
  >
>(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  color: theme.branding.subTextColor
}))

export const StyledJackpotPrizeBody = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}))

export const StyledPrizeImageOuterContainer = styled('article')({
  position: 'relative'
})

export const StyledPrizeImageInnerContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 0px 25px rgba(53, 49, 66, 0.1)',
  borderRadius: theme.borderRadius.medium,
  width: '100px',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '-25px',
  left: '25px',
  zIndex: 1,
  position: 'absolute'
}))

export const StyledJackpotPrizeImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  maxWidth: '100%',
  maxHeight: '100%'
}))

export const StyledJackpotPrizeHeaderContent = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const StyledJackpotPrizeHeaderName = styled(Typography)(({ theme }) => ({
  color: theme.branding.lightTextColor,
  textTransform: 'capitalize'
}))

export const StyledJackpotPrizeHeaderTickets = styled(Typography)(({ theme }) => ({
  color: theme.branding.secondaryColor,
  lineHeight: '36px',
  textAlign: 'end',
  textTransform: 'lowercase'
}))
