import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import ErrorPage from 'layouts/errorPage'

import { RootPaths } from 'utils/helpers'

import Error404Image from './error404Image'

export default function Error404() {
  const { t } = useTranslation()

  return (
    <ErrorPage
      icon={<Error404Image />}
      title={t('pages.404.title')}
      description={t('pages.404.description')}
      ctaButtonProps={{
        component: RouterLink,
        to: RootPaths.home
      }}
      ctaButtonText={t('pages.404.cta')!}
    />
  )
}
