import queryString from 'query-string'

// For use with useParams()
export interface UrlParamTypes {
  id?: string
}

// This will be the master key
// For any query params passed to the url
export const UrlKeywords = {
  categories: 'categories' as const,
  store: 'store' as const,
  storeGroup: 'storeGroup' as const,
  worker_id: 'worker_id' as const,
  email: 'email' as const,
  code: 'code' as const,
  // otp
  loginType: 'loginType' as const, // email or phone
  loginValue: 'loginValue' as const // dl@onaroll.co or 9177956543
}

const DefaultQueryOptions = {
  arrayFormat: 'bracket' as const
}

// Note: Copying the signature from the types file of query-string
// Need `any` since we can have undefined as a value in order to remove
// the key-value pair from the url (https://github.com/sindresorhus/query-string#falsy-values)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getParamsForUrl = <T extends Record<string, any>>(params: T) => {
  // params: { categories: [music, food, movies] }
  // -> 'categories[]=music&categories[]=food&categories[]=movies'
  // OR
  // params: { category: music }
  // -> 'category=music'
  return queryString.stringify(params, DefaultQueryOptions)
}

export const getParamsFromUrl = (params: string) => {
  // params: 'foo[]=1&foo[]=2&foo[]=3'
  // -> {foo: ['1', '2', '3']}
  return queryString.parse(params, DefaultQueryOptions)
}

export const getValueFromUrl = (
  params: string,
  keywords: (keyof typeof UrlKeywords)[]
) => {
  // Ex: "?categories[]=trending" => ["trending"] since "categories" is a valid keyword
  const searchParams = getParamsFromUrl(params)
  const keywordsFromUrl = keywords.map((keyword) => searchParams?.[keyword]).flat()
  const validKeywords = keywordsFromUrl.filter(Boolean) as string[]
  return validKeywords
}

// API for fetching URL params

export const getSelectedCategoriesFromUrl = (params: string) => {
  return getValueFromUrl(params, [UrlKeywords.categories])
}

export const getWorkerIdFromUrl = (params: string) => {
  return getValueFromUrl(params, [UrlKeywords.worker_id])
}
