import { Box, Tabs, styled } from '@mui/material'

export const StyledTabsRootContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: '26px',
  borderTopRightRadius: '26px'
}))

export const StyledTabsHeaderContainer = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.colors.inactive,
  borderRadius: theme.borderRadius.large
}))
