import { Button } from '@mui/material'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ReactComponent as JackpotTicketIcon } from 'assets/icons/AppIcons/JackpotTicketIcon.svg'

import useAppDispatch from 'hooks/useAppDispatch'

import { useNavigation } from 'hooks/useNavigation'

import {
  fetchJackpotCurrentTicketInfoThunk,
  getJackpotCurrentTicketCount
} from 'slices/jackpot'

import { RootPaths } from 'utils/helpers'

import {
  StyledJackpotSubheaderBody,
  StyledJackpotSubheaderHead,
  StyledJackpotSubheaderHeadTitle
} from './styles'

export default function JackpotSubheader() {
  const dispatch = useAppDispatch()

  const currentTickets = useSelector(getJackpotCurrentTicketCount)
  const { t } = useTranslation()
  const navigation = useNavigation()

  useEffect(() => {
    dispatch(fetchJackpotCurrentTicketInfoThunk({ getCachedResults: true }))
  }, [])

  return (
    <>
      <StyledJackpotSubheaderHead>
        <StyledJackpotSubheaderHeadTitle variant="h1">
          {currentTickets}
        </StyledJackpotSubheaderHeadTitle>
        <JackpotTicketIcon />
      </StyledJackpotSubheaderHead>
      <StyledJackpotSubheaderBody variant="body1">
        {t('pages.jackpot.yourTickets')}
      </StyledJackpotSubheaderBody>
      <Button
        onClick={() => navigation.push(RootPaths.jackpotHistory)}
        variant="contained"
        color="secondary"
      >
        {t('pages.jackpot.ticketHistory')}
      </Button>
    </>
  )
}
