import PerkRedemptionCode from 'components/perkRedemptionCode'

import { styled, Typography } from '@mui/material'

export const RedeemedPerksContainer = styled('ul')({
  margin: 0,
  padding: 0
})

export const RedeemedPerkItemBoldText = styled(Typography)({
  fontWeight: 'bold'
})

export const RedeemedPerkItemCollapsibleBody = styled('div')(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(1)
  }
}))

export const RedeemedPerkItemCollapsibleText = styled(Typography)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  '& > span': {
    fontWeight: 'bold'
  }
}))

export const RedeemedPerkItemPerkRedemptionCode = styled(PerkRedemptionCode)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    alignItems: 'flex-start'
  })
)
