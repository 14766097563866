import { Button, ButtonProps } from '@mui/material'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ONAROLL_INTERCOM_NUMBER } from 'pages/login/utils'

import { getSelectedStore } from 'slices/storeSwitcher'
import { getWhoami } from 'slices/whoami'

import { logWarning } from 'utils/logger'

export type ContactUsButtonProps = ButtonProps & {
  messageBody?: string
  handleOnClick?: () => void // optional onClick handler
}

export function ContactUsButton({
  children = '(212) 456-7890',
  messageBody = '',
  handleOnClick,
  ...restProps
}: ContactUsButtonProps) {
  const whoami = useSelector(getWhoami)
  const selectedStore = useSelector(getSelectedStore)

  const logOnClick = () => {
    if (whoami && selectedStore) {
      const { user_profile_id, display_name, phone } = whoami
      const { id: store_id, name: store } = selectedStore
      /* Note: We have no visibility in whether or not a user has ACTUALLY messaged us
       * But with this we know that they had the intent to. We can always follow up on twilio to truly confirm,
       * but this should give us some usefull insight when debugging
       * especially with ErrorBoundaries when TMs reach out to us due to errors
       */
      logWarning(`${display_name} from ${store} has tried to contact onaroll!`, {
        user_profile_id: user_profile_id,
        name: display_name,
        store_id: store_id,
        store: store,
        from: phone,
        to: ONAROLL_INTERCOM_NUMBER
      })
    } else {
      logWarning(
        `User is NOT authed and has tried to contact onaroll! \
        This usually means that the app crashed and the user was able to contact us via the Error Boundary\n
        Make sure to check Twilio logs for user's phone number in order to get more information`
      )
    }

    handleOnClick?.()
  }

  const href = useMemo(() => {
    const defaultHref = `sms://${ONAROLL_INTERCOM_NUMBER}`
    if (messageBody) {
      return `${defaultHref}&body=${encodeURIComponent(messageBody)}`
    }
    return defaultHref
  }, [messageBody])

  return (
    <Button onClick={logOnClick} href={href} {...restProps}>
      {children}
    </Button>
  )
}

export default ContactUsButton
