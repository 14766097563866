export enum RewardType {
  cash = 'cash',
  gift_card = 'gift_card',
  item = 'item'
}

export enum OnarollRewardProductType {
  jackpot_ticket = 'jackpot_ticket'
}

export interface Reward {
  id: string
  title: string
  tagline?: string
  description?: string
  image?: string
  image_alternate?: string
  brand_color?: string
  categories: string[]
  display_in_onboarding: boolean
  reward_type: RewardType
}

export enum RedeemableAt {
  inStore = 'in_store_only',
  online = 'online_only',
  inStoreAndOnline = 'in_store_and_online_only',
  inRestaurant = 'in_restaurant_only',
  inApp = 'app_only'
}

export interface RedemptionRequest {
  reward: Reward
  points: number
  requested_at: string
  fulfilled_at: string
  fulfilled_text: string
  code: string
  pin: string
  redeem_url: string
  can_show_barcode: boolean
}

export interface RewardDiscount {
  id: string
  percent_discount: number
  redemption_limit: number
  points: number
  effective_at: string
  expires_at: string
  reward: Reward
  store_id: string
}

export interface RewardInfo extends Reward {
  discount: RewardDiscount | null
  last_redeemed: string | null
  requested_at: string | null
  total_redeemed: number
  redeemable_at: RedeemableAt | null
  denominations: number[] | null
  min_value: number
  max_value: number
  points_per_unit_cost: number
  configuration?: Record<string, unknown>
}

// The type for the reward we fetch on the Perk's detail page
export interface RewardDetail extends Omit<RewardInfo, 'last_redeemed' | 'discount'> {
  discounts: RewardDiscount[]
}

export interface RedeemedPerk {
  id: string
  reward: RewardInfo
  worker: string
  user_profile: string
  points: number
  requested_at: string
  fulfilled_at: string
  fulfilled_text: string
  code: string
  pin: string
  redeem_url: string
  can_show_barcode: boolean
  cash_value: number | null
}

export enum CategoryTypes {
  all = 'all',
  beauty = 'beauty',
  charity = 'charity',
  clothes = 'clothes',
  coffee = 'coffee',
  entertain = 'entertain',
  fashion = 'fashion',
  food = 'food',
  gaming = 'gaming',
  lifestyle = 'lifestyle',
  movies = 'movies',
  music = 'music',
  restaurant = 'restaurant',
  sports = 'sports',
  tech = 'tech',
  travel = 'travel',
  trending = 'trending'
}

export interface FavoritePerk {
  id: string
  title: string
  image_url: string
}
