import { ButtonProps } from '@mui/material'

import useWindowDimensions from 'hooks/useWindowDimensions'

import StyledFloatingButton from './styles'

export interface FloatingButtonProps extends ButtonProps {
  getTopOffset?: (height: number) => number
  getLeftOffset?: (height: number) => number
}

export function FloatingButton({
  getTopOffset,
  getLeftOffset,
  ...restProps
}: FloatingButtonProps) {
  const { height, width } = useWindowDimensions()
  const top = getTopOffset ? getTopOffset(height) : height * 0.83
  const left = getLeftOffset ? getLeftOffset(width) : width - 140
  return (
    <div>
      <StyledFloatingButton
        color="secondary"
        variant="contained"
        size="large"
        top={top}
        left={left}
        {...restProps}
      />
    </div>
  )
}

export default FloatingButton
