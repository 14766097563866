import { fetchRecords, fetchRecord, postRecord } from 'api'

import {
  RewardDetail,
  RewardInfo,
  FetchCachedThunkProps,
  FetchOneDTO,
  AuthTokensDTO,
  UpsertThunkProps
} from 'types'

import { PERKS, SUGGESTED_PERK } from 'slices/constants'

import { getImpersonator } from 'slices/impersonation'
import { getDefaultSelectedStore } from 'slices/storeSwitcher'
import { authenticatedThunkCreator, shouldRefetch } from 'slices/utils'

import { perksSelectors } from './selectors'

import { conditionalObject } from 'utils/helpers'

import { RootState } from 'reduxStore'

export const fetchPerksThunk = authenticatedThunkCreator<
  RewardInfo[],
  FetchCachedThunkProps | undefined
>(
  `${PERKS}/fetchPerksThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const selectedStoreId = getDefaultSelectedStore(state)?.id
    return await fetchRecords(
      {
        ...authedDTO,
        ...conditionalObject(
          {
            queryParams: { store_id: selectedStoreId }
          },
          selectedStoreId
        )
      },
      PERKS
    )
  },
  {
    condition: (options, { getState }) => {
      const state = getState() as RootState
      const impersonator = getImpersonator(state)
      const prevPerks = perksSelectors.selectAll(state)
      return shouldRefetch(false, prevPerks, impersonator, options)
    }
  }
)

export const fetchPerkThunk = authenticatedThunkCreator<RewardDetail, FetchOneDTO>(
  `${PERKS}/fetchPerkThunk`,
  async (options) => {
    const perk: RewardDetail = await fetchRecord(options, PERKS)
    return perk
  }
)

//todo: this really shouldn't be a thunk, but i fear it's here
//because it's just the most convenient way to add auth to it?
export const submitSuggestedPerkThunk = authenticatedThunkCreator<
  unknown,
  UpsertThunkProps<{ suggestedReward: string }>
>(`${PERKS}/submitSuggestedPerkThunk`, async ({ data, ...authedDTO }, { getState }) => {
  const state = getState() as RootState
  const selectedStoreId = getDefaultSelectedStore(state)?.id
  return await postRecord(
    {
      ...(authedDTO as AuthTokensDTO),
      data: {
        ...data,
        ...conditionalObject({ store_id: selectedStoreId }, selectedStoreId)
      }
    },
    SUGGESTED_PERK
  )
})
