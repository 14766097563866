import { fetchRecords } from 'api'

import {
  JackpotCurrentTicketInfo,
  FetchCachedThunkProps,
  JackpotTicketTransaction
} from 'types'

import { JACKPOT } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

import {
  getTicketsLoading,
  getTicketsHistoryHasLoaded,
  getCurrentTicketInfoHasLoaded
} from './selectors'

import { RootState } from 'reduxStore'

export const fetchJackpotCurrentTicketInfoThunk = authenticatedThunkCreator<
  JackpotCurrentTicketInfo,
  FetchCachedThunkProps | undefined
>(
  `${JACKPOT}/fetchJackpotCurrentTicketInfoThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, 'jackpotCurrentTicketInfo'),
  {
    condition: (options = {}, { getState }) => {
      const state = getState() as RootState
      const isUpdating = getTicketsLoading(state)
      const hasLoaded = getCurrentTicketInfoHasLoaded(state)
      return !isUpdating && (!hasLoaded || !options?.getCachedResults)
    }
  }
)

export const fetchJackpotTicketHistoryThunk = authenticatedThunkCreator<
  JackpotTicketTransaction[],
  FetchCachedThunkProps | undefined
>(
  `${JACKPOT}/fetchJackpotTicketHistoryThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, 'jackpotTicketHistory'),
  {
    condition: (options = {}, { getState }) => {
      const state = getState() as RootState
      const hasTicketsHistoryLoaded = getTicketsHistoryHasLoaded(state)
      return !hasTicketsHistoryLoaded || !options?.getCachedResults
    }
  }
)
