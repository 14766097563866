import styled from 'styled-components'

import { Colors } from 'theme'

interface CategoryIconWrapperProps {
  active?: boolean
  backgroundColor?: string
  innerFill?: string
  innerStroke?: string
}

const CategoryIconWrapper = styled.div<CategoryIconWrapperProps>`
  display: inline-block;
  svg {
    circle {
      fill: ${({ active, backgroundColor }) =>
        active ? backgroundColor : Colors.offWhite};
      stroke: ${({ active }) => (active ? 'none' : Colors.blue)};
    }
    path {
      stroke: ${({ active, innerStroke }) =>
        active ? innerStroke : innerStroke && Colors.black};
      fill: ${({ active, innerFill }) =>
        active ? innerFill : innerFill && Colors.black};
    }
  }
`

export default CategoryIconWrapper
