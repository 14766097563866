import { GraphQLClient } from 'graphql-request'

import { getEnvGraphQLAPIHost } from 'utils/helpers'

const requestMiddleware = (shouldUseFirebase?: boolean) => {
  const accessToken: string = localStorage.getItem('accessToken') as string
  const tokenName: string = shouldUseFirebase ? "Bearer" :'JWT'
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${tokenName} ${accessToken}`
    }
  }
}

export const _client = ({shouldUseFirebase = false}: { shouldUseFirebase?: boolean }) => {
  return new GraphQLClient(getEnvGraphQLAPIHost(), requestMiddleware(shouldUseFirebase))
}
