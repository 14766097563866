import { styled } from '@mui/material'

import { ReactComponent as LogoWithText } from '../../../../assets/images/logo-with-text.svg'

import { conditionalObject } from 'utils/helpers'

import { BrandedLogoProps } from '.'

export const StyledBrandedLogo = styled(LogoWithText, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<BrandedLogoProps>(({ theme, isActive }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '130px'
  },
  ...conditionalObject(
    {
      '& > path:first-of-type': {
        fill: theme.palette.colors.yellow
      }
    },
    isActive
  )
}))
