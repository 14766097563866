import { Button } from '@mui/material'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { RedeemedPerk, RewardType } from 'types'

import { DefaultBaseModalProps } from 'components/modals'

import confetti from 'vendor/confetti'

import useDynamicHeight from 'hooks/useDynamicHeight'
import { useNavigation } from 'hooks/useNavigation'

import { RootPaths } from 'utils/helpers'

import {
  StyledPerkBottomModal,
  StyledSnagPerkModalContent,
  StyledSnagPerkModalFooter
} from './styles'

import PerkModalItem from './perkModalItem'

interface SnagPerkModalProps extends DefaultBaseModalProps {
  perk: RedeemedPerk
}

export default function SnagPerkModal({ isOpen, onClose, perk }: SnagPerkModalProps) {
  const [height, measuredRef] = useDynamicHeight()

  useEffect(() => {
    if (isOpen) {
      confetti()
    }
  }, [isOpen])

  const navigation = useNavigation()
  const { t } = useTranslation()

  const goToRedeemedPerks = () => {
    navigation.push(RootPaths.redeemedPerks)
  }

  const title = t('pages.perk.snagPerkModal.title')
  const content = (
    <>
      <StyledSnagPerkModalContent variant="body1">
        {`${t('pages.perk.snagPerkModal.description', { perkName: perk.reward.title })} ${
          perk.reward.reward_type !== RewardType.item
            ? t('pages.perk.snagPerkModal.withCode')
            : ''
        }`}
      </StyledSnagPerkModalContent>
      <PerkModalItem perk={perk} />
    </>
  )

  const footer = (
    <StyledSnagPerkModalFooter>
      <Button variant="outlined" color="secondary" onClick={goToRedeemedPerks}>
        {t('pages.perk.snagPerkModal.footer.perksWalletButtonText')}
      </Button>
      <Button variant="outlined" color="primary" onClick={onClose}>
        {t('pages.perk.snagPerkModal.footer.closeButtonText')}
      </Button>
    </StyledSnagPerkModalFooter>
  )

  return (
    <StyledPerkBottomModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      content={content}
      footer={footer}
      height={height as number}
      ref={measuredRef as (node: HTMLDivElement) => void}
    />
  )
}
