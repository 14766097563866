import clsx from 'clsx'
import { HTMLMotionProps, motion } from 'framer-motion'

import {
  getInitialXCoord,
  getInitialYCoord,
  HorizontalAnimationDirection,
  VerticalAnimationDirection
} from './utils'

export interface AnimatedContentProps {
  children?: React.ReactNode
  verticalAnimationDirection?: VerticalAnimationDirection
  horizontalAnimationDirection?: HorizontalAnimationDirection
  id?: string
  className?: string
}

interface MotionDivProps {
  motionDivProps?: HTMLMotionProps<'div'>
}

const AnimatedContent = ({
  children,
  verticalAnimationDirection = VerticalAnimationDirection.NONE,
  horizontalAnimationDirection = HorizontalAnimationDirection.NONE,
  motionDivProps,
  id,
  className
}: AnimatedContentProps & MotionDivProps) => {
  const initialYCoord = getInitialYCoord(verticalAnimationDirection)
  const initialXCoord = getInitialXCoord(horizontalAnimationDirection)
  return (
    <motion.div
      animate={{ opacity: 1, y: 0, x: 0 }}
      initial={{
        opacity: 0,
        y: initialYCoord,
        x: initialXCoord
      }}
      transition={{ duration: 0.75 }}
      {...motionDivProps}
      data-testid={id ?? 'AnimatedContent'}
      className={clsx(className, motionDivProps?.className)}
    >
      {children}
    </motion.div>
  )
}

export default AnimatedContent
