import { TypographyProps } from '@mui/material/Typography'

import { useContext, useMemo } from 'react'

import { StepContext } from 'pages/onboarding'

const useTypographyVariantHook = () => {
  const { isOnSmallDevice, isOnTinyDevice } = useContext(StepContext)
  const titleVariant: TypographyProps['variant'] = useMemo(() => {
    if (isOnSmallDevice && !isOnTinyDevice) return 'h2'
    if (isOnTinyDevice) return 'h3'
    return 'h1'
  }, [isOnSmallDevice, isOnTinyDevice])
  const bodyVariant: TypographyProps['variant'] = isOnTinyDevice ? 'body2' : 'body1'

  return { titleVariant, bodyVariant }
}

export default useTypographyVariantHook
