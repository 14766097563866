import { createSelector } from '@reduxjs/toolkit'

import { prizesAdapter } from './reducer'

import { getJackpotCurrentDrawing } from '../drawings/selectors'

import { RootState } from 'reduxStore'

const selectPrizes = (state: RootState) => state.jackpot.prizes

export const prizesSelectors = prizesAdapter.getSelectors<RootState>(selectPrizes)

export const getPrizesLoading = createSelector(selectPrizes, (prizes) => prizes.isLoading)

export const getJackpotPrize = createSelector(
  selectPrizes,
  (_state: RootState, jackpotPrizeId: string) => jackpotPrizeId,
  (prizes, jackpotPrizeId) => prizes.entities[jackpotPrizeId]
)

export const getCurrentDrawingPrizes = createSelector(
  getJackpotCurrentDrawing,
  prizesSelectors.selectAll,
  (drawing, prizes) => prizes.filter((prize) => prize.drawing == drawing?.id)
)
