import { styled, Typography } from '@mui/material'

export const StyledAchievedGoalsContainer = styled('ul')({
  margin: 0,
  padding: 0
})

export const StyledAchievedGoalItemTitle = styled(Typography)({
  fontWeight: 'bold'
})

export const StyledAchievedGoalItemDescription = styled(Typography)(({ theme }) => ({
  color: theme.branding.subTextColor
}))
