// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RedeemedPerk } from 'types'

import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { requestState, REDEEMED_PERKS } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchRedeemedPerksThunk, redeemPerkThunk } from './actions'

export const redeemedPerksAdapter = createEntityAdapter<RedeemedPerk>({
  selectId: (perk) => perk.id,
  sortComparer: (a, b) =>
    new Date(b.requested_at).getTime() - new Date(a.requested_at).getTime()
})

export const redeemedPerksSlice = createSlice({
  name: REDEEMED_PERKS,
  initialState: redeemedPerksAdapter.getInitialState(requestState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRedeemedPerksThunk.fulfilled, (state, action) => {
        redeemedPerksAdapter.setAll(state, action.payload)
        handleRequestFulfilled(state)
      })
      .addCase(redeemPerkThunk.fulfilled, (state, action) => {
        redeemedPerksAdapter.setOne(state, action.payload)
        handleRequestFulfilled(state)
      })
      .addMatcher(
        isAnyOf(fetchRedeemedPerksThunk.pending, redeemPerkThunk.pending),
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(fetchRedeemedPerksThunk.rejected, redeemPerkThunk.rejected),
        (state, action) => {
          handleRequestRejected(state, action)
        }
      )
  }
})

export default redeemedPerksSlice.reducer
