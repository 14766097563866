// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { CelebrationPoint, PaginatedCelebrationPoints } from 'types'

import {
  ADMIN_CELEBRATION_POINTS,
  paginatedResponse,
  requestState,
  RequestState
} from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import {
  fetchAllCelebrationPointsThunk,
  fetchDistributionPointsThunk,
  sendCelebrationPointsThunk
} from '.'

interface AdminCelebrationPointsState extends RequestState {
  celebrationPointsResponse: PaginatedCelebrationPoints
  distributionPoints: number | null
}

const initialCelebrationPointsState: AdminCelebrationPointsState = {
  celebrationPointsResponse: paginatedResponse,
  distributionPoints: null,
  ...requestState
}

export const CelebrationPointsAdapter = createEntityAdapter<CelebrationPoint>({
  selectId: (celebrationPoints) => celebrationPoints.id
})

export const celebrationPointsSlice = createSlice({
  name: ADMIN_CELEBRATION_POINTS,
  initialState: CelebrationPointsAdapter.getInitialState(initialCelebrationPointsState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCelebrationPointsThunk.fulfilled, (state, action) => {
      const { next, previous, results = [] } = action.payload
      state.celebrationPointsResponse = { next, previous }
      const isFresh = Boolean(action.meta.arg?.isInitial)
      if (isFresh) {
        CelebrationPointsAdapter.setAll(state, results)
      } else {
        CelebrationPointsAdapter.setMany(state, results)
      }
      handleRequestFulfilled(state)
    })
    builder.addCase(sendCelebrationPointsThunk.fulfilled, (state, action) => {
      const celebrationPoint = action.payload
      CelebrationPointsAdapter.setOne(state, celebrationPoint)
      handleRequestFulfilled(state)
    })
    builder.addCase(fetchDistributionPointsThunk.fulfilled, (state, action) => {
      state.distributionPoints = action.payload.total_distribution_points
      handleRequestFulfilled(state)
    })
    builder.addMatcher(isAnyOf(fetchAllCelebrationPointsThunk.pending), (state) => {
      state.isLoading = true
    })
    builder.addMatcher(
      isAnyOf(
        fetchAllCelebrationPointsThunk.rejected,
        sendCelebrationPointsThunk.rejected,
        fetchDistributionPointsThunk.rejected
      ),
      (state, action) => {
        handleRequestRejected(state, action)
      }
    )
  }
})

export default celebrationPointsSlice.reducer
