import clsx from 'clsx'

import { ReactNode, useCallback, useState } from 'react'

import { AppIconTypes } from 'types'

import AppIcon from 'components/icons/AppIcon/AppIcon'

import {
  StyledUrgencyAlert,
  StyledUrgencyAlertClose,
  StyledUrgencyAlertContent,
  StyledUrgencyAlertCta
} from './UrgencyAlert.styles'

export interface UrgencyAlertProps {
  children: NonNullable<ReactNode>
  closable?: boolean
  cta?: JSX.Element
  className?: string
}

export enum UrgencyAlertClasses {
  container = 'UrgencyAlert',
  content = 'UrgencyAlert__content',
  ctaContainer = 'UrgencyAlert__ctaContainer'
}

export default function UrgencyAlert({
  children,
  closable,
  cta,
  className
}: UrgencyAlertProps): JSX.Element | null {
  const [open, setOpen] = useState(true)

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  if (!children) return null

  const closeIcon = closable && (
    <StyledUrgencyAlertClose data-testid="UrgencyAlertCloseButton" onClick={onClose}>
      <AppIcon data-testid="UrgencyAlertCloseButtonIcon" type={AppIconTypes.close} />
    </StyledUrgencyAlertClose>
  )

  const icon = <AppIcon data-testid="UrgencyAlertIcon" type={AppIconTypes.circleInfo} />

  const ctaContent = cta && (
    <StyledUrgencyAlertCta
      data-testid="UrgencyAlertCTA"
      className={UrgencyAlertClasses.ctaContainer}
    >
      {cta}
    </StyledUrgencyAlertCta>
  )

  if (!open) return null

  return (
    <StyledUrgencyAlert className={clsx(className, UrgencyAlertClasses.container)}>
      <StyledUrgencyAlertContent className={UrgencyAlertClasses.content}>
        {icon}
        {children}
      </StyledUrgencyAlertContent>
      {ctaContent}
      {closeIcon}
    </StyledUrgencyAlert>
  )
}
