import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { STORE_SWITCHER } from 'slices/constants'

interface StoreSwitcherInitialState {
  pageIndex: number
  open: boolean
}

export const storeSwitcherSlice = createSlice({
  name: STORE_SWITCHER,
  initialState: {
    pageIndex: 0,
    open: false
  } as StoreSwitcherInitialState,
  reducers: {
    goBack(state, action: PayloadAction<number>) {
      const lastIndex = action.payload
      const prevIndex = state.pageIndex
      state.pageIndex = prevIndex === 0 ? lastIndex : prevIndex - 1
    },
    goNext(state, action: PayloadAction<number>) {
      const lastIndex = action.payload
      const prevIndex = state.pageIndex
      state.pageIndex = prevIndex === lastIndex ? 0 : prevIndex + 1
    },
    goSwipe(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload
    },
    toggleOpen(state) {
      const prevIsOpen = state.open
      state.open = !prevIsOpen
    }
  }
})

export default storeSwitcherSlice
