/**
 * Launch Darkly converts all keys to camelCase for the React web SDK
 * So if the LD flag is `i-love-food`, we can access it here as `iLoveFood`
 * https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys
 */

export enum FeatureFlags {
  testFlag = 'testFlag', // ONLY FOR TESTING
  allowTlxEditAccess = 'allowTlxEditAccess',
  disableArrearsAccountRedemptions = 'disableArrearsAccountRedemptions',
  displayGoalsSelfServiceTmx = 'displayGoalsSelfServiceTmx',
  useFirebaseLoginTmx = 'useFirebaseLoginTmx',
  blockRedemptionsByUser = 'blockRedemptionsByUser'
}
