// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { StoreGroup, PaginatedStoreGroups } from 'types'

import { RequestState, requestState, STORE_GROUPS } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchStoreGroupsThunk } from '.'

interface StoreGroupsState extends RequestState {
  storeGroupsResponse: PaginatedStoreGroups
}

const initialStoreGroupsState: StoreGroupsState = {
  storeGroupsResponse: {} as PaginatedStoreGroups,
  ...requestState
}

export const storeGroupsAdapter = createEntityAdapter<StoreGroup>({
  selectId: (store) => store.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const storeGroupsSlice = createSlice({
  name: STORE_GROUPS,
  initialState: storeGroupsAdapter.getInitialState(initialStoreGroupsState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoreGroupsThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchStoreGroupsThunk.rejected, (state, action) => {
      handleRequestRejected(state, action)
    })
    builder.addCase(fetchStoreGroupsThunk.fulfilled, (state, action) => {
      const { next, previous, results = [] } = action.payload
      state.storeGroupsResponse = { next, previous }
      storeGroupsAdapter.setAll(state, results)
      handleRequestFulfilled(state)
    })
  }
})

export default storeGroupsSlice.reducer
