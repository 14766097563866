import { Redirect, Switch } from 'react-router-dom'

import PublicRoute from 'components/PublicRoute'
import ImpersonatorBanner from 'components/impersonatorBanner'

import { RootPaths } from 'utils/helpers'

import { DisabledLoginForm } from './form/disabled'
import { EnabledLoginForm } from './form/enabled'
import OTPLoginForm from './form/otp'

const Login = () => {
  return (
    <>
      <ImpersonatorBanner />
      <Switch>
        <PublicRoute exact path={RootPaths.verifyOTP} component={OTPLoginForm} />
        <PublicRoute exact path={RootPaths.loginWithType} component={EnabledLoginForm} />
        <PublicRoute exact path={RootPaths.welcome} component={DisabledLoginForm} />
        <Redirect from={`${RootPaths.welcome}/*`} to={RootPaths.welcome} />
      </Switch>
    </>
  )
}

export default Login
