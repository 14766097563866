import { Card, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BarcodeSVGContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  background: theme.palette.common.white
}))

export const BarcodeSVG = styled('svg')({
  width: '100%',
  height: 'auto'
})

interface BarcodeCopyTextDataProps {
  copied?: boolean
}

export const BarcodeCopyText = styled('small', {
  shouldForwardProp: (prop) => prop !== 'copied'
})<BarcodeCopyTextDataProps>(({ copied, theme }) => ({
  marginLeft: theme.spacing(1),
  '& span': {
    transition: `color 100ms ${theme.transitions.easing.easeInOut}`,
    color: copied ? '#00B600' : 'inherit'
  }
}))

export const BarcodePin = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
  padding: `${theme.spacing(1)} 0 0`,
  '& span': {
    fontWeight: 'bold'
  }
}))
