const DD_USER_SESSION_ID = 'DD_user_session_id'

const generateUID = () => window.crypto.getRandomValues(new Uint32Array(1)).join('')

const getSessionId = (key: string) => window.sessionStorage.getItem(key)
const setSessionId = (key: string, replace?: boolean) => {
  if (replace) {
    window.sessionStorage.setItem(key, generateUID())
  } else {
    const oldId = getSessionId(key)
    if (!oldId) {
      window.sessionStorage.setItem(key, generateUID())
    }
  }
}
const clearSessionId = (key: string) => {
  window.sessionStorage.setItem(key, '')
}

export const getDDUserSessionId = () => getSessionId(DD_USER_SESSION_ID)
export const setDDUserSessionId = (replace?: boolean) =>
  setSessionId(DD_USER_SESSION_ID, replace)
export const clearDDUserSessionId = () => clearSessionId(DD_USER_SESSION_ID)
