import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'reduxStore'

export const getPointsLoading = (state: RootState) => state.points.isLoading

export const getPointTotals = (state: RootState) => state.points.totals

export const getPointsBalance = createSelector(
  getPointTotals,
  (pointTotals) => pointTotals?.balance ?? 0
)
