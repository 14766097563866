import { Grid } from '@mui/material'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { OnarollDaily } from 'components/trivia'

import { useNavigation } from 'hooks/useNavigation'

import { fetchAllTeamMembersThunk, getTeamMembersResponse } from 'slices/admin'
import { defaultTeamMembersQuery } from 'slices/admin/teams/reducer'
import {
  getTotalEarnableGoalPoints,
  getTotalPointsFromYesterdaysCompletedGoals,
  getYesterdaysAchievedGoals
} from 'slices/goals'
import { getIsTeamLeader, getWhoami } from 'slices/whoami'

import { ChildPaths, TeamLeaderPaths } from 'utils/helpers'

import { TeamMemberGoalCarousel } from '../TeamMemberGoalCarousel'
import HomeSection from '../homeSection'
import TeamLeaderGoalDashboard from '../teamLeaderGoalDashboard'

export default function HomeGoalProgressSection() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  const whoami = useSelector(getWhoami)
  const teamMembersResponse = useSelector(getTeamMembersResponse)
  const isTeamLeader = useSelector(getIsTeamLeader)
  const achievedGoalsYesterday = useSelector(getYesterdaysAchievedGoals)
  const potentialPoints = useSelector(getTotalEarnableGoalPoints)
  const pointsYesterday = useSelector(getTotalPointsFromYesterdaysCompletedGoals)

  useEffect(() => {
    if (isTeamLeader) {
      const queryParams = defaultTeamMembersQuery
      const params = { queryParams, getCachedResults: true }
      dispatch(fetchAllTeamMembersThunk(params))
    }
  }, [dispatch, isTeamLeader])

  const goToTeamLeaderTeamPage = () => {
    navigation.push(TeamLeaderPaths.teamMembers)
  }

  const goToTodaysGoalsPage = () => {
    navigation.push(ChildPaths.goalsToday)
  }

  const achievedYesterday = !!achievedGoalsYesterday?.length

  const points = achievedYesterday ? pointsYesterday : potentialPoints

  const displayName = whoami?.display_name

  const title = isTeamLeader
    ? t('pages.home.section.goalProgress.title')
    : t(
        `pages.home.section.goalProgress.carousel.${
          achievedYesterday
            ? 'achievedYesterday'
            : points
            ? 'noAchievedYesterday'
            : 'noGoals'
        }`,
        {
          name: displayName,
          points: points,
          goalsAchieved: achievedGoalsYesterday?.length,
          goalsPlural: achievedGoalsYesterday?.length === 1 ? '' : 's'
        }
      )

  return (
    <>
      <HomeSection title={title}>
        {isTeamLeader ? (
          <TeamLeaderGoalDashboard
            goToTeamLeaderTeamPage={goToTeamLeaderTeamPage}
            goToTodaysGoalsPage={goToTodaysGoalsPage}
            onboardedCount={teamMembersResponse?.onboarded_count}
            notOnboardedCount={teamMembersResponse?.not_onboarded_count}
            teamMembersCount={teamMembersResponse?.team_members_count}
          />
        ) : (
          <TeamMemberGoalCarousel />
        )}
      </HomeSection>
      <HomeSection title={t('pages.home.section.onarollDaily.title')}>
        <Grid item xs={12}>
          <OnarollDaily />
        </Grid>
      </HomeSection>
    </>
  )
}
