import { useContext } from 'react'

import OnboardingLogoImage from 'assets/images/onboarding-logo.svg'

import { StepContext } from 'pages/onboarding'

import { AnimatedContent } from 'components/AnimatedContent'
import { HorizontalAnimationDirection } from 'components/AnimatedContent/utils'

import { conditionalObject } from 'utils/helpers'

import { onboardingLogoImagePositionStyles, StyledOnboardingLogoImage } from './styles'

interface OnboardingLogoProps {
  isInitialPage: boolean
  shouldAnimateLogoRemoval: boolean
}

export const OnboardingLogo = ({
  isInitialPage,
  shouldAnimateLogoRemoval
}: OnboardingLogoProps) => {
  const { isOnTinyDevice } = useContext(StepContext)

  if (isInitialPage || shouldAnimateLogoRemoval) {
    return (
      <AnimatedContent
        horizontalAnimationDirection={
          shouldAnimateLogoRemoval
            ? HorizontalAnimationDirection.NONE
            : HorizontalAnimationDirection.LEFT
        }
        motionDivProps={{
          transition: { delay: 0.5, duration: 0.75 },
          style: onboardingLogoImagePositionStyles,
          ...conditionalObject(
            {
              transition: { duration: 0.3 },
              initial: { opacity: 1, x: 0 },
              animate: { opacity: 0, x: -100 }
            },
            shouldAnimateLogoRemoval
          )
        }}
      >
        <StyledOnboardingLogoImage
          src={OnboardingLogoImage}
          isOnTinyDevice={isOnTinyDevice}
          data-testid="OnboardingLogoImage"
        />
      </AnimatedContent>
    )
  } else return null
}
