import { styled, Typography } from '@mui/material'

import { AnimatedContent } from 'components/AnimatedContent'
import Divider from 'components/Divider'

import { smallDeviceHeight } from 'utils/breakpoints'

import { StyledPage } from '../LoginFormLayout/LoginFormLayout.styles'

export const StyledAnimatedContent = styled(AnimatedContent, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})({
  flex: 1,
  display: 'flex',
  flexDirection: 'column'
})

export const StyledDisabledPage = styled(StyledPage)(({ theme }) => ({
  paddingBottom: theme.spacing(16)
}))

export const StyledHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const StyledBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1
})

export const StyledTitleContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6)
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.fontSize(4.5), // 36px => 36(pixel value) / 8(0.5 rem factor) = 4.5 => 4.5 * 0.5(rem unit) = 2.25rem = 36px
  lineHeight: '1.2222', //44px => 44 / 36(font size) = 1.2222 ratio
  color: theme.palette.common.white
}))

export const StyledFormTitle = styled(Typography)(({ theme }) => ({
  color: '#F1F1F1',
  fontWeight: 600,
  fontSize: theme.fontSize(1.75), // 14px => 14(pixel value) / 8(0.5 rem factor) = 1.75 => 1.75 * 0.5(rem unit) = 0.875rem = 14px
  lineHeight: '1.1428', // 16px => 16 / 14(font size) = 1.1428 ratio
  marginBottom: '-6px',
  marginTop: theme.spacing(7)
}))

export const StyledFormHelpText = styled(Typography)(({ theme }) => ({
  color: '#F1F1F1',
  fontWeight: 400,
  fontSize: theme.fontSize(1.5), // 12px => 12(pixel value) / 8(0.5 rem factor) = 1.5 => 1.5 * 0.5(rem unit) = 0.75rem = 12px
  lineHeight: '1.3333', // 16px => 16 / 12(font size) = 1.3333 ratio
  marginTop: theme.spacing(2)
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(8)} ${theme.spacing(2)}`
}))

export const StyledForm = styled('form')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [`@media ${smallDeviceHeight}`]: {
    marginBottom: theme.spacing(5)
  }
}))
