import { Button, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledBackButton = styled(Button)({
  padding: 0
})

export const StyledBackButtonIcon = styled(IconButton)({
  padding: 0
})

export const StyledBackButtonText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color?: string }>(({ theme, color }) => ({
  fontWeight: 'bold',
  marginLeft: theme.spacing(2),
  color: color || theme.palette.common.white // matches default icon svg color
}))
