import { Button, styled } from '@mui/material'

import {
  TriviaFormContentButtonStyleProps,
  TriviaFormContentStyleProps
} from './onarollTriviaForm'

export const StyledTriviaFormContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'shouldExpand'
})<TriviaFormContentStyleProps>(({ theme, shouldExpand }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, 56px)',
  justifyContent: 'center',
  gridGap: theme.spacing(1),
  ...(shouldExpand && {
    gridTemplateColumns: '1fr'
  })
}))

export const StyledTriviaFormContentButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isAnswer'
})<TriviaFormContentButtonStyleProps>(({ theme, isAnswer }) => ({
  background: theme.branding.secondaryColor,
  borderRadius: theme.borderRadius.small,
  minWidth: '56px',
  minHeight: '56px',
  '&:hover': {
    backgroundColor: theme.branding.secondaryColor
  },
  ...(isAnswer && {
    backgroundColor: theme.branding.accentColor,
    color: theme.palette.getContrastTextColor(theme.branding.accentColor),
    '&:hover': {
      backgroundColor: theme.branding.accentColor
    }
  })
}))
