import useResizeObserver from 'use-resize-observer'

import { useScrollTrigger, Zoom } from '@mui/material'

import { useLayoutEffect, useState } from 'react'

import { ReactComponent as CaretDownIcon } from 'assets/icons/AppIcons/CaretDownIcon.svg'

import { StyledBottomScroll, StyledBottomScrollButton } from './styles'

type ScrollToBottomButtonOnSmallDeviceProps = JSX.IntrinsicElements['div']

export function ScrollToBottomButtonOnSmallDevice({
  ...props
}: ScrollToBottomButtonOnSmallDeviceProps) {
  const [showScrollButton, setShowScrollButton] = useState(false)

  const { height } = useResizeObserver<HTMLElement>({
    ref: document.getElementById('root')
  })

  const hasScrolledPastTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 5
  })

  useLayoutEffect(() => {
    const safeHeight = Number(height) || 0
    const threshold = 102
    if (safeHeight) {
      const clientHeight = document.body.clientHeight
      const shouldDisplayButton =
        !hasScrolledPastTop &&
        safeHeight > clientHeight &&
        safeHeight - clientHeight > threshold
      if (shouldDisplayButton !== showScrollButton) {
        setShowScrollButton(shouldDisplayButton)
      }
    }
  }, [height, hasScrolledPastTop])

  const handleClick = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  }

  return (
    <Zoom in={showScrollButton}>
      <StyledBottomScroll
        onClick={handleClick}
        role="presentation"
        style={{ transform: 'translateX(50%)' }}
        {...props}
      >
        <StyledBottomScrollButton size="small" aria-label="scroll to bottom">
          <CaretDownIcon />
        </StyledBottomScrollButton>
      </StyledBottomScroll>
    </Zoom>
  )
}

export default ScrollToBottomButtonOnSmallDevice
