import { Box } from '@mui/material'

import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { FavoritePerk } from 'types'

import { StepContext } from 'pages/onboarding'

import { getFavoritePerks } from 'slices/perks'

import { StyledOnboardingGrid } from '../styles'

import { MAX_PERKS_SELECTED } from '../../utils'
import PerkItem from '../PerkItem'

const MAX_PERKS_TO_BE_DISPLAYED = 12

const PerksGrid = () => {
  const favoritePerks = useSelector(getFavoritePerks)
  const { selectedPerkIds, setSelectedPerkIds } = useContext(StepContext)

  const getIsPerkItemSelectable = (id: FavoritePerk['id']) => {
    return selectedPerkIds.length < MAX_PERKS_SELECTED || getIsSelected(id)
  }

  const updateSelectedPerkIds = (perk: FavoritePerk) => {
    if (selectedPerkIds.includes(perk.id)) {
      setSelectedPerkIds(selectedPerkIds.filter((id) => id !== perk.id))
      return
    }
    if (selectedPerkIds.length < MAX_PERKS_SELECTED)
      setSelectedPerkIds([...selectedPerkIds, perk.id])
    return
  }

  const getIsSelected = (id: FavoritePerk['id']) => {
    return selectedPerkIds.includes(id)
  }

  return (
    <StyledOnboardingGrid data-testid="OnboardingPerkStep-GridContainer">
      {favoritePerks?.slice(0, MAX_PERKS_TO_BE_DISPLAYED).map((favPerk) => (
        <Box key={favPerk.id} gridColumn="span 4">
          <PerkItem
            perk={favPerk}
            handleSelected={updateSelectedPerkIds}
            canBeSelected={getIsPerkItemSelectable(favPerk.id)}
            isSelected={getIsSelected(favPerk.id)}
            style={{ height: '100%' }}
          />
        </Box>
      ))}
    </StyledOnboardingGrid>
  )
}

export default PerksGrid
