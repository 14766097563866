import { MigrationManifest } from 'redux-persist'

export const migrations: MigrationManifest = {
  /* the keys in this object indicate the version number */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  0: (state: PersistedState) => ({
    ...state,
    storeSwitcher: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      selectedStore: state?.auth?.whoami?.default_selected_store,
      pageIndex: 0,
      open: false
    }
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  1: (state: PersistedState) => ({
    ...state,
    whoami: {
      whoami: state?.auth?.whoami
    }
  })
}
