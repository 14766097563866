// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListChildComponentProps } from 'react-window'

import { ByGoalFilters, ByStoreFilters, Goal } from 'types'

import GoalsItem from 'pages/goals/goalsItem'

import useAppDispatch from 'hooks/useAppDispatch'

import { AdminContainer } from 'components/AdminContainer'
import InfiniteList from 'components/InfiniteList'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'
import Search from 'components/search'

import { useNavigation } from 'hooks/useNavigation'
import useWindowDimensions from 'hooks/useWindowDimensions'

import {
  fetchAllGoalsThunk,
  getAdminGoalsResponse,
  selectAdminGoalPerParent,
  selectGoalsLoading
} from 'slices/admin'

import { AdminPaths, debounceFetch, getGoalDataWithColors } from 'utils/helpers'

import { CreateButton } from '../shared/styles'

import StoreFilters from '../StoreFilters'
import GoalFilters from './goalFilters'

export default function AdminGoals() {
  const { height, width } = useWindowDimensions()

  const dispatch = useAppDispatch()

  const navigation = useNavigation()

  const [searchValue, setSearchValue] = useState<string>('')
  const [storeFilters, setStoreFilters] = useState<ByStoreFilters>({
    store_id: '',
    group_id: ''
  })
  const [goalFilters, setGoalFilters] = useState<ByGoalFilters>({
    goal_status: ''
  })

  const getFilters = () => ({
    // aggregate all filters here
    ...storeFilters,
    ...goalFilters
  })

  useEffect(() => {
    const queryParams = { ...getFilters(), search: searchValue }
    const payload = { isInitial: true, queryParams }
    const action = fetchAllGoalsThunk(payload)
    dispatch(action)
  }, [storeFilters, goalFilters])

  const allGoalsIsLoading = useSelector(selectGoalsLoading)

  const allGoalsResponse = useSelector(getAdminGoalsResponse)
  const allGoals = useSelector(selectAdminGoalPerParent)

  if (!allGoals.length && allGoalsIsLoading) {
    // only show loading indicator if we have no data and is currently loading
    // since we dispatch multiple actions resulting in the loading state being toggled many times
    // when loading new items in the InfiniteList
    return <HeartLoadingIndicator fullPage />
  }

  if (!allGoalsResponse) {
    return null
  }

  const onSearchChange = (val: string) => {
    setSearchValue(val)
    debounceFetch(dispatch, fetchAllGoalsThunk as typeof fetchAllGoalsThunk, {
      isInitial: true,
      queryParams: { search: val, ...getFilters() }
    })
  }

  const onSearchClear = () => {
    setSearchValue('')
    dispatch(fetchAllGoalsThunk({ isInitial: true, queryParams: getFilters() }))
  }

  const onSelectStoreFilter = (filters: ByStoreFilters) => {
    setStoreFilters(filters)
  }

  const onSelectGoalFilter = (filters: ByGoalFilters) => {
    setGoalFilters(filters)
  }

  const data = getGoalDataWithColors(allGoals)

  const goToForm = (goal?: Goal) => {
    const base = AdminPaths.manageAllGoals
    const path = `${base}/${goal ? goal.id : 'create'}`
    navigation.push(path)
  }

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const goal = data[index]
    if (!goal?.id) return null
    style = { ...style }
    return (
      <GoalsItem
        key={goal.id}
        item={goal}
        onSelectedGoalItem={goToForm}
        style={style}
        hideGoalResult={true}
      />
    )
  }

  const nextPage = allGoalsResponse?.next
  const loadNextPage = () => dispatch(fetchAllGoalsThunk())

  const { group_id, store_id } = storeFilters
  return (
    <>
      <CreateButton
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => goToForm()}
        top={height * 0.83}
        left={width - 140}
      >
        Create +
      </CreateButton>
      <AdminContainer
        headerTitle="Goals"
        filters={
          <>
            <StoreFilters onSelect={onSelectStoreFilter} />
            <GoalFilters onSelect={onSelectGoalFilter} />
          </>
        }
        search={
          <Search
            condensed
            onChange={onSearchChange}
            onClear={onSearchClear}
            value={searchValue}
          />
        }
      >
        <InfiniteList<Goal>
          hasNextPage={!!nextPage}
          isNextPageLoading={allGoalsIsLoading}
          loadNextPage={loadNextPage}
          items={data}
          itemSize={78 + 10} // item height + space between
          renderRow={renderRow}
          disabled={!!(searchValue && store_id && group_id)}
        />
      </AdminContainer>
    </>
  )
}
