import { keyframes, styled } from '@mui/material'

const pulsatingHeart = keyframes`
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
`

export const StyledHeartLoader = styled('div')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  width: '80px',
  height: '80px',
  transform: 'rotate(45deg)',
  transformOrigin: '40px 40px',
  '& div': {
    top: '32px',
    left: '32px',
    position: 'absolute',
    width: '32px',
    height: '32px',
    background: theme.branding.primaryColor,
    animation: `${pulsatingHeart} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1)`,
    '&:after, &:before': {
      content: "' '",
      position: 'absolute',
      display: 'block',
      width: '32px',
      height: '32px',
      background: theme.branding.primaryColor
    },
    '&:before': {
      left: '-24px',
      borderRadius: '50% 0 0 50%'
    },
    '&:after': {
      top: '-24px',
      borderRadius: '50% 50% 0 0'
    }
  }
}))

export const StyledHeartLoaderFullPage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 100px)'
})
