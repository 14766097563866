import { Colors } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

interface CircleLoaderProps extends ComponentProps<typeof ContentLoader> {
  size: number
}

export default function CircleLoader({ size, ...otherProps }: CircleLoaderProps) {
  const radius = size / 2

  return (
    <ContentLoader
      width={size}
      height={size}
      style={{ width: '100%', ...(otherProps?.style || {}) }}
      viewBox={`0 0 ${size} ${size}`}
      backgroundColor={Colors.inactive}
      foregroundColor={Colors.blue}
      data-testid="CircleLoaderPlaceholder"
      {...otherProps}
    >
      <circle cx={radius} cy={radius} r={radius} width={size} height={size} />
    </ContentLoader>
  )
}
