import startCase from 'lodash/startCase'

import { Checkbox, FormControlLabel } from '@mui/material'

import { FieldRenderProps } from 'react-final-form'

import { FormTextFieldProps } from './FormTextField'

export interface FormCheckboxFieldProps<T> extends FieldRenderProps<T> {
  disabled?: boolean
  label?: string
  required?: boolean
}

export default function FormCheckboxField<T>({
  input: { name, value, onChange, checked, ...restInput },
  label,
  disabled = false,
  required = false
}: FormTextFieldProps<T>) {
  const customLabel = startCase(label || name)
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          required={required}
          inputProps={{ ...restInput }}
        />
      }
      label={customLabel}
    />
  )
}
