import { DateTime as dt } from 'luxon'

import { SyntheticEvent } from 'react'

import { StyledDatepicker, TextFieldContainer } from './styles'

interface Props {
  date: string
  onChange: (e: SyntheticEvent) => void
}
export default function FormDateTimeField({ date, onChange }: Props) {
  const ISO = date ? dt.fromJSDate(new Date(date)).toISO() : dt.now().toISO()
  const time = `${ISO.slice(0, 16)}:${ISO.slice(17, 19)}`

  return (
    <TextFieldContainer>
      <StyledDatepicker
        defaultValue={time}
        type="datetime-local"
        fullWidth
        onChange={onChange}
      />
    </TextFieldContainer>
  )
}
