import { Typography } from '@mui/material'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AppIconTypes } from 'types'

import AppIcon from 'components/icons/AppIcon'

import useGlobalModal from 'hooks/useGlobalModal'

import * as S from './useOpenEmailVerificationModal.styles'

export default function useOpenEmailVerificationModal() {
  const { openGlobalModal } = useGlobalModal()
  const { t } = useTranslation()

  const openModal = useCallback((email: string | null) => {
    if (email) {
      openGlobalModal({
        header: <AppIcon type={AppIconTypes.emailVerification} />,
        title: t('modals.global.implementations.userProfile.emailVerification.title'),
        body: (
          <>
            <Typography variant="body1">
              {t('modals.global.implementations.userProfile.emailVerification.body')}
            </Typography>
            <S.VerificationEmailBody>
              <S.VerificationEmailText variant="body1">{email}</S.VerificationEmailText>
            </S.VerificationEmailBody>
          </>
        ),
        showCTA: false
      })
    }
  }, [])

  return openModal
}
