import { styled } from '@mui/material'

export const CheckboxContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap'
}))

export const Checkbox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(2, 4, 4, 0),
  '& input': {
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(2)
  }
}))

export const ColumnFieldsContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderBottom: '2px solid'
}))
