import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import perksIcon from '../../assets/images/perks-unlocked.svg'

import { RedeemedPerk } from 'types'

import { ItemSubHeading } from 'pages/admin/shared/styles'

import CollapsibleRowItem from '../../components/collapsibleRowItem'
import { hasPerkRedemptionValue } from 'components/perkRedemptionCode'
import RowItem from 'components/rowItem'
import { StyledRowItemWrapper } from 'components/rowItem/styles'

import {
  RedeemedPerkItemBoldText,
  RedeemedPerkItemCollapsibleBody,
  RedeemedPerkItemPerkRedemptionCode
} from './styles'

import { amountText, pointsToAmount } from '../../utils/helpers'

interface RedeemedPerkItemProps {
  perk: RedeemedPerk
}

export default function RedeemedPerkItem({ perk }: RedeemedPerkItemProps) {
  const { t } = useTranslation()

  if (!perk) return null

  const {
    points,
    requested_at,
    cash_value,
    reward: { title, image, points_per_unit_cost, configuration }
  } = perk

  const redeemedDate = () => {
    if (!requested_at) {
      return
    }

    const today = new Date()
    if (new Date(requested_at).toDateString() == today.toDateString()) {
      return t('pages.perksWallet.dates.today')
    }

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    if (new Date(requested_at).toDateString() == yesterday.toDateString()) {
      return t('pages.perksWallet.dates.yesterday')
    }

    return new Date(requested_at).toLocaleDateString(
      t('pages.perksWallet.dates.localization'),
      {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit'
      }
    )
  }

  const amount = pointsToAmount(points, points_per_unit_cost)

  const amountString = configuration?.unit
    ? amountText(amount, perk.reward, t)
    : cash_value
    ? `$${cash_value.toFixed(2)}`
    : `${amount}`

  const content = (
    <>
      <StyledRowItemWrapper>
        <div>
          <RedeemedPerkItemBoldText variant="body2">{title}</RedeemedPerkItemBoldText>
          <Typography variant="body2">{amountString}</Typography>
        </div>
        <ItemSubHeading variant="subtitle2">{redeemedDate()}</ItemSubHeading>
      </StyledRowItemWrapper>
    </>
  )

  if (hasPerkRedemptionValue(perk)) {
    return (
      <CollapsibleRowItem
        item={perk}
        image={
          <img
            src={image || perksIcon}
            alt={t('pages.perksWallet.redeemedPerkImageAlt', {
              perkName: title
            })}
          />
        }
        content={content}
      >
        <RedeemedPerkItemCollapsibleBody>
          <RedeemedPerkItemPerkRedemptionCode perk={perk} />
        </RedeemedPerkItemCollapsibleBody>
      </CollapsibleRowItem>
    )
  } else {
    return (
      <RowItem
        item={perk}
        image={
          <img
            src={image || perksIcon}
            alt={t('pages.perksWallet.redeemedPerkImageAlt', {
              perkName: title
            })}
          />
        }
        content={content}
      />
    )
  }
}
