// https://docs.datadoghq.com/logs/log_collection/javascript/
import { datadogLogs } from '@datadog/browser-logs'

import { isDevENV } from './helpers'

type DD_LOG_PARAMS = Record<string, unknown>

const transformParams = (params: DD_LOG_PARAMS) => {
  const stringifiedParams = JSON.stringify(params, null, 2)
  return { ...params, stringifiedParams }
}

export const logToDevConsole = (...params: unknown[]) => {
  if (isDevENV()) {
    // eslint-disable-next-line no-console
    console.debug('Debug: ', ...params)
  }
}

export const logInfo = (text: string, params: DD_LOG_PARAMS = {}) => {
  const newParams = transformParams(params)
  logToDevConsole(text, newParams)
  return datadogLogs.logger.info(text, newParams)
}

export const logWarning = (text: string, params: DD_LOG_PARAMS = {}) => {
  const newParams = transformParams(params)
  logToDevConsole(text, newParams)
  return datadogLogs.logger.warn(text, newParams)
}

export const logError = (text: string, params: DD_LOG_PARAMS = {}) => {
  const newParams = transformParams(params)
  logToDevConsole(text, newParams)
  return datadogLogs.logger.error(text, newParams)
}
