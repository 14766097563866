import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled
} from '@mui/material'

export const StyledBaseModal = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container': {
    alignItems: 'center',
    '& > .MuiPaper-root': {
      margin: 0,
      padding: theme.spacing(5),
      minHeight: '320px',
      borderRadius: theme.borderRadius.medium
    }
  }
}))

export const StyledBaseModalTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.customTypography.display2,
  padding: theme.spacing(0, 2, 1),
  textAlign: 'center'
}))

export const StyledBaseModalContent = styled(DialogContent)({
  padding: 0
})

export const StyledBaseModalActions = styled(DialogActions)({
  justifyContent: 'center'
})

export const StyledBaseModalButton = styled(Button)({
  minWidth: '175px'
})

export const StyledBaseModalHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(5)
}))

export const StyledBaseModalCloseButtonHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  marginBottom: theme.spacing(2)
}))
