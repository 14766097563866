import OnboardingHeaderButtons from './Buttons'
import { OnboardingConfettiBackground } from './ConfettiBackground'
import { OnboardingLogo } from './Logo'

export interface OnboardingHeaderProps {
  activePageIndex: number
  isInitialPage: boolean
  shouldAnimateRemoveLogo: boolean
  onBack: () => void
  onSkip: () => void
}

const OnboardingHeader = ({
  activePageIndex,
  isInitialPage,
  shouldAnimateRemoveLogo,
  onBack,
  onSkip
}: OnboardingHeaderProps) => {
  const shouldShowConfetti = activePageIndex === -1 || activePageIndex === 2
  const shouldAnimateLogoRemoval = activePageIndex === 0 && shouldAnimateRemoveLogo
  const isPerkStep = activePageIndex === 1

  return (
    <>
      <OnboardingConfettiBackground shouldShowConfetti={shouldShowConfetti} />
      <OnboardingLogo
        isInitialPage={isInitialPage}
        shouldAnimateLogoRemoval={shouldAnimateLogoRemoval}
      />
      <OnboardingHeaderButtons
        onBack={onBack}
        onSkip={onSkip}
        isInitialPage={isInitialPage}
        isPerkStep={isPerkStep}
      />
    </>
  )
}

export default OnboardingHeader
