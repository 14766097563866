import { createContext } from 'react'

export interface StepContextType {
  isOnSmallDevice: boolean
  isOnTinyDevice: boolean
  selectedPerkIds: string[]
  firstName?: string
  birthday: string | null
  email: string | null
  setFirstName: (firstName?: string) => void
  setBirthday: (birthday: string | null) => void
  setSelectedPerkIds: (perkIds: string[]) => void
  setEmail: (email: string | null) => void
}

export default createContext<StepContextType>({
  isOnSmallDevice: false,
  isOnTinyDevice: false,
  selectedPerkIds: [],
  firstName: '',
  birthday: null,
  email: null,
  setFirstName: () => {},
  setBirthday: () => {},
  setSelectedPerkIds: () => {},
  setEmail: () => {}
})
