export const ONAROLL_INTERCOM_NUMBER = '+18554590130'

export const cleanPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, '') // replaces all non-digit characters with ''
  return cleaned
}

export const isValidPhoneNumber = (phone: string) => {
  const regex = /^\d{10}$/
  return regex.test(phone)
}
