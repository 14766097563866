import { Button } from '@mui/material'

import { ReactComponent as PerksIcon } from 'assets/icons/AppIcons/PerksIcon.svg'

import { RedeemedPerk, RewardType } from 'types'

import PerkRedemptionCode, { hasPerkRedemptionValue } from 'components/perkRedemptionCode'

import {
  StyledPerkModalItem,
  StyledPerkModalItemLogo,
  StyledPerkModalItemTitle
} from './styles'

interface PerkModalItemProps {
  perk: RedeemedPerk
}

export default function PerkModalItem({ perk }: PerkModalItemProps) {
  const hasRedemptionValue = hasPerkRedemptionValue(perk)
  const {
    reward: { image, title, reward_type },
    points
  } = perk
  const content = (
    <>
      <StyledPerkModalItemTitle variant="h4">{title}</StyledPerkModalItemTitle>
    </>
  )

  const extraInfo =
    reward_type === RewardType.cash ? (
      <></>
    ) : (
      <Button variant="outlined" size="small">
        {points} pts
      </Button>
    )

  return (
    <StyledPerkModalItem
      component="div"
      item={perk}
      image={
        image ? (
          <StyledPerkModalItemLogo src={image} alt={`${title} perk icon`} />
        ) : (
          <PerksIcon />
        )
      }
      content={content}
      extraInfo={extraInfo}
      showDivider={hasRedemptionValue}
    >
      <PerkRedemptionCode perk={perk} />
    </StyledPerkModalItem>
  )
}
