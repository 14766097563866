import { Colors } from 'theme'

export interface NavIndexDotsProps {
  defaultColor?: string
  activeColor?: string
  activeIndex?: number
  numDots?: number
  dotSize?: number
  dotDistanceBetween?: number
}

const getFloorCeilIndex = (index: number, numDots: number) => {
  const maxIndex = numDots - 1
  if (index < 0) return 0
  else if (index > maxIndex) return maxIndex
  else return index
}

const NavIndexDots = ({
  defaultColor = Colors.blue,
  activeColor = Colors.black,
  activeIndex = 0,
  numDots = 5,
  dotSize = 4.5,
  dotDistanceBetween = 29
}: NavIndexDotsProps) => {
  const index = getFloorCeilIndex(activeIndex, numDots)

  const getColorForIndex = (circleIndex: number) => {
    if (circleIndex === index) return activeColor
    return defaultColor
  }

  const width = dotSize + dotDistanceBetween * (numDots + 1)
  const height = 9

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {new Array(numDots).fill('').map((_, index) => (
        <circle
          data-testid="PageIndexDot"
          key={index}
          cx={(index + 1) * dotDistanceBetween + dotSize}
          cy={dotSize}
          r={dotSize}
          fill={getColorForIndex(index)}
        />
      ))}
    </svg>
  )
}

export default NavIndexDots
