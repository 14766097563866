import { Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CopyFieldContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const CopyTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2, 3, 2, 0),
  '& .MuiFilledInput-root': { borderRadius: '4px' },
  '& .MuiFilledInput-underline:before': { border: 'none !important' },
  '& .MuiFilledInput-underline:after': { border: 'none' }
}))
