import { GoalFrequencyOption } from 'types'

export const TODAY = 'today'
export const YESTERDAY = 'yesterday'

export const FREQUENCY_SUBCOPY = {
  [GoalFrequencyOption.daily]: 'Points awarded within 36 hours',
  [GoalFrequencyOption.weekly]: 'Points awarded once a week',
  [GoalFrequencyOption.monthly]: 'Points awwarded once a month'
}
