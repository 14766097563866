import { Button, Card, styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'
import { Field } from 'react-final-form'

import PageWithSubheader from 'layouts/pageWithSubheader'

import AppIcon from 'components/icons/AppIcon'

import UrgencyAlert from 'components/urgencyAlert'

export const StyledUserProfile = styled(PageWithSubheader)(({ theme }) => ({
  '.LayoutPageWithSubheader__header': {
    background: 'inherit'
  },
  '.LayoutPageWithSubheader__content': {
    background: theme.branding.lightTextColor
  }
}))

export const StyledUserProfileFields = styled('section')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  // Magical breakpoint to account for different browser stylings
  // for native input elements like (date picker)
  '@media (max-width: 395px)': {
    gridTemplateColumns: '1fr'
  }
})

export const StyledUserProfileField = styled(Field)(({ theme }) => ({
  margin: theme.spacing(2),
  minWidth: '170px',
  '.MuiInputLabel-root': {
    top: '-26px',
    color: theme.branding.subTextColor,
    ...theme.customTypography.body1Size
  },
  '.MuiInputBase-root': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'bold'
  }
})) as typeof Field

export const StyledUserProfileActionSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(6)
}))

export const StyledUserProfileActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

export const StyledUserProfileSubheader = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 5),
  padding: theme.spacing(0, 5),
  textAlign: 'center',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(155px, 1fr))',
  gridTemplateRows: 'repeat(auto-fit, 110px)',
  gridGap: theme.spacing(3),
  width: '100%'
}))

export const StyledUserProfileSubheaderTitle = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  lineHeight: '32px'
}))

const StyledUserProfileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4, 5)
}))

export const StyledUserProfileGoalsCard = styled(StyledUserProfileCard)(({ theme }) => ({
  color: theme.branding.primaryColor
}))

export const StyledUserProfilePerksCard = styled(StyledUserProfileCard)(({ theme }) => ({
  color: theme.branding.subTextColor
}))

export const StyledForm = styled('form')(({ theme }) => ({
  '.MuiFormControl-root': {
    marginBottom: theme.spacing(3)
  }
}))

export const StyledEmailUnverifiedAlert = styled(UrgencyAlert, {
  shouldForwardProp: (prop) => prop !== 'bold'
})<{ bold?: boolean }>(({ bold }) => ({
  fontWeight: bold ? 'bold' : 'normal'
}))

export const StyledEmailVerifiedIcon = styled(AppIcon, {
  shouldForwardProp: (prop) => prop !== 'verified'
})<{ verified?: boolean }>(({ verified }) => ({
  fill: verified ? 'green' : ''
}))
