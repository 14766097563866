import { createSelector } from '@reduxjs/toolkit'

import { AUTH } from 'slices/constants'

import { getIsImpersonator } from 'slices/impersonation'

import { RootState } from '../../reduxStore'

export const getAuthErrorMsg = (state: RootState) => state[AUTH].error

export const getAuthIsLoading = (state: RootState) => state[AUTH].isLoading
export const getAuthHasLoaded = (state: RootState) => state.auth.hasLoaded

export const getAccessToken = (state: RootState) => state[AUTH].accessToken
export const getRefreshToken = (state: RootState) => state[AUTH].refreshToken
export const getLoginToken = (state: RootState) => state[AUTH].loginToken

export const getIsAuthenticated = createSelector(
  getAccessToken,
  getRefreshToken,
  getIsImpersonator,
  (accessToken, refreshToken, isImpersonator) =>
    !!((accessToken && refreshToken) || (accessToken && isImpersonator))
)

export const getAuthTokens = (state: RootState) => ({
  access: state.auth.accessToken,
  refresh: state.auth.refreshToken
})
