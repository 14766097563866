import { useState } from 'react'

import { DailyQuestion } from 'types'

import {
  StyledTriviaFormContent,
  StyledTriviaFormContentButton
} from './onarollTriviaForm.styles'

interface TriviaFormProps {
  question: DailyQuestion
  onChange: (answer: string) => void
}

export interface TriviaFormContentStyleProps {
  shouldExpand: boolean
}

export interface TriviaFormContentButtonStyleProps {
  isAnswer: boolean
}

export default function OnarollTriviaForm({ onChange, question }: TriviaFormProps) {
  const [answerValue, setAnswerValue] = useState('')

  const onSelectAnswer = (choiceId: string) => {
    setAnswerValue(choiceId)
    onChange(choiceId)
  }

  const shouldExpand = question.choices.some(
    (choice) => String(choice.choice_text).length >= 3
  )

  return (
    <StyledTriviaFormContent shouldExpand={shouldExpand}>
      {question.choices.map(({ choice_id, choice_text }) => (
        <StyledTriviaFormContentButton
          key={choice_id}
          isAnswer={choice_id === answerValue}
          variant="text"
          onClick={() => onSelectAnswer(choice_id)}
        >
          {choice_text}
        </StyledTriviaFormContentButton>
      ))}
    </StyledTriviaFormContent>
  )
}
