import { styled } from '@mui/material/styles'

export const StyledDivider = styled('div', {
  shouldForwardProp: (prop) => (prop !== 'hasText' && prop !== 'useGradient')
})<{ hasText?: boolean; useGradient?: boolean }>(({ hasText, useGradient, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: `${theme.spacing(2)} auto`,
  overflow: 'hidden',
  '.MuiTypography-root': {
    color: theme.palette.colors.grayDisabled,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  '&::before, &::after': {
    content: '""',
    display: 'block',
    height: theme.spacing(0.3),
    minWidth: '60%'
  },
  '&::before': {
    background: useGradient
      ? `linear-gradient(to right, rgba(240,240,240,0), ${theme.palette.colors.grayDisabled})`
      : theme.palette.colors.grayDisabled,
    marginRight: hasText ? theme.spacing(3) : 0
  },
  '&::after': {
    background: useGradient
      ? `linear-gradient(to left, rgba(240,240,240,0), ${theme.palette.colors.grayDisabled})`
      : theme.palette.colors.grayDisabled,
    marginLeft: hasText ? theme.spacing(3) : 0
  }
}))
