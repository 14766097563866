// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useScrollToTop } from 'hooks/useScrollToTop'

// Component wrapper around the `useScrollToTop` hook.
// TODO: Do we really even need this? Not sure why we can't just call the hook direcly in the component that needs it
export function ScrollToTop() {
  useScrollToTop()
  return null
}

export default ScrollToTop
