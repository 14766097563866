// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import useAppDispatch from 'hooks/useAppDispatch'
import { useNavigation } from 'hooks/useNavigation'
import { fetchJackpotCurrentTicketInfoThunk, getJackpotCurrentTicketCount } from 'slices/jackpot'
import { fetchOnboardingPerksThunk, onboardingPerksSelectors } from 'slices/perks'
import { fetchPointTotalsThunk, getPointsBalance } from 'slices/points'
import { Colors } from 'theme'
import { RootPaths, abbreviateNumber, onA11yKeyDown } from 'utils/helpers'

import { Grid } from '@mui/material'

import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import perksIcon from '../../../assets/images/perks-unlocked.svg'
import {
  StyledAccentTile,
  StyledJackpotTileEmptyIcon,
  StyledOnboardingPerkLogo,
  StyledPrimaryTile,
  StyledSecondaryTile,
  StyledTileCTA,
  StyledTileCTAShort,
  StyledTileDisplay,
  StyledTileSubtitle,
  StyledTileTitle
} from '../home.styles'
import HomeSection from '../homeSection'
import { RewardsTileContainer } from './styles'

export default function HomeRewardsSection() {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const currentTickets = useSelector(getJackpotCurrentTicketCount)
  const pointsBalance = useSelector(getPointsBalance)
  const onboardingPerks = useSelector(onboardingPerksSelectors.selectAll)

  const displayOnboardingPerkTile = onboardingPerks.length > 0
  const onboardingPerk = onboardingPerks[0]?.reward

  useEffect(() => {
    dispatch(fetchPointTotalsThunk({ getCachedResults: true }))
    dispatch(fetchJackpotCurrentTicketInfoThunk({ getCachedResults: true }))
    dispatch(fetchOnboardingPerksThunk())
  }, [dispatch])

  const goToPerks = () => {
    navigation.push(RootPaths.perks)
  }

  const goToJackpot = () => {
    navigation.push(RootPaths.jackpot)
  }

  const goToFreeOnboardingPerk = () => {
    if (onboardingPerk?.id) {
      navigation.replace(`${RootPaths.perks}/${onboardingPerk.id}`)
    }
  }

  const title = useMemo(() => {
    return displayOnboardingPerkTile
      ? t('pages.home.section.freePerk.title')
      : t('pages.home.section.rewards.title')
  }, [displayOnboardingPerkTile])

  const pointsAndJackpotTiles = (
    <RewardsTileContainer>
      <StyledAccentTile
        component="article"
        elevation={0}
        role="button"
        tabIndex={0}
        onClick={goToPerks}
        onKeyDown={(event) => onA11yKeyDown(event, goToPerks)}
      >
        <StyledTileDisplay>{abbreviateNumber(pointsBalance)}</StyledTileDisplay>
        <StyledTileSubtitle variant="h5" component="p">
          {t('pages.home.totalPoints.subtitle')}
        </StyledTileSubtitle>
        <StyledTileCTAShort variant="contained" color="secondary">
          {t('pages.home.totalPoints.cta')}
        </StyledTileCTAShort>
      </StyledAccentTile>
      <StyledSecondaryTile
        component="article"
        elevation={0}
        role="button"
        tabIndex={0}
        onClick={goToJackpot}
        onKeyDown={(event) => onA11yKeyDown(event, goToJackpot)}
      >
        <StyledTileDisplay>
          <span>{currentTickets || null}</span>
          <StyledJackpotTileEmptyIcon currentTickets={currentTickets} />
        </StyledTileDisplay>
        <StyledTileSubtitle variant="h5" component="p">
          {t('pages.home.totalJackpotTickets.subtitle')}
        </StyledTileSubtitle>
        <StyledTileCTAShort variant="contained" color="primary">
          {t('pages.home.totalJackpotTickets.cta')}
        </StyledTileCTAShort>
      </StyledSecondaryTile>
    </RewardsTileContainer>
  )

  const freeOnboardingPerkTile = (
    <Grid item xs={12}>
      <StyledPrimaryTile
        component="article"
        elevation={0}
        role="button"
        tabIndex={0}
        onClick={goToFreeOnboardingPerk}
        onKeyDown={(event) => onA11yKeyDown(event, goToFreeOnboardingPerk)}
      >
        <StyledOnboardingPerkLogo
          src={onboardingPerk?.image || perksIcon}
          alt={`${onboardingPerk?.title} perk icon`}
        />
        <StyledTileTitle variant="body2" component="p" color={Colors.purple}>
          {onboardingPerk?.title}
        </StyledTileTitle>
        <StyledTileTitle variant="body2" component="p">
          {t('pages.home.freePerk.title')}
        </StyledTileTitle>
        <StyledTileCTA variant="contained" color="primary">
          {t('pages.home.freePerk.cta')}
        </StyledTileCTA>
      </StyledPrimaryTile>
    </Grid>
  )

  return (
    <HomeSection
      title={title}
      subTitle={displayOnboardingPerkTile ? '' : t('pages.home.section.rewards.subTitle')}
    >
      {displayOnboardingPerkTile ? freeOnboardingPerkTile : pointsAndJackpotTiles}
    </HomeSection>
  )
}
