import { useCallback, useState } from 'react'

const useDynamicHeight = () => {
  const [height, setHeight] = useState(245)

  const measuredRef = useCallback((node: HTMLDivElement) => {
    /* `ref` that the `node` is referencing here would be the whole modal including the backdrop.
     * We need to find the modal content (visible portion we show our users)
     * Once the content component is found, we try to get the height of it's underlying content
     * We then use this to dynamically figure out where to display the confetti background!
     * This is because, due to different screen resolutions, it will cause the modal height to be different
     * which will make the static height of (245px) useless.
     * Note - See the specified `minHeight` in components/modals/bottomModal/styles.ts
     */
    if (node !== null) {
      const modalContent = node.querySelector('.MuiDialog-container > .MuiDialog-paper')
      modalContent && setHeight(modalContent.clientHeight)
    }
  }, [])

  return [height, measuredRef]
}

export default useDynamicHeight
