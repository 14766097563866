import clsx from 'clsx'

import { Box } from '@mui/material'

import { Children } from 'react'

import { TabsClasses } from '../TabsClasses'

interface TabViewProps {
  children?: React.ReactNode
  index: number
  value: number
  className?: string
}

export default function TabView({
  children,
  value = 0,
  index = 0,
  className,
  ...otherProps
}: TabViewProps) {
  return (
    <div
      className={clsx(TabsClasses.VIEW_CONTAINER, className)}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...otherProps}
    >
      {value === index && Children.count(children) >= 1 && (
        <Box sx={{ p: 3 }} data-testid="TabViewContent" className={TabsClasses.VIEW}>
          {children}
        </Box>
      )}
    </div>
  )
}
