import { styled } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

export const StyledGoalProgressImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice' && prop !== 'isOnTinyDevice'
})<{ isOnSmallDevice?: boolean; isOnTinyDevice?: boolean }>(
  ({ isOnSmallDevice, isOnTinyDevice, theme }) => ({
    height: '38vh',
    width: '100%',
    ...conditionalObject(
      { marginTop: theme.spacing(-4) },
      isOnSmallDevice && !isOnTinyDevice
    ),
    ...conditionalObject(
      {
        height: '45vh',
        marginTop: theme.spacing(-4)
      },
      isOnTinyDevice
    )
  })
)
