import { createSelector } from '@reduxjs/toolkit'

import { GoalsHistory } from 'types'

import { getTodaysDate, getYesterdaysDate } from 'utils/form'
import { aggregateValues } from 'utils/helpers'

import { RootState } from 'reduxStore'

export const selectGoalProgressByDate = (state: RootState) =>
  state.goals.goalProgressByDate

export const selectCombinedGoalProgressByDate = (state: RootState) =>
  state.goals.combinedGoalProgressByDate

export const getGoalsLoading = (state: RootState) => state.goals.isLoading

export const getGoalsOnDateLoadingObject = (state: RootState) =>
  state.goals.inFlightRequestsOnDate

export const getGoalsOnDateLoading = createSelector(
  getGoalsOnDateLoadingObject,
  (_state: RootState, date: string) => date,
  (goalsOnDateLoadingObj: Record<string, boolean>, date: string) => {
    return !!goalsOnDateLoadingObj[date]
  }
)

export const getAchievedGoals = (state: RootState) => state.goals.achievedGoals

export const getGoalsOnDate = createSelector(
  selectGoalProgressByDate,
  (_state: RootState, date: string) => date,
  (goals: GoalsHistory | null, date: string) => {
    if (!date) {
      return null
    }
    return goals?.[date]
  }
)

export const getCombinedGoalsOnDate = createSelector(
  selectCombinedGoalProgressByDate,
  (_state: RootState, date: string) => date,
  (goals: GoalsHistory | null, date: string) => {
    if (!date) {
      return null
    }
    return goals?.[date]
  }
)

export const getYesterdaysGoals = (state: RootState) =>
  getCombinedGoalsOnDate(state, getYesterdaysDate())

export const getYesterdaysAchievedGoals = createSelector(getYesterdaysGoals, (goals) =>
  goals?.filter((goal) => goal.completed)
)

export const getTodaysGoals = (state: RootState) =>
  getCombinedGoalsOnDate(state, getTodaysDate())

// Get max number of points you can earn for today's shift based on your goals
export const getTotalEarnableGoalPoints = createSelector(getTodaysGoals, (goals) =>
  aggregateValues(goals ?? [], 'points')
)

export const getTotalPointsFromTodaysCompletedGoals = createSelector(
  getTodaysGoals,
  (goals) => {
    const completedGoals = (goals ?? []).filter((goal) => goal.completed)
    return aggregateValues(completedGoals, 'points')
  }
)

export const getTotalPointsFromYesterdaysCompletedGoals = createSelector(
  getYesterdaysGoals,
  (goals) => {
    const completedGoals = (goals ?? []).filter((goal) => goal.completed)
    return aggregateValues(completedGoals, 'points')
  }
)
