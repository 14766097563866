import { styled } from '@mui/material'

import BaseModal from '../baseModal'

export const StyledBottomModal = styled(BaseModal)({
  '.MuiDialog-container': {
    alignItems: 'flex-end',
    '& > .MuiPaper-root': {
      borderTopLeftRadius: '40px',
      borderTopRightRadius: '40px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: '100%',
      maxWidth: 'none'
    }
  }
})
