import { Colors, getTheme } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

interface PerkItemLoaderProps extends ComponentProps<typeof ContentLoader> {
  bgColor?: keyof typeof Colors
  fgColor?: keyof typeof Colors
  boxColor?: keyof typeof Colors
  width?: string | number | undefined
  height?: string | number | undefined
}

export default function PerkItemLoader({
  bgColor = Colors.inactive as keyof typeof Colors,
  fgColor = Colors.blue as keyof typeof Colors,
  boxColor,
  width = 152,
  height = 170,
  ...otherProps
}: PerkItemLoaderProps) {
  return (
    <ContentLoader
      width={width}
      height={height}
      style={{
        width: '100%',
        backgroundColor: boxColor ? Colors[boxColor] : 'transparent',
        borderRadius: getTheme().borderRadius.medium,
        ...(otherProps?.style || {})
      }}
      viewBox="0 0 152 170"
      backgroundColor={bgColor}
      foregroundColor={fgColor}
      {...otherProps}
    >
      {/* lock points button */}
      <rect x="80" y="13" rx="12" ry="12" width="60" height="20" />
      {/* icon */}
      <rect x="52" y="47" rx="10" ry="10" width="48" height="48" />
      {/* text */}
      <rect x="26" y="105" rx="3" ry="3" width="100" height="10" />
      <rect x="11" y="125" rx="3" ry="3" width="130" height="10" />
      {/* brand color bar */}
      <rect x="44" y="145" rx="3" ry="3" width="64" height="5" />
    </ContentLoader>
  )
}
