import Colors from './Colors'

export const OnarollColorPalette = {
  primaryColor: Colors.purple,
  secondaryColor: Colors.yellow,
  accentColor: Colors.black,
  backgroundColor: Colors.offWhite,
  darkTextColor: Colors.black,
  lightTextColor: Colors.white,
  subTextColor: Colors.gray,
  inactiveColor: Colors.inactive,
  cardColor: Colors.white
}
