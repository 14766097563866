// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ComponentType, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Store } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import { useGetGoalsOnDate } from 'hooks'
import { usePrevious } from 'hooks/usePrevious'

import { fetchDailyQuestionThunk } from 'slices/dailyQuestion'
import { fetchOnboardingPerksThunk } from 'slices/perks'
import { getDefaultSelectedStore } from 'slices/storeSwitcher'

import { getTodaysDate, getYesterdaysDate } from 'utils/form'

export default function withStoreDataRefresh<T>(WrappedComponent: ComponentType<T>) {
  const ComponentThatRefreshesOnStoreChange = (props: T) => {
    const dispatch = useAppDispatch()

    const { getGoalsOnDate, shouldFetchGoalServiceGoals } = useGetGoalsOnDate()

    const selectedStore = useSelector(getDefaultSelectedStore)
    const previousSelectedStore = usePrevious(selectedStore)

    const isInitialRender = (store?: Store) => {
      // If previous is defined, we have changed the store more than once
      // which means that it is NOT the default selected store
      // so we can safely refetch data since we have manually changed the store
      return !(store && previousSelectedStore !== undefined)
    }

    useEffect(() => {
      if (isInitialRender(selectedStore)) {
        // Note: previousSelectedStore will always be undefined on initial render
        // If we are on the initial render, we should NOT refetch anything
        return
      }

      getGoalsOnDate({ date: getTodaysDate(), getCachedResults: false })
      getGoalsOnDate({ date: getYesterdaysDate(), getCachedResults: false })

      dispatch(fetchDailyQuestionThunk())
      dispatch(fetchOnboardingPerksThunk({}))
    }, [selectedStore?.id, shouldFetchGoalServiceGoals])

    return <WrappedComponent {...props} />
  }
  return ComponentThatRefreshesOnStoreChange
}
