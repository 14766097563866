import { useTranslation } from 'react-i18next'

import {
  StyledManagerSupportContainer,
  StyledManagerSupportLink
} from './managerSupport.styles'

import { TEAM_MEMBER_HELP_URL } from './consts'

export function ManagerSupport({ ...restProps }) {
  const { t } = useTranslation()

  return (
    <StyledManagerSupportContainer {...restProps} data-testid="ManagerSupport">
      {t('pages.login.form.enabled.managerSupportMessage.prevText')}
      <StyledManagerSupportLink
        target="_blank"
        rel="noreferrer"
        href={TEAM_MEMBER_HELP_URL}
      >
        {t('pages.login.form.enabled.managerSupportMessage.linkText')}
      </StyledManagerSupportLink>
      {t('pages.login.form.enabled.managerSupportMessage.nextText')}
    </StyledManagerSupportContainer>
  )
}

export default ManagerSupport
