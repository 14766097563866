import { Collapse } from '@mui/material'

import { ReactElement, useState } from 'react'

import FlippableCaretIcon from 'components/icons/FlippableCaretIcon/FlippableCaretIcon'

import { StyledCollapsibleRowItem, StyledCollapsibleRowItemDivider } from './styles'

import { RowItemProps } from '../rowItem'

export interface CollapsibleRowItemProps<T> extends RowItemProps<T> {
  children: ReactElement // override children in order to make required
}

export function CollapsibleRowItem<T>({
  children,
  ...restProps
}: CollapsibleRowItemProps<T>) {
  const [isOpen, setIsOpen] = useState(false)

  // Override onClick
  const onClick = () => {
    setIsOpen((prevState) => !prevState)
  }

  // Override extraInfo
  const extraInfo = <FlippableCaretIcon flip={isOpen} />

  const hasContent = () => {
    const { image, header, content } = restProps
    return Boolean(image || header || content)
  }

  return (
    <StyledCollapsibleRowItem
      data-testid="CollapsibleRowItem"
      {...restProps}
      extraInfo={extraInfo}
      onClick={onClick}
    >
      <Collapse in={isOpen} data-testid="CollapsibleRowItemCollapse">
        {/* Since RowItem also has a divider, we need to make sure we don't display both at once */}
        {!restProps.showDivider && hasContent() && <StyledCollapsibleRowItemDivider />}
        {children}
      </Collapse>
    </StyledCollapsibleRowItem>
  )
}

export default CollapsibleRowItem
