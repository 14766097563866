import { reverse, sortBy } from 'lodash'
import { createSelector } from 'reselect'

import { ADMIN_CSV_SCHEMAS } from 'slices/constants'

import { RootState } from 'reduxStore'

import { CSVSchemasAdapter } from '.'

export const CSVSchemaSelectors = CSVSchemasAdapter.getSelectors<RootState>(
  (state) => state[ADMIN_CSV_SCHEMAS]
)

export const selectCSVSchemas = createSelector(
  CSVSchemaSelectors.selectAll,
  (schemas) => reverse(sortBy(schemas, ['created'])) // NOTE: lodash's sortBy return's a new array to avoid mutation
)

export const getSchemasResponse = (state: RootState) =>
  state[ADMIN_CSV_SCHEMAS].csvSchemaResponse

export const getCSVSchemasLoading = (state: RootState) =>
  state[ADMIN_CSV_SCHEMAS].isLoading
