import { getContrastRatio } from '@mui/material/styles'

import { useLocation } from 'react-router-dom'

import { Brand } from 'types'

import useTheme from 'hooks/useTheme'

import { RootPaths } from 'utils/helpers'

import { BrandLogoImage } from './styles'

// Contrast ratio between the dark text color and the background color

export interface BrandLogoProps {
  brand?: Partial<Brand>
}

export const BrandLogo = ({ brand }: BrandLogoProps) => {
  const location = useLocation()
  const currentTheme = useTheme()

  if (!brand) {
    return null
  }

  const { logo, logo_light, name, background_color, dark_text_color, primary_color } =
    brand

  if (!logo && !logo_light) {
    return null
  }

  if (logo && !logo_light) {
    return <BrandLogoImage src={logo} alt={`${name}'s logo`} />
  }

  if (!logo && logo_light) {
    return <BrandLogoImage src={logo_light} alt={`${name}'s logo`} />
  }

  const COLOR_CONTRAST = 9
  const darkTextColor = dark_text_color || currentTheme.palette.text.primary
  const bgColor = background_color || currentTheme.palette.background.default
  const primaryColor = primary_color || currentTheme.palette.primary.main

  // Check if the current path has dark background or if primary color is set in branding
  const isDarkBgColorPerk = [`${RootPaths.perks}`].includes(location.pathname)
  const isDarkBgColorPath = [`${RootPaths.jackpot}`, `${RootPaths.perks}`].includes(
    location.pathname
  )

  // get the contrast ratio based on background color for all pages
  const bgContrastRatio = getContrastRatio(bgColor, darkTextColor)

  // get the contrast ratio based on primary color for all perks page
  const primaryTextContrastRatio = getContrastRatio(primaryColor, darkTextColor)

  const getLogoSrc = () => {
    if (primaryColor && isDarkBgColorPerk) {
      return primaryTextContrastRatio > COLOR_CONTRAST ? logo_light : logo
    }

    if (isDarkBgColorPath) return logo
    else return bgContrastRatio > COLOR_CONTRAST ? logo_light : logo
  }

  return <BrandLogoImage src={getLogoSrc()} alt={`${name}'s logo`} />
}
