import {
  AdminListFilters,
  CelebrationPoint,
  DistributionPointsResponse,
  PaginatedCelebrationPoints,
  FetchWithParamsAndLazyLoadProps,
  UpsertThunkProps
} from 'types'

import { fetchRecords, postRecord } from 'api'
import ENDPOINTS from 'api/endpoints'

import { ADMIN_CELEBRATION_POINTS } from 'slices/constants'

import { authenticatedThunkCreator, getTransformedParams } from 'slices/utils'

import { RootState } from 'reduxStore'

import { getCelebrationPointsResponse } from '.'

const CELEBRATION_POINTS_ENDPOINT: keyof typeof ENDPOINTS = 'adminCelebrationPoints'
const DISTRIBUTION_POINTS_ENDPOINT: keyof typeof ENDPOINTS = 'adminDistributionPoints'

export const fetchAllCelebrationPointsThunk = authenticatedThunkCreator<
  PaginatedCelebrationPoints,
  FetchWithParamsAndLazyLoadProps<AdminListFilters>
>(
  `${ADMIN_CELEBRATION_POINTS}/fetchAllCelebrationPointsThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const prevCelebrationPoints = getCelebrationPointsResponse(state)
    const params = getTransformedParams(prevCelebrationPoints, authedDTO)

    const celebrationPoints: PaginatedCelebrationPoints = await fetchRecords(
      {
        ...authedDTO,
        queryParams: params
      },
      CELEBRATION_POINTS_ENDPOINT
    )
    return celebrationPoints
  }
)

export const sendCelebrationPointsThunk = authenticatedThunkCreator<
  CelebrationPoint,
  UpsertThunkProps<Partial<CelebrationPoint>>
>(`${ADMIN_CELEBRATION_POINTS}/sendCelebrationPointsThunk`, async (authedDTO) => {
  const celebrationPoint: CelebrationPoint = await postRecord(
    authedDTO,
    CELEBRATION_POINTS_ENDPOINT
  )
  return celebrationPoint
})

export const fetchDistributionPointsThunk =
  authenticatedThunkCreator<DistributionPointsResponse>(
    `${ADMIN_CELEBRATION_POINTS}/fetchDistributionPointsThunk`,
    async (authedDTO) => {
      const distributionPoints: DistributionPointsResponse = await fetchRecords(
        authedDTO,
        DISTRIBUTION_POINTS_ENDPOINT
      )
      return distributionPoints
    }
  )
