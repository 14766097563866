import { AdminListFilters, PaginatedStoreGroups, FetchWithParamsThunk } from 'types'

import { fetchRecords } from 'api'

import { STORE_GROUPS } from 'slices/constants'

import { getImpersonator } from 'slices/impersonation'
import {
  authenticatedThunkCreator,
  formatParamsRequest,
  shouldRefetch
} from 'slices/utils'

import { RootState } from 'reduxStore'

import { storeGroupsSelectors, selectStoreGroupsLoading } from '..'

export const fetchStoreGroupsThunk = authenticatedThunkCreator<
  PaginatedStoreGroups,
  FetchWithParamsThunk<AdminListFilters>
>(
  `${STORE_GROUPS}/fetchStoreGroupsThunk`,
  async (authedDTO) => {
    const request = formatParamsRequest(authedDTO)
    return await fetchRecords(request, STORE_GROUPS)
  },
  {
    condition: (options, { getState }) => {
      const state = getState() as RootState
      const isUpdating = selectStoreGroupsLoading(state)
      const prevStoreGroups = storeGroupsSelectors.selectAll(state)
      const impersonator = getImpersonator(state)

      return shouldRefetch(isUpdating, prevStoreGroups, impersonator, options)
    }
  }
)
