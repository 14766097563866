import { Colors } from 'theme'

import { StyledSplash, StyledSplashSpacing } from './styles'

import BrandLogoWithText from '../brandLogoWithText'

export function Splash() {
  return (
    <StyledSplash hideFooter hideHeader>
      <StyledSplashSpacing>
        <BrandLogoWithText logoTextColor={Colors.white} />
      </StyledSplashSpacing>
    </StyledSplash>
  )
}

export default Splash
