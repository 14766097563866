import {
  AdminListFilters,
  CSVSchema,
  PaginatedCSVSchemas,
  FetchOneDTO,
  FetchWithParamsAndLazyLoadProps,
  UpsertThunkProps
} from 'types'

import { fetchRecord, fetchRecords, postRecord, putRecord } from 'api'

import { ADMIN_CSV_SCHEMAS } from 'slices/constants'

import {
  authenticatedThunkCreator,
  getTransformedParams,
  returnCachedData
} from 'slices/utils'

import { RootState } from 'reduxStore'

import { getCSVSchemasLoading, getSchemasResponse } from '.'

const SCHEMAS_ENDPOINT = 'csvSchemas'

export const fetchAllCSVSchemasThunk = authenticatedThunkCreator<
  PaginatedCSVSchemas,
  FetchWithParamsAndLazyLoadProps<AdminListFilters>
>(
  `${ADMIN_CSV_SCHEMAS}/fetchAllCSVSchemasThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const prevCSVSchemas = getSchemasResponse(state)
    const params = getTransformedParams(prevCSVSchemas, authedDTO)

    if (returnCachedData(prevCSVSchemas, authedDTO, params)) return prevCSVSchemas

    const allCSVSchemas: PaginatedCSVSchemas = await fetchRecords(
      {
        ...authedDTO,
        queryParams: params
      },
      SCHEMAS_ENDPOINT
    )
    return allCSVSchemas
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState
      const isLoading = getCSVSchemasLoading(state)
      if (isLoading) {
        // Cancel execution if a request is already in progress
        return false
      }
    }
  }
)

export const fetchCSVSchemaThunk = authenticatedThunkCreator<CSVSchema, FetchOneDTO>(
  `${ADMIN_CSV_SCHEMAS}/fetchCSVSchemaThunk`,
  async (authedDTO) => {
    const csvSchema: CSVSchema = await fetchRecord(authedDTO, SCHEMAS_ENDPOINT)
    return csvSchema
  }
)

export const updateCSVSchemaThunk = authenticatedThunkCreator<
  CSVSchema,
  Required<UpsertThunkProps<Partial<CSVSchema>>>
>(`${ADMIN_CSV_SCHEMAS}/updateCSVSchemaThunk`, async (authedDTO) => {
  const csvSchema: CSVSchema = await putRecord(authedDTO, SCHEMAS_ENDPOINT)
  return csvSchema
})

export const createCSVSchemaThunk = authenticatedThunkCreator<
  CSVSchema,
  UpsertThunkProps<Partial<CSVSchema>>
>(`${ADMIN_CSV_SCHEMAS}/createCSVSchemaThunk`, async (authedDTO) => {
  const csvSchema: CSVSchema = await postRecord(authedDTO, SCHEMAS_ENDPOINT)
  return csvSchema
})
