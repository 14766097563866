import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getWhoami } from 'slices/whoami'

import {
  JackpotTicketLineItemContainer,
  JackpotTicketLineItemDetail,
  JackpotTicketLineItemLeft,
  JackpotTicketLineItemNumTickets,
  JackpotTicketLineItemRight,
  JackpotTicketLineItemTitle
} from './styles'

interface JackpotTicketLineItemProps {
  date: string
  eventName: string
  numTickets: number
  total: number
  id?: string
}

export const JackpotTicketLineItem = ({
  date,
  eventName,
  numTickets,
  total,
  id
}: JackpotTicketLineItemProps) => {
  const { t } = useTranslation()
  const whoami = useSelector(getWhoami)
  const dateString = DateTime.fromISO(date)
    .setLocale(whoami?.language ?? 'en')
    .toLocaleString({
      weekday: 'short',
      month: 'long',
      day: 'numeric'
    })

  const numTicketsString = `${numTickets > 0 ? '+' : ''}${numTickets} ${t(
    'pages.jackpotHistory.lineItem.tickets',
    { plural: Math.abs(numTickets) === 1 ? '' : 's' }
  )}`
  const totalString = `(${total} ${t('pages.jackpotHistory.lineItem.total')})`
  return (
    <JackpotTicketLineItemContainer data-testid={id}>
      <JackpotTicketLineItemTitle>{dateString}</JackpotTicketLineItemTitle>
      <JackpotTicketLineItemDetail isNegative={numTickets < 0}>
        <JackpotTicketLineItemLeft>{eventName}</JackpotTicketLineItemLeft>
        <JackpotTicketLineItemRight>
          <JackpotTicketLineItemNumTickets>
            {numTicketsString}
          </JackpotTicketLineItemNumTickets>
          <span>{totalString}</span>
        </JackpotTicketLineItemRight>
      </JackpotTicketLineItemDetail>
    </JackpotTicketLineItemContainer>
  )
}
