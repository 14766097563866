import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { VerifyEmailRequest } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import Splash from 'components/splash'

import { useNavigation } from 'hooks/useNavigation'
import { useURLParams } from 'hooks/useURLParams'

import { setSnackbar } from 'slices/snackbar'
import { verifyEmailThunk } from 'slices/whoami/actions'

import { RootPaths } from 'utils/helpers'
import { UrlKeywords } from 'utils/url'

export default function VerifyEmail() {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const { t } = useTranslation()

  const [email, code] = useURLParams([UrlKeywords.email, UrlKeywords.code])

  const errorSnackbar = () => {
    dispatch(
      setSnackbar({
        open: true,
        type: 'error',
        message: t('pages.userProfile.verificationAlert.failure')
      })
    )
  }

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await dispatch(
          verifyEmailThunk({ data: { email, code } as VerifyEmailRequest })
        ).unwrap()

        if (response.is_email_verified) {
          dispatch(
            setSnackbar({
              open: true,
              type: 'success',
              message: t('pages.userProfile.verificationAlert.success', { email: email })
            })
          )
        } else {
          errorSnackbar()
        }
      } catch {
        errorSnackbar()
      }
    }

    verifyEmail()
    navigation.push(RootPaths.userProfile)
  }, [])

  return <Splash />
}
