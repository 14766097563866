import clsx from 'clsx'

import { ReactNode } from 'react'

import { ThemeType } from 'theme'

import { StyledRootLayout } from './styles'

export interface LayoutProps {
  className?: string
  children: ReactNode
  themeType: ThemeType
}

export function Layout({ children, className, themeType }: LayoutProps) {
  return (
    <StyledRootLayout
      square
      component="main"
      elevation={0}
      className={clsx('RootLayout', className)}
      themeType={themeType}
    >
      {children}
    </StyledRootLayout>
  )
}

export default Layout
