import { MouseEvent, KeyboardEvent, useState, useEffect } from 'react'

import { ReactComponent as CheckIcon } from 'assets/icons/AppIcons/CheckIcon.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/AppIcons/CopyIcon.svg'

import { Colors } from 'theme'

import { CopyFieldContainer, CopyTextField } from './styles'

export interface CopyFieldProps {
  textToCopy: string
}

export function CopyField({ textToCopy }: CopyFieldProps) {
  const [copied, setCopied] = useState<boolean>(false)

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null
    if (copied) {
      timer = setTimeout(() => setCopied(false), 2000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [copied])

  const onClick = async (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    // This will allow us to render this button within another clickable container
    event.stopPropagation()
    try {
      await navigator?.clipboard.writeText(textToCopy)
      setCopied(true)
    } catch (error) {
      // eslint-disable-next-line
      console.error('Async: Could not copy text: ', error, textToCopy)
    }
  }

  return (
    <CopyFieldContainer onClick={onClick} data-testid="CopyField">
      <CopyTextField
        InputProps={{ readOnly: true }}
        hiddenLabel
        variant="filled"
        size="small"
        defaultValue={textToCopy}
        fullWidth
      />
      {copied ? (
        <CheckIcon stroke={Colors.success} width={24} height={24} />
      ) : (
        <CopyIcon />
      )}
    </CopyFieldContainer>
  )
}

export default CopyField
