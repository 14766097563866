import {
  alpha,
  Card,
  CardTypeMap,
  styled,
  Typography,
  TypographyTypeMap
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import { ReactComponent as CheckIcon } from 'assets/icons/AppIcons/CheckIcon.svg'

import { PointsExtraInfoProps } from './extraInfo/points'
import { Colors } from '../../theme'

interface UseStyleProps {
  locked?: boolean
}

export const StyledRowItem = styled<
  OverridableComponent<
    CardTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'div'>
  >
>(Card, {
  shouldForwardProp: (prop) => prop !== 'locked'
})<UseStyleProps>(({ theme, locked }) => ({
  textTransform: 'capitalize',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderRadius: theme.borderRadius.small,
  border: !locked ? 'none' : `solid 1px ${theme.palette.colors.gray}`,
  filter: !locked ? 'none' : 'grayscale(1)',
  listStyle: 'none',
  '&.RowItem--highlight': {
    '&:hover, &:active': {
      transition: `background-color 300ms ${theme.transitions.easing.easeInOut}`,
      backgroundColor: alpha(theme.branding.secondaryColor, 0.3)
    }
  }
}))

export const StyledRowItemWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const StyledRowItemImage = styled('div')(({ theme }) => ({
  flexShrink: 0,
  borderRadius: theme.borderRadius.small,
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(3),
  '& > img': {
    width: '48px',
    height: '48px',
    objectFit: 'contain'
  }
}))

export const StyledRowItemContent = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: '100%'
}))

export const StyledRowItemExtraInfo = styled('div')(({ theme }) => ({
  borderRadius: theme.borderRadius.small,
  width: '48px',
  height: '48px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}))

export const StyledRowItemDivider = styled('hr')(({ theme }) => ({
  color: '#eeeeee8c',
  borderStyle: 'solid',
  margin: theme.spacing(3, 0)
}))

// extraInfo
export const StyledExtraInfoIcon = styled(CheckIcon)({
  position: 'absolute',
  top: '-8px',
  right: '-8px'
})

export const StyledExtraInfoPointsValue = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }>
  >
>(Typography, {
  shouldForwardProp: (prop) => prop !== 'item'
})<PointsExtraInfoProps>(() => ({
  fontWeight: 'bold',
  lineHeight: '12px',
  color: Colors.black
}))
