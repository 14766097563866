import { styled, Typography } from '@mui/material'

import { Link } from 'react-router-dom'

import { conditionalObject } from 'utils/helpers'

// TODO: Refactor all these to use `@media ${smallDeviceHeight}` in css instead of js hooks
export const StyledForm = styled('form', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  margin: `${theme.spacing(24)} 0 0`,
  ...conditionalObject({ margin: `${theme.spacing(18)} 0 0` }, isOnSmallDevice)
}))

export const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(({ error, theme }) => ({
  color: theme.palette.colors.white,
  ...conditionalObject({ color: theme.palette.colors.error }, error)
}))

export const StyledWelcomeText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  color: theme.palette.colors.yellow,
  fontSize: theme.spacing(4),
  ...conditionalObject({ fontSize: theme.spacing(3) }, isOnSmallDevice)
}))

export const StyledSignInText = styled(StyledText, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  ...conditionalObject(
    { fontSize: theme.spacing(4), margin: `${theme.spacing(3)} 0` },
    isOnSmallDevice
  )
}))

export const StyledError = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  color: theme.palette.colors.error,
  textAlign: 'center',
  marginTop: theme.spacing(3),
  ...conditionalObject(
    { fontSize: theme.spacing(2.5), marginTop: theme.spacing(2) },
    isOnSmallDevice
  )
}))

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})(({ theme }) => ({
  color: theme.palette.colors.error,
  '&:visited': {
    color: theme.palette.colors.dmRed
  }
}))
