import { isEmpty } from 'lodash'

import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'reduxStore'

export const getImpersonator = (state: RootState) => state.impersonator.impersonator
export const getImpersonatorLoading = (state: RootState) => state.impersonator.isLoading
export const getImpersonatorLoaded = (state: RootState) => state.impersonator.hasLoaded
export const getImpersonatorErrorMsg = (state: RootState) => state.impersonator.error

export const getIsImpersonator = createSelector(
  getImpersonator,
  (impersonator) => !isEmpty(impersonator)
)
