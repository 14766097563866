import i18n from 'i18n'
import { logError } from 'utils/logger'

export default function useChangeUserLanguage() {
  // Change the user's language if we detect a change
  const changeLanguage = async (whoami: { language: string }) => {
    if (whoami) {
      try {
        if (whoami.language !== i18n.language) {
          await i18n.changeLanguage(whoami.language)
        }
      } catch (error) {
        logError(
          `Oh no! Looks like there was an error changing the user's language to ${
            whoami.language
          }: ${(error as Error)?.message}`,
          {
            error,
            whoami
          }
        )
      }
    }
  }
  return {
    changeLanguage
  }
}
