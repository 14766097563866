import { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useState } from 'react'

export const useOTPInput = (inputRef) => {
  const NUM_INPUTS = 6
  const [otpValue, setOTPValue] = useState('')
  const [activeInputIndex, setActiveInputIndex] = useState(0)

  useEffect(() => {
    if (inputRef?.current) {
      const input = inputRef.current.querySelector('input')
      if (!input) return
      input.focus()
    }
  }, [activeInputIndex])

  const focusNext = () => {
    setActiveInputIndex((i) => {
      if (i >= NUM_INPUTS - 1) {
        return i
      }
      return i + 1
    })
  }

  const focusPrevious = () => {
    setActiveInputIndex((i) => {
      if (i <= 0) {
        return i
      }
      return i - 1
    })
  }

  const resetOTPValue = () => {
    setOTPValue('')
    setActiveInputIndex(0)
  }
  const fillAllInputs = (newValues: string[]) => {
    if (newValues.length !== NUM_INPUTS) {
      // ONLY fill if we have the exact data we need
      return
    }
    const otp = otpValue.split('')
    for (let i = 0; i < NUM_INPUTS; i++) {
      otp[i] = newValues.shift() as string
    }
    setActiveInputIndex(NUM_INPUTS - 1)
    setOTPValue(otp.join(''))
  }
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>, handleSubmit) => {
    switch (e.key) {
      case 'Delete':
      case 'Backspace':
        changeOTPAtCurrentIndex('')
        break
      case 'ArrowLeft':
        focusPrevious()
        break
      case 'ArrowRight':
        focusNext()
        break
      case 'Tab':
        // Allow tabbing to the next input
        break
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        // Will propogate and call the OnChange
        break
      case 'Enter':
        handleSubmit()
        break
      default:
        e.preventDefault()
        break
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.trim().length >= 1) {
      changeOTPAtCurrentIndex(value)
    }
  }

  const changeOTPAtCurrentIndex = (value: string) => {
    const newValues = value.split('')
    if (newValues.length === NUM_INPUTS) {
      fillAllInputs(newValues)
    } else {
      const otp = otpValue.split('')
      const latestVal = value.substring(value.length - 1)
      otp[activeInputIndex] = latestVal
      if (!value) {
        focusPrevious()
      } else {
        focusNext()
      }
      setOTPValue(otp.join(''))
    }
  }

  const onPaste = (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()

    // Get pastedData in an array of max NUM_INPUTS
    const clipboardData: string | undefined = (e.clipboardData as DataTransfer).getData(
      'text/plain'
    )
    // Make sure we have actual data pasted
    if (!clipboardData) return
    const pastedOTP: string[] = clipboardData.slice(0, NUM_INPUTS).split('')
    // Make sure we have an exact match between the length of the pasted data and our expected OTP
    if (pastedOTP.length < NUM_INPUTS) return
    // Make sure we are inputting numeric values only
    const isAllNumeric = pastedOTP.every((x) => /\d/.test(x))
    if (!isAllNumeric) return
    fillAllInputs(pastedOTP)
  }

  return {
    otpValue,
    handleInputChange,
    resetOTPValue,
    NUM_INPUTS,
    onPaste,
    onKeyDown,
    activeInputIndex
  }
}
