import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Placeholder, { PrizeItemLoader } from '../../placeholder'

import useAppDispatch from 'hooks/useAppDispatch'

import Carousel from 'components/carousel'

import {
  fetchJackpotCurrentDrawingThunk,
  getCurrentDrawingPrizes,
  getPrizesLoading
} from 'slices/jackpot'

import {
  StyledCurrentDrawing,
  StyledCurrentDrawingIndex,
  StyledCurrentDrawingTitle
} from './styles'

import JackpotSection from './jackpotSection'
import PrizeItem, { EmptyPrizeItem } from './prizeItem'
import { useTranslation } from 'react-i18next'

export default function JackpotCurrentDrawing() {
  const dispatch = useAppDispatch()
  const [pageIndex, setPageIndex] = useState(0)
  const prizesLoading = useSelector(getPrizesLoading)
  const prizes = useSelector(getCurrentDrawingPrizes)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchJackpotCurrentDrawingThunk({ getCachedResults: true }))
  }, [])

  const onSwipe = (index: number) => {
    setPageIndex(index)
  }

  const renderPrizes = () => {
    if (!prizes.length)
      return (
        <EmptyPrizeItem
          title={t("pages.jackpot.emptyPrize.title")}
          description={t("pages.jackpot.emptyPrize.description")}
        />
      )
    return (
      <Carousel onSwipe={onSwipe} showNavButtons={prizes.length > 1}>
        {prizes.map((prize) => (
          <PrizeItem key={prize.id} prize={prize} />
        ))}
      </Carousel>
    )
  }

  return (
    <JackpotSection>
      <StyledCurrentDrawing>
        <StyledCurrentDrawingTitle moreSpace title={t("pages.jackpot.monthlyPrizes")} />
        {prizes.length > 0 && (
          <StyledCurrentDrawingIndex
            lessProminent
            title={`${pageIndex + 1} of ${prizes.length}`}
          />
        )}
      </StyledCurrentDrawing>
      <Placeholder isLoading={prizesLoading} loader={PrizeItemLoader}>
        {renderPrizes()}
      </Placeholder>
    </JackpotSection>
  )
}
