import { styled } from '@mui/material'

import Page from 'layouts/page'

// Note: Do NOT customize this with a branding theme since it renders before any theming is applied
// If you using branding, it will show the default colors and then rerender with the new colors
// since the theming is async. All this happens pretty fast, it will be very weird seeing all the colors change so fast before the app loads

// TODO: Refactor - we have similar styles in onboarding/styles.ts
export const StyledSplash = styled(Page)(({ theme }) => ({
  '.LayoutPage__wrapper': {
    padding: 0,
    overflow: 'hidden',
    backgroundColor: theme.palette.colors.black,
    color: theme.palette.getContrastTextColor(theme.palette.colors.black),
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}))

export const StyledSplashSpacing = styled('div')({
  marginTop: '-48px' // logo height
})
