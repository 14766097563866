import { RedeemedPerk, FetchCachedThunkProps, UpsertThunkProps } from 'types'

import { fetchRecords, postRecord } from 'api'

import { REDEEMED_PERKS } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

interface RedeemPerkDTO {
  points: string
  discount: string | null
  reward: string
}

export const fetchRedeemedPerksThunk = authenticatedThunkCreator<
  RedeemedPerk[],
  FetchCachedThunkProps | undefined
>(
  `${REDEEMED_PERKS}/fetchRedeemedPerksThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, REDEEMED_PERKS)
)

export const redeemPerkThunk = authenticatedThunkCreator<
  RedeemedPerk,
  UpsertThunkProps<RedeemPerkDTO>
>(`${REDEEMED_PERKS}/redeemPerk`, async (authedDTO) => {
  return await postRecord(authedDTO, 'redeemReward')
})
