import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useAppDispatch from 'hooks/useAppDispatch'

import { OnboardingFooter, OnboardingHeader } from '../components'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'

import { useOpenEmailVerificationModal } from 'hooks'

import { onboardingModalSeenThunk } from 'slices/onboarding'
import { getIsFavoritePerksLoading, postFavoritePerksThunk } from 'slices/perks'
import { getWhoamiIsLoading, updateUserSettingsV4Thunk } from 'slices/whoami'
import { getWhoami } from 'slices/whoami/selectors'

import { StyledOnboardingSteps } from '../styles'

import { StepsContent } from '.'
import stepContext from '../stepContext'
import { INITIAL_INDEX, MAX_INDEX } from './utils'

const REJECTED = 'rejected'

const Steps = () => {
  const dispatch = useAppDispatch()

  const whoami = useSelector(getWhoami)
  const isWhoamiLoading = useSelector(getWhoamiIsLoading)
  const isFavoritePerksLoading = useSelector(getIsFavoritePerksLoading)

  const { birthday, selectedPerkIds, firstName, email } = useContext(stepContext)

  const [pageIndex, setPageIndex] = useState(INITIAL_INDEX)
  const [hasStarted, setHasStarted] = useState(false)
  const [hasGoneBackAfterStart, setHasGoneBackAfterStart] = useState(false)
  const openEmailVerificationModal = useOpenEmailVerificationModal()

  const isInitialPage = pageIndex === INITIAL_INDEX
  const isLastPage = pageIndex === MAX_INDEX - 1
  const isLoading = isWhoamiLoading || isFavoritePerksLoading

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          onSwipe(pageIndex - 1)
          break
        case 'ArrowRight':
          onSwipe(pageIndex + 1)
          break
        default:
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [pageIndex])

  const onStepButtonClick = () => {
    if (isLastPage) {
      onFinishOnboarding()
      return
    }

    onSwipe(pageIndex + 1)
  }

  const onBack = () => {
    setPageIndex((prevState) => {
      if (prevState === 0 || prevState === INITIAL_INDEX) {
        setHasStarted(false)
        setHasGoneBackAfterStart(false)
        return INITIAL_INDEX
      } else {
        setHasGoneBackAfterStart(true)
        return prevState - 1
      }
    })
  }

  const onNext = () => {
    if (!hasStarted) setHasStarted(true)
    setPageIndex(isLastPage ? MAX_INDEX - 1 : pageIndex + 1)
  }

  const onSwipe = (index: number) => {
    if (index > pageIndex) onNext()
    else onBack()
  }

  const handleUpdateUserSettings = async () => {
    const userSettingsRes = await dispatch(
      updateUserSettingsV4Thunk({
        id: whoami?.user_profile_id,
        data: {
          display_name: firstName,
          birthday,
          email,
          resend_verification_email: true
        }
      })
    )
    return userSettingsRes.meta.requestStatus === REJECTED
  }

  const handleSaveFavoritePerks = async () => {
    if (selectedPerkIds.length === 0) return

    dispatch(
      postFavoritePerksThunk({
        data: {
          reward_ids: selectedPerkIds
        }
      })
    )
  }

  const onFinishOnboarding = async () => {
    let hasUserSettingsError = false

    hasUserSettingsError = await handleUpdateUserSettings()

    if (!hasUserSettingsError) {
      await handleSaveFavoritePerks()

      dispatch(onboardingModalSeenThunk({}))
      if (whoami && !whoami.is_email_verified) {
        openEmailVerificationModal(email)
      }
    }
  }

  if (isLoading) {
    return <HeartLoadingIndicator fullPage />
  }

  return (
    <StyledOnboardingSteps
      style={{
        justifyContent: isInitialPage ? 'center' : 'space-between'
      }}
      data-testid="OnboardingSteps-OuterWrapper"
    >
      <OnboardingHeader
        activePageIndex={pageIndex}
        isInitialPage={isInitialPage}
        // only animate logo removal if it's when the user lands on the goal page after the initial onboarding page
        shouldAnimateRemoveLogo={!hasGoneBackAfterStart}
        onBack={onBack}
        onSkip={onNext}
      />
      <StepsContent
        hasStarted={hasStarted}
        activePageIndex={pageIndex}
        onSwipe={onSwipe}
      />
      <OnboardingFooter
        activePageIndex={pageIndex}
        isInitialPage={isInitialPage}
        isLastPage={isLastPage}
        stepButtonOnClick={onStepButtonClick}
      />
    </StyledOnboardingSteps>
  )
}

export default Steps
