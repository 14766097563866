// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { GoalPointGroup } from 'types'

import { createSlice } from '@reduxjs/toolkit'

import { ADMIN_GOAL_POINT_GROUPS, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchAllGoalPointGroupsThunk } from './actions'

interface AdminGoalPointGroupsState extends RequestState {
  allGoalPointGroups: GoalPointGroup[] | null
}

const initialState: AdminGoalPointGroupsState = {
  allGoalPointGroups: null,
  ...requestState
}

export const adminGoalPointGroupsSlice = createSlice({
  name: ADMIN_GOAL_POINT_GROUPS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllGoalPointGroupsThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllGoalPointGroupsThunk.rejected, (state, action) => {
      handleRequestRejected(state, action)
    })
    builder.addCase(fetchAllGoalPointGroupsThunk.fulfilled, (state, action) => {
      state.allGoalPointGroups = action.payload
      handleRequestFulfilled(state)
    })
  }
})

export default adminGoalPointGroupsSlice.reducer
