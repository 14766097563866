import { CSSProperties } from "react";

export interface BorderRadiusType {
  small: CSSProperties['borderRadius']
  medium: CSSProperties['borderRadius']
  large: CSSProperties['borderRadius']
  half: CSSProperties['borderRadius']
  full: CSSProperties['borderRadius']
}

export const BorderRadius: BorderRadiusType = {
  small: '8px',
  medium: '20px',
  large: '100px',
  half: '50%',
  full: '100%'
}
