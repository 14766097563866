import { ReactComponent as GoalsIcon } from 'assets/icons/AppIcons/GoalsIcon.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/AppIcons/HelpIcon.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/AppIcons/HomeIcon.svg'
import { ReactComponent as JackpotIcon } from 'assets/icons/AppIcons/JackpotIcon.svg'
import { ReactComponent as PerksIcon } from 'assets/icons/AppIcons/PerksIcon.svg'

import { ChildPaths, RootPaths } from 'utils/helpers'

export enum FooterRoute {
  Home,
  Goals,
  Perks,
  Jackpot,
  Help
}

export interface FooterRouteConfig {
  rootPathPrefix: string // The prefix used to determine which tab is active
  exact?: boolean // Used when rootPathPrefix should match routes exactly
  path?: string // Path used to navigate when tab is clicked (defaults to rootPathPrefix)
  value: number // The index of the tab
  translatedLabelPath: string // The path used to find translations for label
  icon: JSX.Element // The icon used to display on tab
}

const routeConfig: FooterRouteConfig[] = [
  {
    rootPathPrefix: RootPaths.home,
    exact: true,
    value: FooterRoute.Home,
    translatedLabelPath: 'footer.tab.home',
    icon: <HomeIcon />
  },
  {
    rootPathPrefix: RootPaths.goals,
    path: ChildPaths.goalsToday,
    value: FooterRoute.Goals,
    translatedLabelPath: 'footer.tab.goals',
    icon: <GoalsIcon />
  },
  {
    rootPathPrefix: RootPaths.perks,
    value: FooterRoute.Perks,
    translatedLabelPath: 'footer.tab.perks',
    icon: <PerksIcon />
  },
  {
    rootPathPrefix: RootPaths.jackpot,
    value: FooterRoute.Jackpot,
    translatedLabelPath: 'footer.tab.jackpot',
    icon: <JackpotIcon />
  },
  {
    rootPathPrefix: RootPaths.help,
    value: FooterRoute.Help,
    translatedLabelPath: 'footer.tab.help',
    icon: <HelpIcon />
  }
]

export default routeConfig
