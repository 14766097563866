import clsx from 'clsx'
import startCase from 'lodash/startCase'
import { getSpacing } from 'theme'

import { TextFieldProps } from '@mui/material'

import { ChangeEvent } from 'react'
import { FieldRenderProps } from 'react-final-form'

import { ReactComponent as IndentArrowIcon } from 'assets/icons/AppIcons/IndentArrowIcon.svg'

import { AppIconTypes } from 'types'

import AppIcon from 'components/icons/AppIcon'

import { StyledErrorMessage, StyledTextField, TextFieldContainer } from './styles'

export interface FormTextFieldProps<T> extends FieldRenderProps<T> {
  inputFieldProps?: TextFieldProps
  shrinkLabel?: boolean
  isSubField?: boolean
  required?: boolean
}

export default function FormTextField<T>({
  input: { name, onChange, ...restInput },
  meta: { touched, error, submitError, dirtySinceLastSubmit },
  inputFieldProps: { label, ...restInputFieldProps } = {},
  shrinkLabel,
  className,
  isSubField = false,
  required = false,
  ...arbitraryProps
}: FormTextFieldProps<T>) {
  const customLabel = (label as string) || startCase(name)
  const hasNewSubmitError = submitError && !dirtySinceLastSubmit
  return (
    <>
      <TextFieldContainer isSubField={isSubField} className={clsx(className)}>
        {isSubField && <IndentArrowIcon />}
        <StyledTextField
          {...arbitraryProps}
          required={required}
          InputLabelProps={{ shrink: shrinkLabel }}
          {...restInput}
          {...restInputFieldProps}
          isSubField={isSubField}
          fullWidth={!isSubField}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.value)
          }
          label={customLabel}
          error={Boolean(error)}
        />
      </TextFieldContainer>
      {touched && (error || hasNewSubmitError) && (
        <StyledErrorMessage>
          <AppIcon
            width={20}
            height={20}
            style={{ marginRight: getSpacing(0.5) }}
            type={AppIconTypes.circleAlert}
          />
          {error || submitError}
        </StyledErrorMessage>
      )}
    </>
  )
}
