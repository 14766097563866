import { Typography } from '@mui/material'

import { CSSProperties } from 'react'

import { ReactComponent as AvatarIcon } from 'assets/icons/AppIcons/AvatarIcon.svg'

import { CelebrationPoint } from 'types'

import { PointsExtraInfo } from 'components/rowItem'

import { Colors } from 'theme'

import { ItemSubHeading } from '../shared/styles'
import { StyledOutboxItem } from './styles'

export interface OutboxItemProps {
  item: CelebrationPoint
  onSelectItem: (key: CelebrationPoint) => void
  style: CSSProperties
}

export default function OutboxItem({ item, onSelectItem, style }: OutboxItemProps) {
  const { recipient_name, amount, date } = item
  const dateOfTransfer = new Date(date).toLocaleDateString()
  const content = (
    <>
      <Typography variant="h4">{recipient_name}</Typography>
      <ItemSubHeading variant="subtitle2">{`Sent on ${dateOfTransfer}`}</ItemSubHeading>
    </>
  )

  const onOutboxItemSelected = () => onSelectItem(item)

  const celebrationPointsSlice = {
    points: amount,
    color: Colors.success,
    completed: amount > 0
  }

  return (
    <StyledOutboxItem
      item={celebrationPointsSlice}
      image={<AvatarIcon />}
      extraInfo={<PointsExtraInfo item={celebrationPointsSlice} />}
      content={content}
      onClick={onOutboxItemSelected}
      style={style}
    />
  )
}
