import { Button, styled, TypographyTypeMap } from '@mui/material'
import { Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { ElementType, HTMLAttributes } from 'react'

import RowItem from 'components/rowItem'
import { PointsExtraInfoProps } from 'components/rowItem/extraInfo/points'
import { Colors } from '../../theme'

export const StyledQuestionWidget = styled(RowItem)<PointsExtraInfoProps>(
  ({ item: { completed } }) => ({
    '.RowItem__extraInfo': {
      position: 'relative',
      flexShrink: 0,
      // TODO: Refactor the backgroundColor for ALL these `__extraInfo` rowItems
      backgroundColor: completed ? Colors.successLight : Colors.gray200
    }
  })
)

export const StyledQuestionWidgetSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.gray
}))

export const StyledQuestionWidgetCTA = styled(Button)({
  width: '100%'
})

export const StyledQuestionWidgetContent = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'initial'
}))

export const StyledQuestionWidgetAnsweredText = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }, 'p'>
  >
>(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  padding: `${theme.spacing(3)} 0`,
  textAlign: 'center',
  textTransform: 'initial'
}))
