import { BaseResponse, PaginatedResponse } from '../http'
import { ChoiceField } from '../utils'
import { CSVSchema } from './CSVSchema'
import { AdminStore } from './Store'

export enum GoalFrequencyOption {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly'
}

export type GoalFrequency = ChoiceField<keyof typeof GoalFrequencyOption>

export type GoalSource = 'kaiser' | 'goal_service'

export interface Goal extends BaseResponse {
  title: string
  description: string
  result: string
  points: number
  completed: boolean
  goal_type: string
  icon: string
  frequency: keyof typeof GoalFrequencyOption
  store_id: string
  group_id: string
  result_text: string
  result_status: 'processing' | 'achieved' | 'not_achieved'
  parent: string | null
  expires_at: string
  testing: boolean
  gp_testing_status: boolean | null
  source?: GoalSource // default should be kaiser
}

export interface AdminGoal extends Goal {
  name: string
  store_name: string
  group_name: string
  configuration: Record<string, unknown>
  eligibility: Record<'roles', string[]>
  location: string | null
  dependencies: string[]
  max_completions: number
  effective_at: string
  point_group: string
  schedule: string | null
  versions: AdminGoal[]
  testing: boolean
}

export interface GoalsHistory {
  [date: string]: Goal[]
}

export type PaginatedAdminGoals = PaginatedResponse<AdminGoal>

export interface GoalWithCsvSchema extends AdminGoal {
  csv_schemas: CSVSchema[]
  csv_schema: CSVSchema[]
  flour_field: string
  store: AdminStore
}

// Must match kaiser/goal/constants.py
export enum GoalPointGroupOption {
  individual = 'individual',
  store_all = 'store_all',
  store_checked_in = 'store_checked_in'
}

export type GoalPointGroup = ChoiceField<keyof typeof GoalPointGroupOption>
