import { Typography } from '@mui/material'

import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SendIcon } from 'assets/icons/AppIcons/SendIcon.svg'

import useAppDispatch from 'hooks/useAppDispatch'

import { AutoCompleteField, FormTextField } from 'components/form'
import { SimpleModal } from 'components/modals'

import { useNavigation } from 'hooks/useNavigation'

import {
  fetchAllTeamMembersThunk,
  fetchDistributionPointsThunk,
  getTeamMembersLoading,
  sendCelebrationPointsThunk,
  teamMemberSelectors
} from 'slices/admin'
import { setSnackbar } from 'slices/snackbar'

import { TeamLeaderPaths } from 'utils/helpers'

import {
  StyledCelebrateFormHeader,
  StyledCelebrateTeamForm,
  StyledCelebrateTeamFormSubmit
} from './styles'

interface FormValues {
  recipient_id: string
  amount: number
  body: string
}

export default function CelebrateForm() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalButtonDisabled, setIsModalButtonDisabled] = useState(false)
  const [formState, setFormState] = useState<FormValues | null>(null)
  const { t } = useTranslation()
  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  const renderTeamMembersSelect = () => (
    <AutoCompleteField
      name="recipient_id"
      label={t('pages.admin.celebrateTeam.form.fields.selectTeamMembers')}
      required
      handleFetchOptions={fetchAllTeamMembersThunk}
      selectFetchedResults={teamMemberSelectors.selectAll}
      selectResultsLoading={getTeamMembersLoading}
      getOptionLabel={(tm) => tm.full_name}
    />
  )

  const handleModalClose = () => setIsModalOpen(false)

  const onSubmit = async (values: FormValues) => {
    const amountError = {
      amount: t('pages.admin.celebrateTeam.form.validation.submit.amount')
    }
    try {
      const response = await dispatch(fetchDistributionPointsThunk()).unwrap()
      const distributionPoints = response.total_distribution_points
      if (
        distributionPoints === 0 ||
        distributionPoints < values.amount ||
        values.amount <= 0
      ) {
        return amountError
      } else {
        setFormState(values)
        setIsModalOpen(true)
      }
    } catch (error) {
      return amountError
    }
  }

  const onSend = async () => {
    if (formState) {
      try {
        setIsModalButtonDisabled(true)
        const response = await dispatch(
          sendCelebrationPointsThunk({ data: formState })
        ).unwrap()
        navigation.push(TeamLeaderPaths.teamMemberCelebrateOutbox)

        const canReceiveSmsToast = response.can_receive_sms ? 'success' : 'warning'
        dispatch(
          setSnackbar({
            open: true,
            type: canReceiveSmsToast,
            message: t(`pages.admin.celebrateTeam.form.toasts.${canReceiveSmsToast}`, {
              amount: response.amount,
              name: response.recipient_name
            })
          })
        )
      } catch (error) {
        setIsModalButtonDisabled(false)
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: t('pages.admin.celebrateTeam.form.toasts.error', {
              amount: formState?.amount
            })
          })
        )
      }
    }
  }

  return (
    <>
      <StyledCelebrateFormHeader>
        <SendIcon />
        <Typography variant="h4">{t('pages.admin.celebrateTeam.form.title')}</Typography>
      </StyledCelebrateFormHeader>
      <Form
        subscription={{ submitting: true, pristine: true }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => (
          <StyledCelebrateTeamForm onSubmit={handleSubmit}>
            {renderTeamMembersSelect()}
            <Field<number>
              data-testid="Amount-TextInput"
              required
              name="amount"
              type="number"
              inputFieldProps={{
                label: t('pages.admin.celebrateTeam.form.fields.amount')
              }}
              component={FormTextField}
            />
            <Field<string>
              data-testid="Message-TextInput"
              required
              name="body"
              multiline
              maxRows={Infinity}
              inputFieldProps={{
                label: t('pages.admin.celebrateTeam.form.fields.body')
              }}
              component={FormTextField}
            />
            <StyledCelebrateTeamFormSubmit
              data-testid="CelebrateSubmitButton"
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting || pristine}
              fullWidth
            >
              {t('pages.admin.celebrateTeam.form.footerActionButtons.submit')}
            </StyledCelebrateTeamFormSubmit>
          </StyledCelebrateTeamForm>
        )}
      />
      <SimpleModal
        data-testid="SendPointsModal"
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={t('pages.admin.celebrateTeam.modal.title')}
        content={t('pages.admin.celebrateTeam.modal.content', {
          amount: formState?.amount || 0
        })}
        onActionClicked={onSend}
        disableActionButton={isModalButtonDisabled}
        ctaText={t('pages.admin.celebrateTeam.modal.footerActionButtons.confirm')}
      />
    </>
  )
}
