import { styled, IconButton, InputBase } from '@mui/material'

import { SearchStyleProps } from '.'

export const StyledSearch = styled('div', {
  shouldForwardProp: (prop) => prop !== 'condensed'
})<SearchStyleProps>(({ condensed, theme }) => ({
  position: 'relative',
  backgroundColor: theme.branding.lightTextColor,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  border: '1px solid rgba(148, 159, 183, 0.2)',
  borderRadius: theme.borderRadius.large,
  marginBottom: theme.spacing(4),
  ...(condensed && {
    // we need to override the above style due to specificity
    margin: 0,
    padding: 0
  })
}))

export const StyledSearchIcon = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const StyledSearchCloseIcon = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  right: 0
}))

export const StyledSearchCloseIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(2)
}))

export const StyledSearchInput = styled(InputBase)(({ theme }) => ({
  '.MuiInputBase-root': {
    width: '100%',
    fontWeight: 'bold',
    ...theme.customTypography.body1Size
  },
  '.MuiInputBase-input': {
    padding: theme.spacing(3, 10),
    width: '100%'
  }
}))
