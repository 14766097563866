import { StyledJackpot } from './styles'

import JackpotCurrentDrawing from './jackpotCurrentDrawing'
import JackpotCurrentDrawingCountdown from './jackpotCurrentDrawingCountdown'
import JackpotInfo from './jackpotInfo'
import JackpotSubheader from './jackpotSubheader'
import JackpotWinners from './jackpotWinners'

export default function Jackpot() {
  return (
    <StyledJackpot themeType="dark" subheaderContent={<JackpotSubheader />}>
      <JackpotCurrentDrawing />
      <JackpotCurrentDrawingCountdown />
      <JackpotInfo />
      <JackpotWinners />
    </StyledJackpot>
  )
}
