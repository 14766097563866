export enum LoginType {
  email = 'email',
  phone = 'phone'
}

export enum OTPChannel {
  email = 'email',
  phone = 'sms',
  verification_email = 'verification_email'
}
