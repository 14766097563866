import { Colors } from 'theme'

import { Button, Typography, styled } from '@mui/material'

interface CreateButtonProps {
  top: number
  left: number
}

export const CreateButton = styled(Button)<CreateButtonProps>(({ top, left }) => ({
  opacity: 0.9,
  position: 'fixed',
  zIndex: 9999,
  borderRadius: 10,
  top,
  left
}))

export const ItemSubHeading = styled(Typography)({
  color: Colors.gray
})

export const StyledForm = styled('form')(({ theme }) => ({
  '& .MuiFormControl-root, textarea:not(:last-child)': {
    marginBottom: theme.spacing(3)
  }
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0)
}))

export const FilterButton = styled(Button)({
  textDecoration: 'underline',
  marginLeft: 5,
  borderRadius: 0
})
