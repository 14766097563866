import { postRecord } from 'api'

import { SMSSendRequestData, UpsertThunkProps } from 'types'

import { SMS } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

// TODO: Figure out how to decouple this from redux/slices. At the moment, this is the best way
// to inject auth tokens for fetches
// Unless, we have a need to fetch SMS for/from a specific user, then we can actually use redux to track this
export const sendSMSThunk = authenticatedThunkCreator<
  unknown,
  UpsertThunkProps<SMSSendRequestData>
>(`${SMS}/sendSMSThunk`, async (authedDTO) => await postRecord(authedDTO, 'sendSMS'))
