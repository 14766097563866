import { TeamRolesResponse, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { TEAM_ROLES } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

export const fetchTeamRolesThunk = authenticatedThunkCreator<
  TeamRolesResponse[],
  FetchCachedThunkProps | undefined
>(
  `${TEAM_ROLES}/fetchTeamRolesThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, TEAM_ROLES)
)
