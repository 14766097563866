// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AdminStore, PaginatedStores } from 'types'

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { RequestState, requestState, ADMIN_STORES } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchAllStoresThunk } from '.'

interface AdminStoresState extends RequestState {
  storesResponse: PaginatedStores
}

const initialStoresState: AdminStoresState = {
  storesResponse: {} as PaginatedStores,
  ...requestState
}

export const adminStoresAdapter = createEntityAdapter<AdminStore>({
  selectId: (store) => store.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const adminStoresSlice = createSlice({
  name: ADMIN_STORES,
  initialState: adminStoresAdapter.getInitialState(initialStoresState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllStoresThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllStoresThunk.rejected, (state, action) => {
      handleRequestRejected(state, action)
    })
    builder.addCase(fetchAllStoresThunk.fulfilled, (state, action) => {
      const { next, previous, results = [] } = action.payload
      state.storesResponse = { next, previous }
      adminStoresAdapter.setAll(state, results)
      handleRequestFulfilled(state)
    })
  }
})

export default adminStoresSlice.reducer
