import { styled } from '@mui/material'

export const StyledGoalsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
})

export const StyledGoalsChartContainer = styled('div')(({ theme }) => ({
  maxWidth: '260px',
  margin: theme.spacing(4, 0)
}))

export const StyledGoalsItemContainer = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  ':last-child': {
    marginBottom: theme.spacing(9.6)
  }
}))

export const GoalsModalContainer = styled('div')({
  textAlign: 'center'
})
