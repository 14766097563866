import {
  csvSchemaColumnFieldList,
  csvSchemaFilterSelector,
  FILTER,
  SELECTOR
} from 'constants/globals'
import { map } from 'lodash'
import { Colors } from 'theme'

import { useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { StoreGroup, CSVField, CSVSchema } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import UpsertFormContainer, {
  AutoCompleteField,
  FormSelectField,
  FormTextField
} from 'components/form'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'

import { useNavigation } from 'hooks/useNavigation'

import {
  createCSVSchemaThunk,
  CSVSchemaSelectors,
  fetchCSVSchemaThunk,
  fetchStoreGroupsThunk,
  getCSVSchemasLoading,
  getSchemasResponse,
  selectStoreGroupsLoading,
  storeGroupsSelectors,
  updateCSVSchemaThunk
} from 'slices/admin'

import { formatSelectValues, FormSelectParams } from 'utils/form'
import { AdminPaths } from 'utils/helpers'
import { UrlParamTypes } from 'utils/url'

import { FilterButton, StyledForm, SubmitButton } from '../shared/styles'
import { Checkbox, CheckboxContainer, ColumnFieldsContainer } from './styles'

import { RootState } from 'reduxStore'

interface Values {
  manual_date: boolean
  manual_stores: boolean
  name: string
  start_column: number
  start_row: number
  transpose: boolean
  [key: string]: boolean | string | number
}

const COLUMN_NAME = 'column_name_'
const COLUMN_RENAME = 'column_rename_'
const COLUMN_TYPE = 'column_type_'
const COLUMN_REGEX = 'column_regex_'
const FILTER_OR_SELECTOR = 'filter_or_selector_'

export default function AdminCSVSchemaForm({ entityType }: { entityType: string }) {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const { id: csvSchemaIdFromParams } = useParams<UrlParamTypes>()

  const [storeGroup, setStoreGroup] = useState<Partial<StoreGroup>>({ name: '', id: '' })

  const [columnCount, setColumnCount] = useState<number>(0)

  const allCSVSchemas = useSelector(getSchemasResponse)?.results
  const selectedCSVSchema = useSelector((state: RootState) =>
    CSVSchemaSelectors.selectById(state, csvSchemaIdFromParams as string)
  )
  const isSelectedCSVSchemaLoading = useSelector(getCSVSchemasLoading)

  const isCSVSchemaCreationForm = () => csvSchemaIdFromParams === 'create'

  const actionType = isCSVSchemaCreationForm() ? 'Add' : 'Update'

  useEffect(() => {
    if (!isCSVSchemaCreationForm()) {
      dispatch(
        fetchCSVSchemaThunk({
          pk: csvSchemaIdFromParams as string
        })
      )
    }
  }, [])

  const getInitialValues = useMemo(() => {
    if (isCSVSchemaCreationForm()) return {}

    if (!selectedCSVSchema) return null

    const {
      manual_date,
      manual_stores,
      name,
      start_column,
      start_row,
      store_group,
      store_group_id,
      transpose,
      fields
    } = selectedCSVSchema as CSVSchema

    setStoreGroup({ id: store_group_id, name: store_group })
    setColumnCount(fields?.length || columnCount)

    const fieldValues = {} as Record<string, string>

    fields?.forEach((field: CSVField, i: number) => {
      fieldValues[`${COLUMN_NAME}${i + 1}`] = field.name
      if (field.rename) {
        fieldValues[`${COLUMN_RENAME}${i + 1}`] = field.rename
      }
      if (field.type) {
        fieldValues[`${COLUMN_TYPE}${i + 1}`] = field.type
      }
      if (field.selector) {
        fieldValues[`${FILTER_OR_SELECTOR}${i + 1}`] = SELECTOR
        fieldValues[`${COLUMN_REGEX}${i + 1}`] = field.selector.regex
      }
      if (field.filter) {
        fieldValues[`${FILTER_OR_SELECTOR}${i + 1}`] = FILTER
        fieldValues[`${COLUMN_REGEX}${i + 1}`] = field.filter.regex
      }
    })

    return {
      manual_date,
      manual_stores,
      name,
      start_column,
      start_row,
      transpose,
      store_group: store_group_id,
      ...fieldValues
    }
  }, [allCSVSchemas, isSelectedCSVSchemaLoading])

  const getMemoizedOptions = <T,>({ options, getLabel, getValue }: FormSelectParams<T>) =>
    useMemo(
      () =>
        formatSelectValues({
          options,
          getLabel,
          getValue
        }),
      [options]
    )

  const columnTypeOptions = map(csvSchemaColumnFieldList, (fieldName) => ({
    name: fieldName,
    id: fieldName
  }))
  const memoizedColumnTypeOptions = getMemoizedOptions({
    options: columnTypeOptions,
    getLabel: ({ name }) => name,
    getValue: ({ id }) => id
  })

  const filterSelectorOptions = map(csvSchemaFilterSelector, (fieldName) => ({
    name: fieldName,
    id: fieldName
  }))
  const memoizedFilterSelectorOptions = getMemoizedOptions({
    options: filterSelectorOptions,
    getLabel: ({ name }) => name,
    getValue: ({ id }) => id
  })

  const valuesToListenToForChanges = {
    submitting: true
  }

  const pruneData = (values: Values): Partial<CSVSchema> => {
    const { manual_date, manual_stores, name, start_column, start_row, transpose } =
      values

    const fields: CSVField[] = []

    for (let i = 0; i < columnCount; i++) {
      const name = values[`${COLUMN_NAME}${i + 1}`] as string
      const rename = values[`${COLUMN_RENAME}${i + 1}`] as string
      const type = values[`${COLUMN_TYPE}${i + 1}`] as string
      const filterOrSelector = values[`${FILTER_OR_SELECTOR}${i + 1}`] as string
      const regex = values[`${COLUMN_REGEX}${i + 1}`] as string

      const field: CSVField = { name }

      if (rename) {
        field.rename = rename
      }
      if (type) {
        field.type = type
      }
      if (filterOrSelector === FILTER) {
        field[filterOrSelector] = { regex }
      } else if (filterOrSelector === SELECTOR) {
        field[filterOrSelector] = { regex }
      }

      fields.push(field)
    }

    return {
      manual_date,
      manual_stores,
      name,
      start_column,
      start_row,
      store_group_id: storeGroup.id,
      transpose,
      fields
    }
  }

  const onSubmit = async (values: Values) => {
    const data = pruneData(values)

    dispatch(
      !isCSVSchemaCreationForm() && csvSchemaIdFromParams
        ? updateCSVSchemaThunk({ id: csvSchemaIdFromParams, data })
        : createCSVSchemaThunk({ data })
    )
    navigation.push(AdminPaths.manageCSV)
  }

  const renderNameField = () => (
    <Field<string> required name="name" component={FormTextField} />
  )

  const renderStoreGroupField = () => (
    <AutoCompleteField
      name="store_group"
      required
      handleFetchOptions={fetchStoreGroupsThunk}
      selectFetchedResults={storeGroupsSelectors.selectAll}
      selectResultsLoading={selectStoreGroupsLoading}
      onChange={(_event, value) => {
        const selectedValue = value as StoreGroup
        setStoreGroup(selectedValue)
      }}
    />
  )

  const renderStartRowColFields = () => (
    <>
      <Field<number>
        required
        defaultValue={0}
        name="start_row"
        type="number"
        component={FormTextField}
      />
      <Field<number>
        required
        defaultValue={0}
        name="start_column"
        type="number"
        component={FormTextField}
      />
    </>
  )

  const renderColumnButtons = () => {
    const buttons = [
      { label: 'Add Column', onClick: () => setColumnCount(columnCount + 1) },
      { label: 'Remove Column', onClick: () => setColumnCount(columnCount - 1) }
    ]
    return buttons.map(({ label, onClick }) => (
      <FilterButton
        key={label}
        style={{ color: Colors.orange }}
        size="large"
        onClick={onClick}
      >
        {label}
      </FilterButton>
    ))
  }
  const renderCheckboxFields = () => (
    <CheckboxContainer>
      <Checkbox>
        <Field<boolean> name="transpose" component="input" type="checkbox" /> Transpose
      </Checkbox>
      <Checkbox>
        <Field<boolean> name="manual_stores" component="input" type="checkbox" /> Manual
        Stores
      </Checkbox>
      <Checkbox>
        <Field<boolean> name="manual_date" component="input" type="checkbox" /> Manual
        Date
      </Checkbox>
    </CheckboxContainer>
  )

  const renderCSVColumnFields = () => {
    return [...Array(columnCount)].map((_, i) => (
      <ColumnFieldsContainer key={i}>
        <Field<string>
          required
          isSubField
          name={`${COLUMN_NAME}${i + 1}`}
          component={FormTextField}
        />
        <Field<string>
          required
          isSubField
          name={`${COLUMN_RENAME}${i + 1}`}
          component={FormTextField}
        />
        <Field<string>
          isSubField
          name={`${COLUMN_TYPE}${i + 1}`}
          component={(props) => (
            <FormSelectField {...props} options={memoizedColumnTypeOptions} />
          )}
        />
        <Field<string>
          isSubField
          name={`${FILTER_OR_SELECTOR}${i + 1}`}
          render={(props) => {
            return (
              <>
                <FormSelectField {...props} options={memoizedFilterSelectorOptions} />
                {/* eslint-disable-next-line react/prop-types */}
                {props.input.value ? (
                  <Field<string>
                    required
                    isSubField
                    name={`${COLUMN_REGEX}${i + 1}`}
                    component={FormTextField}
                  />
                ) : null}
              </>
            )
          }}
        />
      </ColumnFieldsContainer>
    ))
  }

  const form = (
    <Form
      subscription={valuesToListenToForChanges}
      onSubmit={onSubmit}
      initialValues={getInitialValues}
      render={({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          {renderNameField()}
          {renderStoreGroupField()}
          {renderStartRowColFields()}
          {renderCheckboxFields()}
          {renderCSVColumnFields()}
          {renderColumnButtons()}
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
            fullWidth
          >
            {actionType}
          </SubmitButton>
        </StyledForm>
      )}
    />
  )

  return (
    <>
      {isSelectedCSVSchemaLoading ? (
        <HeartLoadingIndicator fullPage />
      ) : (
        <UpsertFormContainer
          actionType={actionType}
          entityType={entityType}
          form={form}
          containerProps={{ themeType: 'orange', showBackButton: true }}
        />
      )}
    </>
  )
}
