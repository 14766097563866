import { useTranslation } from 'react-i18next'

import { RewardDetail, RewardType } from 'types'

import { amountText, pointsToAmount } from 'utils/helpers'

import {
  StyledBoldCardText,
  StyledBoldDiscountText,
  StyledColumnContainer,
  StyledPerkCard,
  StyledPerkCardBottomRow,
  StyledPerkCardTopRow,
  StyledPerkImage,
  StyledPerkImageOuterContainer,
  StyledPerkTitle,
  StyledPoints,
  StyledStrikethroughDiscountText,
  StyledSubTitle
} from './styles'

export interface PerkCardProps {
  rewardDetail: RewardDetail
  points: number
  discountedPoints: number | null
  percent_discount?: number
}

// For a discounted perk, both points and discountedPoints are required.
//   - points represents the value of the perk
//   - discountedPoints represents the cost of the perk
const PerkCard = ({
  rewardDetail,
  points,
  discountedPoints,
  percent_discount
}: PerkCardProps) => {
  const { t } = useTranslation()

  const { image, title, reward_type: rewardType } = rewardDetail

  const pointsPerUnitCost = rewardDetail.points_per_unit_cost

  const amount = pointsToAmount(points, pointsPerUnitCost)

  const perkAmountText = amountText(amount, rewardDetail, t)

  const perkSubtitleText =
    rewardType === RewardType.cash
      ? t('pages.perk.card.cash')
      : rewardType === RewardType.gift_card
      ? t('pages.perk.card.giftCard')
      : rewardDetail.description ?? ''

  const discountText =
    percent_discount === 100
      ? t('pages.perk.card.freePerk')
      : `${discountedPoints} ${t('pages.perk.perkPointsAbbr')}`

  const discountUI = (
    <>
      <StyledStrikethroughDiscountText>
        {points} {t('pages.perk.perkPointsAbbr')}
      </StyledStrikethroughDiscountText>{' '}
      <StyledBoldDiscountText>{discountText}</StyledBoldDiscountText>
    </>
  )

  return (
    <StyledPerkImageOuterContainer>
      <StyledPerkCard>
        <StyledPerkCardTopRow>
          <StyledPerkImage src={image} alt={title} />
          <StyledColumnContainer>
            <StyledBoldCardText>{perkAmountText}</StyledBoldCardText>
            <StyledPoints>
              {percent_discount
                ? discountUI
                : `${points} ${t('pages.perk.perkPointsAbbr')}`}
            </StyledPoints>
          </StyledColumnContainer>
        </StyledPerkCardTopRow>
        <StyledPerkCardBottomRow>
          <div>
            <StyledPerkTitle>{title}</StyledPerkTitle>
            <StyledSubTitle>{perkSubtitleText}</StyledSubTitle>
          </div>
          {percent_discount && (
            <StyledBoldDiscountText>
              {percent_discount != 100
                ? t('pages.perk.card.percentOff', { percentage: percent_discount })
                : ''}
            </StyledBoldDiscountText>
          )}
        </StyledPerkCardBottomRow>
      </StyledPerkCard>
    </StyledPerkImageOuterContainer>
  )
}

export default PerkCard
