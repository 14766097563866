import { Typography } from '@mui/material'

import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageWithModal from 'layouts/pageWithModal'

import useAppDispatch from 'hooks/useAppDispatch'

import { BackButton } from 'components/BackButton'

import { SUGGESTED_PERK } from 'slices/constants'

import { submitSuggestedPerkThunk } from 'slices/perks'

import {
  StyledSuggestPerkForm,
  StyledSuggestPerkFormCTA,
  StyledSuggestPerkFormField,
  StyledSuggestPerkHeaderTitle,
  StyledSuggestPerkSuccessTitle,
  StyledSuggestPerSuccessContent
} from './styles'

export default function SuggestPerk() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const [suggestionSuccess, setSuggestionSuccess] = useState<null | boolean>(null)

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (inputValue) {
      try {
        await dispatch(
          submitSuggestedPerkThunk({ data: { [SUGGESTED_PERK]: inputValue } })
        ).unwrap() // built-in toolkit method; see https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        setSuggestionSuccess(true)
      } catch {
        setSuggestionSuccess(false)
      }
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    setInputValue(value)
  }

  const headerContent = (
    <div>
      <BackButton />
      <StyledSuggestPerkHeaderTitle variant="h3">
        {t('pages.perks.suggestPerk.header.title')}
      </StyledSuggestPerkHeaderTitle>
    </div>
  )

  const successContent = (
    <StyledSuggestPerSuccessContent>
      <StyledSuggestPerkSuccessTitle variant="h2" component="h1">
        {t('pages.perks.suggestPerk.success.title')}
      </StyledSuggestPerkSuccessTitle>
      <Typography variant="body1">
        {t('pages.perks.suggestPerk.success.description', {
          perkName: inputValue || t('pages.perks.suggestPerk.success.defaultPerkText')
        })}
      </Typography>
    </StyledSuggestPerSuccessContent>
  )

  const formContent = (
    <StyledSuggestPerkForm onSubmit={onSubmit}>
      <StyledSuggestPerkFormField
        autoFocus
        fullWidth
        required
        placeholder={`${t('pages.perks.suggestPerk.form.input.placeholder')}...`}
        onChange={onChange}
        value={inputValue}
      />
      <StyledSuggestPerkFormCTA
        disabled={!inputValue}
        type="submit"
        variant="contained"
        color="primary"
      >
        {t('pages.perks.suggestPerk.form.submitText')}
      </StyledSuggestPerkFormCTA>
    </StyledSuggestPerkForm>
  )

  return (
    <PageWithModal headerContent={headerContent} showHeaderImage={false}>
      {suggestionSuccess ? successContent : formContent}
    </PageWithModal>
  )
}
