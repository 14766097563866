import { useCallback } from 'react'
import { Redirect } from 'react-router-dom'

import { NavigationProps, useNavigation } from 'hooks/useNavigation'

import { RootPaths } from 'utils/helpers'

export interface UseRedirectOptions extends Partial<NavigationProps> {
  returnAsComponent?: boolean
}

export default function useCustomRedirect(path: RootPaths = RootPaths.home) {
  const navigation = useNavigation()
  return useCallback(
    ({ returnAsComponent = false, ...restProps }: UseRedirectOptions = {}) => {
      if (returnAsComponent) {
        return (
          <Redirect
            to={{
              pathname: path,
              preserveParams: false,
              ...restProps
            }}
          />
        )
      } else {
        navigation.replace({
          pathname: path,
          preserveParams: false,
          ...restProps
        })
      }
    },
    [path]
  )
}
