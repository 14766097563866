/**
 * Uses a given factor to calculate spacing in rem
 * 
 *  Multiply the factor by 5 to get the spacing in pixels
 * 
 *  Divide the desired pixel length by 5 to get the factor
 * 
 * @param factor 
 * @returns 
 */
export const getSpacing = (factor: number) => `${factor * 0.3125}rem` // = 2 * 0.3125rem(5px) = 0.625rem = 10px (A.K.A a factor of 5)
