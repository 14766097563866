import { styled } from '@mui/material'

import BaseModal from '../baseModal'

export const StyledSimpleModal = styled(BaseModal)(({ theme }) => ({
  '.MuiDialog-container': {
    margin: `0 ${theme.spacing(4)}`,
    '& > .MuiPaper-root': {
      minHeight: 0
    },
    '.BaseModal__actions': {
      marginTop: theme.spacing(2)
    }
  }
}))
