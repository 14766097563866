import { Colors } from 'theme'

import { styled, Button } from '@mui/material'

import { conditionalObject } from 'utils/helpers'

// containers

export const StyledPhoneContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice' && prop !== 'error'
})<{ isOnSmallDevice?: boolean; error?: boolean }>(
  ({ isOnSmallDevice, error, theme }) => ({
    borderRadius: theme.spacing(4),
    background: '#4B455E', // TODO: possibly create theme color for this
    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    ...conditionalObject(
      {
        padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
      },
      isOnSmallDevice
    ),
    ...conditionalObject(
      { border: `${theme.spacing(0.5)} solid ${theme.palette.colors.error}` },
      error
    )
  })
)

export const StyledInputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row'
})

// inputs

export const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  fontFamily: 'Poppins',
  display: 'block',
  padding: `${theme.spacing(1)} 0`,
  outline: 'none',
  backgroundColor: 'inherit',
  fontSize: theme.spacing(5),
  lineHeight: theme.spacing(5),
  '::placeholder': {
    color: theme.palette.colors.white,
    fontWeight: 'normal'
  },
  color: theme.palette.colors.white,
  width: '100%',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  border: 'none',
  borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.colors.blue}`,
  // the below three styles are needed to remove border rounding + shadows on ios devices
  borderRadius: 0,
  WebkitAppearance: 'none',
  WebkitBorderRadius: 0,
  ...conditionalObject(
    { fontSize: theme.spacing(3.5), lineHeight: theme.spacing(3.5) },
    isOnSmallDevice
  )
}))

export const StyledInternationalCountryCode = styled(StyledInput, {
  shouldForwardProp: (prop) => prop !== 'isOnSmallDevice'
})<{ isOnSmallDevice?: boolean }>(({ isOnSmallDevice, theme }) => ({
  '::placeholder': {
    color: theme.palette.colors.blue,
    fontWeight: 'bold',
    // below 3 styles needed to remove the disabled stylings safari adds to the text input
    WebkitTextFillColor: theme.palette.colors.blue,
    opacity: 1
  },
  opacity: 1,
  width: theme.spacing(8),
  ...conditionalObject({ width: theme.spacing(6) }, isOnSmallDevice)
}))

// buttons

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.colors.white
}))

export const disabledButtonStyles = {
  background: '#C8C8C8', // TODO: possibly create theme color for this
  color: Colors.white
}

export const StyledNextButton = styled(StyledButton)(({ theme }) => ({
  marginTop: theme.spacing(5)
}))
