import { authenticatedThunkCreator } from "slices/utils";
import { FetchCachedThunkProps, JackpotWinnersResponse } from "types";
import { JACKPOT } from 'slices/constants'
import { fetchRecords } from 'api'

export const fetchJackpotWinnersThunk = authenticatedThunkCreator<
    JackpotWinnersResponse,
    FetchCachedThunkProps | undefined
>(
    `${JACKPOT}/fetchJackpotWinnersThunk`,
    async (authedDTO) => await fetchRecords(authedDTO, 'jackpotWinners'),
)
