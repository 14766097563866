const ENDPOINTS = {
  obtainToken: 'token/obtain',
  refreshToken: 'token/refresh',
  impersonate: 'token/impersonate',
  suggestedReward: 'suggested-rewards',
  onarollDailyAnswer: 'onaroll-daily/answer',
  onarollDaily: 'onaroll-daily',
  redeemReward: 'redeem',
  perks: 'rewards',
  onboardingPerks: 'onboarding-rewards',
  yesterdaysGoals: 'goals-yesterday',
  goalsOnDate: 'goals-on-date',
  pointDoughEntries: 'pointsdoughentries',
  pointTotals: 'point-totals',
  acknowledgePerk: 'acknowledge-perk',
  sendOTP: 'otp/send',
  sendVerificationEmail: 'otp/send-verification-email',
  verifyOTP: 'otp/verify',
  verifyEmail: 'otp/verify-email',
  logout: 'logout',
  whoami: 'whoami',
  onboardingSeen: 'onboarding-seen',
  userSettings: 'user-settings',
  news: 'news-posts',
  redeemedPerks: 'redeemed-rewards',
  jackpotCurrentTicketInfo: 'ticket-count',
  jackpotTicketHistory: 'jackpot-history',
  jackpotCurrentDrawing: 'current-drawing',
  jackpotWinners: 'winners',
  achievedGoals: 'achieved-goals',
  stats: 'stats',
  brands: 'brands',
  stores: 'stores',
  allGoals: 'tlx/goals',
  allGoalPointGroups: 'tlx/all-goal-point-groups',
  allGoalFrequencies: 'tlx/all-goal-frequencies',
  allStores: 'tlx/all-stores',
  storeGroups: 'tlx/all-store-groups',
  locations: 'tlx/all-locations',
  teamMembers: 'tlx/team',
  teamRoles: 'tlx/team-roles',
  csvSchemas: 'tlx/csv-schemas',
  schedules: 'tlx/schedules',
  adminCelebrationPoints: 'celebration/points',
  adminDistributionPoints: 'distribution/points',
  employments: 'tlx/employments',
  favoriteRewards: 'favorite-rewards',
  sendSMS: 'sms/send',
  sessionTrack: 'session/track'
} as const

export default ENDPOINTS
