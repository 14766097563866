import { Colors } from 'theme'

import { alpha } from '@mui/material'

export const MuiPaperConfig = (primaryColor: string) => ({
  defaultProps: {
    elevation: 0
  },
  styleOverrides: {
    root: {
      boxShadow: 'none'
    },
    elevation1: {
      boxShadow: `0px 0px 25px 0px ${alpha(Colors.black, 0.1)}`
    },
    elevation2: {
      boxShadow: `0px 5px 50px ${alpha(primaryColor, 0.2)}`
    },
    elevation3: {
      boxShadow: '0px 0px 100px rgba(0, 0, 0, 0.25)'
    }
  }
})
