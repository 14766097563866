import clsx from 'clsx'
import startCase from 'lodash/startCase'

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps
} from '@mui/material'

import { memo } from 'react'
import { FieldRenderProps } from 'react-final-form'

import { ReactComponent as IndentArrowIcon } from 'assets/icons/AppIcons/IndentArrowIcon.svg'

import { FormSelectOptions } from 'utils/form'

import { SelectFieldContainer } from './styles'

export interface FormSelectProps extends FieldRenderProps<string | string[]> {
  formControlProps?: FormControlProps
  selectProps?: SelectProps
  options: FormSelectOptions[]
  multiple?: boolean
  required?: boolean
  shrinkLabel?: boolean
  allowNone?: boolean
  renderValue?: (value: unknown) => React.ReactNode
}

function FormSelectField({
  input: { name, value, onChange, ...restInput },
  meta,
  formControlProps = {},
  selectProps: { label, ...restSelectProps } = {},
  options,
  multiple,
  className,
  required = false,
  shrinkLabel = true,
  allowNone = true,
  isSubField = false,
  renderValue
}: FormSelectProps) {
  const selectValue = multiple && !value ? [] : value
  const labelId = `form-select-label-${name}`
  const items = options.map(({ label: optionLabel, value }) => (
    <MenuItem key={`key-${value}`} value={value}>
      {(renderValue && renderValue(value)) || optionLabel}
    </MenuItem>
  ))
  const data = allowNone
    ? [
        <MenuItem key={`clearable-select-item-${labelId}`} value={''}>
          <em>None</em>
        </MenuItem>,
        ...items
      ]
    : [...items]

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const e = {
      ...event,
      target: { ...event.target, value: event.target.value as string | string[] | null }
    }
    if (e.target.value === '' || (multiple && e.target.value?.includes(''))) {
      e.target.value = multiple ? [] : null
    }
    return onChange(e)
  }

  const formControlStyles = isSubField
    ? { width: '100%', marginLeft: '10px' }
    : { width: '100%' }

  return (
    <SelectFieldContainer isSubField={isSubField}>
      {isSubField && <IndentArrowIcon />}
      <FormControl
        {...formControlProps}
        error={Boolean(meta.error)}
        className={clsx(className)}
        style={formControlStyles}
      >
        <InputLabel id={labelId} shrink={shrinkLabel}>
          {label || startCase(name)}
          {required && ' *'}
        </InputLabel>
        <Select
          {...restSelectProps}
          name={name}
          required={required}
          onChange={handleChange}
          inputProps={restInput}
          value={selectValue}
          labelId={labelId}
          multiple={multiple}
          renderValue={renderValue}
        >
          {data}
        </Select>
        {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </SelectFieldContainer>
  )
}
export default memo(FormSelectField)
