// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { AdminListFilters, AdminStore, StoreGroup } from 'types'

import { useEffect, useMemo, useState } from 'react'

import useAppDispatch from 'hooks/useAppDispatch'

import { AutoSelectFilter } from 'components/selectFilters'

import {
  fetchStoreGroupsThunk,
  fetchAllStoresThunk,
  storeGroupsSelectors,
  adminStoresSelectors
} from 'slices/admin'

interface StoreFiltersProps {
  onSelect: (filters: AdminListFilters) => void
}
export const StoreFilters = ({ onSelect }: StoreFiltersProps) => {
  const initialSelectValue = { name: '', id: '' }
  const [store, setStore] = useState<Partial<AdminStore>>(initialSelectValue)
  const [group, setGroup] = useState<Partial<StoreGroup>>(initialSelectValue)

  const setFilterContraints = useMemo(
    () => (group.id ? { group_id: group.id } : undefined),
    [group.id]
  )

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchStoreGroupsThunk({ queryParams: { store_id: store.id as string } }))
  }, [store.id])

  useEffect(() => {
    dispatch(fetchAllStoresThunk({ queryParams: { group_id: group.id } }))
  }, [group.id])

  return (
    <>
      <AutoSelectFilter<Partial<StoreGroup>>
        fieldName="Store Group"
        input={{ onChange: () => null }}
        handleFetchOptions={fetchStoreGroupsThunk}
        selectFetchedResults={storeGroupsSelectors.selectAll}
        onSelect={(groupId, groupName) => {
          const id = groupId as string
          setGroup({ ...group, id, name: groupName as string })
          onSelect({ group_id: id, store_id: store.id as string })
        }}
      />
      <AutoSelectFilter<Partial<AdminStore>>
        fieldName="Store"
        input={{ onChange: () => null }}
        handleFetchOptions={fetchAllStoresThunk}
        selectFetchedResults={adminStoresSelectors.selectAll}
        filterConstraints={setFilterContraints}
        onSelect={(storeId, storeName) => {
          const id = storeId as string
          setStore({ ...store, id, name: storeName as string })
          onSelect({ store_id: id, group_id: group.id })
        }}
      />
    </>
  )
}

export default StoreFilters
