import { FormApi } from 'final-form'

import { Button, InputAdornment, Typography } from '@mui/material'

import { useCallback, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  AppIconTypes,
  MessageFrequency,
  PreferredLanguage,
  SendVerificationEmailRequest,
  UserSettingsV3,
  UserSettingsV4,
  Whoami
} from 'types'

import { OTPChannel } from 'pages/login/constants'

import useAppDispatch from 'hooks/useAppDispatch'

import { FormSelectField, FormTextField } from 'components/form'

import { useOpenEmailVerificationModal } from 'hooks'
import { useNavigation } from 'hooks/useNavigation'

import { logoutAction } from 'slices/auth'
import { fetchAchievedGoals, getAchievedGoals } from 'slices/goals'
import { fetchRedeemedPerksThunk, redeemedPerksSelectors } from 'slices/perks'
import { getWhoami, updateUserSettingsV4Thunk } from 'slices/whoami'
import { sendVerificationEmailThunk } from 'slices/whoami/actions'

import { getMemoizedSelectValues } from 'utils/form'
import {
  onA11yKeyDown,
  RootPaths,
  setSnackbarSuccess,
  setSnackbarError
} from 'utils/helpers'
import { logError } from 'utils/logger'

import {
  StyledUserProfile,
  StyledUserProfileFields,
  StyledUserProfileGoalsCard,
  StyledUserProfileActionSection,
  StyledUserProfileActionButton,
  StyledUserProfilePerksCard,
  StyledUserProfileSubheader,
  StyledUserProfileSubheaderTitle,
  StyledUserProfileField,
  StyledForm,
  StyledEmailUnverifiedAlert,
  StyledEmailVerifiedIcon
} from './styles'

import { currentISODateWithoutTimezone } from './utils'

interface FormValues {
  display_name: string
  birthday: string
  phone: string
  email: string | null
  language: PreferredLanguage
  sms_frequency: MessageFrequency
}

export default function UserProfile() {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const whoami = useSelector(getWhoami)
  const achievedGoals = useSelector(getAchievedGoals) ?? []
  const perkRedemptions = useSelector(redeemedPerksSelectors.selectAll)
  const openEmailVerificationModal = useOpenEmailVerificationModal()

  const initialValues = useMemo(() => {
    const { display_name, birthday, phone, email, language, sms_frequency } =
      whoami as Whoami

    return {
      display_name,
      birthday,
      phone,
      email,
      language,
      sms_frequency
    }
  }, [whoami])

  useEffect(() => {
    const actions = [
      fetchRedeemedPerksThunk({
        getCachedResults: true
      }),
      fetchAchievedGoals({
        getCachedResults: true
      })
    ]
    actions.forEach(dispatch)
  }, [])

  const onResendVerificationEmailClick = useCallback(async () => {
    const email = whoami?.email
    if (email) {
      openEmailVerificationModal(email)
      dispatch(
        sendVerificationEmailThunk({
          data: {
            channel: OTPChannel.verification_email,
            to: email
          } as SendVerificationEmailRequest
        })
      )
    }
  }, [whoami?.email])

  const onLogout = async () => {
    try {
      await dispatch(logoutAction())
      navigation.replace({ pathname: RootPaths.welcome, preserveParams: false })
    } catch (error) {
      let message = 'Error - Failed to log out user!'
      if (error instanceof Error) message = error.message
      // TODO: What should we do if the user fails to logout?
      logError(message)
    }
  }

  const goToPerksWallet = () => {
    navigation.push(RootPaths.redeemedPerks)
  }

  const goToAchievedGoals = () => {
    navigation.push(RootPaths.achievedGoals)
  }

  const onSubmit = async (
    values: FormValues,
    form: FormApi<
      FormValues,
      {
        display_name: string
        birthday: string
        phone: string
        email: string | null
        language: PreferredLanguage
        sms_frequency: MessageFrequency
      }
    >
  ) => {
    const formState = form.getState()
    const dirtyValues: UserSettingsV3 = formState.dirty
      ? Object.keys(formState.dirtyFields).reduce(
          (memo, df) => ({
            ...memo,
            [df]: values[df as keyof UserSettingsV3] || null
          }),
          {}
        )
      : {}
    dispatch(
      updateUserSettingsV4Thunk({
        id: whoami?.user_profile_id,
        data: dirtyValues as UserSettingsV4
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setSnackbarSuccess(t('pages.userProfile.form.success')))
        if (values?.email && whoami?.email !== values?.email) {
          // Only send verification email once we know the email was changed successfully
          openEmailVerificationModal(values.email)
        }
      })
      .catch(() => {
        dispatch(setSnackbarError(t('pages.userProfile.form.error')))
        form.initialize(initialValues)
      })
  }

  const subheaderContent = (
    <StyledUserProfileSubheader>
      <StyledUserProfileGoalsCard
        elevation={0}
        role="button"
        tabIndex={0}
        onClick={goToAchievedGoals}
        onKeyDown={(event) => onA11yKeyDown(event, goToAchievedGoals)}
      >
        <StyledUserProfileSubheaderTitle
          data-testid="achieved-goals"
          variant="h2"
          component="p"
        >
          {achievedGoals.length}
        </StyledUserProfileSubheaderTitle>
        <Typography variant="h5" component="p">
          {t('pages.userProfile.achievedGoals')}
        </Typography>
      </StyledUserProfileGoalsCard>

      <StyledUserProfilePerksCard
        elevation={0}
        role="button"
        tabIndex={0}
        onClick={goToPerksWallet}
        onKeyDown={(event) => onA11yKeyDown(event, goToPerksWallet)}
      >
        <StyledUserProfileSubheaderTitle variant="h2" component="p">
          {perkRedemptions.length}
        </StyledUserProfileSubheaderTitle>
        <Typography variant="h5" component="p">
          {t('pages.userProfile.redeemedPerks')}
        </Typography>
      </StyledUserProfilePerksCard>
    </StyledUserProfileSubheader>
  )

  const renderDisplayNameField = () => (
    <StyledUserProfileField
      name="display_name"
      data-testid="displayName"
      type="text"
      inputFieldProps={{
        label: t('pages.userProfile.form.name')
      }}
      shrinkLabel={false}
      component={FormTextField}
      required
    />
  )

  const renderBirthdayField = () => (
    <StyledUserProfileField
      name="birthday"
      data-testid="birthday"
      type="date"
      inputFieldProps={{
        label: t('pages.userProfile.form.birthday')
      }}
      inputProps={{ max: currentISODateWithoutTimezone() }}
      shrinkLabel={false}
      component={FormTextField}
    />
  )

  const renderPhoneField = () => (
    <StyledUserProfileField
      name="phone"
      data-testid="phone"
      type="tel"
      inputFieldProps={{
        label: t('pages.userProfile.form.phone')
      }}
      InputProps={{
        readOnly: true,
        disabled: true
      }}
      shrinkLabel={false}
      component={FormTextField}
      required
    />
  )

  const renderEmailField = () => {
    return (
      <StyledUserProfileField
        name="email"
        data-testid="email"
        type="email"
        inputFieldProps={{
          label: t('pages.userProfile.form.email')
        }}
        InputProps={
          whoami?.email
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledEmailVerifiedIcon
                      verified={whoami?.is_email_verified}
                      type={AppIconTypes.verified}
                    />
                  </InputAdornment>
                )
              }
            : {}
        }
        shrinkLabel={false}
        component={FormTextField}
      />
    )
  }

  const renderPreferredLanguageSelect = () => {
    const preferredLanguageOptions = getMemoizedSelectValues({
      options: Object.values(PreferredLanguage),
      getLabel: (value) => t(`pages.userProfile.form.languagePreference.values.${value}`),
      getValue: (value) => value
    })

    return (
      <StyledUserProfileField
        name="language"
        formControlProps={{
          'data-testid': 'language'
        }}
        selectProps={{
          label: t('pages.userProfile.form.languagePreference.title')
        }}
        shrinkLabel={false}
        allowNone={false}
        render={(props) => (
          <FormSelectField {...props} options={preferredLanguageOptions} />
        )}
        required
      />
    )
  }

  const renderMessageFrequencySelect = () => {
    const messageFrequencyOptions = getMemoizedSelectValues({
      options: Object.values(MessageFrequency),
      getLabel: (value) => t(`pages.userProfile.form.textingPreference.values.${value}`),
      getValue: (value) => value
    })

    return (
      <StyledUserProfileField
        name="sms_frequency"
        formControlProps={{
          'data-testid': 'smsFrequency'
        }}
        selectProps={{
          label: t('pages.userProfile.form.textingPreference.title')
        }}
        shrinkLabel={false}
        allowNone={false}
        render={(props) => (
          <FormSelectField {...props} options={messageFrequencyOptions} />
        )}
        required
      />
    )
  }

  return (
    <StyledUserProfile themeType="neutral" subheaderContent={subheaderContent}>
      <Form
        subscription={{
          submitting: true,
          pristine: true,
          hasValidationErrors: true
        }}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
          <StyledForm onSubmit={handleSubmit}>
            {whoami?.email && !whoami?.is_email_verified && (
              <StyledEmailUnverifiedAlert
                bold
                cta={
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onResendVerificationEmailClick}
                  >
                    <Typography variant="body1">
                      {t('pages.userProfile.unverifiedAlert.cta')}
                    </Typography>
                  </Button>
                }
              >
                <Typography variant="body1">
                  {t('pages.userProfile.unverifiedAlert.title')}
                </Typography>
              </StyledEmailUnverifiedAlert>
            )}
            <StyledUserProfileFields>
              {renderDisplayNameField()}
              {renderBirthdayField()}
              {renderPhoneField()}
              {renderEmailField()}
              {renderPreferredLanguageSelect()}
              {renderMessageFrequencySelect()}
            </StyledUserProfileFields>

            <StyledUserProfileActionSection>
              <StyledUserProfileActionButton
                data-testid="submit"
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting || pristine || hasValidationErrors}
                fullWidth
              >
                {t('pages.userProfile.save')}
              </StyledUserProfileActionButton>
              <StyledUserProfileActionButton
                data-testid="logout"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={onLogout}
              >
                {t('pages.userProfile.logout')}
              </StyledUserProfileActionButton>
            </StyledUserProfileActionSection>
          </StyledForm>
        )}
      />
    </StyledUserProfile>
  )
}
