import { useTranslation } from 'react-i18next'

import { AnimatedContent, VerticalAnimationDirection } from 'components/AnimatedContent'

import {
  StyledOnboardingFooterTermsLink,
  StyledOnboardingFooterTermsText
} from './styles'

interface FooterLinksProps {
  isLastPage: boolean
}

const privacyPolicyURL =
  'https://www.notion.so/onaroll/Onaroll-Privacy-Policy-4eee1b7121b74dff964f25ccce65f5d1'
const smsTermsURL =
  'https://www.notion.so/onaroll/Mobile-Terms-of-Service-57acc34dd8234af991b6f2b45e040404'

const getLink = (href: string, transText: string) => (
  <StyledOnboardingFooterTermsLink href={href} target="_blank" underline="hover">
    {transText}
  </StyledOnboardingFooterTermsLink>
)

const FooterLinks = ({ isLastPage }: FooterLinksProps) => {
  const { t } = useTranslation()

  if (!isLastPage) return null
  return (
    <AnimatedContent verticalAnimationDirection={VerticalAnimationDirection.NONE}>
      <StyledOnboardingFooterTermsText variant="h5" data-testid="OnboardingFooter-Links">
        {t('pages.onboarding.termStep.footer.body')}
        <br />
        {getLink(privacyPolicyURL, t('pages.onboarding.termStep.footer.privacyPolicy'))}
        {' | '}
        {getLink(smsTermsURL, t('pages.onboarding.termStep.footer.smsTerms'))}
      </StyledOnboardingFooterTermsText>
    </AnimatedContent>
  )
}

export default FooterLinks
