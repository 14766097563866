import { styled } from '@mui/material/styles'

interface ScrollConcealerProps {
  height: number
  width: number
  heightoffset?: number
}

export const StyledScrollBarConcealer = styled('div')<ScrollConcealerProps>(
  ({ theme, height, width, heightoffset = 0 }) => ({
    height,
    width,
    backgroundColor: '#f0f4fd',
    position: 'absolute',
    top: heightoffset,
    right: 0,
    [theme.breakpoints.down('md')]: {
      height: 0
    }
  })
)
