import { Middleware } from '@reduxjs/toolkit'

import { updateHeaders } from 'api/protocols'

import { logError } from 'utils/logger'
import { getDDUserSessionId } from 'utils/session'

import { RootState } from 'reduxStore'

const LANGUAGE_HEADER = {
  en: 'en-US',
  es: 'es-ES'
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const logAndReportCrash: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const ddSessionId = getDDUserSessionId()

    try {
      return next(action)
    } catch (error) {
      const errParams = {
        action,
        whoami: store.getState()?.whoami?.whoami,
        error: (error as Error)?.message ?? error,
        ddSessionId
      }
      logError(`[Custom Redux Middleware]: Error dispatching ${action.type}`, errParams)
      throw error
    }
  }

// eslint-disable-next-line @typescript-eslint/ban-types
export const setLanguageHeader: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const state = store.getState()
    const language = state.whoami.whoami?.language
    if (language) {
      updateHeaders({ 'Accept-Language': LANGUAGE_HEADER[language] })
    }
    return next(action)
  }
