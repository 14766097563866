import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { CategoryTypes } from 'types'

import FlippableCaretIcon from 'components/icons/FlippableCaretIcon/FlippableCaretIcon'

import { useNavigation } from 'hooks/useNavigation'

import { getPerkCategories } from 'slices/perks'

import { StyledPerkCategories, StyledPerkCategoriesText } from './styles'

import { onA11yKeyDown, toTitleCase } from '../../utils/helpers'
import { getParamsForUrl, getSelectedCategoriesFromUrl } from '../../utils/url'
import SelectCategoriesModal from './selectCategoriesModal'

const MAX_CATEGORIES_TO_DISPLAY = 3 as const

export default function PerkCategories() {
  const location = useLocation()
  const navigation = useNavigation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState<CategoryTypes[]>([])
  const categories = useSelector(getPerkCategories)

  const { t } = useTranslation()

  useEffect(() => {
    // If we have any categories in the url on first render
    // then set them all as active filters

    const prevCategories = getSelectedCategoriesFromUrl(location.search)
    if (prevCategories.length) {
      setSelectedCategories(prevCategories as CategoryTypes[])
    }
  }, [])

  useEffect(() => {
    // Sync categories with url so we can consume in other components

    // NOTE: If the selectedCategories state is empty on initial render we are going to need to
    // wrap this in a `if (!isMounted) { history.push(...) }` with the `useIsMounted` hook
    // Since selectedCategories can be empty when de-selecting filters
    // we only want to save it to the url when WE caused it to be empty
    // not because on initial render there is nothing to show

    const prevCategories = getSelectedCategoriesFromUrl(location.search)
    const areCategoriesEqual =
      prevCategories.length === selectedCategories.length &&
      prevCategories.every((category) =>
        selectedCategories.includes(category as CategoryTypes)
      )

    // Only push new route if we are updating the current categories
    if (!areCategoriesEqual) {
      navigation.pushParams({
        search: getParamsForUrl({ categories: selectedCategories })
      })
    }
  }, [selectedCategories])

  if (!categories?.length) return null

  const onClose = () => {
    setIsModalOpen(false)
  }

  const onClear = () => {
    setSelectedCategories([])
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const onCategorySelected = (category: CategoryTypes) => {
    setSelectedCategories((prevCategories) => {
      const alreadySelected = prevCategories.includes(category)
      const updatedCategories = alreadySelected
        ? prevCategories.filter((prevCategory) => prevCategory !== category)
        : [...prevCategories, category]
      return updatedCategories
    })
  }

  const getTranslationsForPerkCategory = (category: string) => {
    const translationCategory = `pages.perks.categories.select.${category.toLowerCase()}`
    const translatedCategory = t(translationCategory)

    if (translatedCategory !== translationCategory) {
      return translatedCategory
    }
    return category
  }

  const truncateAndSummarize = (
    arr: string[],
    suffix: string,
    maxLength = MAX_CATEGORIES_TO_DISPLAY
  ) => {
    arr = arr.map((category) => getTranslationsForPerkCategory(category))
    if (arr.length <= maxLength) {
      return arr?.map(toTitleCase).join(', ')
    }
    const items = arr.slice(0, maxLength).map(toTitleCase).join(', ')
    const summarizedText = `${items} + ${arr.length - maxLength} ${suffix}`
    return summarizedText
  }

  const renderCategories = () => {
    if (selectedCategories.length === 0) return t('pages.perks.categories.trigger')
    return truncateAndSummarize(selectedCategories, t('pages.perks.summarizeTextSuffix'))
  }

  return (
    <>
      <StyledPerkCategories
        role="button"
        tabIndex={0}
        onClick={openModal}
        onKeyDown={(event) => onA11yKeyDown(event, openModal)}
      >
        <StyledPerkCategoriesText variant="body1">
          {renderCategories()}
        </StyledPerkCategoriesText>
        <FlippableCaretIcon flip={isModalOpen} />
      </StyledPerkCategories>
      <SelectCategoriesModal
        onCategorySelected={onCategorySelected}
        selectedCategories={selectedCategories}
        categories={categories}
        isOpen={isModalOpen}
        onClose={onClose}
        onClear={onClear}
      />
    </>
  )
}
