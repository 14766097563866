export enum SMSTypes {
  invite = 'invite'
}

export interface SMSSendRequestData {
  message_body: string
  user_profile_ids: string[]
  message_type: SMSTypes
}

export interface SMSSendResponse {
  Success: string[]
  Failure: string[]
}
