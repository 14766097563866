import { logError } from 'utils/logger'
import { setupApp } from 'utils/firebase'

const setupFirebase = () => {
  try {
    setupApp()
  } catch (error) {
    logError("Error initializing firebase")
  }
}

export default setupFirebase
