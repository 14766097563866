import { TeamMemberV2 } from 'types'

import { strSort } from 'utils/sort'

export const sortByFirstName = (a: TeamMemberV2, b: TeamMemberV2) => {
  return strSort(a, b, 'first_name')
}

export const sortByMissingPhone = (a: TeamMemberV2, b: TeamMemberV2) => {
  const missingPhone = (tm: TeamMemberV2) => !tm.phone
  const toNum = (tm: TeamMemberV2) => Number(missingPhone(tm))
  const [first, second] = [a, b].map(toNum)
  return second - first
}

export const sortByMissingPhoneThenByFirstName = (a: TeamMemberV2, b: TeamMemberV2) =>
  sortByMissingPhone(a, b) || sortByFirstName(a, b)
