import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from 'assets/icons/AppIcons/CloseIcon.svg'

import {
  StyledOnboardingContactUsWidgetContainer,
  StyledOnboardingErrorCloseIconContainer,
  StyledOnboardingContactUsWidget
} from './styles'

export interface OnboardingContactUsWidgetProps {
  i18nKey: string
  onClose?: () => void
  messageBody?: string
}

const OnboardingContactUsWidget = ({
  i18nKey,
  onClose,
  messageBody
}: OnboardingContactUsWidgetProps) => {
  const { t } = useTranslation()

  return (
    <StyledOnboardingContactUsWidgetContainer data-testid="OnboardingContactUsWidget-Container">
      {onClose && (
        <StyledOnboardingErrorCloseIconContainer
          data-testid="OnboardingContactUsWidget-CloseButton"
          onClick={onClose}
        >
          <CloseIcon />
        </StyledOnboardingErrorCloseIconContainer>
      )}
      <StyledOnboardingContactUsWidget title={t(i18nKey)} messageBody={messageBody} />
    </StyledOnboardingContactUsWidgetContainer>
  )
}

export default OnboardingContactUsWidget
