import { SelectChangeEvent } from '@mui/material'
import { Dispatch } from '@reduxjs/toolkit'

import { SetStateAction } from 'react'

import { UserSettingsV2 } from 'types'

import { updateUserSettingsV2Thunk } from 'slices/whoami'

export const updateUserSetting =
  <T>(
    user_profile_id: string | undefined,
    setting: keyof UserSettingsV2,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    setStateFn?: (value: SetStateAction<T | null>) => void
  ) =>
  (event: SelectChangeEvent<unknown>) => {
    const value = event?.target?.value as T
    if (value) {
      setStateFn && setStateFn(value)
      const data: UserSettingsV2 = { [setting]: value }
      const action = updateUserSettingsV2Thunk({ id: user_profile_id, data })
      dispatch && dispatch(action)
    }
  }

export const currentISODateWithoutTimezone = () =>
  new Date().toISOString().split('T')[0].toString()
