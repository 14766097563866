import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxStore";
import { winnersAdapter } from './reducer'

const selectWinners = (state: RootState) => state.jackpot.winners

export const winnersSelectors = winnersAdapter.getSelectors<RootState>(selectWinners)

export const getWinnersLoading = createSelector(selectWinners, (winners) => winners.isLoading)

export const getJackpotWinners = createSelector(
    winnersSelectors.selectAll,
    (winners) => winners
)
