import { ReactFragment } from 'react'

import { BannerSC } from './styles'

interface Props {
  children?: ReactFragment
}

const Banner = ({ children }: Props) => {
  return !children ? null : (
    <BannerSC data-testid="banner">
      <strong>{children}</strong>
    </BannerSC>
  )
}
export default Banner
