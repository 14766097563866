// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DailyQuestion } from 'types'

import { createSlice } from '@reduxjs/toolkit'

import { DAILY_QUESTION, requestState, RequestState } from 'slices/constants'

import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { fetchDailyQuestionThunk } from './actions'

interface DailyQuestionState extends RequestState {
  dailyQuestion: DailyQuestion
}

const initialState: DailyQuestionState = {
  dailyQuestion: {} as DailyQuestion,
  ...requestState
}

export const dailyQuestionSlice = createSlice({
  name: DAILY_QUESTION,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyQuestionThunk.fulfilled, (state, action) => {
        state.dailyQuestion = action.payload
        handleRequestFulfilled(state)
      })
      .addCase(fetchDailyQuestionThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDailyQuestionThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
  }
})

export default dailyQuestionSlice.reducer
