import { CategoryTypes } from 'types'

import { Button } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CategoryIcon from 'components/icons/CategoryIcon/CategoryIcon'
import { DefaultBaseModalProps } from 'components/modals'

import {
  StyledCategoriesModal,
  StyledCategoriesModalFooter,
  StyledCategoriesModalIcon,
  StyledCategoriesModalIconContainer,
  StyledCategoriesModalIconText
} from './styles'

interface SelectCategoriesModalProps extends DefaultBaseModalProps {
  categories: string[]
  onCategorySelected: (key: CategoryTypes) => void
  onClear: () => void
  selectedCategories: CategoryTypes[]
}

export default function SelectCategoriesModal({
  categories,
  isOpen,
  onCategorySelected,
  onClose,
  onClear,
  selectedCategories
}: SelectCategoriesModalProps) {
  const { t } = useTranslation()

  const onCategoryClick = (category: CategoryTypes) => {
    onCategorySelected?.(category)
  }

  const title = t('pages.perks.categories.modal.title')

  const content = () => {
    return categories.map((category) => {
      const categoryType = CategoryTypes[category.toLowerCase() as CategoryTypes]
      // If we can't find the category, don't display it
      if (!categoryType) return null
      return (
        <StyledCategoriesModalIconContainer key={category}>
          <StyledCategoriesModalIcon
            onClick={() => onCategoryClick(categoryType)}
            size="large"
          >
            <CategoryIcon
              type={categoryType}
              active={selectedCategories.includes(categoryType)}
            />
          </StyledCategoriesModalIcon>
          <StyledCategoriesModalIconText variant="h5" component="span">
            {t(`pages.perks.categories.select.${category.toLowerCase()}`)}
          </StyledCategoriesModalIconText>
        </StyledCategoriesModalIconContainer>
      )
    })
  }

  const footer = (
    <StyledCategoriesModalFooter>
      <Button variant="outlined" color="secondary" onClick={onClear}>
        {t('pages.perks.categories.modal.footer.clearButtonText')}
      </Button>
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {t('pages.perks.categories.modal.footer.closeButtonText')}
      </Button>
    </StyledCategoriesModalFooter>
  )

  return (
    <StyledCategoriesModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      content={content()}
      footer={footer}
    />
  )
}
