import { Typography, styled } from '@mui/material'

export const Card = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.grayDisabled}`,
  padding: `${theme.spacing(6)} ${theme.spacing(5)}`,
  borderRadius: '24px'
}))

export const LottieAnimationContainer = styled('div')({
  margin: '0 auto',
  maxWidth: '180px'
})

export const ResponseHeading = styled(Typography)(({ theme }) => ({
  ...theme.customTypography.display2,
  marginBottom: theme.spacing(3)
}))

export const ResponseText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  '& > span': {
    fontWeight: 'bold'
  }
}))
