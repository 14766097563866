import { Box } from '@mui/material'

import { PerkItemLoader } from 'placeholder'

import { StyledOnboardingGrid } from '../styles'

const LoadingPerksGrid = () => {
  return (
    <StyledOnboardingGrid data-testid="OnboardingPerkStep-GridContainer">
      {Array.from(Array(12)).map((_, i) => (
        <Box
          gridColumn="span 4"
          key={i}
          data-testid={`OnboardingPerkStep-LoadingGridItem${i}`}
        >
          <PerkItemLoader boxColor="lightBlack" width="100%" height="100%" />
        </Box>
      ))}
    </StyledOnboardingGrid>
  )
}

export default LoadingPerksGrid
