import { AutoSuggestField } from 'components/form'
import { AutoSuggestProps } from 'components/form/AutoSuggestField'

import useStyles from './styles'
import { CommonResponse } from '../../types'

export default function AutoSelectFilter<T extends CommonResponse>(props: AutoSuggestProps<T>) {
  const {suggestionsContainer, containerClass } = useStyles()
  return (
    <AutoSuggestField<T>
      {...props}
      suggestionsClass={suggestionsContainer}
      containerClass={containerClass}
    />
  )
}
