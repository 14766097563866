import { styled } from '@mui/material'

export const StyledOnboarding = styled('div')({
  padding: 0,
  overflow: 'hidden',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
})

export const StyledOnboardingSpacing = styled('div')({
  marginTop: '-48px' // logo height
})

export const StyledOnboardingSteps = styled(StyledOnboarding)(({ theme }) => ({
  backgroundColor: theme.palette.colors.black,
  padding: theme.spacing(5),
  justifyContent: 'center'
}))
