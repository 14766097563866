import { BRANDS } from 'slices/constants'

import { brandsAdapter } from './reducer'

import { RootState } from 'reduxStore'

export const selectBrandsLoading = (state: RootState) => state[BRANDS].isLoading

export const selectBrandsHasLoaded = (state: RootState) => state[BRANDS].hasLoaded

export const brandsSelectors = brandsAdapter.getSelectors<RootState>(
  (state) => state.brands
)
