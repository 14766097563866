import {
  API,
  ARRIVE_ON_TIME,
  COLUMN_FIELD,
  COLUMN_VALUE,
  CSV,
  dataSourceList,
  MANUAL_ENTRY,
  pointTypeList,
  thresholdList,
  WORK_A_SHIFT
} from 'constants/globals'
import { filter, keys, map, split, startCase, transform } from 'lodash'
import { DateTime as dt, DurationInput } from 'luxon'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { EntityId } from '@reduxjs/toolkit'

import { useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  AdminGoal,
  AdminListFilters,
  AdminStore,
  CSVField,
  CSVSchema,
  GoalFrequencyOption,
  GoalPointGroupOption,
  GoalWithCsvSchema,
  StoreGroup,
  Filter
} from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import UpsertFormContainer, {
  AutoCompleteField,
  FormDateTimeField,
  FormSelectField,
  FormTextField
} from 'components/form'
import FormCheckboxField from 'components/form/FormCheckBox'
import HeartLoadingIndicator from 'components/heartLoadingIndicator'
import GoalIcon, { GoalIconTypes } from 'components/icons/GoalIcon/GoalIcon'

import { useNavigation } from 'hooks/useNavigation'

import {
  adminGoalsSelectors,
  adminStoresSelectors,
  createGoalThunk,
  CSVSchemaSelectors,
  fetchAllCSVSchemasThunk,
  fetchAllGoalFrequenciesThunk,
  fetchAllGoalPointGroupsThunk,
  fetchAllLocationsThunk,
  fetchAllSchedulesThunk,
  fetchAllStoresThunk,
  fetchGoalThunk,
  fetchStoreGroupsThunk,
  fetchTeamRolesThunk,
  getAllGoalFrequencies,
  getAllGoalPointGroups,
  locationsSelectors,
  schedulesSelectors,
  selectAdminGoals,
  selectAdminStoresLoading,
  selectGoalsLoading,
  selectGoalVersionsForForm,
  selectStoreGroupsLoading,
  storeGroupsSelectors,
  teamRolesSelectors,
  updateGoalThunk
} from 'slices/admin'
import { setSnackbar } from 'slices/snackbar'

import {
  formatAPIDate,
  formatSelectValues,
  FormSelectOptions,
  FormSelectParams
} from 'utils/form'
import { AdminPaths } from 'utils/helpers'
import { UrlParamTypes } from 'utils/url'

import { FilterButton, StyledForm, SubmitButton } from '../shared/styles'

import { RootState } from 'reduxStore'

import goalsConstants from './goals.constants'

// Note: These MUST match the `name` props and field types on each field
interface Values {
  title?: string
  description?: string
  points: number
  max_completions?: number
  effective_at?: string
  expires_at?: string
  configuration: string
  roles: string[]
  store_group?: string
  store?: string
  point_group?: string
  frequency?: GoalFrequencyOption
  location?: string
  schedule?: string
  dependencies: string[]
  data_field: string
  threshold: number
  late_threshold: number
  early_threshold: number
  work_a_shift_threshold?: boolean
  column_field: string
  column_value: number
  icon: string
  result_text: string
  testing: boolean
}

interface HistoryState {
  values?: Values
}

interface Options {
  id?: string
  name?: string
}

export default function AdminGoalsForm({ entityType }: { entityType: string }) {
  const REGEX_FIELD = 'keywords_'
  const COMPARE_FIELD = 'column_name_'
  const dispatch = useAppDispatch()
  const history = useHistory()
  const navigation = useNavigation()
  const { id: goalIdFromParams } = useParams<UrlParamTypes>()

  const [filtersCount, setFiltersCount] = useState<number>(0)
  const [store, setStore] = useState<Partial<AdminStore>>({ name: '', id: '' })
  const [group, setGroup] = useState<Partial<StoreGroup>>({ name: '', id: '' })
  const [dataFields, setDataFields] = useState<Options[]>([])
  const [flourFields, setFlourFields] = useState<Options[]>([])
  const [columnFields, setColumnFields] = useState<FormSelectOptions[]>([])
  const [storeCSVSchemas, setStoreCSVSchemas] = useState<CSVSchema[]>([])

  const allGoals = useSelector(selectAdminGoals)
  const selectedGoal = useSelector((state: RootState) =>
    adminGoalsSelectors.selectById(state, goalIdFromParams as string)
  )
  const isSelectedGoalLoading = useSelector(selectGoalsLoading)
  const allLocations = useSelector(locationsSelectors.selectAll)
  const allSchedules = useSelector(schedulesSelectors.selectAll)
  const allGoalPointGroups = useSelector(getAllGoalPointGroups)
  const allGoalFrequencies = useSelector(getAllGoalFrequencies)
  const csvSchemas = useSelector(CSVSchemaSelectors.selectAll)
  const teamRoles = useSelector(teamRolesSelectors.selectAll)
  const allStores = useSelector(adminStoresSelectors.selectAll)

  const dependencies: AdminGoal[] = useMemo(
    () =>
      allGoals?.filter(
        ({ id, store_id, group_id }) =>
          id !== goalIdFromParams && (group_id == group.id || store_id == store.id)
      ) || [],
    [store, group, allGoals, goalIdFromParams]
  )

  const configOptions = map(pointTypeList, (pt) => {
    if (pt === 'checkin') return { name: startCase('Arrive on Time'), id: 'checkin' }
    else return { name: startCase(pt), id: pt }
  })

  const allIcons = map(GoalIconTypes, (type) => ({ name: type }))

  const dataSourceOptions: Options[] = map(dataSourceList, (dataSource) => ({
    name: dataSource === MANUAL_ENTRY ? dataSource : dataSource.toUpperCase(),
    id: dataSource
  }))
  const thresholdOptions = map(thresholdList, (threshold) => ({
    name: threshold,
    id: threshold
  }))
  const csvSchemaOptions = map(storeCSVSchemas, ({ name, id }) => ({ name, id }))

  const isGoalCreationForm = () => goalIdFromParams === 'create'

  const actionType = isGoalCreationForm() ? 'Add' : 'Update'
  const cloneType = isGoalCreationForm() ? 'Add & Clone' : 'Clone'

  const setCsvDataFields = (
    schemaId?: string,
    csvSchemas?: CSVSchema[],
    selectedDataFieldId?: string
  ) => {
    const dataFields = csvSchemas
      ?.filter((schema: CSVSchema) => schema.id === schemaId)[0]
      ?.fields.map((field: CSVField) => ({ id: field.rename, name: field.name }))
    selectedDataFieldId && setCsvColumnFields(selectedDataFieldId, dataFields)
    setDataFields(dataFields as Options[])
  }

  const setCsvColumnFields = (dataFieldId?: string, availableDataFields?: Options[]) => {
    const columnFields = availableDataFields?.reduce(
      (fields, dataField: Options) =>
        dataField.id !== dataFieldId
          ? [...fields, { label: dataField.name, value: dataField.id }]
          : fields,
      [] as FormSelectOptions[]
    )
    setColumnFields(columnFields as FormSelectOptions[])
  }

  const getFlourFields = (storeId: string, store: AdminStore) => {
    const flourFields = store.flour_fields
    if (store.id === storeId && flourFields.length > 0) {
      return flourFields.reduce(
        (acc, field: string) => [...acc, { id: field, name: field }],
        [] as Options[]
      )
    }
    return []
  }
  const getStoreFlourSchema = (storeId: string, store: AdminStore | null = null) => {
    const selectedStoreFlourFields = store
      ? getFlourFields(storeId, store)
      : allStores?.reduce((accum, store: AdminStore) => {
          const flourFields = getFlourFields(storeId, store)
          return flourFields.length > 0 ? flourFields : accum
        }, [] as Options[])
    setFlourFields(selectedStoreFlourFields)
  }

  const getInitialValues = useMemo(() => {
    if (isGoalCreationForm()) {
      const state = history.location?.state as HistoryState
      const date = dt.now()
      const effective_at = date.toISO()
      const expires_at = date.plus(goalsConstants.addToExpiresAt).toISO()
      if (!state?.values) return { effective_at, expires_at }
      return { ...state.values }
    }

    if (!selectedGoal) return null

    const {
      title,
      description,
      points,
      frequency,
      group_id,
      group_name,
      store_id,
      store_name,
      configuration,
      eligibility,
      dependencies,
      location,
      schedule,
      max_completions,
      effective_at,
      expires_at,
      point_group,
      icon,
      csv_schema,
      csv_schemas,
      flour_field,
      store,
      result_text,
      testing
    } = selectedGoal as GoalWithCsvSchema

    const filters = configuration?.filters as Filter[]
    setStore({ id: store_id, name: store_name })
    setGroup({ id: group_id, name: group_name })
    setFiltersCount(filters?.length || filtersCount)
    setStoreCSVSchemas(csv_schemas as CSVSchema[])
    getStoreFlourSchema(store_id, store)
    csv_schema &&
      setCsvDataFields(
        csv_schema[0].id as string,
        csv_schemas,
        configuration?.data_field as string | undefined
      )

    const filterFields = {} as Record<string, string | number>
    filters?.forEach((filter: Filter, i: number) => {
      filterFields[`${COMPARE_FIELD}${i + 1}`] = filter.compare_field
      filterFields[`${REGEX_FIELD}${i + 1}`] = filter.regex
    })

    const dataSource = csv_schema ? 'csv' : flour_field ? 'Manual Entry' : 'api'
    const csv = csv_schema && csv_schema[0].id

    const config = {
      select_csv: csv,
      configuration: configuration?.ignore_shift_start
        ? WORK_A_SHIFT
        : configuration?.goal,
      data_field: flour_field ? flour_field : configuration?.data_field,
      threshold: configuration?.compare_value,
      early_threshold: configuration?.start_early,
      late_threshold: configuration?.start_late,
      work_a_shift_threshold: configuration?.ignore_shift_start,
      threshold_type: configuration?.compare_value ? 'Column Value' : 'Column Field',
      column_value: configuration?.compare_value,
      column_field: configuration?.compare_field,
      ...filterFields
    }

    return {
      title,
      description,
      icon,
      points,
      max_completions,
      effective_at,
      expires_at,
      store_group: group_id,
      store: store_id,
      point_group,
      frequency,
      location,
      schedule,
      dependencies,
      roles: eligibility?.roles,
      data_source: dataSource,
      result_text,
      testing,
      ...config
    }
  }, [allGoals, isSelectedGoalLoading, goalIdFromParams])

  useEffect(() => {
    if (csvSchemas) {
      setStoreCSVSchemas(csvSchemas)
    }
  }, [csvSchemas])

  useEffect(() => {
    dispatch(
      fetchAllLocationsThunk({
        getCachedResults: true
      })
    )
    dispatch(
      fetchAllSchedulesThunk({
        getCachedResults: true
      })
    )
    dispatch(
      fetchAllGoalPointGroupsThunk({
        getCachedResults: true
      })
    )
    dispatch(
      fetchAllGoalFrequenciesThunk({
        getCachedResults: true
      })
    )
    dispatch(
      fetchTeamRolesThunk({
        getCachedResults: true
      })
    )
    if (!isGoalCreationForm()) {
      dispatch(
        fetchGoalThunk({
          pk: goalIdFromParams as string
        })
      )
    }
  }, [])

  const getMemoizedOptions = <T,>({ options, getLabel, getValue }: FormSelectParams<T>) =>
    useMemo(
      () =>
        formatSelectValues({
          options,
          getLabel,
          getValue
        }),
      [options]
    )

  const valuesToListenToForChanges = {
    submitting: true
  }

  const csvSchemaFieldOptions = {
    options: getMemoizedOptions({
      options: dataFields,
      getLabel: ({ name }) => name,
      getValue: ({ id }) => id
    })
  }

  const pruneFormData = (values: Values): Partial<AdminGoal> => {
    const {
      title,
      description,
      points,
      frequency,
      store_group,
      store,
      configuration,
      roles,
      dependencies = [], // Please see https://tinyurl.com/3tat6wzv for reference
      location,
      schedule,
      max_completions,
      effective_at,
      expires_at,
      point_group,
      data_field,
      late_threshold,
      early_threshold,
      work_a_shift_threshold,
      column_field,
      column_value,
      icon,
      result_text,
      testing
    } = values

    const filterFields = filter(
      keys(values),
      (key) => key.includes(REGEX_FIELD) || key.includes(COMPARE_FIELD)
    )
    const defaultFilter: Filter = {
      compare_field: '',
      regex: ''
    }
    const filterObj = transform(
      filterFields,
      (result: Record<string, Filter>, val: string) => {
        const keywordKey = split(val, 'keywords_')[1]
        const columnNameKey = split(val, 'column_name_')[1]

        const resKey: string = keywordKey ?? columnNameKey
        const currentFilter = {
          ...defaultFilter,
          ...result[resKey]
        }

        const fieldValue = values[val as keyof Values] as string
        result[resKey] = {
          compare_field: columnNameKey ? fieldValue : currentFilter.compare_field,
          regex: keywordKey ? fieldValue : currentFilter.regex
        }
      },
      {}
    )

    const filters: Filter[] = map(filterObj)
    const config = {
      goal: configuration === WORK_A_SHIFT ? ARRIVE_ON_TIME : configuration,
      data_field,
      compare_value: Number(column_value),
      compare_field: column_field,
      start_early: Number(early_threshold),
      start_late: Number(late_threshold),
      ignore_shift_start: work_a_shift_threshold,
      filters
    }

    return {
      title,
      description,
      icon,
      points,
      max_completions,
      effective_at: formatAPIDate(effective_at as string),
      expires_at: formatAPIDate(expires_at as string),
      group_id: store_group,
      store_id: store,
      point_group,
      frequency,
      location,
      schedule,
      dependencies,
      eligibility: { roles: roles ?? [] },
      configuration: config,
      result_text,
      testing
    }
  }

  const onSubmit = async (values: Values) => {
    const pruned = pruneFormData(values)
    const payload = { data: pruned }
    if (!isGoalCreationForm() && goalIdFromParams) {
      dispatch(updateGoalThunk({ id: goalIdFromParams, ...payload }))
    } else {
      dispatch(createGoalThunk(payload))
    }
    navigation.push(AdminPaths.manageAllGoals)
  }

  const cloneOrAddAndCloneGoal = (values: Values) => {
    if (isGoalCreationForm()) {
      const pruned = pruneFormData(values)
      const payload = { data: pruned }
      dispatch(createGoalThunk(payload))
    }
    const cloneValues = {
      ...values,
      title: `Clone of ${values.title}`
    }
    navigation.push({
      pathname: `${AdminPaths.manageAllGoals}/create`,
      state: { values: cloneValues }
    })

    const message = isGoalCreationForm()
      ? 'Goal has been saved and cloned!'
      : 'Goal has been cloned!'
    dispatch(
      setSnackbar({
        open: true,
        type: 'info',
        message
      })
    )
    window.scrollTo(0, 0)
  }

  const renderDateFields = () => {
    const fields: { name: string; plus?: DurationInput }[] = [
      { name: 'effective_at' },
      { name: 'expires_at' }
    ]
    return (
      <>
        {fields.map(({ name }) => (
          <Field
            key={name}
            name={name}
            required
            shrinkLabel
            component={(props) => (
              <FormDateTimeField
                // eslint-disable-next-line react/prop-types
                date={props.input.value as string}
                // eslint-disable-next-line react/prop-types
                onChange={props.input.onChange}
              />
            )}
          />
        ))}
      </>
    )
  }

  const renderFilterFields = () => {
    return [...Array(filtersCount)].map((_, i) => (
      <>
        <Field<string>
          isSubField
          name={`${COMPARE_FIELD}${i + 1}`}
          component={(props) => <FormSelectField {...props} {...csvSchemaFieldOptions} />}
        />
        <Field<string>
          isSubField
          name={`${REGEX_FIELD}${i + 1}`}
          component={FormTextField}
        />
      </>
    ))
  }

  const renderCheckinSubfields = () => (
    <>
      <Field<number>
        required
        isSubField
        name="late_threshold"
        type="number"
        component={FormTextField}
      />
      <Field<number>
        required
        isSubField
        name="early_threshold"
        type="number"
        component={FormTextField}
      />
    </>
  )
  const renderWorkAShiftSubfields = () => (
    <>
      <Field<number>
        required
        isSubField
        name="work_a_shift_threshold"
        component={FormTextField}
        defaultValue={1}
      />
    </>
  )

  const renderCSVSubfields = () => (
    <>
      <Field<string>
        name="select_csv"
        required
        parse={(value, _) => {
          setCsvDataFields(value, storeCSVSchemas)
          return value
        }}
        subscription={{
          touched: true,
          error: true,
          value: true
        }}
        render={(props) => (
          <FormSelectField
            {...props}
            options={getMemoizedOptions({
              options: csvSchemaOptions,
              getLabel: ({ id, name }) => name || (id as string),
              getValue: ({ id }) => id
            })}
          />
        )}
      />
      <Field<string>
        name="data_field"
        required
        isSubField
        subscription={{
          touched: true,
          error: true,
          value: true
        }}
        parse={(value, _) => {
          setCsvColumnFields(value, dataFields)
          return value
        }}
        component={(props) => <FormSelectField {...props} {...csvSchemaFieldOptions} />}
      />
      <Field<string>
        name="threshold_type"
        required
        isSubField
        subscription={{
          touched: true,
          error: true,
          value: true
        }}
        render={(props) => {
          return (
            <>
              <FormSelectField
                {...props}
                options={getMemoizedOptions({
                  options: thresholdOptions,
                  getLabel: ({ name }) => name,
                  getValue: ({ id }) => id
                })}
              />
              {/* eslint-disable-next-line react/prop-types */}
              {renderSubfields(props.input.value)}
            </>
          )
        }}
      />
      <>
        {filtersCount > 0 && renderFilterFields()}
        <FilterButton
          color="primary"
          size="large"
          onClick={() => setFiltersCount(filtersCount + 1)}
        >
          Add Filters
        </FilterButton>
      </>
    </>
  )

  const renderColumnField = () => (
    <Field<string[]>
      name="column_field"
      required
      isSubField
      subscription={{
        touched: true,
        error: true,
        value: true
      }}
      render={(props) => <FormSelectField {...props} options={columnFields} />}
    />
  )

  const renderColumnValue = () => (
    <Field<number>
      required
      isSubField
      name="column_value"
      type="number"
      subscription={{
        touched: true,
        error: true,
        value: true
      }}
      component={FormTextField}
    />
  )

  const renderManualEntryFields = () => (
    <>
      <Field<string>
        name="data_field"
        required
        isSubField
        subscription={{
          error: true,
          value: true
        }}
        render={(props) => (
          <FormSelectField
            {...props}
            options={getMemoizedOptions({
              options: flourFields,
              getLabel: ({ name }) => name,
              getValue: ({ id }) => id
            })}
          />
        )}
      />
      <Field<number>
        required
        isSubField
        type="number"
        name="column_value"
        subscription={{
          touched: true,
          error: true,
          value: true
        }}
        component={FormTextField}
      />
    </>
  )

  const renderSubfields = (value: string | string[]) => {
    switch (value) {
      case ARRIVE_ON_TIME:
        return renderCheckinSubfields()
      case WORK_A_SHIFT:
        return renderWorkAShiftSubfields()
      case CSV:
        return renderCSVSubfields()
      case COLUMN_FIELD:
        return renderColumnField()
      case COLUMN_VALUE:
        return renderColumnValue()
      case MANUAL_ENTRY:
        return renderManualEntryFields()
      case API:
        return null
      default:
        return null
    }
  }

  const renderGoalIconValue = (value: unknown) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <GoalIcon type={GoalIconTypes[value as keyof typeof GoalIconTypes]} size="24" />
      <span style={{ marginLeft: 5 }}>{startCase(value as string)}</span>
    </div>
  )

  const filterSchemas = (queryParams: AdminListFilters) => {
    dispatch(fetchAllCSVSchemasThunk({ isInitial: true, queryParams }))
  }

  const handleVersionChange = (event: SelectChangeEvent<string>) => {
    const { value: pk } = event.target
    dispatch(fetchGoalThunk({ pk }))
    const base = AdminPaths.manageAllGoals
    const path = `${base}/${pk}`
    navigation.replace(path)
  }

  const allVersions = useSelector((state: RootState) =>
    selectGoalVersionsForForm(state, goalIdFromParams as EntityId)
  )

  const versionField = (
    <FormControl fullWidth>
      <InputLabel>Version</InputLabel>
      <Select
        label="Version"
        value={selectedGoal?.id}
        onChange={handleVersionChange}
        disabled={allVersions.length < 2}
      >
        {allVersions.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const form = (
    <>
      {versionField}
      <Form
        subscription={valuesToListenToForChanges}
        onSubmit={onSubmit}
        initialValues={getInitialValues}
        render={({ handleSubmit, submitting, form: { getState } }) => (
          <StyledForm onSubmit={handleSubmit}>
            <AutoCompleteField
              name="store_group"
              required={!(store.id || group.id)}
              handleFetchOptions={fetchStoreGroupsThunk}
              selectFetchedResults={storeGroupsSelectors.selectAll}
              selectResultsLoading={selectStoreGroupsLoading}
              onChange={(_event, value) => {
                const selectedGroup = value as StoreGroup
                setGroup(selectedGroup)
                if (selectedGroup?.id) {
                  const { id } = selectedGroup
                  filterSchemas({ group_id: id })
                  getStoreFlourSchema(id)
                }
              }}
            />
            <AutoCompleteField
              name="store"
              required={!(store.id || group.id)}
              handleFetchOptions={fetchAllStoresThunk}
              selectFetchedResults={adminStoresSelectors.selectAll}
              selectResultsLoading={selectAdminStoresLoading}
              filterConstraints={{ group_id: group?.id }}
              onChange={(_event, value) => {
                const selectedStore = value as AdminStore
                setStore(selectedStore)
                if (selectedStore?.id) {
                  const { id } = selectedStore
                  filterSchemas({ store_id: id })
                  getStoreFlourSchema(id)
                }
              }}
            />
            <Field<string> required name="title" component={FormTextField} />
            <Field<string> name="description" component={FormTextField} />
            <Field<string>
              name="icon"
              component={(props) => (
                <FormSelectField
                  {...props}
                  renderValue={renderGoalIconValue}
                  options={getMemoizedOptions({
                    options: allIcons,
                    getLabel: ({ name }) => startCase(name),
                    getValue: ({ name }) => name
                  })}
                />
              )}
            />
            <Field<number>
              required
              defaultValue={0}
              name="points"
              type="number"
              component={FormTextField}
            />
            <Field<number>
              name="max_completions"
              type="number"
              component={FormTextField}
            />
            {renderDateFields()}
            <Field<string>
              name="configuration"
              required
              component={(props) => (
                <>
                  <FormSelectField
                    {...props}
                    options={getMemoizedOptions({
                      options: configOptions,
                      getLabel: ({ name }) => name,
                      getValue: ({ id }) => id
                    })}
                  />
                  {/* eslint-disable-next-line react/prop-types */}
                  {renderSubfields(props.input.value)}
                </>
              )}
            />
            <Field<string[]>
              name="data_source"
              required
              subscription={{
                touched: true,
                error: true,
                value: true
              }}
              component={(props) => (
                <>
                  <FormSelectField
                    {...props}
                    options={getMemoizedOptions({
                      options: dataSourceOptions,
                      getLabel: ({ name }) => name,
                      getValue: ({ id }) => id
                    })}
                  />
                  {/* eslint-disable-next-line react/prop-types */}
                  {renderSubfields(props.input.value)}
                </>
              )}
            />
            <Field<string> name="result_text" component={FormTextField} />
            <Field<string[]>
              name="roles"
              component={(props) => {
                return (
                  <FormSelectField
                    {...props}
                    multiple
                    options={getMemoizedOptions({
                      options: teamRoles,
                      getLabel: ({ name }) => name,
                      getValue: ({ id }) => id
                    })}
                  />
                )
              }}
            />
            <Field<string>
              name="point_group"
              required
              defaultValue={GoalPointGroupOption.individual}
              component={(props) => (
                <FormSelectField
                  {...props}
                  options={getMemoizedOptions({
                    options: allGoalPointGroups,
                    getLabel: ({ label }) => label,
                    getValue: ({ id }) => id
                  })}
                />
              )}
            />
            <Field<string>
              name="frequency"
              required
              defaultValue={GoalFrequencyOption.daily}
              component={(props) => (
                <FormSelectField
                  {...props}
                  options={getMemoizedOptions({
                    options: allGoalFrequencies,
                    getLabel: ({ label }) => label,
                    getValue: ({ id }) => id
                  })}
                />
              )}
            />
            <Field<string>
              name="location"
              component={(props) => (
                <FormSelectField
                  {...props}
                  options={getMemoizedOptions({
                    options: allLocations,
                    getLabel: ({ address_line_1, city, state, zipcode }) =>
                      `${address_line_1} ${city}, ${state} ${zipcode}`,
                    getValue: ({ id }) => id
                  })}
                />
              )}
            />
            <Field<string>
              name="schedule"
              component={(props) => (
                <FormSelectField
                  {...props}
                  options={getMemoizedOptions({
                    options: allSchedules,
                    getLabel: ({ name }) => name,
                    getValue: ({ id }) => id
                  })}
                />
              )}
            />
            <Field<string[]>
              name="dependencies"
              component={(props) => (
                <FormSelectField
                  {...props}
                  multiple
                  options={getMemoizedOptions({
                    options: dependencies,
                    getLabel: ({ title }) => title,
                    getValue: ({ id }) => id
                  })}
                />
              )}
            />
            <Field<boolean>
              name="testing"
              type="checkbox"
              component={FormCheckboxField}
              defaultValue={true}
            />
            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
              fullWidth
            >
              {actionType}
            </SubmitButton>
            <SubmitButton
              variant="contained"
              color="secondary"
              onClick={() => cloneOrAddAndCloneGoal(getState().values)}
              fullWidth
            >
              {cloneType}
            </SubmitButton>
          </StyledForm>
        )}
      />
    </>
  )

  return (
    <>
      {isSelectedGoalLoading ? (
        <HeartLoadingIndicator fullPage />
      ) : (
        <UpsertFormContainer
          actionType={actionType}
          entityType={entityType}
          form={form}
          containerProps={{ showBackButton: true }}
        />
      )}
    </>
  )
}
