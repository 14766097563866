import { styled } from '@mui/material'

import RowItem from 'components/rowItem'

export const StyledCollapsibleRowItem = styled(RowItem)({
  '&.RowItem': {
    '&:hover': {
      transform: 'none'
    },
    '& .RowItem__extraInfo': {
      width: 'auto',
      height: 'auto'
    }
  }
})

export const StyledCollapsibleRowItemDivider = styled('hr')(({ theme }) => ({
  color: '#eeeeee8c',
  borderStyle: 'solid',
  margin: theme.spacing(3, 0)
}))
