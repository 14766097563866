import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { RewardInfo } from 'types'

import { perksByRedeemable } from 'slices/perks'

import { getSelectedCategoriesFromUrl } from '../utils/url'

export default function useFilteredPerks() {
  const rewards = useSelector(perksByRedeemable)
  const location = useLocation()
  const [filteredPerks, setFilteredPerks] = useState<RewardInfo[]>(rewards)

  useEffect(() => {
    // Only filter if we actually have data to filter on
    if (rewards?.length) {
      const selectedCategories = getSelectedCategoriesFromUrl(location.search)
      // If we do not have any filters active - default to all
      if (!selectedCategories?.length) {
        setFilteredPerks(rewards)
      } else {
        const filtered = rewards.filter((reward) => {
          // normalize to match (we don't know what format the BE will send)
          const rewardCategories = reward.categories.map((category) =>
            category.toLowerCase()
          )
          return selectedCategories.some((category) =>
            rewardCategories.includes(category as string)
          )
        })
        setFilteredPerks(filtered)
      }
    }
  }, [rewards, location.search])

  return filteredPerks
}
