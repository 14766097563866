import clsx from 'clsx'

import { Box, Tab } from '@mui/material'

import { StyledTabHeader, StyledTabHeaderDivider } from './TabHeader.styles'

import { TabsClasses } from '../TabsClasses'

interface TabHeaderProps extends React.ComponentProps<typeof Tab> {
  index: number
  activeIndex: number
}

export default function TabHeader({
  activeIndex = 0,
  index = 0,
  label = `Tab ${index}`,
  className,
  ...otherProps
}: TabHeaderProps) {
  const showDivider =
    activeIndex !== index && (index >= activeIndex + 1 || index < activeIndex - 1)

  return (
    <>
      <StyledTabHeader
        className={clsx(TabsClasses.HEADER, className)}
        label={label}
        id={`tab-${index}`}
        aria-controls={`tabpanel-${index}`}
        value={index}
        {...otherProps}
      />
      {showDivider && (
        <Box
          sx={{ position: 'relative' }}
          data-testid="tabs-header-divider"
          className={TabsClasses.HEADER_DIVIDER}
        >
          <StyledTabHeaderDivider />
        </Box>
      )}
    </>
  )
}
