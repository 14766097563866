import { Colors } from 'theme'

import { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

export default function PrizeItemLoader(
  props: ComponentProps<typeof ContentLoader> = {}
) {
  return (
    <ContentLoader
      width={300}
      height={105}
      style={{ width: '100%', ...(props?.style || {}) }}
      viewBox="0 0 300 105"
      backgroundColor={Colors.inactive}
      foregroundColor={Colors.blue}
      {...props}
    >
      {/* icon */}
      <rect x="205" y="12" rx="10" ry="10" width="80" height="80" />
      {/* text */}
      <rect x="15" y="27" rx="3" ry="3" width="110" height="10" />
      <rect x="15" y="47" rx="3" ry="3" width="160" height="10" />
      <rect x="15" y="67" rx="3" ry="3" width="130" height="10" />
    </ContentLoader>
  )
}
