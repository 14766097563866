export enum AppIconTypes {
  addPerk = 'addPerk',
  arrow = 'arrow',
  avatar = 'avatar',
  caretDown = 'caretDown',
  caretLeft = 'caretLeft',
  caretRight = 'caretRight',
  caretUp = 'caretUp',
  check = 'check',
  circleAlert = 'circleAlert',
  circleDashed = 'circleDashed',
  circleInfo = 'circleInfo',
  circleX = 'circleX',
  close = 'close',
  copy = 'copy',
  email = 'email',
  emailVerification = 'emailVerification',
  filter = 'filter',
  goals = 'goals',
  help = 'help',
  home = 'home',
  indentArrow = 'indentArrow',
  jackpot = 'jackpot',
  jackpotTicket = 'jackpotTicket',
  lock = 'lock',
  monitorRedeemCheck = 'monitorRedeemCheck',
  monitorRedeem = 'monitorRedeem',
  navArrowLeft = 'navArrowLeft',
  navArrowRight = 'navArrowRight',
  news = 'news',
  onaroll = 'onaroll',
  onarollStar = 'onarollStar',
  onboardingCheckmark = 'onboardingCheckmark',
  openedLock = 'openedLock',
  perks = 'perks',
  phoneRedeemCheck = 'phoneRedeemCheck',
  phoneRedeem = 'phoneRedeem',
  search = 'search',
  send = 'send',
  store = 'store',
  storeRedeemCheck = 'storeRedeemCheck',
  storeRedeem = 'storeRedeem',
  verified = 'verified'
}
