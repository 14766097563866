import { PaginatedLocations, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { LOCATIONS } from 'slices/constants'

import { authenticatedThunkCreator } from 'slices/utils'

export const fetchAllLocationsThunk = authenticatedThunkCreator<
  PaginatedLocations,
  FetchCachedThunkProps
>(
  `${LOCATIONS}/fetchAllLocationsThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, LOCATIONS)
)
