import { isEmpty } from 'lodash'
import { Colors } from 'theme'

import { Button, Typography } from '@mui/material'

import { CSSProperties, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SMSSendResponse, SMSTypes, TeamMemberV2 } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import useGlobalModal from 'hooks/useGlobalModal'

import { fetchTeamMemberThunk } from 'slices/admin'
import { sendSMSThunk } from 'slices/sms'
import { setSnackbar } from 'slices/snackbar'
import { getWhoami } from 'slices/whoami'

import { formatPhoneNumberWithSpace } from 'utils/helpers'

import { ItemSubHeading } from '../shared/styles'
import { StyledAvatarIcon, StyledTeamMemberItem } from './styles'

const TRANSLATION_BASE = 'pages.admin.team.teamMemberItem'
const TRANSLATION_MODAL_BASE = 'pages.admin.team.modal'

export interface TeamMemberProps {
  teamMember: TeamMemberV2
  onSelectItem: (key: TeamMemberV2) => void
  style: CSSProperties
}

export default function TeamMemberItem({
  teamMember,
  onSelectItem,
  style
}: TeamMemberProps) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { openGlobalModal } = useGlobalModal()
  const whoami = useSelector(getWhoami)

  const { id, full_name, onboarded_at, phone } = teamMember
  const onboarding_seen = onboarded_at != null && !isEmpty(onboarded_at)

  const getSubtitle = () => {
    if (!phone)
      return (
        <span style={{ color: Colors.error }}>{t(`${TRANSLATION_BASE}.noPhone`)}</span>
      )
    return formatPhoneNumberWithSpace(phone)
  }

  const content = (
    <>
      <Typography variant="h4">{full_name}</Typography>
      <ItemSubHeading variant="subtitle2">{getSubtitle()}</ItemSubHeading>
    </>
  )

  const sendTextMessage = useCallback(async () => {
    try {
      const res = (await dispatch(
        sendSMSThunk({
          data: {
            message_body: t(`${TRANSLATION_MODAL_BASE}.textMessage`, {
              tmName: full_name,
              tlName: whoami?.display_name
            }),
            user_profile_ids: [id],
            message_type: SMSTypes.invite
          }
        })
      ).unwrap()) as SMSSendResponse

      // Note: We are only handling ONE TM at a time here
      // so if failure exists, we know that the TM was NOT sent a text message
      if (res['Failure']?.length) {
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: t(`${TRANSLATION_MODAL_BASE}.textMessageFail`)
          })
        )
      } else if (res['Success']?.length) {
        dispatch(
          setSnackbar({
            open: true,
            type: 'success',
            message: t(`${TRANSLATION_MODAL_BASE}.textMessageSuccess`)
          })
        )
        // Note: There will only be ONE TM at any given time in this component
        // So the below is the same as res['Success']?.[0]
        res['Success'].forEach((pk) => dispatch(fetchTeamMemberThunk({ pk })))
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          open: true,
          type: 'error',
          message: t(`${TRANSLATION_MODAL_BASE}.textMessageFail`)
        })
      )
    }
  }, [teamMember, whoami])

  const onTeamMemberSelected = () => onSelectItem(teamMember)

  const onInviteClicked = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      openGlobalModal({
        title: t(`${TRANSLATION_MODAL_BASE}.title`),
        body: t(`${TRANSLATION_MODAL_BASE}.body`, { name: teamMember.full_name }),
        ctaProps: {
          children: t(`${TRANSLATION_MODAL_BASE}.actionText`)!,
          onClick: sendTextMessage
        },
        closeButtonText: t(`${TRANSLATION_MODAL_BASE}.closeButtonText`),
        showCloseButton: true
      })
    },
    [teamMember]
  )

  const renderExtraInfo = () => {
    if (!teamMember.phone) {
      return undefined
    }
    if (teamMember.onboarded_at) {
      return (
        <Typography variant="body1">
          {t(`${TRANSLATION_BASE}.activeSince`, {
            date: new Date(teamMember.onboarded_at).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
          })}
        </Typography>
      )
    }
    if (teamMember.invite_sent_at) {
      return (
        <Typography variant="body1">
          {t(`${TRANSLATION_BASE}.inviteSentOn`, {
            date: new Date(teamMember.invite_sent_at).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
          })}
        </Typography>
      )
    }
    return (
      <Button variant="contained" onClick={onInviteClicked}>
        {t(`${TRANSLATION_BASE}.invite`)}
      </Button>
    )
  }

  return (
    <StyledTeamMemberItem
      item={teamMember}
      image={<StyledAvatarIcon active={onboarding_seen} />}
      content={content}
      onClick={onTeamMemberSelected}
      style={style}
      extraInfo={renderExtraInfo()}
    />
  )
}
