// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Colors } from 'theme'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import perksIcon from '../../assets/images/perks-unlocked.svg'
import { ReactComponent as AddPerkIcon } from 'assets/icons/AppIcons/AddPerkIcon.svg'
import { ReactComponent as LockIcon } from 'assets/icons/AppIcons/LockIcon.svg'

import { RewardInfo } from 'types'

import { useNavigation } from 'hooks/useNavigation'

import {
  amountToPoints,
  ChildPaths,
  onA11yKeyDown,
  totalPointsWithDiscount
} from 'utils/helpers'

import {
  PerkItemTitleContainer,
  PerkLogoContainer,
  StyledLargePerkItem,
  StyledLockIcon,
  StyledPerkItemDiscountText,
  StyledPerkItemHeader,
  StyledPerkItemLogo,
  StyledPerkItemTagline,
  StyledPerkItemTitle,
  StyledSlimPerk,
  StyledSuggestPerkItem,
  SuggestPerkIconWrapper
} from './styles'

export interface PerkItemProps {
  isOnSmallDevice: boolean
  perk: RewardInfo
  pointsBalance: number
}

interface SuggestPerkItemProps {
  isOnSmallDevice: boolean
}

export function SuggestPerkItem({ isOnSmallDevice }: SuggestPerkItemProps) {
  const navigation = useNavigation()
  const { t } = useTranslation()

  const navigateToSuggestPerk = () => {
    navigation.push(ChildPaths.suggestPerk)
  }

  return (
    <StyledSuggestPerkItem
      elevation={0}
      component="li"
      role="button"
      tabIndex={0}
      onKeyDown={(event) => onA11yKeyDown(event, navigateToSuggestPerk)}
      onClick={navigateToSuggestPerk}
      isOnSmallDevice={isOnSmallDevice}
    >
      <SuggestPerkIconWrapper isOnSmallDevice={isOnSmallDevice}>
        <AddPerkIcon />
      </SuggestPerkIconWrapper>
      <StyledPerkItemTitle
        component="p"
        variant="body2"
        isOnSmallDevice={isOnSmallDevice}
      >
        {t('pages.perks.perkItem.suggest')}
      </StyledPerkItemTitle>
    </StyledSuggestPerkItem>
  )
}

const PerkItemHeader = ({
  discount,
  canRedeem,
  isSlim = false
}: {
  discount?: number
  canRedeem: boolean
  isSlim?: boolean
}) => {
  const { t } = useTranslation()
  const discountText = useMemo(() => {
    if (!discount) return ''
    return discount < 100
      ? t('pages.perk.card.percentOff', { percentage: discount })
      : t('pages.perks.perkItem.free')
  }, [discount])

  const lockIcon = canRedeem ? (
    ''
  ) : (
    <StyledLockIcon>
      <LockIcon fill={Colors.gray} width={12} height={16} data-testid="lock-icon" />
    </StyledLockIcon>
  )

  return (
    <StyledPerkItemHeader isSlim={isSlim}>
      <StyledPerkItemDiscountText variant="body2">
        {discountText}
      </StyledPerkItemDiscountText>
      {lockIcon}
    </StyledPerkItemHeader>
  )
}

export default function PerkItem({
  isOnSmallDevice,
  perk,
  pointsBalance
}: PerkItemProps) {
  const navigation = useNavigation()
  const match = useRouteMatch()

  const navigateToSelectedPerk = (id: string) => {
    const getChildPath = (id: string) => {
      let url = match.url
      if (!url.endsWith('/')) {
        url = url.concat('/')
      }
      return `${url}${id}`
    }
    navigation.push(getChildPath(id))
  }

  const { id, title, image, discount, min_value, points_per_unit_cost } = perk
  const percentDiscount = discount?.percent_discount
  const minPerkPointValue =
    min_value || min_value == 0
      ? amountToPoints(min_value, points_per_unit_cost)
      : points_per_unit_cost
  const minPerkPointCost = percentDiscount
    ? totalPointsWithDiscount(minPerkPointValue, percentDiscount)
    : minPerkPointValue
  const canRedeem = minPerkPointCost <= pointsBalance

  const itemLogo = (
    <StyledPerkItemLogo src={image || perksIcon} alt={`${title} perk icon`} />
  )

  const content = (
    <>
      <StyledPerkItemTitle
        component="p"
        variant="body2"
        isOnSmallDevice={isOnSmallDevice}
      >
        {title}
      </StyledPerkItemTitle>
      <StyledPerkItemTagline noWrap variant="body2" isOnSmallDevice={isOnSmallDevice} />
    </>
  )

  const perkItemHeader =
    percentDiscount || !canRedeem ? (
      <PerkItemHeader
        discount={percentDiscount}
        canRedeem={canRedeem}
        isSlim={isOnSmallDevice}
      />
    ) : undefined

  const largePerk = (
    <StyledLargePerkItem
      elevation={0}
      component="li"
      role="button"
      tabIndex={0}
      onKeyDown={(event) => onA11yKeyDown(event, () => navigateToSelectedPerk(id))}
      onClick={() => navigateToSelectedPerk(id)}
      isOnSmallDevice={isOnSmallDevice}
      canRedeem={canRedeem}
    >
      {perkItemHeader}
      <PerkLogoContainer>{itemLogo}</PerkLogoContainer>
      <PerkItemTitleContainer>{content}</PerkItemTitleContainer>
    </StyledLargePerkItem>
  )

  const slimPerk = (
    <StyledSlimPerk
      item={perk}
      header={perkItemHeader}
      image={itemLogo}
      content={content}
      onClick={(perk) => navigateToSelectedPerk(perk.id)}
      locked={!canRedeem}
    />
  )

  return isOnSmallDevice ? slimPerk : largePerk
}
