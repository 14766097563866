// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { JackpotTicketTransaction } from 'types'

import { requestState, TICKETS } from 'slices/constants'

import { handleRequestRejected, handleRequestFulfilled } from 'slices/utils'

import {
  fetchJackpotCurrentTicketInfoThunk,
  fetchJackpotTicketHistoryThunk
} from './actions'

const initialTicketsState = {
  total: 0,
  jackpotHistoryLoaded: false,
  ...requestState
}

export const ticketsAdapter = createEntityAdapter<JackpotTicketTransaction>({
  selectId: (ticket) => ticket.id
})

export const ticketsSlice = createSlice({
  name: TICKETS,
  initialState: ticketsAdapter.getInitialState(initialTicketsState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJackpotCurrentTicketInfoThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchJackpotCurrentTicketInfoThunk.rejected, (state, action) => {
        handleRequestRejected(state, action)
      })
      .addCase(fetchJackpotCurrentTicketInfoThunk.fulfilled, (state, action) => {
        const { total_amount } = action.payload
        state.total = total_amount
        handleRequestFulfilled(state)
      })
      .addCase(fetchJackpotTicketHistoryThunk.fulfilled, (state, action) => {
        const tickets: JackpotTicketTransaction[] = action.payload
        ticketsAdapter.setMany(state, tickets)
        state.jackpotHistoryLoaded = true
      })
  }
})
export default ticketsSlice.reducer
