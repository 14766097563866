import { PointTotal, FetchCachedThunkProps } from 'types'

import { fetchRecords } from 'api'

import { POINTS } from 'slices/constants'

import { getImpersonator } from 'slices/impersonation'
import { authenticatedThunkCreator, shouldRefetch } from 'slices/utils'

import { getPointsLoading, getPointTotals } from './selectors'

import { RootState } from 'reduxStore'

export const fetchPointTotalsThunk = authenticatedThunkCreator<
  PointTotal,
  FetchCachedThunkProps
>(
  `${POINTS}/fetchPointTotalsThunk`,
  async (authedDTO) => await fetchRecords(authedDTO, 'pointTotals'),
  {
    condition: (options, { getState }) => {
      const state = getState() as RootState
      const isUpdating = getPointsLoading(state)
      const impersonator = getImpersonator(state)
      const prevPoints = getPointTotals(state)
      return shouldRefetch(isUpdating, prevPoints, impersonator, options)
    }
  }
)
