import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber
} from 'firebase/auth'
import { enrichToken } from 'api'
import { useRef, useState } from 'react'
import { UseRedirectOptions } from 'hooks/useRedirect'
import useAppDispatch from 'hooks/useAppDispatch'
import { useLocation } from 'react-router-dom'
import { LoginStateParams } from 'types'
import { firebaseTokensThunk } from 'slices/auth'
import { setDDUserSessionId } from 'utils/session'
import { RootPaths } from 'utils/helpers'
import { useNavigation } from 'hooks/useNavigation'

export const useFirebase = () => {
  const [submittingFirebase, setSubmittingFirebase] = useState(false)
  const [firebaseLoginError, setFirebaseLoginError] = useState<string | null>()
  const [reCaptchaToken, setReCaptchaToken] = useState(null)
  const recaptchaVerifierRef = useRef(null)

  const location = useLocation<Partial<LoginStateParams>>()
  const verificationId = location?.state?.verificationId
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const { search } = location
  const auth = getAuth()

  const authenticateFirebaseUser = async (redirectToHome: ({
                                                             returnAsComponent,
                                                             ...restProps
                                                           }?: UseRedirectOptions) => void, userProfileId?: string) => {
    const idToken = localStorage.getItem('fbIdToken')
    const refreshToken = localStorage.getItem('fbRefreshToken')
    await dispatch(firebaseTokensThunk({ 'idToken': idToken, 'refreshToken': refreshToken }))
    setDDUserSessionId(true)
    redirectToHome({ returnAsComponent: false, search: userProfileId ? `?user_profile_id=${userProfileId}` : null })
  }

  const initializeReCaptchaVerifier = async () => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          callback: (response: never) => {
            setReCaptchaToken(response)
          },
          'expired-callback': () => {
            setReCaptchaToken(null)
            setFirebaseLoginError('pages.login.form.errors.expired')
          },
          'error-callback': () => {
            setReCaptchaToken(null)
            setFirebaseLoginError('pages.login.form.errors.expired')
          }
        }
      )
    }
    await recaptchaVerifierRef.current.render().then((widgetId: RecaptchaVerifier) => {
      window.recaptchaVerifier = widgetId
    })
    // Cleanup on unmount
    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear()
      }
    }
  }

  const requestFirebaseOTP = async (phoneNumber: string) => {
    return await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifierRef.current)
  }

  const handleFirebaseSubmit = async (otpValue: string, resetOTPValue: () => void) => {
    // Do not allow any submits if we are in the middle of submitting the form or we do not have data to submit
    if (submittingFirebase) {
      return
    }
    if (!otpValue) {
      setFirebaseLoginError('pages.login.form.errors.incorrectCode')
      return
    }
    setSubmittingFirebase(true)

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otpValue)
      const initialUserCredential = await signInWithCredential(auth, credential)
      const originalIdToken = await initialUserCredential.user.getIdToken()
      await enrichToken({ idToken: originalIdToken })
      const idToken = await initialUserCredential.user.getIdToken(true)

      // note that instead of using a refresh token we ideally use the getIdToken call to auto refresh as needed
      const refreshToken = initialUserCredential.user.refreshToken

      if (idToken?.length && refreshToken?.length) {
        localStorage.setItem('fbIdToken', idToken)
        localStorage.setItem('fbRefreshToken', refreshToken)
        window.location.pathname = '/a/auth'
      }
      setFirebaseLoginError('pages.login.form.errors.unauthorized')
    } catch (error) {
      setFirebaseLoginError('pages.login.form.errors.incorrectCode')
    } finally {
      setSubmittingFirebase(false)
      resetOTPValue()
    }
  }
  const submitLoginForm = async (phoneNumber: string, email: string, setLoginError: (arg0: string) => void, loginType: 'phone' | 'email', loginValue: string) => {
    const enteredValue = loginType ?? 'phone'

    if (!recaptchaVerifierRef.current) {
      setLoginError(`pages.login.form.errors.missingRecaptcha`)
      return
    }

    const res = await requestFirebaseOTP(`+1${phoneNumber}`)

    if (!res.verificationId) {
      setLoginError(`pages.login.form.errors.${enteredValue}NotFoundError`)
    } else {
      const state: LoginStateParams = {
        loginValue,
        loginType,
        verificationId: res.verificationId
      }
      navigation.replace({
        pathname: RootPaths.verifyOTP,
        state,
        search
      })
    }
  }


  return {
    handleFirebaseSubmit,
    submittingFirebase,
    firebaseLoginError,
    authenticateFirebaseUser,
    initializeReCaptchaVerifier,
    reCaptchaToken,
    recaptchaVerifierRef,
    submitLoginForm
  }
}
