import { styled, Typography } from '@mui/material'

export const StyledLoginHelpTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: theme.fontSize(2),
  margin: theme.spacing(2)
}))

export const StyledLoginHelpLink = styled('a')(({ theme }) => ({
  color: theme.palette.colors.yellow,
  fontWeight: 'bold',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.colors.purple
  }
}))
