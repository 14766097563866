export const Colors = {
  purple: '#735CDD',
  yellow: '#FFD30A',
  black: '#353142',
  lightBlack: '#4B455E',
  offWhite: '#F0F4FD',
  white: '#fff',
  gray: '#949FB7',
  blue: '#C6D0E8',
  inactive: '#E5EAF8',
  orange: '#FFAB40',
  error: '#d32f2f',
  success: '#00D78A',
  successLight: '#C2F5DE',
  disabled: 'rgba(0, 0, 0, 0.2)',
  grayDisabled: '#C4C4C4',
  dmRed: '#FF8484', // Note: `dm` stands for dark mode. This is compliant with our black theme
  placeholder: '#A5A5A5',
  gray600: '#3D414B',
  gray200: '#F1F1F1',
  gray500: '#777777',
  gray400: '#929292'
}

export type OnarollColor = (typeof Colors)[keyof typeof Colors]

export default Colors
