import { Colors } from 'theme'

import { SVGProps } from 'react'

import { ReactComponent as AddPerkIcon } from 'assets/icons/AppIcons/AddPerkIcon.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/AppIcons/ArrowIcon.svg'
import { ReactComponent as AvatarIcon } from 'assets/icons/AppIcons/AvatarIcon.svg'
import { ReactComponent as CaretDownIcon } from 'assets/icons/AppIcons/CaretDownIcon.svg'
import { ReactComponent as CaretLeftIcon } from 'assets/icons/AppIcons/CaretLeftIcon.svg'
import { ReactComponent as CaretRightIcon } from 'assets/icons/AppIcons/CaretRightIcon.svg'
import { ReactComponent as CaretUpIcon } from 'assets/icons/AppIcons/CaretUpIcon.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/AppIcons/CheckIcon.svg'
import { ReactComponent as CircleAlertIcon } from 'assets/icons/AppIcons/CircleAlertIcon.svg'
import { ReactComponent as CircleDashedIcon } from 'assets/icons/AppIcons/CircleDashedIcon.svg'
import { ReactComponent as CircleInfoIcon } from 'assets/icons/AppIcons/CircleInfoIcon.svg'
import { ReactComponent as CircleXIcon } from 'assets/icons/AppIcons/CircleXIcon.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/AppIcons/CloseIcon.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/AppIcons/CopyIcon.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/AppIcons/EmailIcon.svg'
import { ReactComponent as EmailVerificationIcon } from 'assets/icons/AppIcons/EmailVerificationIcon.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/AppIcons/FilterIcon.svg'
import { ReactComponent as GoalsIcon } from 'assets/icons/AppIcons/GoalsIcon.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/AppIcons/HelpIcon.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/AppIcons/HomeIcon.svg'
import { ReactComponent as IndentArrowIcon } from 'assets/icons/AppIcons/IndentArrowIcon.svg'
import { ReactComponent as JackpotIcon } from 'assets/icons/AppIcons/JackpotIcon.svg'
import { ReactComponent as JackpotTicketIcon } from 'assets/icons/AppIcons/JackpotTicketIcon.svg'
import { ReactComponent as LockIcon } from 'assets/icons/AppIcons/LockIcon.svg'
import { ReactComponent as MonitorRedeemCheckIcon } from 'assets/icons/AppIcons/MonitorRedeemCheckIcon.svg'
import { ReactComponent as MonitorRedeemIcon } from 'assets/icons/AppIcons/MonitorRedeemIcon.svg'
import { ReactComponent as NavArrowLeftIcon } from 'assets/icons/AppIcons/NavArrowLeftIcon.svg'
import { ReactComponent as NavArrowRightIcon } from 'assets/icons/AppIcons/NavArrowRightIcon.svg'
import { ReactComponent as NewsIcon } from 'assets/icons/AppIcons/NewsIcon.svg'
import { ReactComponent as OnarollIcon } from 'assets/icons/AppIcons/OnarollIcon.svg'
import { ReactComponent as OnarollStarIcon } from 'assets/icons/AppIcons/OnarollStarIcon.svg'
import { ReactComponent as OnboardingCheckmarkIcon } from 'assets/icons/AppIcons/OnboardingCheckmarkIcon.svg'
import { ReactComponent as OpenedLockIcon } from 'assets/icons/AppIcons/OpenedLockIcon.svg'
import { ReactComponent as PerksIcon } from 'assets/icons/AppIcons/PerksIcon.svg'
import { ReactComponent as PhoneRedeemCheckIcon } from 'assets/icons/AppIcons/PhoneRedeemCheckIcon.svg'
import { ReactComponent as PhoneRedeemIcon } from 'assets/icons/AppIcons/PhoneRedeemIcon.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/AppIcons/SearchIcon.svg'
import { ReactComponent as SendIcon } from 'assets/icons/AppIcons/SendIcon.svg'
import { ReactComponent as StoreIcon } from 'assets/icons/AppIcons/StoreIcon.svg'
import { ReactComponent as StoreRedeemCheckIcon } from 'assets/icons/AppIcons/StoreRedeemCheckIcon.svg'
import { ReactComponent as StoreRedeemIcon } from 'assets/icons/AppIcons/StoreRedeemIcon.svg'
import { ReactComponent as VerifiedIcon } from 'assets/icons/AppIcons/VerifiedIcon.svg'

import { AppIconTypes } from 'types'

export type AppIconProps = SVGProps<SVGSVGElement> & { defaultFill?: string }

const getFill = (props: AppIconProps, fallBackColor = '#fff') =>
  props?.fill || props?.defaultFill || fallBackColor

const AppIconMap = {
  [AppIconTypes.addPerk]: (props: AppIconProps) => <AddPerkIcon {...props} />,
  [AppIconTypes.arrow]: (props: AppIconProps) => <ArrowIcon {...props} />,
  [AppIconTypes.avatar]: (props: AppIconProps) => <AvatarIcon {...props} />,
  [AppIconTypes.caretDown]: (props: AppIconProps) => <CaretDownIcon {...props} />,
  [AppIconTypes.caretLeft]: (props: AppIconProps) => <CaretLeftIcon {...props} />,
  [AppIconTypes.caretRight]: (props: AppIconProps) => <CaretRightIcon {...props} />,
  [AppIconTypes.caretUp]: (props: AppIconProps) => <CaretUpIcon {...props} />,
  [AppIconTypes.check]: (props: AppIconProps) => <CheckIcon {...props} />,
  [AppIconTypes.circleAlert]: (props: AppIconProps) => (
    <CircleAlertIcon {...props} fill={getFill(props, Colors.dmRed)} />
  ),
  [AppIconTypes.circleDashed]: (props: AppIconProps) => <CircleDashedIcon {...props} />,
  [AppIconTypes.circleInfo]: (props: AppIconProps) => <CircleInfoIcon {...props} />,
  [AppIconTypes.circleX]: (props: AppIconProps) => <CircleXIcon {...props} />,
  [AppIconTypes.close]: (props: AppIconProps) => <CloseIcon {...props} />,
  [AppIconTypes.copy]: (props: AppIconProps) => <CopyIcon {...props} />,
  [AppIconTypes.email]: (props: AppIconProps) => (
    <EmailIcon {...props} fill={getFill(props)} />
  ),
  [AppIconTypes.emailVerification]: (props: AppIconProps) => (
    <EmailVerificationIcon {...props} />
  ),
  [AppIconTypes.filter]: (props: AppIconProps) => <FilterIcon {...props} />,
  [AppIconTypes.goals]: (props: AppIconProps) => <GoalsIcon {...props} />,
  [AppIconTypes.help]: (props: AppIconProps) => <HelpIcon {...props} />,
  [AppIconTypes.home]: (props: AppIconProps) => <HomeIcon {...props} />,
  [AppIconTypes.indentArrow]: (props: AppIconProps) => <IndentArrowIcon {...props} />,
  [AppIconTypes.jackpot]: (props: AppIconProps) => <JackpotIcon {...props} />,
  [AppIconTypes.jackpotTicket]: (props: AppIconProps) => <JackpotTicketIcon {...props} />,
  [AppIconTypes.lock]: (props: AppIconProps) => <LockIcon {...props} />,
  [AppIconTypes.monitorRedeemCheck]: (props: AppIconProps) => (
    <MonitorRedeemCheckIcon {...props} />
  ),
  [AppIconTypes.monitorRedeem]: (props: AppIconProps) => <MonitorRedeemIcon {...props} />,
  [AppIconTypes.navArrowLeft]: (props: AppIconProps) => <NavArrowLeftIcon {...props} />,
  [AppIconTypes.navArrowRight]: (props: AppIconProps) => <NavArrowRightIcon {...props} />,
  [AppIconTypes.news]: (props: AppIconProps) => <NewsIcon {...props} />,
  [AppIconTypes.onaroll]: (props: AppIconProps) => <OnarollIcon {...props} />,
  [AppIconTypes.onarollStar]: (props: AppIconProps) => <OnarollStarIcon {...props} />,
  [AppIconTypes.onboardingCheckmark]: (props: AppIconProps) => (
    <OnboardingCheckmarkIcon {...props} />
  ),
  [AppIconTypes.openedLock]: (props: AppIconProps) => <OpenedLockIcon {...props} />,
  [AppIconTypes.perks]: (props: AppIconProps) => <PerksIcon {...props} />,
  [AppIconTypes.phoneRedeemCheck]: (props: AppIconProps) => (
    <PhoneRedeemCheckIcon {...props} />
  ),
  [AppIconTypes.phoneRedeem]: (props: AppIconProps) => <PhoneRedeemIcon {...props} />,
  [AppIconTypes.search]: (props: AppIconProps) => <SearchIcon {...props} />,
  [AppIconTypes.send]: (props: AppIconProps) => <SendIcon {...props} />,
  [AppIconTypes.store]: (props: AppIconProps) => <StoreIcon {...props} />,
  [AppIconTypes.storeRedeemCheck]: (props: AppIconProps) => (
    <StoreRedeemCheckIcon {...props} />
  ),
  [AppIconTypes.storeRedeem]: (props: AppIconProps) => <StoreRedeemIcon {...props} />,
  [AppIconTypes.verified]: (props: AppIconProps) => (
    // The trick is to set the `fill` of every nested path to `inherit` so that we can
    // control the fill through the parent svg via these props
    // TODO: Do the same for all the other app icons
    <VerifiedIcon {...props} fill={getFill(props, Colors.grayDisabled)} />
  )
}

export const AppIcon = (props: AppIconProps & { type: AppIconTypes }) => {
  const { type, ...restProps } = props
  return AppIconMap[type](restProps)
}

export default AppIcon
