import { Box, Tab, styled } from '@mui/material'

import { TabsClasses } from '../TabsClasses'

export const StyledTabHeader = styled(Tab)(({ theme }) => ({
  color: 'inherit',
  border: 'none',
  margin: 0,
  minHeight: '48px',
  '&:first-of-type': {
    margin: 0
  },
  '&.Mui-selected': {
    backgroundColor: theme.branding.accentColor,
    color: theme.palette.getContrastTextColor(theme.branding.accentColor),
    fontWeight: 'bold'
  },
  '&:last-of-type': {
    [`& + .${TabsClasses.HEADER_DIVIDER}`]: {
      display: 'none'
    }
  }
}))

export const StyledTabHeaderDivider = styled(Box)(({ theme }) => ({
  width: '2px',
  backgroundColor: theme.palette.colors.disabled,
  height: '22px',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 0
}))
