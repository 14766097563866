import { FormEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledQuestionForm,
  StyledQuestionFormButtonContainer,
  StyledQuestionFormButton
} from './questionFormContainer.styles'

interface QuestionFormContainerProps {
  children: ReactElement
  closeForm: () => void
  disabled?: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<boolean | undefined>
}
export default function QuestionFormContainer({
  children,
  closeForm,
  disabled = false,
  onSubmit
}: QuestionFormContainerProps) {
  const { t } = useTranslation()
  return (
    <StyledQuestionForm onSubmit={onSubmit} autoComplete="off">
      {children}
      <StyledQuestionFormButtonContainer>
        <StyledQuestionFormButton variant="outlined" color="primary" onClick={closeForm}>
          {t('onaroll.question.form.cancelButtonText')}
        </StyledQuestionFormButton>
        <StyledQuestionFormButton
          disabled={disabled}
          color="primary"
          variant="contained"
          type="submit"
        >
          {t('onaroll.question.form.submitButtonText')}
        </StyledQuestionFormButton>
      </StyledQuestionFormButtonContainer>
    </StyledQuestionForm>
  )
}
