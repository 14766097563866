import { Card, styled, Typography } from '@mui/material'

import { ReactComponent as CircleInfoIcon } from 'assets/icons/AppIcons/CircleInfoIcon.svg'

export const StyledNote = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: theme.borderRadius.small,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.branding.accentColor,
  padding: theme.spacing(3)
}))

export const StyledNoteMessage = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.getContrastTextColor(theme.branding.accentColor),
  ...theme.customTypography.body3Size
}))

export const StyledInfoIcon = styled(CircleInfoIcon)(({ theme }) => ({
  marginRight: theme.spacing(2),
  flexShrink: 0,
  '& path': {
    fill: theme.palette.getContrastTextColor(theme.branding.accentColor)
  }
}))
