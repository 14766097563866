import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsOnSmallDevice } from 'hooks/mediaQueries'

import { formatPhoneNumber } from 'utils/helpers'

import {
  disabledButtonStyles,
  StyledInputContainer,
  StyledNextButton,
  StyledPhoneContainer,
  StyledInput
} from '../styles'

export interface EmailInputProps {
  shouldShowError?: boolean
  loginError?: string | null
  value?: string
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function EmailInput({
  shouldShowError = false,
  value,
  loginError,
  handleChange
}: EmailInputProps) {
  const { t } = useTranslation()
  const isOnSmallDevice = useIsOnSmallDevice()
  const isDisabled = Boolean(loginError || !value || value?.length < 3)

  return (
    <StyledPhoneContainer isOnSmallDevice={isOnSmallDevice} error={shouldShowError}>
      <StyledInputContainer>
        <StyledInput
          type="email"
          placeholder={t('pages.login.form.emailInputPlaceholder')}
          onChange={handleChange}
          value={formatPhoneNumber(value ?? '')}
          autoFocus
          isOnSmallDevice={isOnSmallDevice}
          data-testid="EmailInput-Enabled"
        />
      </StyledInputContainer>

      <StyledNextButton
        variant="contained"
        fullWidth
        size={isOnSmallDevice ? 'small' : 'medium'}
        disabled={isDisabled}
        type="submit"
        style={isDisabled ? disabledButtonStyles : undefined}
        data-testid="NextButton"
      >
        {t('pages.login.form.enabled.nextButton')}
      </StyledNextButton>
    </StyledPhoneContainer>
  )
}
