import { styled } from '@mui/material/styles'

import { ReactComponent as ArrowIcon } from 'assets/icons/AppIcons/ArrowIcon.svg'

export const GoalCarouselContainer = styled('div')`
  overflow: hidden;
`
export const GoalCarouselSubtitleContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`
export const RightArrowIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`
export const CheckTodaysGoalsContainer = styled('div')`
  background: ${({ theme }) => theme.branding.cardColor};
  padding: ${({ theme }) => theme.spacing(3)};
  min-height: ${({ theme }) => `calc(48px + (2 * ${theme.spacing(3)}))`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
`

export const CheckTodaysGoalsTitle = styled('div')`
  flex: 1 1 0;
  margin-left: ${({ theme }) => theme.spacing(3)};
`
