import { styled, Typography } from '@mui/material'

import Input from 'components/Input'

export const InputsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSmallerThanTablet'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
  '.MuiFormControl-root:not(:last-of-type)': {
    marginBottom: theme.spacing(8)
  },
  'label+.MuiInput-root': {
    marginTop: theme.spacing(2.4)
  },
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    maxWidth: '80%'
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '50%'
  }
}))

export const StepTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  '&:first-of-type': {
    marginTop: theme.spacing(5)
  },
  '&:last-of-type': {
    marginBottom: theme.spacing(5)
  },
  color: theme.palette.colors.white,
  ...theme.customTypography.display2
}))

export const DateInput = styled(Input)<{ value: string | null }>(({ theme, value }) => ({
  color: value ? theme.palette.common.white : theme.palette.colors.placeholder
}))
