import { FooterRouteConfig } from '../footerRouteConfig'

export const getFooterPath = (config: FooterRouteConfig) => {
  if (config.path) {
    return config.path
  }
  return config.rootPathPrefix
}

export const findURLmatchingConfig = (
  config: FooterRouteConfig[],
  newURLPath: string
) => {
  return config.find((rc) => {
    if (rc.exact) {
      // Allows us to strictly match on a route and ignore child routes
      // Ex: Allows / (root/home page) to not match any routes that start with "/"
      return rc.rootPathPrefix === newURLPath
    }
    // Hightlight tab where the current url matches the root path
    // Ex: Allows /goals tab to be active when on child /goals/today route
    return newURLPath.includes(rc.rootPathPrefix)
  })
}
