import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StepContext } from 'pages/onboarding'
import {
  Step,
  StyledOnboardingStepBody,
  StyledOnboardingStepTitle
} from 'pages/onboarding/components'

import { OnboardingPerksGrid } from '.'
import { useTypographyVariantHook } from '..'

const StepPerks = () => {
  const { t } = useTranslation()
  const { isOnSmallDevice, isOnTinyDevice } = useContext(StepContext)
  const { titleVariant, bodyVariant } = useTypographyVariantHook()

  return (
    <Step>
      <StyledOnboardingStepTitle
        variant={titleVariant}
        isOnTinyDevice={isOnTinyDevice}
        data-testid="OnboardingPerkStep-Title"
      >
        {t('pages.onboarding.perkStep.title')}
      </StyledOnboardingStepTitle>
      <StyledOnboardingStepBody
        variant={bodyVariant}
        isOnSmallDevice={isOnSmallDevice}
        data-testid="OnboardingPerkStep-Body"
      >
        <Trans
          components={{ bold: <b /> }}
          i18nKey="pages.onboarding.perkStep.body"
          t={t}
        />
      </StyledOnboardingStepBody>
      <OnboardingPerksGrid />
    </Step>
  )
}

export default StepPerks
